import React, { useEffect, useRef, useState } from "react";
import { useGlobal } from "reactn";
import { Database, Storage } from "@ionic/storage";
import { Drivers } from "@ionic/storage";
import * as CordovaSQLiteDriver from "localforage-cordovasqlitedriver";
import axios from "axios";
import { useHistory } from "react-router";
import { isPlatform } from "@ionic/react";

export const Context = React.createContext<any>(undefined);

export const AuthProvider: React.FC = ({ children }) => {
  const [db, setDb] = useState<Database | null>(null);
  const isFirstRender = useRef(true);
  const isSecondRender = useRef(true);

  const [showTabBars, setShowTabBars] = useState(false);

  const history = useHistory();

  const [authValues, setAuthValues] = React.useState<any>({
    authenticated: false,
    user: null,
    userInfo: null,
    errors: null,
  });
  const [initialized, setInitialized] = React.useState<any>(false);

  const [language, setLanguage] = useGlobal("language");

  const [token, setToken] = React.useState<string>("");

  async function doLoginAsGuest() {
    await db.set("token", "");
    setToken("");
    await db.set("authValues", {
      authenticated: true,
      user: {
        id: "guest",
        email: "",
        phone: "",
        firstName: "Guest",
        lastName: "Mode",
        guide: false,
        admin: false,
        languages: {
          CS: 5,
          EN: 5,
        },
        partner: "",
        appLanguage: language.toUpperCase(),
        profileImage: null,
        coverImage: null,
        address: {
          type: "ADDRESS",
          latitude: null,
          longitude: null,
          airport: null,
          country: "",
          region: "",
          city: "",
          address: "",
          countryText: {
            CS: "Česko",
            EN: "Czech Republic",
          },
        },
        gender: "M",
        dateOfBirth: "2000-01-01",
      },
    });
    setAuthValues(
      {
        authenticated: true,
        user: {
          id: "guest",
          email: "",
          phone: "",
          firstName: "Guest",
          lastName: "Mode",
          guide: false,
          admin: false,
          languages: {
            CS: 5,
            EN: 5,
          },
          partner: "",
          appLanguage: language.toUpperCase(),
          profileImage: null,
          coverImage: null,
          address: {
            type: "ADDRESS",
            latitude: null,
            longitude: null,
            airport: null,
            country: "",
            region: "",
            city: "",
            address: "",
            countryText: {
              CS: "Česko",
              EN: "Czech Republic",
            },
          },
          gender: "M",
          dateOfBirth: "2000-01-01",
        },
      } /* ,
      history.push("/tab1") */
    );
    setInitialized(true);
  }

  useEffect(() => {
    async function fetchData() {
      const store = new Storage({
        name: "kangelo__db",
        driverOrder: [
          CordovaSQLiteDriver._driver,
          Drivers.IndexedDB,
          Drivers.LocalStorage,
        ],
      });
      await store.defineDriver(CordovaSQLiteDriver);
      const db = await store.create();
      setDb(db);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function doAuth() {
      let tempValues = await db.get("authValues");
      let tempToken = await db.get("token");
      await loadNewAdventure();
      if (tempValues === null) {
        if (!isPlatform("capacitor") && appWidth >900) {
          doLoginAsGuest();
        } else setInitialized(true);
        return;
      }
      if (tempValues.authenticated) {
        if (tempValues.user.id === "guest") {
          setAuthValues(tempValues);
          setInitialized(true)
        } else {
          setToken(tempToken);
          userData(tempToken);
        }
      } else {
        if (!isPlatform("capacitor") && appWidth > 900) {
          doLoginAsGuest();
        } else setInitialized(true);
      }
    }
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (isSecondRender.current) {
      isSecondRender.current = false;
      doAuth();
    }
  }, [db]);

  const [newAdventure, setNewAdventure] = useGlobal("newAdventure");
  const [newAdventureLang, setNewAdventureLang] = useGlobal("newAdventureLang");
  const [, setNewAdventureLangCurrent] = useGlobal("newAdventureLangCurrent");

  const [, setShowLoginPage] = useGlobal("showLoginPage");
  const [, setShowLoginModal] = useGlobal("showLoginModal");
  const [appWidth] = useGlobal("appWidth");


  var lang = require("./assets/language/" + language + ".json");
  lang = lang[0];

  const logout = async () => {
    setAuthValues({
      authenticated: false,
      user: null,
    });
    setToken("");
    await db.set("authValues", {
      authenticated: false,
      user: null,
    });
    await db.set("token", "");
    if (!isPlatform("capacitor") && appWidth > 900) {
      doLoginAsGuest();
    } 
  };

  /* const saveImageDataToCache = async (id: string, data: string) => {
    await db.set(id, { timeStamp: new Date(), imageData: data });
  }; */

  const userData = async (tokenos: string) => {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });

    var config = {
      headers: {
        "X-Auth-Token": tokenos,
      },
    };
    api
      .get("/user", config)
      .then(async (res) => {
        setLanguage(res.data.appLanguage.toLowerCase());
        setAuthValues({
          authenticated: true,
          user: res.data,
        });
        setInitialized(true);
        await db.set("authValues", {
          authenticated: true,
          user: res.data,
        });
        setShowLoginPage(false);
        setShowLoginModal(false);
      })
      .then(/* () => history.replace("/tab1") */)
      .catch((error) => {
        setInitialized(true);
        console.log(error);
      });
  };

  const checkImageCache = async (id: string) => {
    if (id === "") return false;
    const keys = await db.keys();
    return keys.includes(id);
  };

  const saveNewAdventure = async () => {
    await db.set("newAdventureSavedData", newAdventure);
    await db.set("newAdventureLangSavedData", newAdventureLang);
  };

  const loadNewAdventure = async () => {
    let loadedData: any = await db.get("newAdventureSavedData");

    if (loadedData !== null) {
      setNewAdventure(loadedData);
    }
    let loadedDataLang: any = await db.get("newAdventureLangSavedData");

    if (loadedDataLang !== null) {
      setNewAdventureLang(loadedDataLang);
      setNewAdventureLangCurrent(loadedDataLang[0]);
    }
  };

  const clearNewAdventure = async () => {
    await db.remove("newAdventureSavedData");
    await db.remove("newAdventureLangSavedData");
    setNewAdventureLang([""]);
    setNewAdventureLangCurrent("");
    setNewAdventure({
      image: "",
      images: [],
      difficulty: 0,
      meals: "",
      transit: "",
      accommodation: "",
      categories: [""],
      locations: [
        {
          type: "ADDRESS",
          latitude: null,
          longitude: null,
          airport: null,
          country: null,
          region: null,
          city: null,
          address: null,
          countryText: { CS: "", EN: "" },
        },
      ],
      departure: {
        type: "",
        latitude: null,
        longitude: null,
        airport: null,
        country: null,
        region: null,
        city: null,
        address: null,
        countryText: { CS: "", EN: "" },
      },
      arrival: {
        type: "",
        latitude: null,
        longitude: null,
        airport: null,
        country: null,
        region: null,
        city: null,
        address: null,
        countryText: { CS: "", EN: "" },
      },
      dates: [
        {
          begin: "",
          end: "",
          prices: {
            EUR: 0,
            CZK: 0,
          },
          capacity: 0,
        },
      ],
      itinerary: [
        {
          titleText: { CS: "", EN: "" },
          descriptionText: { CS: "", EN: "" },
          images: [],
        },
      ],
      descriptionText: { CS: "", EN: "" },
      includedText: { CS: null, EN: null },
      notIncludedText: { CS: null, EN: null },
      accommodationText: { CS: null, EN: null },
      mealsText: { CS: null, EN: null },
      transitText: { CS: null, EN: null },
      difficultyText: { CS: null, EN: null },
      moreInfoText: { CS: null, EN: null },
      nameText: {},
      documents: [],
      partner: null,
    });
  };

  let state = {
    authValues,
    initialized,
    logout,
    token,
    setToken,
    setAuthValues,
    lang,
    saveNewAdventure,
    loadNewAdventure,
    clearNewAdventure,
    db,
    checkImageCache,
    userData,
    showTabBars,
    setShowTabBars,
  };

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export default Context;
