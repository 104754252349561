import {
  IonTitle,
  IonItem,
  IonList,
  IonImg,
  IonText,
  IonLabel,
  IonCard,
  IonSpinner,
  IonAvatar,
  IonCardContent,
  IonChip,
  IonCol,
  IonFooter,
} from "@ionic/react";
/* import './Tab1.css'; */

import defaultAdvImage from "../../assets/images/default_adventure_image.jpg";

import React, { useState } from "react";
import axios from "axios";

import { useEffect, useGlobal } from "reactn";

import moment from "moment";

import AuthContext from "../../my-context";
import showImage from "../../functions/showImage";
import { useHistory } from "react-router";
import { Heart, UserCircle } from "phosphor-react";
import Rate from "rc-rate";
import setCountryName from "../../functions/setCountryName";
import setEnumText from "../../functions/setEnumText";
import setLike from "../../functions/setLike";
import checkProperty from "../../functions/checkProperty";

/* interface Props {
  advData: any;
} */

const MyTravel: React.FC /* <Props> */ = (/* { advData }: Props */) => {
  const { token, db, checkImageCache, lang, authValues } =
    React.useContext(AuthContext);
  const history = useHistory();

  const [userId] = useGlobal("userId");
  const [language] = useGlobal("language");
  const [, setAdventureId] = useGlobal("AdventrureId");

  const [userIcon, setUserIcon] = useState("");

  const [guideAdventures, setGuideAdventures] = useState([
    {
      id: "",
      image: "",
      imageData: "",
      locations: [{ country: "" }],
      categories: [""],
      isLiked: false,
      guide: { firstName: "", profileImage: "", profileImageData: "" },
      rating: 0,
      ratingCount: 0,
      difficulty: 0,
      nameText: { CS: "", EN: "" },
      dates: [{ begin: "", end: "" }],
    },
  ]);

  const [showLoading, setShowLoading] = useState(true);

  const [languages, setLanguages] = useState("");

  const getTripLength = (item: any) => {
    var start = moment(item.dates[0].begin, "YYYY-MM-DD");
    var end = moment(item.dates[0].end, "YYYY-MM-DD");
    var length = end.diff(start, "days") + 1;
    if (length === 1) {
      return "1 " + lang.label191;
    } else if (length < 5) {
      let message = length.toString() + " " + lang.label192;
      return message;
    } else {
      let message = length.toString() + " " + lang.label193;
      return message;
    }
  };

  const getLowestPrice = (item: any) => {
    let min = 100000000;
    for (var i = 0; i < item.dates.length; i++) {
      if (item.dates[i].prices.CZK < min) {
        min = item.dates[i].prices.CZK;
      }
    }
    let config = language;
    language === "cs" ? (config = config + "-CZ") : (config = config + "-GB");
    return (
      <div style={{ display: "inline-block" }}>
        {" "}
        • {min.toLocaleString(config) + " Kč"}
      </div>
    );
  };

  async function fetchData() {
    let help: any = [];
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {};
    if (authValues.user.id !== "guest") {
      config = {
        headers: {
          "X-Auth-Token": token,
        },
      };
    }
    api
      .get("/adventures/current", config)
      .then(async (res) => {
        help = res.data.data.filter(
          (d: any) => d.guide.id === authValues.user.id
        );
        Promise.all(
          help.map(async (item: any) => {
            item.imageData = await showImage(item.image, token);
            return item;
          })
        ).then(() => {
          setGuideAdventures(help);
          setShowLoading(false);
        });
      })
      .catch((err) => {
        console.log(err.data);
        console.log(err.status);
        console.log(err.statusText);
        console.log(err.headers);
        console.log(err.config);
        console.log(err);
      });
  }

  useEffect(() => {
    fetchData();
    async function getIcon() {
      let help = await db.get(authValues.user.profileImage);
      setUserIcon(help);
    }
    getIcon();
  }, []);

  return (
    <div>
      {guideAdventures.length > 0 &&
        guideAdventures.map((item, i) => {
          return (
            <IonCard key={`${i}`} className="adv-card">
              {showLoading ? (
                <IonSpinner className="spinner-loading2" color="primary" />
              ) : (
                <div>
                  <div className="card-cont">
                    <img
                      src={item.image !== "" ? item.imageData : defaultAdvImage}
                      className="adventure-cover"
                      alt="adventure background"
                      onClick={() => {
                        return (
                          setAdventureId(item.id),
                          history.push("/adventures/" + item.id)
                        );
                      }}
                    />

                    <IonChip className="adv-chip">
                      <IonLabel>
                        {setCountryName(item.locations[0].country, language)}
                      </IonLabel>
                    </IonChip>
                    {item.categories.map((category, i) => {
                      return (
                        <IonChip className={"adv-chip" + i} key={`${i}`}>
                          <IonLabel>{setEnumText(category, lang)}</IonLabel>
                        </IonChip>
                      );
                    })}
                    <IonChip
                      className="adv-like"
                      onClick={() => {
                        let itemsTemp = [...guideAdventures];
                        itemsTemp[i].isLiked = !item.isLiked;
                        setGuideAdventures(itemsTemp);
                        setLike(
                          item.id,
                          "adventures",
                          itemsTemp[i].isLiked,
                          token
                        );
                      }}
                    >
                      <Heart
                        className="heart-css"
                        size={24}
                        weight={item.isLiked ? "fill" : "duotone"}
                        color="#3fd0d4"
                      />
                    </IonChip>
                    {/* <IonChip
                  className="adv-partner"
                  onClick={() => {
                    setPartnerName(item.partner);
                    setShowModalPart(true);
                  }}
                >
                  <Handshake size={24} color="#3fd0d4" />
                </IonChip> */}
                    <IonChip className="adv-guide-chip">
                      <IonLabel>{item.guide.firstName}</IonLabel>
                    </IonChip>
                    <IonAvatar className="guide-avatar">
                      {userIcon === "" ? (
                        <UserCircle
                          size={78}
                          fill="black"
                          weight="fill"
                          style={{
                            marginLeft: "-7px",
                            marginTop: "-7px",
                          }}
                        />
                      ) : (
                        <img
                          src={userIcon}
                          alt="guide"
                          className="guide-avatar-img"
                        />
                      )}
                    </IonAvatar>
                  </div>
                  <IonCardContent style={{ paddingTop: "8px" }}>
                    <IonFooter>
                      {item.rating !== null && (
                        <div
                          style={{
                            display: "inline",
                            marginRight: "5px",
                          }}
                        >
                          <Rate
                            count={5}
                            value={item.rating}
                            allowHalf={true}
                            disabled={true}
                            className="rating-stars"
                          />{" "}
                          {item.rating} ({item.ratingCount})
                        </div>
                      )}
                      <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "top",
                          width: "30px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="20"
                          fill="rgb(63, 208, 212)"
                          viewBox="0 0 400 256"
                        >
                          <rect width="52" height="256" fill="none"></rect>
                          <line
                            x1="340"
                            y1="208"
                            x2="28"
                            y2="208"
                            fill="none"
                            stroke="rgb(63, 208, 212)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          ></line>
                          <rect
                            x="42"
                            y="160"
                            width="56"
                            height="48"
                            strokeWidth="16"
                            stroke="rgb(63, 208, 212)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill={item.difficulty >= 1 ? "true" : "none"}
                          ></rect>
                          <rect
                            x="100"
                            y="140"
                            width="56"
                            height="68"
                            strokeWidth="16"
                            stroke="rgb(63, 208, 212)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill={item.difficulty >= 2 ? "true" : "none"}
                          ></rect>
                          <rect
                            x="158"
                            y="120"
                            width="56"
                            height="88"
                            strokeWidth="16"
                            stroke="rgb(63, 208, 212)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill={item.difficulty >= 3 ? "true" : "none"}
                          ></rect>
                          <rect
                            x="216"
                            y="100"
                            width="56"
                            height="108"
                            strokeWidth="16"
                            stroke="rgb(63, 208, 212)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill={item.difficulty >= 4 ? "true" : "none"}
                          ></rect>
                          <rect
                            x="274"
                            y="80"
                            width="56"
                            height="128"
                            strokeWidth="16"
                            stroke="rgb(63, 208, 212)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill={item.difficulty >= 5 ? "true" : "none"}
                          ></rect>
                        </svg>
                      </div>
                      <IonCol>
                        {item.difficulty}
                        /5
                      </IonCol>
                    </IonFooter>
                    <IonFooter style={{ fontWeight: "bold", color: "black" }}>
                      {checkProperty(item.nameText, language)}
                    </IonFooter>
                    <IonFooter style={{ fontSize: "13px" }}>
                      {moment(item.dates[0].begin, "YYYY-MM-DD").format(
                        "DD.MM."
                      )}{" "}
                      -{" "}
                      {moment(item.dates[0].end, "YYYY-MM-DD").format(
                        "DD.MM.YYYY"
                      )}{" "}
                      • {item.dates.length}{" "}
                      {item.dates.length > 1 ? lang.label194 : lang.label195} •{" "}
                      {getTripLength(item)} {getLowestPrice(item)}
                    </IonFooter>
                  </IonCardContent>
                </div>
              )}
            </IonCard>
          );
        })}
      {guideAdventures.length === 0 && (
        <IonItem
          className="edit-my-story-area no-border"
          /*         style={{ margin: "0 25px", width: "100vw" }} */
        >
          <IonText
            style={{
              fontSize: "12px",
              display: "block",
              width: "90vw",
              padding: "0 15px",
              whiteSpace: "pre-line",
            }}
          >
            {lang.label414}
          </IonText>
        </IonItem>
      )}
    </div>
  );
};

export default MyTravel;
