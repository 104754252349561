import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonFooter,
  IonImg,
  IonGrid,
  IonRow,
  IonBackButton,
  IonText,
  IonChip,
  IonLabel,
  IonSpinner,
  IonAvatar,
  IonCol,
  IonModal,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from "@ionic/react";

import "./Tab1.css";

import "./adventure.css";
import "./userpage.css";

import defaultAdvImage from "../assets/images/default_adventure_image.jpg";

import axios from "axios";

import moment from "moment";

import AuthContext from "../my-context";
import React, { useRef, useState } from "react";
import { useHistory, withRouter } from "react-router";

import { useEffect, useGlobal } from "reactn";

import dumb_photo_guide_cover from "../assets/images/dumb_photo_guide_cover.jpg";

import { Heart, Translate, UserCircle } from "phosphor-react";

import showImage from "../functions/showImage";
import setCountryName from "../functions/setCountryName";
import setEnumText from "../functions/setEnumText";
import setLike from "../functions/setLike";
import Rate from "rc-rate";
import checkProperty from "../functions/checkProperty";
import Header from "./header";

interface RouterPropsCustom {
  props: { match: { params: { userId: string } } };
}

const Userpage: React.FC = (props: any) => {
  const { token, db, checkImageCache, lang, authValues } =
    React.useContext(AuthContext);

  const history = useHistory();
  const [appWidth] = useGlobal("appWidth");

  const [userId, setUserId] = useGlobal("userId");
  const [language] = useGlobal("language");
  const [, setAdventureId] = useGlobal("AdventrureId");

  const [userInfo, setUserInfo] = useState({
    id: "",
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
    guide: false,
    admin: false,
    isLiked: false,
    languages: {
      en: 1,
    },
    partner: "",
    appLanguage: "CS",
    profileImage: "",
    myStoryText: { CS: "" },
    coverImage: null,
    address: {
      type: "ADDRESS",
      latitude: null,
      longitude: null,
      airport: null,
      country: "",
      region: "",
      city: "",
      address: "",
      countryText: {
        CS: "",
        EN: "",
      },
    },
    gender: "X",
    dateOfBirth: "",
    success: false,
  });

  const [guideAdventures, setGuideAdventures] = useState([
    {
      id: "",
      image: "",
      imageData: "",
      locations: [{ country: "" }],
      categories: [""],
      isLiked: false,
      guide: { firstName: "", profileImage: "", profileImageData: "" },
      rating: 0,
      ratingCount: 0,
      difficulty: 0,
      nameText: { CS: "", EN: "" },
      dates: [{ begin: "", end: "" }],
    },
  ]);

  const [showLoading, setShowLoading] = useState(true);
  const [showLoading2, setShowLoading2] = useState(true);

  const [showModalLanguages, setShowModalLanguages] = useState(false);

  const [newCoverImage, setNewCoverImage] = useState<string | undefined>("");

  const [userIcon, setUserIcon] = useState("");

  const [languages, setLanguages] = useState("");
  const [languagesShort, setLanguagesShort] = useState("");

  async function getImageData() {
    let dataTemp = "";
    if (await checkImageCache(userInfo.coverImage)) {
      dataTemp = await db.get(userInfo.coverImage);
    } else {
      if (userInfo.coverImage !== "" && userInfo.coverImage !== null) {
        dataTemp = await showImage(userInfo.coverImage, token);
      } else dataTemp = "";
    }
    setNewCoverImage(dataTemp);
  }

  const getTripLength = (item: any) => {
    var start = moment(item.dates[0].begin, "YYYY-MM-DD");
    var end = moment(item.dates[0].end, "YYYY-MM-DD");
    var length = end.diff(start, "days") + 1;
    if (length === 1) {
      return "1 " + lang.label191;
    } else if (length < 5) {
      let message = length.toString() + " " + lang.label192;
      return message;
    } else {
      let message = length.toString() + " " + lang.label193;
      return message;
    }
  };

  const getLowestPrice = (item: any) => {
    let min = 100000000;
    for (var i = 0; i < item.dates.length; i++) {
      if (item.dates[i].prices.CZK < min) {
        min = item.dates[i].prices.CZK;
      }
    }
    let config = language;
    language === "cs" ? (config = config + "-CZ") : (config = config + "-GB");
    return (
      <div style={{ display: "inline-block" }}>
        {" "}
        • {min.toLocaleString(config) + " Kč"}
      </div>
    );
  };

  async function getUserInfo(id: string) {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .get("/user/" + id, config)
      .then(async (res) => {
        console.log(id);
        setUserInfo(res.data);
        if (res.data.guide) {
          let tempik = await showImage(res.data.profileImage, token);
          setUserIcon(tempik);
        }
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  function showRestLangCounter() {
    let count = languages.split(",").length - 3;
    let textString = count < 5 ? lang.label430 : lang.label429;
    if (count > 0) {
      let returnString = " +" + count + " " + textString;
      return returnString;
    } else {
      return "";
    }
  }

  async function fetchData() {
    let help: any = [];
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {};
    if (authValues.user.id !== "guest") {
      config = {
        headers: {
          "X-Auth-Token": token,
        },
      };
    }
    api
      .get("/adventures/current", config)
      .then(async (res) => {
        help = res.data.data.filter(
          (d: any) => d.guide.id === props.match.params.userId
        );
        Promise.all(
          help.map(async (item: any) => {
            item.imageData = await showImage(item.image, token);
            return item;
          })
        ).then(() => {
          setGuideAdventures(help);
          setShowLoading(false);
        });
      })
      .catch((err) => {
        console.log(err.data);
        console.log(err.status);
        console.log(err.statusText);
        console.log(err.headers);
        console.log(err.config);
        console.log(err);
      });
  }

  useEffect(() => {
    if (userInfo.id !== "") {
      if (!userInfo.languages) return;
      let langJSON = require("../assets/language/languages.json");
      let langKeys = Object.keys(userInfo.languages);
      langKeys.forEach((key, index) => {
        langKeys[index] = langJSON[0][key][language];
      });

      let tempText = "";
      let tempTextShort = "";
      for (var i = 0; i < langKeys.length; i++) {
        if (i === 0) {
          tempText = langKeys[i];
          tempTextShort = langKeys[i];
        } else {
          tempText = tempText + ", " + langKeys[i];
          if (i < 3) {
            tempTextShort = tempTextShort + ", " + langKeys[i];
          }
        }
      }
      setLanguages(tempText);
      setLanguagesShort(tempTextShort);
      getImageData().then(() => setShowLoading2(false));
    } else return;
  }, [userInfo]);

  useEffect(() => {
    /* console.log(props.match.params.userId); */
    /* console.log(props);
    getUserInfo(userId);
    fetchData(); */
    /* getUserInfo(props.match.params.userId);
    setUserId(props.match.params.userId);
    fetchData(); */
  }, []);

  useIonViewDidEnter(() => {
    setShowLoading(true);
    setShowLoading2(true);
    getUserInfo(window.location.pathname.split("/")[2]);
    setUserId(window.location.pathname.split("/")[2]);
    fetchData();
  });

  return showLoading2 ? (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="back-butt web-box">
        <IonToolbar className="back-butt">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tab1"
              className="back-button"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSpinner
          className="spinner-loading"
          color="primary"
          style={{ marginTop: "10vh" }}
        />
        <IonText className="text-center" style={{ display: "block" }}>
          {lang.label222}
        </IonText>
      </IonContent>
    </IonPage>
  ) : (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonContent scrollY={true} className="adventure-content">
        <div className="web-box" style={{ position: "relative" }}>
          <IonHeader
            className="back-butt web-box"
            style={{ position: "absolute" }}
          >
            <IonToolbar className="back-butt" style={{ position: "relative" }}>
              <IonButtons
                slot="start"
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <IonBackButton defaultHref="/tab1" />
                {userInfo.guide && (
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      position: "absolute",
                      right: "15px",
                    }}
                  >
                    <IonChip className="guide-chip-inner">
                      <IonLabel>{lang.label197}</IonLabel>
                    </IonChip>
                    <Heart
                      size={24}
                      color="#3FD0D4"
                      weight={userInfo.isLiked ? "fill" : "duotone"}
                      onClick={() => {
                        let advUserInfoTemp = { ...userInfo };
                        advUserInfoTemp.isLiked = !advUserInfoTemp.isLiked;
                        setUserInfo(advUserInfoTemp);
                        setLike(
                          advUserInfoTemp.id,
                          "users",
                          advUserInfoTemp.isLiked,
                          token
                        );
                      }}
                    />
                  </div>
                )}
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonImg
            className="adv-img adventure-slides"
            style={{
              objectFit: "cover",
              width: "100vw",
            }}
            src={newCoverImage === "" ? dumb_photo_guide_cover : newCoverImage}
          ></IonImg>
          <IonCard
            style={{
              marginInline: "0",
              borderRadius: "25px",
              marginBlockStart: "-40px",
            }}
          >
            <IonCardContent>
              <IonHeader style={{ height: "35px" }}>
                <IonGrid>
                  <IonRow
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h1 style={{ color: "black" }}>{userInfo.firstName}</h1>
                    {/* <div style={{ float: "right", display: "inline-flex" }}>
                  <Medal
                    size={21}
                    color="#3FD0D4"
                    style={{ margin: "0 10 0 10" }}
                  />{" "}
                  <Heart size={21} color="#3FD0D4" />
                </div> */}
                  </IonRow>
                </IonGrid>
              </IonHeader>
              <IonFooter className="user-card-footer">
                <IonGrid>
                  {/* <IonRow style={{ lineHeight: "25px", height: "25px" }}>
                <MapPin
                  size={21}
                  style={{ verticalAlign: "middle", marginRight: "5px" }}
                  color="#3FD0D4"
                />
                Praha, Česká republika
                
              </IonRow> */}
                  <IonRow
                    style={{ lineHeight: "25px", height: "25px" }}
                    onClick={() =>
                      languages.split(",").length > 3
                        ? setShowModalLanguages(true)
                        : ""
                    }
                  >
                    <Translate
                      size={21}
                      style={{ verticalAlign: "middle", marginRight: "5px" }}
                      color="#3FD0D4"
                    />
                    {languages !== "" ? languagesShort : lang.label332}{" "}
                    {showRestLangCounter()}
                  </IonRow>
                </IonGrid>

                {/* <div style={{ float: "right" }}>
              {advData.rating} ({advData.ratingCount})
            </div> */}
              </IonFooter>
            </IonCardContent>
          </IonCard>

          <IonText
            style={{
              margin: "5px 20px 0 20px",
              display: "block",
              fontSize: "16px",
              whiteSpace: "pre-line",
            }}
          >
            {userInfo?.myStoryText?.CS}
          </IonText>
          {userInfo.guide && (
            <div className="text-center">
              <IonText
                style={{
                  display: "block",
                  margin: "10px 20px 0 20px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textAlign: "left",
                }}
              >
                {lang.label21} {lang.label238.toLowerCase()}{" "}
                {userInfo.firstName}
              </IonText>
              {guideAdventures.map((item, i) => {
                return (
                  <IonCard
                    key={`${i}`}
                    className="adventures-card"
                    style={{ minWidth: "240px" }}
                  >
                    {showLoading ? (
                      <IonSpinner
                        className="spinner-loading2"
                        color="primary"
                      />
                    ) : (
                      <div>
                        <div className="card-cont">
                          <img
                            src={
                              item.image !== ""
                                ? item.imageData
                                : defaultAdvImage
                            }
                            className="adventure-cover"
                            alt="adventure background"
                            onClick={() => {
                              return (
                                setAdventureId(item.id),
                                history.push("/adventures/" + item.id)
                              );
                            }}
                          />

                          <IonChip className="adv-chip">
                            <IonLabel>
                              {setCountryName(
                                item.locations[0].country,
                                language
                              )}
                            </IonLabel>
                          </IonChip>
                          {item.categories.map((category, i) => {
                            return (
                              <IonChip className={"adv-chip" + i} key={`${i}`}>
                                <IonLabel>
                                  {setEnumText(category, lang)}
                                </IonLabel>
                              </IonChip>
                            );
                          })}
                          <IonChip
                            className="adv-like"
                            onClick={() => {
                              let itemsTemp = [...guideAdventures];
                              itemsTemp[i].isLiked = !item.isLiked;
                              setGuideAdventures(itemsTemp);
                              setLike(
                                item.id,
                                "adventures",
                                itemsTemp[i].isLiked,
                                token
                              );
                            }}
                          >
                            <Heart
                              className="heart-css"
                              size={24}
                              weight={item.isLiked ? "fill" : "duotone"}
                              color="#3fd0d4"
                            />
                          </IonChip>
                          {/* <IonChip
                  className="adv-partner"
                  onClick={() => {
                    setPartnerName(item.partner);
                    setShowModalPart(true);
                  }}
                >
                  <Handshake size={24} color="#3fd0d4" />
                </IonChip> */}
                          <IonChip className="adv-guide-chip">
                            <IonLabel>{item.guide.firstName}</IonLabel>
                          </IonChip>
                          <IonAvatar className="guide-avatar">
                            {userIcon === "" || userIcon === undefined ? (
                              <UserCircle
                                size={78}
                                fill="black"
                                weight="fill"
                                style={{
                                  marginLeft: "-7px",
                                  marginTop: "-7px",
                                }}
                              />
                            ) : (
                              <img
                                src={userIcon}
                                alt="guide"
                                className="guide-avatar-img"
                              />
                            )}
                          </IonAvatar>
                        </div>
                        <IonCardContent style={{ paddingTop: "8px" }}>
                          <IonFooter>
                            {item.rating !== null && (
                              <div
                                style={{
                                  display: "inline",
                                  marginRight: "5px",
                                }}
                              >
                                <Rate
                                  count={5}
                                  value={item.rating}
                                  allowHalf={true}
                                  disabled={true}
                                  className="rating-stars"
                                />{" "}
                                {item.rating} ({item.ratingCount})
                              </div>
                            )}
                            <div
                              style={{
                                display: "inline-block",
                                verticalAlign: "top",
                                width: "30px",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="20"
                                fill="rgb(63, 208, 212)"
                                viewBox="0 0 400 256"
                              >
                                <rect
                                  width="52"
                                  height="256"
                                  fill="none"
                                ></rect>
                                <line
                                  x1="340"
                                  y1="208"
                                  x2="28"
                                  y2="208"
                                  fill="none"
                                  stroke="rgb(63, 208, 212)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="16"
                                ></line>
                                <rect
                                  x="42"
                                  y="160"
                                  width="56"
                                  height="48"
                                  strokeWidth="16"
                                  stroke="rgb(63, 208, 212)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  fill={item.difficulty >= 1 ? "true" : "none"}
                                ></rect>
                                <rect
                                  x="100"
                                  y="140"
                                  width="56"
                                  height="68"
                                  strokeWidth="16"
                                  stroke="rgb(63, 208, 212)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  fill={item.difficulty >= 2 ? "true" : "none"}
                                ></rect>
                                <rect
                                  x="158"
                                  y="120"
                                  width="56"
                                  height="88"
                                  strokeWidth="16"
                                  stroke="rgb(63, 208, 212)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  fill={item.difficulty >= 3 ? "true" : "none"}
                                ></rect>
                                <rect
                                  x="216"
                                  y="100"
                                  width="56"
                                  height="108"
                                  strokeWidth="16"
                                  stroke="rgb(63, 208, 212)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  fill={item.difficulty >= 4 ? "true" : "none"}
                                ></rect>
                                <rect
                                  x="274"
                                  y="80"
                                  width="56"
                                  height="128"
                                  strokeWidth="16"
                                  stroke="rgb(63, 208, 212)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  fill={item.difficulty >= 5 ? "true" : "none"}
                                ></rect>
                              </svg>
                            </div>
                            <IonCol>
                              {item.difficulty}
                              /5
                            </IonCol>
                          </IonFooter>
                          <IonFooter
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            {checkProperty(item.nameText, language)}
                          </IonFooter>
                          <IonFooter style={{ fontSize: "13px" }}>
                            {moment(item.dates[0].begin, "YYYY-MM-DD").format(
                              "DD.MM."
                            )}{" "}
                            -{" "}
                            {moment(item.dates[0].end, "YYYY-MM-DD").format(
                              "DD.MM.YYYY"
                            )}{" "}
                            • {item.dates.length}{" "}
                            {item.dates.length > 1
                              ? lang.label194
                              : lang.label195}{" "}
                            • {getTripLength(item)} {getLowestPrice(item)}
                          </IonFooter>
                        </IonCardContent>
                      </div>
                    )}
                  </IonCard>
                );
              })}
            </div>
          )}
        </div>
      </IonContent>

      <IonModal
        isOpen={showModalLanguages}
        cssClass="part-modal"
        onDidDismiss={() => setShowModalLanguages(false)}
      >
        <div style={{ height: "100%" }}>
          <IonText style={{ display: "block", marginTop: "10px" }}>
            {languages}
          </IonText>
        </div>
      </IonModal>
    </IonPage>
  );
};

export default withRouter(Userpage);
