import {
  IonTitle,
  IonItem,
  IonList,
  IonImg,
  IonText,
  IonGrid,
  IonThumbnail,
} from "@ionic/react";
/* import './Tab1.css'; */

import React from "react";
import { useGlobal } from "reactn";
import checkProperty from "../../functions/checkProperty";

interface Props {
  advData: any;
}

const AdvItinerary: React.FC<Props> = ({ advData }: Props) => {
  const [language] = useGlobal("language");
  return (
    <div style={{ width: "100%" }}>
      <IonList style={{ paddingTop: 0 }}>
        {advData.itinerary.length === 0 && (
          <IonTitle>Itinerář není vyplněn.</IonTitle>
        )}
        {advData.itinerary.map((item: any, index: number) => {
          return (
            <IonItem
              key={index}
              style={{
                "--padding-start": "20px",
                "--padding-end": "20px",
                "--inner-padding-end": "0px",
              }}
              className="no-border"
            >
              <IonList className="itinerary-list">
                <IonItem className="no-border itenerary-item">
                  <IonTitle
                    className="text-bold"
                    style={{
                      fontSize: "16px",
                      paddingTop: "5px",
                      paddingLeft: 0,
                    }}
                  >
                    {checkProperty(item.titleText, language)}
                  </IonTitle>
                </IonItem>
                <IonItem
                  className="no-border itenerary-item"
                  style={{ "--min-height": "auto" }}
                >
                  <IonText
                    style={{
                      whiteSpace: "pre-line",
                      width: "100%",
                      userSelect: "auto",
                    }}
                  >
                    {checkProperty(item.descriptionText, language)}
                  </IonText>
                </IonItem>
                {item.images.length > 0 && (
                  <IonItem
                    className="no-border itenerary-item"
                    style={{ marginTop: "5px" }}
                  >
                    <IonGrid style={{ padding: 0 }}>
                      {item.images.map((image: string, indexis: number) => (
                        <IonThumbnail key={indexis} className="iti-images">
                          <IonImg
                            src={image}
                            style={{
                              margin: "5px 0",
                            }}
                          ></IonImg>
                        </IonThumbnail>
                      ))}
                    </IonGrid>
                  </IonItem>
                )}
              </IonList>
            </IonItem>
          );
        })}
      </IonList>
    </div>
  );
};

export default AdvItinerary;
