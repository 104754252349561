import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButton,
  IonLabel,
  IonInput,
  IonItem,
  IonLoading,
  IonToast,
  IonList,
  IonImg,
  IonSelect,
  IonSelectOption,
  IonRow,
  IonModal,
  IonText,
  IonThumbnail,
  IonIcon,
  IonButtons,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";

import logo from "../assets/images/kangelo.png";

import AuthContext from "../my-context";
import { useHistory } from "react-router";
import axios from "axios";
import { useGlobal } from "reactn";
import { ArrowLeft, Eye, EyeSlash } from "phosphor-react";
import { languageOutline, languageSharp } from "ionicons/icons";

interface Props {
  login: ({ user, password }: { user: string; password: string }) => void;
}

export const CreateAccountPage: React.FC<Props> = (props: Props) => {
  const {
    authValues: { user, errors },
    lang,
  } = React.useContext(AuthContext);

  async function fetchPrivacy() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: { "Accept-Language": language },
    };
    api
      .get("/legal/terms", config)
      .then((resp) => {
        setTermsData(resp.data.data);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
    api
      .get("/legal/privacy", config)
      .then((respon) => {
        setPrivacyData(respon.data.data);
      })
      .catch((error) => {
        // what now?
        console.log(error.data);
      });
  }

  function validateEmail(email: string) {
    const res =
      //eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  }

  const [termsData, setTermsData] = useState("");
  const [privacyData, setPrivacyData] = useState("");

  //input form
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (showPassword) {
      setTimeout(() => setShowPassword(false), 3000);
    }
  }, [showPassword]);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  const [language, setLanguage] = useGlobal("language");

  //global modal close
  const [, setShowModal3] = useGlobal("showCreateAcc");

  const [termsModal, setTermsModal] = useState(false);

  //loading
  const [showLoading, setShowLoading] = useState(false);

  //error screen
  const [showErrors, setShowErrors] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [segment, setSegment] = useState<any>("OP");

  const history = useHistory();

  const createAccount = () => {
    const newUser = {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      appLanguage: language.toUpperCase(),
    };

    const api = axios.create({
      baseURL: `https://app.kangelo.com`,
    });
    api
      .post("/register", newUser)
      .then((res) => {
        setShowLoading(false);
        if (res.data.success) {
          props.login({ user: email, password: password });
        } else {
          setToastMessage(res.data.message);
          setShowToast(true);
        }
      })
      .catch((error) => {
        setToastMessage(error.message);
        setShowToast(true);
        setShowLoading(false);
        console.log(error.message);
      });
  };

  const doCreateAccount = async () => {
    if (
      /* phone === "" || */
      firstName === "" ||
      lastName === "" ||
      password === "" ||
      email === ""
    ) {
      setToastMessage(lang.label233);
      setShowToast(true);
    } else if (!validateEmail(email)) {
      setToastMessage(lang.label186);
      setShowToast(true);
    } else if (password.length < 8) {
      setToastMessage(lang.label185);
      setShowToast(true);
    } else if (
      validateEmail(email) &&
      /* phone !== "" && */
      firstName !== "" &&
      lastName !== "" &&
      password !== ""
    ) {
      setShowLoading(true);
      createAccount();
    }
  };

  return (
    <IonPage>
      <IonHeader className="back-butt safe-zone-header" style={{ zIndex: 2 }}>
        <IonButton
          className="back-butt test"
          style={{ zIndex: 1 }}
          onClick={() => {
            setShowModal3(false);
          }}
        >
          <ArrowLeft size={28} />
        </IonButton>
      </IonHeader>
      <IonContent className="ion-padding" fullscreen style={{ zIndex: 0 }}>
        <IonLoading
          message="Creating User"
          isOpen={showLoading}
          /* onDidDismiss={() => user != null ?? history.replace("/tab1")} */
        />
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            height: "100%",
          }}
        >
          <IonRow
            style={{
              justifyContent: "center",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IonThumbnail
              style={{
                height: "auto",
                width: "25vw",
                maxWidth: "100px",
                position: "relative",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <IonImg
                src={logo}
                style={{
                  height: "100%",
                  maxHeight: "100px",
                }}
              />
            </IonThumbnail>
          </IonRow>
          {/* <IonRow style={{ justifyContent: "center", marginTop: "15px" }}>
            Kangelo Travel Club
          </IonRow>
          <IonRow
            style={{
              justifyContent: "center",
              marginTop: "15px",
              marginBottom: "18px",
            }}
          >
            {lang.label1}
          </IonRow> */}
        </div>
      </IonContent>
      <IonToolbar
        className="ion-padding"
        style={{
          background: "rgba(255, 255, 255, 0.63)",
          height: "auto",
          borderRadius: "25px 25px 0 0",
          display: "block",
          padding: 0,
          overflow: "auto",
          zIndex: 1,
        }}
        slot="bottom"
      >
        <IonList className="list-transparent">
          <IonItem className="item-lang no-border">
            <IonIcon ios={languageOutline} md={languageSharp}></IonIcon>
            <IonLabel
              /* position="fixed" */ style={{
                maxWidth: "fit-content",
                marginLeft: "5px",
              }}
            >
              {lang.label106}
            </IonLabel>
            <IonSelect
              value={language}
              onIonChange={(e) => setLanguage(e.detail.value)}
              style={{ background: "transparent", maxWidth: "fit-content" }}
            >
              <IonSelectOption value="cs">Čeština</IonSelectOption>
              <IonSelectOption value="en">English</IonSelectOption>
            </IonSelect>
          </IonItem>

          <IonItem className="item-transparent no-border">
            <IonLabel position="floating">E-mail *</IonLabel>
            <IonInput
              style={{ maxHeight: "25px", padding: 0 }}
              type="email"
              name="email"
              onIonChange={(e) => setEmail(e.detail.value as any)}
            />
          </IonItem>
          <IonItem className="item-transparent no-border">
            <IonLabel position="floating">{lang.label49} *</IonLabel>
            <IonInput
              style={{ maxHeight: "25px", padding: 0 }}
              type="text"
              name="firstName"
              onIonChange={(e) => setFirstName(e.detail.value as any)}
            />
          </IonItem>
          <IonItem className="item-transparent no-border">
            <IonLabel position="floating">{lang.label50} *</IonLabel>
            <IonInput
              style={{ maxHeight: "25px", padding: 0 }}
              type="text"
              name="surname"
              onIonChange={(e) => setLastName(e.detail.value as any)}
            />
          </IonItem>
          <IonItem className="item-transparent no-border">
            <IonLabel position="floating">{lang.label57}</IonLabel>
            <IonInput
              style={{ maxHeight: "25px", padding: 0 }}
              type="tel"
              name="phone"
              inputmode="tel"
              onIonChange={(e) => {
                setPhone(e.detail.value as any);
              }}
            />
          </IonItem>
          <IonItem className="item-transparent no-border">
            <IonLabel position="floating">{lang.label159} *</IonLabel>
            <IonInput
              style={{ maxHeight: "25px", padding: 0 }}
              type={showPassword ? "text" : "password"}
              name="password"
              clearOnEdit={false}
              onIonChange={(e) => setPassword(e.detail.value as any)}
            />
            {showPassword
              ? password.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      right: "15px",
                      bottom: "8px",
                      zIndex: 10,
                      width: "24px",
                      height: "24px",
                      paddingTop: "2px",
                    }}
                  >
                    <Eye
                      size={20}
                      onClick={() => setShowPassword((state) => !state)}
                    />
                  </div>
                )
              : password.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      right: "15px",
                      bottom: "8px",
                      zIndex: 10,
                      width: "24px",
                      height: "24px",
                      paddingTop: "2px",
                    }}
                  >
                    <EyeSlash
                      size={20}
                      onClick={() => setShowPassword((state) => !state)}
                    />
                  </div>
                )}
          </IonItem>
        </IonList>
        <IonButton className="sign-up-butt" onClick={() => doCreateAccount()}>
          {lang.label161}
        </IonButton>
        <p
          onClick={() => {
            fetchPrivacy();
            setTermsModal(true);
          }}
          style={{
            textAlign: "center",
            fontSize: "10px",
            textDecoration: "underline",
          }}
        >
          {lang.label6}
        </p>
      </IonToolbar>
      <IonToast
        isOpen={showErrors}
        message={errors?.message}
        onDidDismiss={() => setShowErrors(false)}
        duration={2000}
        color="danger"
      ></IonToast>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={1200}
        color="danger"
        cssClass="toast-pass-change"
      />
      <IonModal
        isOpen={termsModal}
        /* cssClass="modal-fullscreen" */
        backdropDismiss
        onDidDismiss={() => {
          setTermsModal(false);
        }}
      >
        <IonHeader
          /* className="main-header-search safe-zone-header" */ style={{
            borderBottom: "1px solid #DEDEDE",
          }}
        >
          <IonToolbar>
            <IonButtons>
              <IonButton
                onClick={() => setTermsModal(false)}
                className="modal-search-back"
              >
                <ArrowLeft size={24} color="black" />
              </IonButton>
              <IonSegment
                value={segment}
                onIonChange={(e) => setSegment(e.detail.value)}
              >
                <IonSegmentButton value="OP">
                  <IonLabel>{lang.label455}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="GDPR">
                  <IonLabel>{lang.label456}</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem className="legal-item">
            <div
              style={{ width: "100%" }}
              dangerouslySetInnerHTML={{
                __html: segment === "OP" ? termsData : privacyData,
              }}
            />
          </IonItem>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};
