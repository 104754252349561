import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonFooter,
  IonAvatar,
  IonChip,
  IonLabel,
  IonModal,
  IonText,
  IonBackButton,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import "./Tab1.css";

import axios from "axios";

import moment from "moment";

import Rate from "rc-rate";
import "rc-rate/assets/index.css";

import AuthContext from "../my-context";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { useGlobal } from "reactn";
import { Heart, Handshake } from "phosphor-react";
import { nanoid } from "nanoid";
import TravelAdventures from "./Travellist/TravelAdventures";
import TravelGuide from "./Travellist/TravelGuide";
import showImage from "../functions/showImage";
import Header from "./header";

const Travellist: React.FC = () => {
  const isFirstRender = useRef(true);

  const history = useHistory();

  const [, setAdventureId] = useGlobal("AdventrureId");
  const [appWidth] = useGlobal("appWidth");

  const { token, lang, db, checkImageCache } = React.useContext(AuthContext);

  const [showModalPart, setShowModalPart] = useState(false); //partner modal
  const [partnerName, setPartnerName] = useState("");

  const [segment, setSegment] = useState<string | undefined>("default");

  const empty = [
    {
      id: "",
      image: "",
      imageData: "",
      rating: 0,
      ratingCount: 0,
      difficulty: 0,
      isLiked: false,
      guide: {
        id: "",
        firstName: "",
        lastName: "",
        profileImage: "",
        profileImageData: "",
        guide: false,
        admin: false,
      },
      meals: "",
      transit: "",
      accommodation: "",
      categories: [""],
      country: "",
      locations: [
        {
          type: "",
          latitude: "",
          longitude: "",
          airport: "",
          country: "",
          continent: "",
          region: "",
          city: "",
          address: "",
          countryText: {
            CS: "",
            EN: "",
          },
        },
      ],
      departure: {
        type: "",
        latitude: "",
        longitude: "",
        airport: "",
        country: "",
        city: "",
        address: "",
        countryText: "",
      },
      arrival: {
        type: "",
        latitude: "",
        longitude: "",
        airport: "",
        country: "",
        city: "",
        address: "",
        countryText: "",
      },
      dates: [
        {
          id: "",
          begin: "",
          end: "",
          prices: {
            EUR: 0,
            CZK: 0,
          },
          capacity: 0,
          free: 0,
        },
        {
          id: "",
          begin: "",
          end: "",
          prices: {
            EUR: 0,
            CZK: 0,
          },
          capacity: 0,
          free: 0,
        },
      ],
      itinerary: [
        {
          title: "",
          description: "",
          image: "",
        },
      ],
      nameText: {
        CS: "",
        EN: "",
      },
      partner: null,
      descriptionText: null,
      includedText: null,
      notIncludedText: null,
      accommodationText: null,
      mealsText: null,
      transitText: null,
      difficultyText: null,
      moreInfoText: null,
    },
  ];

  const [advData, setAdvData] = useState<any>(empty);

  const [reservations, setReservations] = useState<any>(empty);

  const getTripLength = (item: any) => {
    var start = moment(item.dates[0].begin, "YYYY-MM-DD");
    var end = moment(item.dates[0].end, "YYYY-MM-DD");
    var length = end.diff(start, "days") + 1;
    if (length === 1) {
      return "1 " + lang.label191;
    } else if (length < 5) {
      let message = length.toString() + " " + lang.label192;
      return message;
    } else {
      let message = length.toString() + " " + lang.label193;
      return message;
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      fetchData();
      // eslint-disable-next-line
      isFirstRender.current = false;
    }
  }, [reservations]);

  async function fetchData() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .get("/likes/adventures", config)
      .then((res) => {
        /*         let change = [...res.data.data];
        let change = change.data.data.map((item: any) => {
          item.data = empty[0].data;
        });
        console.log(change); */
        /*         console.log(res);*/
        fetchAdv(res.data.data);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
        console.log(err.status);
        console.log(err.statusText);
        console.log(err.headers);
        console.log(err.config);
        console.log(err);
      });
  }

  async function fetchAdv(prop: any) {
    let help = JSON.parse(JSON.stringify(advData));
    Promise.all(
      prop.map(async (x: any, index: number) => {
        const api = axios.create({
          baseURL: `https://app.kangelo.com/`,
        });
        var config = {
          headers: {
            "X-Auth-Token": token,
          },
        };
        return api
          .get("/adventures/" + x, config)
          .then((res) => {
            help[index] = res.data;
          })
          .catch((err) => {
            // what now?
            console.log(err.data);
            console.log(err.status);
            console.log(err.statusText);
            console.log(err.headers);
            console.log(err.config);
            console.log(err);
          });
      })
    ).then(() => setImages(help));
  }

  async function setImages(help: any) {
    Promise.all(
      help.map(async (item: any) => {
        if (await checkImageCache(item.image)) {
          item.imageData = await db.get(item.image);
        } else {
          if (item.image !== "" || item.image !== null) {
            item.imageData = await showImage(item.image, token);
            await db.set(item.image, item.imageData);
          } else item.imageData = "";
        }
        if (await checkImageCache(item.guide.profileImage)) {
          item.guide.profileImageData = await db.get(item.guide.profileImage);
        } else {
          if (item.guide.profileImage !== "") {
            if (item.guide.profileImage !== null) {
              item.guide.profileImageData = await showImage(
                item.guide.profileImage,
                token
              );
              await db.set(
                item.guide.profileImage,
                item.guide.profileImageData
              );
            }
          } else item.guide.profileImageData = "";
        }
        return item;
      })
    ).then((resp) => {
      setAdvData(resp);
    });

    /* setAdvData(help); */
  }

  function renderSwitch(segment: any) {
    switch (segment) {
      case "guide":
        return <TravelGuide token={token} />;
      default:
        return <TravelAdventures advData={advData} />;
    }
  }

  return (
    <IonPage id="main">
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar /* className="back-butt" */>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tab1"
              text={lang.label38}
              className="back-button"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent scrollY={true} className="adventure-content">
        <div className="web-box">
          <IonSegment
            scrollable
            onIonChange={(e) => setSegment(e.detail.value)}
            value={segment}
            style={{ justifyContent: "center" }}
          >
            <IonSegmentButton value="default" className="segment-picker">
              <IonLabel>{lang.label21}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="guide"
              className="segment-picker"
              /* disabled={true} */
            >
              <IonLabel>{lang.label197}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {renderSwitch(segment)}
        </div>
      </IonContent>

      <IonModal
        isOpen={showModalPart}
        cssClass="part-modal"
        onDidDismiss={() => setShowModalPart(false)}
      >
        <IonText>
          {lang.label196} {partnerName}.
        </IonText>
      </IonModal>
    </IonPage>
  );
};

export default Travellist;
