import axios from "axios";

export default async function SetLike(
  id: string,
  type: string,
  isLiked: boolean,
  token: string
) {
  let response = null;
  const api = axios.create({
    baseURL: `https://app.kangelo.com/`,
  });
  var config = {
    headers: {
      "X-Auth-Token": token,
    },
  };

  isLiked
    ? api
        .put("/likes/" + type + "/" + id, null, config)
        .then((res) => {
          response = res;
        })
        .catch((err) => {
          // what now?
          response = "error";
        })
    : api
        .delete("/likes/" + type + "/" + id, config)
        .then((res) => {
          response = res;
        })
        .catch((err) => {
          // what now?
          response = "error";
        });

  return;
}
