import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonSearchbar,
  IonSlides,
  IonSlide,
  IonItem,
  IonList,
  IonCard,
  IonCardContent,
  IonFooter,
  IonModal,
  IonAvatar,
  IonImg,
  IonChip,
  IonLabel,
  IonText,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonRow,
  IonSpinner,
  IonInput,
  IonCol,
  useIonViewWillEnter,
  isPlatform,
  useIonViewWillLeave,
} from "@ionic/react";
import "./Tab1.css";
import {
  Heart,
  ArrowLeft,
  MagnifyingGlass,
  UserCircle,
  MapPin,
} from "phosphor-react";

import africa from "../assets/conti/noun_africa_2161025.svg";
import antarctica from "../assets/conti/noun_Antarctica_1314618.svg";
import asia from "../assets/conti/noun_asia_194338.svg";
import australia from "../assets/conti/noun_Oceania_301972.svg";
import europe from "../assets/conti/noun_europe_194340.svg";
import northAmerica from "../assets/conti/noun_North America_416786.svg";
import southAmerica from "../assets/conti/noun_South America_740450.svg";
import world from "../assets/conti/noun_world_3219965.svg";

import defaultAdvImage from "../assets/images/default_adventure_image.jpg";

import climbing from "../assets/categories/AdventureCategories/noun_Climbing_380699.svg";
import ferrata from "../assets/categories/AdventureCategories/noun_Via_ferrata_886685.svg";
import ccskiing from "../assets/categories/AdventureCategories/noun_Cross-Country_Skiing_510.svg";
import mtb from "../assets/categories/AdventureCategories/noun_MTB_1272650.svg";
import snowboarding from "../assets/categories/AdventureCategories/noun_snowboarding_1667528.svg";
import skier from "../assets/categories/AdventureCategories/noun_skier_2218013.svg";
import dive from "../assets/categories/AdventureCategories/noun_dive_1427211.svg";
import hiker from "../assets/categories/AdventureCategories/noun_Hiker_3810655.svg";
import relax from "../assets/categories/AdventureCategories/noun_relax_566382.svg";
import canyoning from "../assets/categories/AdventureCategories/noun_canyoning_893735.svg";
import boat from "../assets/categories/AdventureCategories/noun_Boat_5263.svg";
import helicopter from "../assets/categories/AdventureCategories/noun_Helicopter_72877.svg";
import hikerskies from "../assets/categories/AdventureCategories/noun_Hiker_with_Skis_1503554.svg";
import mountainclimb from "../assets/categories/AdventureCategories/noun_Mountain_Climbing_45240.svg";
import accessory from "../assets/categories/AdventureCategories/noun_accessory_3518721.svg";
import van from "../assets/categories/AdventureCategories/noun_Van_704014.svg";
import camp from "../assets/categories/AdventureCategories/noun_camp_2024078.svg";
import monument from "../assets/categories/AdventureCategories/noun_Monument_7146.svg";
import hiking from "../assets/categories/AdventureCategories/noun_Hiking_1779379.svg";
import mangoal from "../assets/categories/AdventureCategories/noun_man_goal_1814058.svg";
import map from "../assets/categories/AdventureCategories/noun_Map_4243273.svg";
import snowshoes from "../assets/categories/AdventureCategories/noun_snowshoer_1484245.svg";

import becomeGuideImage from "../assets/images/guide_become.jpg";
import becomeatrav from "../assets/images/becomeatrav.jpg";

import Promo from "../assets/videos/Kangelo_app_promo3.mp4";

import Rate from "rc-rate";
import "rc-rate/assets/index.css";

import moment from "moment";

import axios from "axios";

import AuthContext from "../my-context";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import Header from "./header";
import { useGlobal } from "reactn";
import setLike from "../functions/setLike";
import setCountryName from "../functions/setCountryName";
import setEnumText from "../functions/setEnumText";
import setCountriesOfContinent from "../functions/setCountriesOfContinent";
import checkProperty from "../functions/checkProperty";
import FooterPageMap from "./FooterPageMap";
import PromoVideo from "../components/promoVideo";

const Tab1: React.FC = () => {
  const isFirstRender = useRef(true);

  const { token, lang, checkImageCache, db, authValues, setShowTabBars } =
    React.useContext(AuthContext);

  const [isNavBackgroundVisible, setIsNavBackgroundVisible] = useState(false);

  /* const pop = () => {
    console.log("HEHEHEHE");
    if (window.scrollY > 200) {
      setIsNavBackgroundVisible(true);
      return;
    }

    setIsNavBackgroundVisible(false);
  }; */

  /* useEffect(() => {
    window.addEventListener("scroll", pop);
    console.log(window.scrollY);
    console.log("HEHEHEHE");
    return () => window.removeEventListener("scroll", pop);
  }, []); */

  const [userId, setUserId] = useGlobal("userId");
  const [showGuestModal, setShowGuestModal] = useGlobal("showGuestModal");
  const [deviceInfo] = useGlobal("deviceInfo");
  const [platformInfo] = useGlobal("platformInfo");
  const [appWidth, setAppWidth] = useGlobal("appWidth");

  useIonViewWillEnter(() => {
    setAppUrl("/tab1");
    setShowTabBars(true);
  });

  useIonViewWillLeave(() => {
    setAppUrl("");
    setShowTabBars(false);
  });

  const [, setAppUrl] = useGlobal("appUrl");

  const [continentFilter, setContinentFilter] = useState<any>({
    africa: false,
    antarctica: false,
    asia: false,
    australia: false,
    europe: false,
    northAmerica: false,
    southAmerica: false,
  });
  const [countHelper, setCountHelper] = useState(0);

  const [categoriesFilter, setCategoriesFilter] = useState({
    CROSS_COUNTRY_SKIING: false,
    CANYONING: false,
    BIKING: false,
    EXPEDITION: false,
    HELISKIING: false,
    MOUNTAIN_ASCENTS: false,
    BOATING: false,
    ICEBERG: false,
    CLIMBING: false,
    SKIING: false,
    OTHERS: false,
    DIVING: false,
    SIGHTSEEING: false,
    MOUNTAIN_CROSSING: false,
    RELAX_AND_CHILL: false,
    ROADTRIP: false,
    SKI_MOUNTAINEERING: false,
    SNOWSHOES: false,
    SNOWBOARDING: false,
    HIKING: false,
    VIA_FERRATA: false,
    WEEKEND_TRIP: false,
  });

  const [countriesFilter, setCountriesFilter] = useState("");

  const [continentsFilter, setContinentsFilter] = useState([""]);

  const [dummyCountries, setDummyCountries] = useState([[""]]);

  const [showLoading, setShowLoading] = useState(true);
  const [showLoading2, setShowLoading2] = useState(true);
  const [showLoading3, setShowLoading3] = useState(true);

  useEffect(() => {
    setAppUrl("/tab1");
    fetchData();
    // eslint-disable-next-line
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry: any) => {
          if (entry.isIntersecting) {
            setIsNavBackgroundVisible(false);
          } else {
            setIsNavBackgroundVisible(true);
          }
        });
      },
      { threshold: 0.12 }
    );
    let target = document.querySelector("#main-video");
    if (target !== null) observer.observe(target);
  }, []);

  const [empty] = useState<any>([
    {
      id: "",
      image: "",
      imageData: "",
      rating: 0,
      ratingCount: 0,
      partner: null,
      difficulty: 0,
      isLiked: false,
      guide: {
        id: "",
        firstName: "",
        lastName: "",
        profileImage: "",
        profileImageData: "",
        guide: false,
        admin: false,
      },
      meals: "",
      transit: "",
      accommodation: "",
      categories: [""],
      country: "",
      locations: [
        {
          type: "",
          latitude: "",
          longitude: "",
          airport: "",
          country: "",
          continent: "",
          continentText: { CS: "", EN: "" },
          region: "",
          city: "",
          address: "",
          countryText: {
            CS: "",
            EN: "",
          },
        },
      ],
      departure: {
        type: "",
        latitude: "",
        longitude: "",
        airport: "",
        country: "",
        city: "",
        address: "",
        countryText: "",
      },
      arrival: {
        type: "",
        latitude: "",
        longitude: "",
        airport: "",
        country: "",
        city: "",
        address: "",
        countryText: "",
      },
      dates: [
        {
          id: "",
          begin: "",
          end: "",
          prices: {
            EUR: 0,
            CZK: 0,
          },
          capacity: 0,
          free: 0,
        },
        {
          id: "",
          begin: "",
          end: "",
          prices: {
            EUR: 0,
            CZK: 0,
          },
          capacity: 0,
          free: 0,
        },
      ],
      itinerary: [
        {
          title: "",
          description: "",
          image: "",
        },
      ],
      nameText: {
        CS: "",
        EN: "",
      },
      descriptionText: null,
      includedText: null,
      notIncludedText: null,
      accommodationText: null,
      mealsText: null,
      transitText: null,
      difficultyText: null,
      moreInfoText: null,
      /*       countryText: {
              CS: "",
              EN: "",
            }, */
    },
  ]);

  const history = useHistory();

  const getTripLength = (item: any) => {
    var start = moment(item.dates[0].begin, "YYYY-MM-DD");
    var end = moment(item.dates[0].end, "YYYY-MM-DD");
    var length = end.diff(start, "days") + 1;
    if (length === 1) {
      return "1 " + lang.label191;
    } else if (length < 5) {
      let message = length.toString() + " " + lang.label192;
      return message;
    } else {
      let message = length.toString() + " " + lang.label193;
      return message;
    }
  };

  const [, setAdventureId] = useGlobal("AdventrureId");
  const [language] = useGlobal("language");

  async function fetchData() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {};
    if (authValues.user.id !== "guest") {
      config = {
        headers: {
          "X-Auth-Token": token,
        },
      };
    }
    api
      .get("/adventures/current", config)
      .then(async (res) => {
        setItems(res.data.data);
        sortItems(res.data.data);
        setShowLoading(false);
        Promise.all(
          res.data.data.map(async (item: any) => {
            item.imageData = await showImage(item.image);
            /*   item.guide.profileImageData = await showImage(
              item.guide.profileImage
            ); */
            return item;
          })
        ).then(() => {
          setItems(res.data.data);
          sortItems(res.data.data);
        });
      })
      .catch((err) => {
        console.log(err.data);
        console.log(err.status);
        console.log(err.statusText);
        console.log(err.headers);
        console.log(err.config);
        console.log(err);
        console.log(err.response);
      });
  }

  const getLowestPrice = (item: any) => {
    let min = 100000000;
    for (var i = 0; i < item.dates.length; i++) {
      if (item.dates[i].prices.CZK < min) {
        min = item.dates[i].prices.CZK;
      }
    }
    let config = language;
    language === "cs" ? (config = config + "-CZ") : (config = config + "-GB");
    return (
      <div style={{ display: "inline-block" }}>
        {" "}
        • {min.toLocaleString(config) + " Kč"}
      </div>
    );
  };

  const [searchTextModal, setSearchTextModal] = useState("");

  const [searchTextModalAdv, setSearchTextModalAdv] = useState(empty);
  /* const [searchTextModalCont, setSearchTextModalCont] = useState(empty); */
  const [searchTextModalDest, setSearchTextModalDest] = useState(empty);

  const [searchTextModalContinent, setSearchTextModalContinent] = useState([
    "",
  ]);
  const [searchTextModalContinentNumber, setSearchTextModalContinentNumber] =
    useState([0]);

  const [searchTextModalCountry, setSearchTextModalCountry] = useState([
    ["", ""],
  ]);
  const [searchTextModalCountryNumber, setSearchTextModalCountryNumber] =
    useState([0]);

  /* const [partnerName, setPartnerName] = useState<string | null>(""); */

  const [showSearch, setShowSearch] = useState(false); //search modal
  /* const [showModalPart, setShowModalPart] = useState(false); //partner modal */

  const [items, setItems] = useState(empty);

  const [contriesFilter, setContriesFilter] = useState([[""]]);

  const [advFilters, setAdvFilters] = useGlobal("advFilter");

  useEffect(() => {
    if (isFirstRender.current) {
      // eslint-disable-next-line
      isFirstRender.current = false;
      return;
    }
    setContinents();
  }, [items]);

  const [sortedItems, setSortedItems] = useState(empty);
  const [lastSpotItems, setLastSpotItems] = useState(empty);

  const slideOpts = {
    slidesPerView: 4.3,
    initialSlide: 0,
    speed: 400,
    watchOverflow: true,
  };
  const slideOpts2 = {
    slidesPerView: 1.1,
    initialSlide: 0,
    speed: 400,
  };
  const slideOpts3 = {
    slidesPerView: 4,
    initialSlide: 0,
    speed: 400,
    watchOverflow: true,
  };

  async function sortItems(items: any) {
    const sorted = items.slice().sort(
      (a: any, b: any) =>
        new Date(a.dates[0].begin).valueOf() -
        new Date(b.dates[0].begin).valueOf()

      /* {
      if (b.dates.length === 0 || a.dates.length === 0) return false;
      return */
      /* } */
    );
    const sortedSlice = sorted.slice(0, 4);
    const lastSpot = sorted
      .slice()
      .sort(
        (a: any, b: any) =>
          a.dates[0].free.valueOf() - b.dates[0].free.valueOf()
      );
    const lastSpotSlice = lastSpot.slice(0, 4);
    Promise.all(
      sortedSlice.map(async (item: any) => {
        item.imageData = await showImage(item.image);
        item.guide.profileImageData = await showImage(item.guide.profileImage);
        return item;
      })
    )
      .then(() => {
        setSortedItems(sortedSlice);
      })
      .then(() => {
        setShowLoading2(false);
      });

    Promise.all(
      lastSpotSlice.map(async (item: any) => {
        item.imageData = await showImage(item.image);
        item.guide.profileImageData = await showImage(item.guide.profileImage);
        return item;
      })
    )
      .then(() => {
        setLastSpotItems(lastSpotSlice);
      })
      .then(() => {
        setShowLoading3(false);
      });

    /* }); */
  }

  const setContinents = () => {
    let temp = JSON.parse(JSON.stringify(items));
    let contiTemp = [""];
    temp.map((item: any) => {
      contiTemp = findContinent(item);
      item.locations.map((location: any, index: number) => {
        location.continent = contiTemp[index];
        return location;
      });
      return item;
    });
    isFirstRender.current = true;
    setItems(temp);
    filterContinents(temp);
    filterCategories(temp);
  };

  const filterCategories = (temp: any) => {
    let temporary = JSON.parse(JSON.stringify(categoriesFilter));
    let tempCategories = [""];
    temp.forEach((tempX: any) => {
      tempX.categories.forEach((tempY: string) => {
        tempCategories.push(tempY);
      });
    });
    tempCategories.forEach((item: string) => {
      if (item === "CANYONING") {
        temporary.CANYONING = true;
      } else if (item === "BIKING") {
        temporary.BIKING = true;
      } else if (item === "EXPEDITION") {
        temporary.EXPEDITION = true;
      } else if (item === "HELISKIING") {
        temporary.HELISKIING = true;
      } else if (item === "MOUNTAIN_ASCENTS") {
        temporary.MOUNTAIN_ASCENTS = true;
      } else if (item === "BOATING") {
        temporary.BOATING = true;
      } else if (item === "ICEBERG") {
        temporary.ICEBERG = true;
      } else if (item === "CLIMBING") {
        temporary.CLIMBING = true;
      } else if (item === "SKIING") {
        temporary.SKIING = true;
      } else if (item === "OTHERS") {
        temporary.OTHERS = true;
      } else if (item === "DIVING") {
        temporary.DIVING = true;
      } else if (item === "SIGHTSEEING") {
        temporary.SIGHTSEEING = true;
      } else if (item === "MOUNTAIN_CROSSING") {
        temporary.MOUNTAIN_CROSSING = true;
      } else if (item === "RELAX_AND_CHILL") {
        temporary.RELAX_AND_CHILL = true;
      } else if (item === "ROADTRIP") {
        temporary.ROADTRIP = true;
      } else if (item === "SKI_MOUNTAINEERING") {
        temporary.SKI_MOUNTAINEERING = true;
      } else if (item === "SNOWSHOES") {
        temporary.SNOWSHOES = true;
      } else if (item === "SNOWBOARDING") {
        temporary.SNOWBOARDING = true;
      } else if (item === "HIKING") {
        temporary.HIKING = true;
      } else if (item === "VIA_FERRATA") {
        temporary.VIA_FERRATA = true;
      } else if (item === "WEEKEND_TRIP") {
        temporary.WEEKEND_TRIP = true;
      } else if (item === "CROSS_COUNTRY_SKIING") {
        temporary.CROSS_COUNTRY_SKIING = true;
      }
    });
    setCategoriesFilter(temporary);
  };

  const setProperText = (text: string) => {
    switch (text) {
      case "europe":
        return "Europe";
      case "asia":
        return "Asia";
      case "africa":
        return "Africa";
      case "australia":
        return "Oceania";
      case "southAmerica":
        return "South America";
      case "northAmerica":
        return "North America";
      case "antarctica":
        return "Polar";
    }
  };

  const filterContinents = (temp: any) => {
    let temporary = JSON.parse(JSON.stringify(continentFilter));
    temp.map((item: any) => {
      item.locations.forEach((location: any) => {
        if (location.contitent === "Africa") {
          temporary.africa = true;
        } else if (location.contitent === "Antarctica") {
          temporary.antarctica = true;
        } else if (location.continent === "Asia") {
          temporary.asia = true;
        } else if (location.continent === "Australia") {
          temporary.australia = true;
        } else if (location.continent === "Europe") {
          temporary.europe = true;
        } else if (location.continent === "North America") {
          temporary.northAmerica = true;
        } else if (location.continent === "South America") {
          temporary.southAmerica = true;
        }
      });
    });
    let tempX = [[""]];
    let count = 1;
    Object.keys(temporary).forEach((key) => {
      if (temporary[key]) {
        let contriesFilterTemp = items.filter((item: any) => {
          return (
            item.locations.filter((location: any) => {
              return location.continentText.EN === setProperText(key);
            }).length > 0
          );
        });
        let propArray = [key];
        contriesFilterTemp.forEach((item: any) => {
          for (var i = 0; i < item.locations.length; i++) {
            propArray.push(item.locations[i].country);
          }
        });
        tempX[count - 1] = propArray.filter((v, i, a) => a.indexOf(v) === i);
        count = count + 1;
      }
      setDummyCountries([...tempX]);
    });
    setCountHelper(count);
    setContinentFilter(temporary);
  };

  const findContinent = (prop: any) => {
    let jsonData = require("../assets/countries_states_cities/countries+states+cities.json");
    let propArray = [""];
    for (var i = 0; i < prop.locations.length; i++) {
      propArray[i] = prop.locations[i].country;
    }
    let jsonDataFiltered = jsonData.filter(
      (data: any) =>
        propArray.includes(data.iso2) /* data.iso2 === prop.locations.country */
    );
    if (jsonDataFiltered.length > 0) {
      /*       if (jsonDataFiltered[0].iso2 === "ES") {
              return "Africa"
            } */
      let jsonDataFilteredRegions = [""];
      for (var j = 0; j < jsonDataFiltered.length; j++) {
        jsonDataFilteredRegions[j] = jsonDataFiltered[j].region;
      }
      return jsonDataFilteredRegions;
    } else return [""];
  };

  const selectCountry = (name: string) => {
    let tempSelect = JSON.parse(JSON.stringify(advFilters));

    if (tempSelect.destinations.includes(name)) {
      if (name === "World") {
        tempSelect.destinations = [""];
      } else {
        for (var i = 0; i < tempSelect.destinations.length; i++) {
          if (tempSelect.destinations[i] === name) {
            tempSelect.destinations.splice(i, 1);
            i--;
          }
        }
      }
    } else {
      if (name === "World") {
        tempSelect.destinations = [name];
      } else {
        tempSelect.destinations = tempSelect.destinations.concat(name);
      }
    }
    setAdvFilters(tempSelect);
  };

  function whatCountriesShow(continent: string) {
    if (continentsFilter.includes(continent)) {
      let temp = { ...advFilters };
      let jsonData = require("../assets/countries_states_cities/countries+states+cities.json");
      let tempX = jsonData.filter((c: any) => c.region === continent);
      temp.destinations = temp.destinations.filter((country: any) => {
        let check = false;
        tempX.forEach((t: any) => {
          if (t.iso2 === country) {
            check = true;
          }
        });
        return !check;
      });
      setAdvFilters(temp);
      setCountriesFilter("");
      /* setIsCountriesShown((state) => !state); */
    } else if (countriesFilter === "") {
      setCountriesFilter(continent);
      /* setIsCountriesShown((state) => !state); */
    } else {
      setCountriesFilter(continent);
    }
    setFilterOfContinents(continent);
  }

  function searchFunction(e: string) {
    setSearchTextModal(e);
    if (e.length > 2) {
      const help = items.filter(
        (item: any) =>
          item.nameText?.CS?.toLowerCase().includes(e.toLowerCase()) ||
          item.nameText?.EN?.toLowerCase().includes(e.toLowerCase()) ||
          item?.locations[0]?.countryText?.EN.toLowerCase().includes(
            e.toLowerCase()
          ) ||
          item?.locations[0]?.countryText?.CS.toLowerCase().includes(
            e.toLowerCase()
          ) ||
          (item?.locations[0]?.region !== null
            ? item?.locations[0]?.region.toLowerCase().includes(e.toLowerCase())
            : false) ||
          (item?.locations[0]?.address !== null
            ? item?.locations[0]?.address
                .toLowerCase()
                .includes(e.toLowerCase())
            : false) ||
          item?.locations[0]?.continentText.EN.toLowerCase().includes(
            e.toLowerCase()
          ) ||
          item?.locations[0]?.continentText.CS.toLowerCase().includes(
            e.toLowerCase()
          )
      );

      if (help.length === 0) {
        setSearchTextModalAdv(empty);
      } else {
        setSearchTextModalAdv(help);
      }

      let helpContinents = JSON.parse(JSON.stringify(items));
      helpContinents.forEach((item: any) => {
        item.locations = item.locations.filter(
          (location: any) =>
            location.continentText.CS?.toLowerCase().includes(
              e.toLowerCase()
            ) ||
            location.continentText.EN?.toLowerCase().includes(e.toLowerCase())
        );
      });
      helpContinents = helpContinents.filter(
        (item: any) => item.locations.length !== 0
      );

      let helpCountries = JSON.parse(JSON.stringify(items));
      helpCountries.forEach((item: any) => {
        item.locations = item.locations.filter(
          (location: any) =>
            location.countryText.CS?.toLowerCase().includes(e.toLowerCase()) ||
            location.countryText.EN?.toLowerCase().includes(e.toLowerCase())
        );
      });
      helpCountries = helpCountries.filter(
        (item: any) => item.locations.length !== 0
      );

      let helpDest = JSON.parse(JSON.stringify(items));
      helpDest.forEach((item: any) => {
        item.locations = item.locations.filter(
          (location: any) =>
            location.address?.toLowerCase().includes(e.toLowerCase()) ||
            location.city?.toLowerCase().includes(e.toLowerCase()) ||
            location.region?.toLowerCase().includes(e.toLowerCase()) ||
            location.countryText.CS?.toLowerCase().includes(e.toLowerCase()) ||
            location.countryText.EN?.toLowerCase().includes(e.toLowerCase())
        );
      });
      helpDest = helpDest.filter((item: any) => item.locations.length !== 0);

      if (helpCountries.length === 0) {
        setSearchTextModalCountry([["", ""]]);
        setSearchTextModalCountryNumber([0]);
      } else {
        let tempCountry = [["", ""]];
        let tempCountryNumber = [0];
        helpCountries.forEach((itemConti: any) => {
          if (
            !tempCountry.includes([
              itemConti.locations[0].countryText.EN,
              itemConti.locations[0].country,
            ]) &&
            !tempCountry.includes([
              itemConti.locations[0].countryText.CS,
              itemConti.locations[0].country,
            ])
          ) {
            tempCountryNumber.push(
              helpCountries.filter(
                (loc: any) =>
                  loc.locations[0].countryText.CS ===
                  itemConti.locations[0].countryText.CS
              ).length
            );
            tempCountry.push(
              language === "cs"
                ? [
                    itemConti.locations[0].countryText.CS,
                    itemConti.locations[0].country,
                  ]
                : [
                    itemConti.locations[0].countryText.EN,
                    itemConti.locations[0].country,
                  ]
            );
          }
        });
        setSearchTextModalCountry(tempCountry);
        setSearchTextModalCountryNumber(tempCountryNumber);
      }

      if (helpContinents.length === 0) {
        setSearchTextModalContinent([""]);
        setSearchTextModalContinentNumber([0]);
      } else {
        let tempConti = [""];
        let tempNumber = [0];
        helpContinents.forEach((itemConti: any) => {
          if (
            !tempConti.includes(itemConti.locations[0].continentText.EN) &&
            !tempConti.includes(itemConti.locations[0].continentText.CS)
          ) {
            tempNumber.push(
              helpContinents.filter(
                (loc: any) =>
                  loc.locations[0].continentText.CS ===
                  itemConti.locations[0].continentText.CS
              ).length
            );
            tempConti.push(
              language === "cs"
                ? itemConti.locations[0].continentText.CS
                : itemConti.locations[0].continentText.EN
            );
          }
        });
        setSearchTextModalContinent(tempConti);
        setSearchTextModalContinentNumber(tempNumber);
      }

      if (helpDest.length === 0) {
        setSearchTextModalDest(empty);
      } else {
        setSearchTextModalDest(helpDest);
      }
    } else {
      setSearchTextModalContinent([""]);
      setSearchTextModalContinentNumber([0]);
      setSearchTextModalCountry([["", ""]]);
      setSearchTextModalCountryNumber([0]);
      setSearchTextModalAdv(empty);
      setSearchTextModalDest(empty);
    }
  }

  const [segment, setSegment] = useState<string | undefined>("default");

  useEffect(() => {
    var filtered = [[""]];
    continentsFilter.forEach((conti: string, i: number) => {
      let contriesFilterTemp = items.filter((item: any) => {
        return (
          item.locations.filter((location: any) => {
            return location.continent === /* countriesFilter */ conti;
          }).length > 0
        );
      });
      let propArray = [""];
      contriesFilterTemp.forEach((item: any) => {
        for (var i = 0; i < item.locations.length; i++) {
          if (propArray[0] === "") {
            propArray[0] = item.locations[i].country;
          } else propArray.push(item.locations[i].country);
        }
      });

      filtered[i] = propArray.filter((v, i, a) => a.indexOf(v) === i);
    });
    setContriesFilter([...filtered]);
  }, [countriesFilter]);

  function setFilterOfContinents(conti: string) {
    let tempSelect = JSON.parse(JSON.stringify(continentsFilter));
    if (continentsFilter.includes("World")) {
      if (conti === "World") {
        tempSelect = [""];
        selectCountry(conti);
        setContinentsFilter(tempSelect);
        return;
      } else {
        tempSelect = ["", conti];
        setContinentsFilter(tempSelect);
      }
    } else if (conti === "World") {
      tempSelect = ["World"];
      selectCountry(conti);
      setContinentsFilter(tempSelect);
      return;
    } else {
      if (tempSelect.includes(conti) /* && countriesFilter === conti */) {
        for (var i = 0; i < tempSelect.length; i++) {
          if (tempSelect[i] === conti) {
            tempSelect.splice(i, 1);
            i--;
          }
        }
      } else {
        /* if (tempSelect.includes(conti)) {
          return;
        } else { */
        tempSelect = tempSelect.concat(conti);
        /* } */
      }
      setContinentsFilter(tempSelect);
      return;
    }

    /* if (tempSelect.includes(conti) && countriesFilter === conti) {
      for (var i = 0; i < tempSelect.length; i++) {
        if (tempSelect[i] === conti) {
          tempSelect.splice(i, 1);
          i--;
        }
      }
    } else {
      if (tempSelect.includes(conti)) {
        return;
      } else {
        if (conti === "World") selectCountry(conti);
        tempSelect = tempSelect.concat(conti);
      }
    }
    setContinentsFilter(tempSelect); */
  }

  function renderSwitch(segment: any) {
    switch (segment) {
      case "categories":
        return (
          <IonGrid>
            <IonSlides
              className="adv-categories-swipe"
              options={slideOpts}
              style={{ height: "86px" }}
            >
              <IonSlide
                className="conti-group"
                onClick={() => {
                  let tempCategories = JSON.parse(JSON.stringify(advFilters));
                  if (tempCategories.categories.includes("All")) {
                    tempCategories.categories = [""];
                  } else {
                    tempCategories.categories = ["All"];
                  }
                  setAdvFilters(tempCategories);
                }}
              >
                <div className="categories-img">
                  <IonImg
                    src={map}
                    class="conti-img"
                    style={
                      advFilters.categories.includes("All")
                        ? { opacity: 1 }
                        : { opacity: 0.3 }
                    }
                  />
                </div>
                <IonText className="text-main-filter">{lang.label299}</IonText>
              </IonSlide>
              {categoriesFilter.CROSS_COUNTRY_SKIING && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "CROSS_COUNTRY_SKIING"];
                    } else if (
                      tempCategories.categories.includes("CROSS_COUNTRY_SKIING")
                    ) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (
                          tempCategories.categories[i] ===
                          "CROSS_COUNTRY_SKIING"
                        ) {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat(
                          "CROSS_COUNTRY_SKIING"
                        );
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={ccskiing}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("CROSS_COUNTRY_SKIING")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label99}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.CANYONING && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "CANYONING"];
                    } else if (
                      tempCategories.categories.includes("CANYONING")
                    ) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "CANYONING") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("CANYONING");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={canyoning}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("CANYONING")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label93}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.BIKING && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "BIKING"];
                    } else if (tempCategories.categories.includes("BIKING")) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "BIKING") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("BIKING");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={mtb}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("BIKING")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label97}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.EXPEDITION && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "EXPEDITION"];
                    } else if (
                      tempCategories.categories.includes("EXPEDITION")
                    ) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "EXPEDITION") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("EXPEDITION");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={accessory}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("EXPEDITION")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label78}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.HELISKIING && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "HELISKIING"];
                    } else if (
                      tempCategories.categories.includes("HELISKIING")
                    ) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "HELISKIING") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("HELISKIING");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={helicopter}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("HELISKIING")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label89}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.MOUNTAIN_ASCENTS && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "MOUNTAIN_ASCENTS"];
                    } else if (
                      tempCategories.categories.includes("MOUNTAIN_ASCENTS")
                    ) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (
                          tempCategories.categories[i] === "MOUNTAIN_ASCENTS"
                        ) {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("MOUNTAIN_ASCENTS");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={mangoal}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("MOUNTAIN_ASCENTS")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label82}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.BOATING && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "BOATING"];
                    } else if (tempCategories.categories.includes("BOATING")) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "BOATING") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("BOATING");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={boat}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("BOATING")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label92}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.ICEBERG && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "ICEBERG"];
                    } else if (tempCategories.categories.includes("ICEBERG")) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "ICEBERG") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("ICEBERG");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={mountainclimb}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("ICEBERG")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label86}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.CLIMBING && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "CLIMBING"];
                    } else if (tempCategories.categories.includes("CLIMBING")) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "CLIMBING") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("CLIMBING");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={climbing}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("CLIMBING")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label90}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.SKIING && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "SKIING"];
                    } else if (tempCategories.categories.includes("SKIING")) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "SKIING") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("SKIING");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={skier}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("SKIING")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label85}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.OTHERS && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "OTHERS"];
                    } else if (tempCategories.categories.includes("OTHERS")) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "OTHERS") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("OTHERS");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={map}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("OTHERS")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label98}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.DIVING && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "DIVING"];
                    } else if (tempCategories.categories.includes("DIVING")) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "DIVING") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("DIVING");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={dive}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("DIVING")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label91}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.SIGHTSEEING && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "SIGHTSEEING"];
                    } else if (
                      tempCategories.categories.includes("SIGHTSEEING")
                    ) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "SIGHTSEEING") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("SIGHTSEEING");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={monument}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("SIGHTSEEING")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label84}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.MOUNTAIN_CROSSING && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "MOUNTAIN_CROSSING"];
                    } else if (
                      tempCategories.categories.includes("MOUNTAIN_CROSSING")
                    ) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (
                          tempCategories.categories[i] === "MOUNTAIN_CROSSING"
                        ) {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("MOUNTAIN_CROSSING");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={hiker}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("MOUNTAIN_CROSSING")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label83}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.RELAX_AND_CHILL && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "RELAX_AND_CHILL"];
                    } else if (
                      tempCategories.categories.includes("RELAX_AND_CHILL")
                    ) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (
                          tempCategories.categories[i] === "RELAX_AND_CHILL"
                        ) {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("RELAX_AND_CHILL");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={relax}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("RELAX_AND_CHILL")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label95}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.ROADTRIP && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "ROADTRIP"];
                    } else if (tempCategories.categories.includes("ROADTRIP")) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "ROADTRIP") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("ROADTRIP");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={van}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("ROADTRIP")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label80}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.SKI_MOUNTAINEERING && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "SKI_MOUNTAINEERING"];
                    } else if (
                      tempCategories.categories.includes("SKI_MOUNTAINEERING")
                    ) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (
                          tempCategories.categories[i] === "SKI_MOUNTAINEERING"
                        ) {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("SKI_MOUNTAINEERING");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={hikerskies}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("SKI_MOUNTAINEERING")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label88}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.SNOWSHOES && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "SNOWSHOES"];
                    } else if (
                      tempCategories.categories.includes("SNOWSHOES")
                    ) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "SNOWSHOES") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("SNOWSHOES");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={snowshoes}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("SNOWSHOES")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label87}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.SNOWBOARDING && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "SNOWBOARDING"];
                    } else if (
                      tempCategories.categories.includes("SNOWBOARDING")
                    ) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "SNOWBOARDING") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("SNOWBOARDING");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={snowboarding}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("SNOWBOARDING")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label96}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.HIKING && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "HIKING"];
                    } else if (tempCategories.categories.includes("HIKING")) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "HIKING") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("HIKING");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={hiking}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("HIKING")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label94}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.VIA_FERRATA && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "VIA_FERRATA"];
                    } else if (
                      tempCategories.categories.includes("VIA_FERRATA")
                    ) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "VIA_FERRATA") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("VIA_FERRATA");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={ferrata}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("VIA_FERRATA")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label81}</IonText>
                </IonSlide>
              )}
              {categoriesFilter.WEEKEND_TRIP && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    let tempCategories = JSON.parse(JSON.stringify(advFilters));
                    if (advFilters.categories.includes("All")) {
                      tempCategories.categories = ["", "WEEKEND_TRIP"];
                    } else if (
                      tempCategories.categories.includes("WEEKEND_TRIP")
                    ) {
                      for (
                        var i = 0;
                        i < tempCategories.categories.length;
                        i++
                      ) {
                        if (tempCategories.categories[i] === "WEEKEND_TRIP") {
                          tempCategories.categories.splice(i, 1);
                          i--;
                        }
                      }
                    } else {
                      tempCategories.categories =
                        tempCategories.categories.concat("WEEKEND_TRIP");
                    }
                    setAdvFilters(tempCategories);
                  }}
                >
                  <div className="categories-img">
                    <IonImg
                      src={camp}
                      class="conti-img"
                      style={
                        advFilters.categories.includes("WEEKEND_TRIP")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label79}</IonText>
                </IonSlide>
              )}
            </IonSlides>
            {(advFilters.categories.length !== 1 ||
              advFilters.categories[0] === "All") && (
              <IonRow style={{ justifyContent: "center" }}>
                <IonButton
                  className="filter-butt"
                  onClick={() => {
                    let tempFilters = JSON.parse(JSON.stringify(advFilters));
                    tempFilters.destinations = [""];
                    setAdvFilters(tempFilters);
                    history.push("/adventures/");
                  }}
                >
                  {lang.label162}
                </IonButton>
              </IonRow>
            )}
          </IonGrid>
        );
      default:
        return showLoading ? (
          <IonSpinner className="spinner-loading" color="primary" />
        ) : (
          <div style={{ padding: "5px" }}>
            <IonSlides
              className={
                countHelper < 5
                  ? "adv-location-swipe count-fix"
                  : "adv-location-swipe"
              }
              pager={false}
              options={slideOpts}
            >
              <IonSlide
                className="conti-group"
                onClick={() => {
                  whatCountriesShow("World");
                }}
              >
                <div className="destination-map-img">
                  <IonImg
                    src={world}
                    class="conti-img"
                    style={
                      continentsFilter.includes("World")
                        ? { opacity: 1 }
                        : { opacity: 0.3 }
                    }
                  />
                </div>
                <IonText className="text-main-filter">{lang.label9}</IonText>
              </IonSlide>
              {continentFilter.africa && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    whatCountriesShow("Africa");
                  }}
                >
                  <div className="destination-map-img">
                    <IonImg
                      src={africa}
                      class="conti-img"
                      style={
                        continentsFilter.includes("Africa")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label10}</IonText>
                </IonSlide>
              )}
              {continentFilter.northAmerica && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    whatCountriesShow("North America");
                  }}
                >
                  <div className="destination-map-img">
                    <IonImg
                      src={northAmerica}
                      class="conti-img"
                      style={
                        continentsFilter.includes("North America")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label11}</IonText>
                </IonSlide>
              )}
              {continentFilter.southAmerica && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    whatCountriesShow("South America");
                  }}
                >
                  <div className="destination-map-img">
                    <IonImg
                      src={southAmerica}
                      class="conti-img"
                      style={
                        continentsFilter.includes("South America")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label12}</IonText>
                </IonSlide>
              )}
              {continentFilter.europe && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    whatCountriesShow("Europe");
                  }}
                >
                  <div className="destination-map-img">
                    <IonImg
                      src={europe}
                      class="conti-img"
                      style={
                        continentsFilter.includes("Europe")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label13}</IonText>
                </IonSlide>
              )}
              {continentFilter.asia && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    whatCountriesShow("Asia");
                  }}
                >
                  <div className="destination-map-img">
                    <IonImg
                      src={asia}
                      class="conti-img"
                      style={
                        continentsFilter.includes("Asia")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label15}</IonText>
                </IonSlide>
              )}
              {continentFilter.australia && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    whatCountriesShow("Oceania");
                  }}
                >
                  <div className="destination-map-img">
                    <IonImg
                      src={australia}
                      class="conti-img"
                      style={
                        continentsFilter.includes("Oceania")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label14}</IonText>
                </IonSlide>
              )}
              {continentFilter.antarctica && (
                <IonSlide
                  className="conti-group"
                  onClick={() => {
                    whatCountriesShow("Polar");
                  }}
                >
                  <div className="destination-map-img">
                    <IonImg
                      src={antarctica}
                      class="conti-img"
                      style={
                        continentsFilter.includes("Polar")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText className="text-main-filter">{lang.label76}</IonText>
                </IonSlide>
              )}
            </IonSlides>
            {!continentsFilter.includes("World") &&
              continentsFilter.length > 1 && (
                <IonGrid
                  style={{
                    borderTop: "5px solid #CCCCCC",
                    borderBottom: "5px solid #CCCCCC",
                    padding: "15px 25px 10px 25px",
                  }}
                >
                  {!continentsFilter.includes("World") && (
                    <IonRow style={{ justifyContent: "center" }}>
                      {contriesFilter.map((conti, i) => {
                        if (conti[0] === "") return;
                        return (
                          <IonRow
                            key={i}
                            style={{
                              width: "100%",
                              display: "block",
                              marginBottom: "15px",
                              /* borderBottom: "1px solid #DEDEDE", */
                            }}
                          >
                            <IonRow style={{ justifyContent: "center" }}>
                              <IonText>
                                {setEnumText(continentsFilter[i], lang)}
                              </IonText>
                            </IonRow>
                            <IonRow style={{ justifyContent: "center" }}>
                              {conti.map((country, j) => {
                                if (country === "") return;
                                return (
                                  <div style={{ padding: 0 }} key={j}>
                                    <IonChip
                                      onClick={() => selectCountry(country)}
                                      className={
                                        advFilters.destinations.includes(
                                          country
                                        )
                                          ? "selected-country"
                                          : "nonselected-country"
                                      }
                                    >
                                      <IonLabel>
                                        {setCountryName(country, language)}
                                      </IonLabel>
                                    </IonChip>
                                  </div>
                                );
                              })}
                            </IonRow>
                          </IonRow>
                        );
                      })}
                    </IonRow>
                  )}
                  <IonRow style={{ justifyContent: "center" }}>
                    <IonButton
                      className="filter-butt"
                      onClick={() => {
                        let tempFilters = JSON.parse(
                          JSON.stringify(advFilters)
                        );
                        tempFilters.categories = [""];
                        continentsFilter.forEach((c) => {
                          let check = false;
                          let countryOfContinent = setCountriesOfContinent(c);
                          tempFilters.destinations.forEach((d: string) => {
                            if (countryOfContinent.includes(d)) {
                              check = true;
                            }
                          });

                          if (check === false) {
                            tempFilters.destinations =
                              tempFilters.destinations.concat(
                                countryOfContinent
                              );
                          }
                        });
                        setAdvFilters(tempFilters);
                        history.push("/adventures/");
                      }}
                    >
                      {lang.label162}
                    </IonButton>
                  </IonRow>
                </IonGrid>
              )}
            {continentsFilter.includes("World") && (
              <IonGrid
                style={{
                  borderTop: "5px solid #CCCCCC",
                  borderBottom: "5px solid #CCCCCC",
                  padding: "10px 25px 10px 25px",
                }}
              >
                <IonRow style={{ justifyContent: "center" }}>
                  <IonButton
                    className="filter-butt"
                    onClick={() => {
                      let tempFilters = JSON.parse(JSON.stringify(advFilters));
                      tempFilters.categories = [""];
                      setAdvFilters(tempFilters);
                      history.push("/adventures/");
                    }}
                  >
                    {lang.label162}
                  </IonButton>
                </IonRow>
              </IonGrid>
            )}
          </div>
        );
    }
  }

  async function showImage(id: string) {
    if (await checkImageCache(id)) {
      return await db.get(id);
    }
    if (id === null) return;
    if (id.substr(0, 4) === "http") {
      return id;
    }
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    /* var config = {
      headers: {
        "X-Auth-Token": token,
      },
    }; */
    return api
      .get("/media/" + id /* , config */)
      .then(async (res) => {
        await db.set(id, res.data.data);
        return res.data.data;
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  useIonViewWillEnter(() => {
    let temp = { ...advFilters };
    temp.destinations = [""];
    temp.categories = [""];
    setAdvFilters(temp);
    setContinentsFilter([""]);
    setCountriesFilter("");
    setContriesFilter([[""]]);
  });

  function continentImage(text: string) {
    switch (text) {
      case "Afrika":
      case "Africa":
      case "africa":
        return africa;
      case "Severní Amerika":
      case "North America":
      case "northAmerica":
        return northAmerica;
      case "Jižní Amerika":
      case "South America":
      case "southAmerica":
        return southAmerica;
      case "Evropa":
      case "Europe":
      case "europe":
        return europe;
      case "Austrálie a Oceánie":
      case "Australia and Oceania":
      case "australia":
        return australia;
      case "Asie":
      case "Asia":
      case "asia":
        return asia;
      case "Antarktida":
      case "Antarctica":
      case "antarctica":
        return antarctica;
    }
  }

  return (
    <IonPage>
      <Header
        styles="overlay"
        styles2={isNavBackgroundVisible ? "shadow-box" : "no-background"}
      />

      <IonContent
        /* fullscreen */
        className="adventures-cont"
      >
        <IonModal
          isOpen={showSearch}
          cssClass="search-modala"
          onDidDismiss={() => setShowSearch(false)}
        >
          <IonHeader
            className="main-header-search safe-zone-header"
            style={{ borderBottom: "1px solid #DEDEDE" }}
          >
            <IonButton
              onClick={() => setShowSearch(false)}
              className="modal-search-back"
            >
              <ArrowLeft size={24} color="black" />
            </IonButton>
            <IonItem
              className="search-item searchbar-input"
              style={{ marginTop: "5px" }}
            >
              <MagnifyingGlass
                size={24}
                style={{ paddingLeft: "5px", marginLeft: "5px" }}
              />
              <IonInput
                className="modal-search"
                value={searchTextModal}
                autofocus={true}
                placeholder={lang.label223}
                onIonChange={(e) => searchFunction(e.detail.value!)}
              ></IonInput>
            </IonItem>
          </IonHeader>
          <IonContent>
            {searchTextModalContinent[1] && (
              <div>
                <IonList>
                  {searchTextModalContinent.map((item, i) => {
                    if (item === "") return;
                    return (
                      <IonItem
                        className="user-info-item item-input-grey hover-anime"
                        key={`${i}`}
                        onClick={() => {
                          let tempFilters = JSON.parse(
                            JSON.stringify(advFilters)
                          );
                          tempFilters.destinations = [""];
                          tempFilters.destinations =
                            tempFilters.destinations.concat(
                              setCountriesOfContinent(item)
                            );
                          /* console.log(setCountriesOfContinent(item)); */
                          tempFilters.categories = [""];
                          setAdvFilters(tempFilters);
                          setShowSearch(false);
                          setSearchTextModal("");
                          history.push("/adventures/");
                        }}
                      >
                        <IonImg
                          style={{
                            width: "5em",
                            objectFit: "cover",
                            height: "50px",
                          }}
                          src={continentImage(item)}
                        ></IonImg>
                        <IonText style={{ marginLeft: "10px" }}>
                          {item}, {searchTextModalContinentNumber[i]}&nbsp;
                          {lang.label21.toLowerCase()}
                        </IonText>
                      </IonItem>
                    );
                  })}
                </IonList>
              </div>
            )}
            {searchTextModalCountry[1] && (
              <div>
                {/* <IonTitle>{lang.label279}</IonTitle> */}
                <IonList>
                  {searchTextModalCountry.map((item, i) => {
                    if (item[0] === "") return;
                    return (
                      <IonItem
                        className="user-info-item item-input-grey hover-anime"
                        key={`${i}`}
                        onClick={() => {
                          let tempFilters = { ...advFilters };
                          tempFilters.destinations = ["", item[1]];
                          tempFilters.categories = [""];
                          setAdvFilters(tempFilters);
                          setShowSearch(false);
                          setSearchTextModal("");
                          history.push("/adventures/");
                        }}
                      >
                        {/* <IonImg
                          style={{
                            width: "5em",
                            objectFit: "cover",
                            height: "50px",
                          }}
                          src={continentImage(item)}
                        ></IonImg> */}
                        <MapPin size={32} style={{ minWidth: "32px" }} />
                        <IonText style={{ marginLeft: "10px" }}>
                          {item[0]}, {searchTextModalCountryNumber[i]}&nbsp;
                          {lang.label21.toLowerCase()}
                        </IonText>
                      </IonItem>
                    );
                  })}
                </IonList>
              </div>
            )}
            {searchTextModalAdv[0].id !== "" && (
              <div>
                <IonTitle>{lang.label224}</IonTitle>
                <IonList>
                  {searchTextModalAdv.map((item: any, i: number) => (
                    <IonItem
                      key={`${i}`}
                      className="user-info-item item-input-grey hover-anime"
                      onClick={() => {
                        return (
                          setAdventureId(item.id),
                          setShowSearch(false),
                          setSearchTextModal(""),
                          history.push("/adventures/" + item.id)
                        );
                      }}
                    >
                      <IonImg
                        style={{
                          width: "5em",
                          objectFit: "cover",
                          height: "50px",
                        }}
                        src={
                          item.image !== "" ? item.imageData : defaultAdvImage
                        }
                        alt={
                          item.nameText.hasOwnProperty("CS")
                            ? item.nameText.CS
                            : item.nameText.EN
                        }
                      ></IonImg>
                      <IonLabel style={{ marginLeft: "10px" }}>
                        {checkProperty(item.nameText, language)}
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              </div>
            )}
            {searchTextModal.length > 2 &&
              searchTextModalAdv[0].id === "" &&
              !searchTextModalCountry[1] &&
              !searchTextModalContinent[1] && (
                <div
                  style={{
                    borderBottom: "1px solid #DEDEDE",
                    marginLeft: "25px",
                    marginRight: "25px",
                    padding: "0 0 10px 0",
                  }}
                >
                  <IonTitle
                    style={{
                      marginTop: "10px",
                      padding: 0,
                    }}
                  >
                    {lang.label445}
                  </IonTitle>
                </div>
              )}
            {searchTextModalAdv[0].id === "" &&
              searchTextModalDest[0].id === "" &&
              !searchTextModalContinent[1] &&
              !searchTextModalCountry[1] && (
                <div
                  style={{
                    marginLeft: "25px",
                    marginRight: "25px",
                    padding: 0,
                  }}
                >
                  <IonTitle
                    style={{
                      marginTop: "10px",
                      padding: 0,
                    }}
                  >
                    {lang.label446}
                  </IonTitle>
                </div>
              )}
            {searchTextModalAdv[0].id === "" &&
              searchTextModalDest[0].id === "" &&
              !searchTextModalContinent[1] &&
              !searchTextModalCountry[1] && (
                <IonList style={{ paddingBottom: 0 }}>
                  <IonItem
                    style={{ marginBottom: 0 }}
                    className="user-info-item item-input-grey hover-anime"
                    onClick={() => {
                      let tempFilt = JSON.parse(JSON.stringify(advFilters));
                      tempFilt.destinations = [""];
                      setAdvFilters(tempFilt);
                      history.replace("/adventures");
                    }}
                  >
                    <div style={{ width: "5em", textAlign: "center" }}>
                      <MapPin size={32} style={{ minWidth: "32px" }} />
                    </div>
                    <IonText style={{ marginLeft: "10px" }}>
                      {lang.label369}
                    </IonText>
                  </IonItem>
                </IonList>
              )}
            {searchTextModalAdv[0].id === "" &&
              searchTextModalDest[0].id === "" &&
              !searchTextModalContinent[1] &&
              !searchTextModalCountry[1] &&
              Object.keys(continentFilter).map((key: string, index: number) => {
                if (continentFilter[key]) {
                  return (
                    <div key={`${key}`}>
                      <IonList style={{ paddingBottom: 0 }}>
                        <IonItem
                          style={{ marginBottom: 0 }}
                          className="user-info-item item-input-grey hover-anime"
                          onClick={() => {
                            let tempFilters = JSON.parse(
                              JSON.stringify(advFilters)
                            );
                            tempFilters.destinations = [""];
                            tempFilters.destinations =
                              tempFilters.destinations.concat(
                                setCountriesOfContinent(key)
                              );
                            /* console.log(setCountriesOfContinent(item)); */
                            tempFilters.categories = [""];
                            setAdvFilters(tempFilters);
                            setShowSearch(false);
                            setSearchTextModal("");
                            history.push("/adventures/");
                          }}
                        >
                          <IonImg
                            style={{
                              width: "5em",
                              objectFit: "cover",
                              height: "50px",
                            }}
                            src={continentImage(key)}
                            alt={key}
                          ></IonImg>
                          <IonLabel style={{ marginLeft: "10px" }}>
                            {setEnumText(key, lang)}
                          </IonLabel>
                        </IonItem>
                      </IonList>

                      {dummyCountries.map((countries, i) => {
                        if (countries[0] === key) {
                          return (
                            <IonList
                              style={{ paddingTop: 0, paddingBottom: 0 }}
                              key={i}
                            >
                              {countries.map((country, y) => {
                                if (y === 0 || y > 9) return;

                                return (
                                  <IonItem
                                    key={y}
                                    style={{ marginBottom: 0 }}
                                    className="user-info-item item-input-grey hover-anime"
                                    onClick={() => {
                                      let tempFilters = JSON.parse(
                                        JSON.stringify(advFilters)
                                      );
                                      tempFilters.destinations = [""];
                                      tempFilters.destinations =
                                        tempFilters.destinations.concat(
                                          country
                                        );
                                      tempFilters.categories = [""];
                                      setAdvFilters(tempFilters);
                                      setShowSearch(false);
                                      setSearchTextModal("");
                                      history.push("/adventures/");
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "5em",
                                        textAlign: "center",
                                      }}
                                    >
                                      <MapPin
                                        size={32}
                                        style={{ minWidth: "32px" }}
                                      />
                                    </div>
                                    <IonLabel style={{ marginLeft: "10px" }}>
                                      {setCountryName(country, language)}
                                    </IonLabel>
                                  </IonItem>
                                );
                              })}
                            </IonList>
                          );
                        } else return;
                      })}
                    </div>
                  );
                }
              })}

            {/* {searchTextModalDest[0].id !== "" && (
              <div>
                <IonTitle>{lang.label280}</IonTitle>
                <IonList>
                  {searchTextModalDest.map((item, i) => (
                    <IonItem
                      key={`${i}`}
                      className="user-info-item item-input-grey"
                      onClick={() => {
                        return (
                          setAdventureId(item.id),
                          setShowSearch(false),
                          setSearchTextModal(""),
                          history.push("/adventures/" + item.id)
                        );
                      }}
                    >
                      <IonImg
                        style={{
                          width: "5em",
                          objectFit: "cover",
                          height: "50px",
                        }}
                        src={
                          item.image !== "" ? item.imageData : defaultAdvImage
                        }
                        alt={
                          item.nameText.hasOwnProperty("CS")
                            ? item.nameText.CS
                            : item.nameText.EN
                        }
                      ></IonImg>
                      <IonLabel style={{ marginLeft: "10px" }}>
                        {item.nameText.CS}
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              </div>
            )} */}
          </IonContent>
        </IonModal>

        {/* <IonModal
          isOpen={showModalPart}
          cssClass="part-modal"
          onDidDismiss={() => setShowModalPart(false)}
        >
          <IonText>
            {lang.label196} {partnerName}.
          </IonText>
        </IonModal> */}

        {/* <IonSlides pager={true} options={slideOpts3} className="tab1-slider">
          <IonSlide>
            <h1
              style={{
                textAlign: "left",
                width: "100%",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            >
              {lang.label25}
            </h1>
          </IonSlide> */}
        {/* <IonSlide>
            <h1
              style={{
                textAlign: "left",
                width: "100%",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            >
              Dobrodružství na
              <br />
              poslední chvíli!
            </h1>
          </IonSlide>
          <IonSlide>
            <h1
              style={{
                textAlign: "left",
                width: "100%",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            >
              Nemáš dovolenou?
              <br />
              Pojeď na víkendOFFku!
            </h1>
          </IonSlide>
          <IonSlide>
            <h1
              style={{
                textAlign: "left",
                width: "100%",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            >
              Poslední volná místa
              <br />
              na dobrodružství.
            </h1>
          </IonSlide> */}
        {/* </IonSlides> */}
        <div
          style={{
            top: 0,
            maxHeight: "84vh",
            maxWidth: "1920px",
            overflow: "hidden",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <PromoVideo appWidth={appWidth} />
        </div>
        <div className="web-box" style={{ transform: "translateY(-30px)" }}>
          <IonSearchbar
            style={{ background: "white" }}
            className="main-search tab1-search"
            value=""
            placeholder={lang.label16}
            onClick={(e) => {
              e.preventDefault();
              setShowSearch(true);
            }}
          ></IonSearchbar>

          <IonSegment
            scrollable
            onIonChange={(e) => setSegment(e.detail.value)}
            value={segment}
            style={{ overflowX: "hidden", justifyContent: "center" }}
          >
            <IonSegmentButton value="default" className="segment-picker">
              <IonLabel>{lang.label7}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="categories" className="segment-picker">
              <IonLabel>{lang.label8}</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {renderSwitch(segment)}

          <div style={{ marginLeft: "25px", marginTop: "10px" }}>
            {lang.label17}
          </div>

          <IonSlides
            pager={false}
            options={
              /* deviceInfo.platform === "web" */
              appWidth > 991 ? slideOpts3 : slideOpts2
            }
            style={{ height: "auto", width: "100%" }}
          >
            {!showLoading &&
              sortedItems.map((item: any, i: number) => {
                /* console.log(deviceInfo);
                console.log(platformInfo); */

                return (
                  <IonSlide style={{ textAlign: "left" }} key={`${i}`}>
                    <IonCard
                      key={`${i}`}
                      className="adv-card"
                      style={{ minWidth: "235px" }}
                    >
                      {showLoading2 ? (
                        <IonSpinner
                          className="spinner-loading2"
                          color="primary"
                        />
                      ) : (
                        <div>
                          <div className="card-cont">
                            <img
                              src={
                                item.image !== ""
                                  ? item.imageData
                                  : defaultAdvImage
                              }
                              className="adventure-cover hover-anime"
                              alt="adventure background"
                              onClick={() => {
                                return (
                                  setAdventureId(item.id),
                                  history.push("adventures/" + item.id)
                                );
                              }}
                            />

                            <IonChip
                              className="adv-chip"
                              onClick={() => {
                                return (
                                  setAdventureId(item.id),
                                  history.push("adventures/" + item.id)
                                );
                              }}
                            >
                              <IonLabel>
                                {setCountryName(
                                  item.locations[0].country,
                                  language
                                )}
                              </IonLabel>
                            </IonChip>
                            {item.categories.map((category: any, i: number) => {
                              return (
                                <IonChip
                                  className={"adv-chip" + i}
                                  key={`${i}`}
                                  onClick={() => {
                                    return (
                                      setAdventureId(item.id),
                                      history.push("adventures/" + item.id)
                                    );
                                  }}
                                >
                                  <IonLabel>
                                    {setEnumText(category, lang)}
                                  </IonLabel>
                                </IonChip>
                              );
                            })}
                            <IonChip
                              className="adv-like"
                              onClick={
                                authValues.user.id === "guest"
                                  ? (e) => {
                                      e.preventDefault();
                                      setShowGuestModal(true);
                                    }
                                  : () => {
                                      let sortedItemsTemp = [...sortedItems];
                                      sortedItemsTemp[i].isLiked =
                                        !item.isLiked;
                                      setSortedItems(sortedItemsTemp);
                                      setLike(
                                        item.id,
                                        "adventures",
                                        sortedItemsTemp[i].isLiked,
                                        token
                                      );
                                    }
                              }
                            >
                              <Heart
                                className="heart-css"
                                size={24}
                                weight={item.isLiked ? "fill" : "duotone"}
                                color="#3fd0d4"
                              />
                            </IonChip>
                            {/* <IonChip
                            className="adv-partner"
                            onClick={() => {
                              setPartnerName(item.partner);
                              setShowModalPart(true);
                            }}
                          >
                            <Handshake size={24} color="#3fd0d4" />
                          </IonChip> */}
                            <IonChip
                              className="adv-guide-chip"
                              onClick={
                                authValues.user.id === "guest"
                                  ? (e) => {
                                      e.preventDefault();
                                      setShowGuestModal(true);
                                    }
                                  : () => {
                                      setUserId(item.guide.id);
                                      history.push(
                                        "/userpage/" + item.guide.id
                                      );
                                    }
                              }
                            >
                              <IonLabel>{item.guide.firstName}</IonLabel>
                            </IonChip>
                            <IonAvatar
                              className="guide-avatar hover-anime"
                              onClick={
                                authValues.user.id === "guest"
                                  ? (e) => {
                                      e.preventDefault();
                                      setShowGuestModal(true);
                                    }
                                  : () => {
                                      setUserId(item.guide.id);
                                      history.push(
                                        "/userpage/" + item.guide.id
                                      );
                                    }
                              }
                            >
                              {item.guide.profileImage === null ? (
                                <UserCircle
                                  size={78}
                                  fill="black"
                                  weight="fill"
                                  style={{
                                    marginLeft: "-7px",
                                    marginTop: "-7px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={item.guide.profileImageData}
                                  alt="guide"
                                  className="guide-avatar-img"
                                />
                              )}
                            </IonAvatar>
                          </div>
                          <IonCardContent
                            style={{ paddingTop: "8px" }}
                            className="hover-anime"
                            onClick={() => {
                              return (
                                setAdventureId(item.id),
                                history.push("adventures/" + item.id)
                              );
                            }}
                          >
                            <IonFooter>
                              {item.rating !== null && (
                                <div
                                  style={{
                                    display: "inline",
                                    marginRight: "5px",
                                  }}
                                >
                                  <Rate
                                    count={5}
                                    value={item.rating}
                                    allowHalf={true}
                                    disabled={true}
                                    className="rating-stars"
                                  />{" "}
                                  {item.rating} ({item.ratingCount})
                                </div>
                              )}
                              <div
                                style={{
                                  display: "inline-block",
                                  verticalAlign: "top",
                                  width: "30px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="20"
                                  fill="rgb(63, 208, 212)"
                                  viewBox="0 0 400 256"
                                >
                                  <rect
                                    width="52"
                                    height="256"
                                    fill="none"
                                  ></rect>
                                  <line
                                    x1="340"
                                    y1="208"
                                    x2="28"
                                    y2="208"
                                    fill="none"
                                    stroke="rgb(63, 208, 212)"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="16"
                                  ></line>
                                  <rect
                                    x="42"
                                    y="160"
                                    width="56"
                                    height="48"
                                    strokeWidth="16"
                                    stroke="rgb(63, 208, 212)"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill={
                                      item.difficulty >= 1 ? "true" : "none"
                                    }
                                  ></rect>
                                  <rect
                                    x="100"
                                    y="140"
                                    width="56"
                                    height="68"
                                    strokeWidth="16"
                                    stroke="rgb(63, 208, 212)"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill={
                                      item.difficulty >= 2 ? "true" : "none"
                                    }
                                  ></rect>
                                  <rect
                                    x="158"
                                    y="120"
                                    width="56"
                                    height="88"
                                    strokeWidth="16"
                                    stroke="rgb(63, 208, 212)"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill={
                                      item.difficulty >= 3 ? "true" : "none"
                                    }
                                  ></rect>
                                  <rect
                                    x="216"
                                    y="100"
                                    width="56"
                                    height="108"
                                    strokeWidth="16"
                                    stroke="rgb(63, 208, 212)"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill={
                                      item.difficulty >= 4 ? "true" : "none"
                                    }
                                  ></rect>
                                  <rect
                                    x="274"
                                    y="80"
                                    width="56"
                                    height="128"
                                    strokeWidth="16"
                                    stroke="rgb(63, 208, 212)"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill={
                                      item.difficulty >= 5 ? "true" : "none"
                                    }
                                  ></rect>
                                </svg>
                              </div>
                              <IonCol>
                                {item.difficulty}
                                /5
                              </IonCol>
                            </IonFooter>
                            <IonFooter
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              {checkProperty(item.nameText, language)}
                            </IonFooter>
                            <IonFooter style={{ fontSize: "13px" }}>
                              {moment(item.dates[0].begin, "YYYY-MM-DD").format(
                                "DD.MM."
                              )}{" "}
                              -{" "}
                              {moment(item.dates[0].end, "YYYY-MM-DD").format(
                                "DD.MM.YYYY"
                              )}{" "}
                              • {item.dates.length}{" "}
                              {item.dates.length > 1
                                ? lang.label194
                                : lang.label195}{" "}
                              • {getTripLength(item)} {getLowestPrice(item)}
                            </IonFooter>
                          </IonCardContent>
                        </div>
                      )}
                    </IonCard>
                  </IonSlide>
                );
              })}
          </IonSlides>

          <div style={{ marginLeft: "25px", marginTop: "10px" }}>
            {lang.label433}
          </div>

          <IonSlides
            pager={false}
            options={appWidth > 991 ? slideOpts3 : slideOpts2}
            style={{ height: "auto" }}
          >
            {!showLoading &&
              lastSpotItems.map((item: any, i: number) => {
                return (
                  <IonSlide style={{ textAlign: "left" }} key={`${i}`}>
                    <IonCard
                      key={`${i}`}
                      className="adv-card"
                      style={{ minWidth: "235px" }}
                    >
                      {showLoading3 ? (
                        <IonSpinner
                          className="spinner-loading2"
                          color="primary"
                        />
                      ) : (
                        <div>
                          <div className="card-cont">
                            <img
                              src={
                                item.image !== ""
                                  ? item.imageData
                                  : defaultAdvImage
                              }
                              className="adventure-cover hover-anime"
                              alt="adventure background"
                              onClick={() => {
                                return (
                                  setAdventureId(item.id),
                                  history.push("adventures/" + item.id)
                                );
                              }}
                            />

                            <IonChip
                              className="adv-chip"
                              onClick={() => {
                                return (
                                  setAdventureId(item.id),
                                  history.push("adventures/" + item.id)
                                );
                              }}
                            >
                              <IonLabel>
                                {setCountryName(
                                  item.locations[0].country,
                                  language
                                )}
                              </IonLabel>
                            </IonChip>
                            {item.categories.map((category: any, i: number) => {
                              return (
                                <IonChip
                                  className={"adv-chip" + i}
                                  key={`${i}`}
                                  onClick={() => {
                                    return (
                                      setAdventureId(item.id),
                                      history.push("adventures/" + item.id)
                                    );
                                  }}
                                >
                                  <IonLabel>
                                    {setEnumText(category, lang)}
                                  </IonLabel>
                                </IonChip>
                              );
                            })}
                            <IonChip
                              className="adv-like"
                              onClick={
                                authValues.user.id === "guest"
                                  ? (e) => {
                                      e.preventDefault();
                                      setShowGuestModal(true);
                                    }
                                  : () => {
                                      let sortedItemsTemp = [...sortedItems];
                                      sortedItemsTemp[i].isLiked =
                                        !item.isLiked;
                                      setSortedItems(sortedItemsTemp);
                                      setLike(
                                        item.id,
                                        "adventures",
                                        sortedItemsTemp[i].isLiked,
                                        token
                                      );
                                    }
                              }
                            >
                              <Heart
                                className="heart-css"
                                size={24}
                                weight={item.isLiked ? "fill" : "duotone"}
                                color="#3fd0d4"
                              />
                            </IonChip>
                            {/* <IonChip
                            className="adv-partner"
                            onClick={() => {
                              setPartnerName(item.partner);
                              setShowModalPart(true);
                            }}
                          >
                            <Handshake size={24} color="#3fd0d4" />
                          </IonChip> */}
                            <IonChip
                              className="adv-guide-chip"
                              onClick={
                                authValues.user.id === "guest"
                                  ? (e) => {
                                      e.preventDefault();
                                      setShowGuestModal(true);
                                    }
                                  : () => {
                                      setUserId(item.guide.id);
                                      history.push(
                                        "/userpage/" + item.guide.id
                                      );
                                    }
                              }
                            >
                              <IonLabel>{item.guide.firstName}</IonLabel>
                            </IonChip>
                            <IonAvatar
                              className="guide-avatar hover-anime"
                              onClick={
                                authValues.user.id === "guest"
                                  ? (e) => {
                                      e.preventDefault();
                                      setShowGuestModal(true);
                                    }
                                  : () => {
                                      setUserId(item.guide.id);
                                      history.push(
                                        "/userpage/" + item.guide.id
                                      );
                                    }
                              }
                            >
                              {item.guide.profileImage === null ? (
                                <UserCircle
                                  size={78}
                                  fill="black"
                                  weight="fill"
                                  style={{
                                    marginLeft: "-7px",
                                    marginTop: "-7px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={item.guide.profileImageData}
                                  alt="guide"
                                  className="guide-avatar-img"
                                />
                              )}
                            </IonAvatar>
                          </div>
                          <IonCardContent
                            style={{ paddingTop: "8px" }}
                            className="hover-anime"
                            onClick={() => {
                              return (
                                setAdventureId(item.id),
                                history.push("adventures/" + item.id)
                              );
                            }}
                          >
                            <IonFooter>
                              {item.rating !== null && (
                                <div
                                  style={{
                                    display: "inline",
                                    marginRight: "5px",
                                  }}
                                >
                                  <Rate
                                    count={5}
                                    value={item.rating}
                                    allowHalf={true}
                                    disabled={true}
                                    className="rating-stars"
                                  />{" "}
                                  {item.rating} ({item.ratingCount})
                                </div>
                              )}
                              <div
                                style={{
                                  display: "inline-block",
                                  verticalAlign: "top",
                                  width: "30px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="20"
                                  fill="rgb(63, 208, 212)"
                                  viewBox="0 0 400 256"
                                >
                                  <rect
                                    width="52"
                                    height="256"
                                    fill="none"
                                  ></rect>
                                  <line
                                    x1="340"
                                    y1="208"
                                    x2="28"
                                    y2="208"
                                    fill="none"
                                    stroke="rgb(63, 208, 212)"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="16"
                                  ></line>
                                  <rect
                                    x="42"
                                    y="160"
                                    width="56"
                                    height="48"
                                    strokeWidth="16"
                                    stroke="rgb(63, 208, 212)"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill={
                                      item.difficulty >= 1 ? "true" : "none"
                                    }
                                  ></rect>
                                  <rect
                                    x="100"
                                    y="140"
                                    width="56"
                                    height="68"
                                    strokeWidth="16"
                                    stroke="rgb(63, 208, 212)"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill={
                                      item.difficulty >= 2 ? "true" : "none"
                                    }
                                  ></rect>
                                  <rect
                                    x="158"
                                    y="120"
                                    width="56"
                                    height="88"
                                    strokeWidth="16"
                                    stroke="rgb(63, 208, 212)"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill={
                                      item.difficulty >= 3 ? "true" : "none"
                                    }
                                  ></rect>
                                  <rect
                                    x="216"
                                    y="100"
                                    width="56"
                                    height="108"
                                    strokeWidth="16"
                                    stroke="rgb(63, 208, 212)"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill={
                                      item.difficulty >= 4 ? "true" : "none"
                                    }
                                  ></rect>
                                  <rect
                                    x="274"
                                    y="80"
                                    width="56"
                                    height="128"
                                    strokeWidth="16"
                                    stroke="rgb(63, 208, 212)"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill={
                                      item.difficulty >= 5 ? "true" : "none"
                                    }
                                  ></rect>
                                </svg>
                              </div>
                              <IonCol>
                                {item.difficulty}
                                /5
                              </IonCol>
                            </IonFooter>
                            <IonFooter
                              style={{ fontWeight: "bold", color: "black" }}
                            >
                              {checkProperty(item.nameText, language)}
                            </IonFooter>
                            <IonFooter style={{ fontSize: "13px" }}>
                              {moment(item.dates[0].begin, "YYYY-MM-DD").format(
                                "DD.MM."
                              )}{" "}
                              -{" "}
                              {moment(item.dates[0].end, "YYYY-MM-DD").format(
                                "DD.MM.YYYY"
                              )}{" "}
                              • {item.dates.length}{" "}
                              {item.dates.length > 1
                                ? lang.label194
                                : lang.label195}{" "}
                              • {getTripLength(item)} {getLowestPrice(item)}
                            </IonFooter>
                          </IonCardContent>
                        </div>
                      )}
                    </IonCard>
                  </IonSlide>
                );
              })}
          </IonSlides>

          {(authValues.user.id === "guest" || !authValues.user.guide) && (
            <div style={{ marginLeft: "25px", marginTop: "10px" }}>
              {lang.label434}
            </div>
          )}
          {authValues.user.id === "guest" && (
            <IonCard
              /* className="adv-card" */
              style={{
                /* width: "100%", */
                maxWidth: "100vw",
                borderRadius: "15px",
                border: "solid 1px #3fd0d4",
              }}
              onClick={() => {
                history.push("/becomeatraveler/");
              }}
              className="hover-anime"
            >
              <div>
                <div className="card-cont">
                  <img
                    src={becomeatrav}
                    /* className="adventure-cover" */
                    alt="adventure background"
                    style={{
                      width: "100%",
                      maxHeight: "50vh",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <IonCardContent style={{ paddingTop: "8px" }}>
                  <IonFooter style={{ fontWeight: "bold", color: "black" }}>
                    {lang.label465}
                  </IonFooter>
                </IonCardContent>
              </div>
            </IonCard>
          )}
          {!authValues.user.guide && authValues.user.id !== "guest" && (
            <IonCard
              /* className="adv-card" */
              style={{
                /* width: "100%", */
                maxWidth: "100vw",
                borderRadius: "15px",
                border: "solid 1px #3fd0d4",
              }}
              className="hover-anime"
              onClick={() => {
                if (authValues.user.id === "guest") {
                  setShowGuestModal(true);
                } else return history.push("/becaguide/");
              }}
            >
              <div>
                <div className="card-cont">
                  <img
                    src={becomeGuideImage}
                    /* className="adventure-cover" */
                    alt="adventure background"
                    style={{
                      width: "100%",
                      maxHeight: "50vh",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <IonCardContent style={{ paddingTop: "8px" }}>
                  <IonFooter style={{ fontWeight: "bold", color: "black" }}>
                    {lang.label26}
                  </IonFooter>
                </IonCardContent>
              </div>
            </IonCard>
          )}
        </div>
        <FooterPageMap />
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
