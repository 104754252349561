import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonFooter,
  IonImg,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  IonBackButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonList,
  IonItem,
  IonChip,
  IonAvatar,
  IonTextarea,
  IonReorderGroup,
  IonReorder,
  IonSpinner,
  IonLoading,
  IonDatetime,
  IonThumbnail,
  IonToast,
} from "@ionic/react";

import { Camera, CameraDirection, CameraResultType } from "@capacitor/camera";

import "./Tab1.css";

import "./adventure.css";

import moment from "moment";

import defaultAdvImage from "../assets/images/default_adventure_image.jpg";

import axios from "axios";

import Rate from "rc-rate";
import "rc-rate/assets/index.css";
import "./myAdventure.css";

import AuthContext from "../my-context";
import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router";

import { useEffect, useGlobal } from "reactn";

import {
  ArrowLeft,
  CaretDown,
  CaretUp,
  Files,
  GlobeHemisphereWest,
  Heart,
  MinusCircle,
  Pencil,
  PlusCircle,
  UserCircle,
  XCircle,
  Image,
  ArrowsClockwise,
} from "phosphor-react";
import setLike from "../functions/setLike";
import showImage from "../functions/showImage";
import getCroppedImg from "../components/cropImage";
import Cropper from "react-easy-crop";
import setCountryName from "../functions/setCountryName";
import setEnumText from "../functions/setEnumText";
import { nanoid } from "nanoid";
import { ItemReorderEventDetail } from "@ionic/core";
import checkProperty from "../functions/checkProperty";
import Header from "./header";
import FooterPageMap from "./FooterPageMap";

const MyAdventure: React.FC = () => {
  const { token, lang, db, checkImageCache } = React.useContext(AuthContext);
  const [showLoading, setShowLoading] = useState(true);
  const [newAdventureLangCurrent, setNewAdventureLangCurrent] = useGlobal(
    "newAdventureLangCurrent"
  );
  const [appWidth] = useGlobal("appWidth");

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast2, setShowToast2] = useState(false);
  const [toastMessage2, setToastMessage2] = useState("");
  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);
  const isThirdRender = useRef(false);

  const isFirstRender5 = useRef(true);
  const isThirdRender5 = useRef(false);

  const isFirstRender3 = useRef(true);
  const isThirdRender3 = useRef(false);

  const isFirstRender4 = useRef(true);
  const isThirdRender4 = useRef(false);

  const history = useHistory();

  const [imageData, setImageData] = useState("");
  const [coverImageData, setCoverImageData] = useState("");
  const [profileImageData, setProfileImageData] = useState("");

  async function fetchData() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    let advItem = { ...advData };
    api
      .get("/adventures/" + myAdventureId, config)
      .then(async (resp) => {
        /* const testicek = ; */
        /* testicek[0] !== ""
          ? */ setNewAdventureLangCurrent(Object.keys(resp.data.nameText)[0]);
        /* : setNewAdventureLangCurrent("EN"); */
        advItem = await getImageData(resp.data);
      })
      .then(() => {
        let editHelp = [...isDateEdited];
        advItem.dates.forEach((date: any, indexol: number) => {
          editHelp[indexol] = false;
        });
        setIsDateEdited(editHelp);
        let advTemp = JSON.parse(JSON.stringify(advItem.dates));
        setDates(advTemp);
        setAdvData(advItem);
        let temp = JSON.parse(JSON.stringify(advItem));
        setEditedAdventure(temp);

        let helpX = JSON.parse(JSON.stringify(temp));
        helpX.itinerary.map((obj: any) => (obj.id = nanoid()));
        setItinerary(helpX.itinerary);
        if (helpX.images.length === 0) {
          setImages([""]);
          setImagesDefault([""]);
        } else {
          setImages(helpX.images);
          setImagesDefault(JSON.parse(JSON.stringify(helpX.images)));
        }

        /* setImagesDataDefault(JSON.parse(JSON.stringify(helpX.images))); */
        setItineraryDefault(JSON.parse(JSON.stringify(helpX.itinerary)));
        setShowLoading(false);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  async function getImageData(item: any) {
    var imageDataTemp = "";
    var coverImageDataTemp = "";
    var profileImageDataTemp = "";

    if (await checkImageCache(item.image)) {
      imageDataTemp = await db.get(item.image);
    } else {
      if (item.image !== "" || item.image !== null) {
        imageDataTemp = await showImage(item.image, token);
        await db.set(item.image, imageDataTemp);
      } else imageDataTemp = "";
    }
    setImageData(imageDataTemp);
    const tempImgData = JSON.parse(JSON.stringify(imageDataTemp));
    setNewMainImage(tempImgData);
    if (await checkImageCache(item.guide.profileImage)) {
      profileImageDataTemp = await db.get(item.guide.profileImage);
    } else {
      if (item.guide.profileImage !== "") {
        if (item.guide.profileImage !== null) {
          profileImageDataTemp = await showImage(
            item.guide.profileImage,
            token
          );
          await db.set(item.guide.profileImage, profileImageDataTemp);
        }
      } else profileImageDataTemp = "";
    }
    setProfileImageData(profileImageDataTemp);
    /* if (await checkImageCache(item.guide.coverImage)) {
      coverImageDataTemp = await db.get(item.guide.coverImage);
    } else {
      if (item.guide.coverImage !== "") {
        if (item.guide.coverImage !== null) {
          coverImageDataTemp = await showImage(item.guide.coverImage, token);
          await db.set(item.guide.coverImage, coverImageDataTemp);
        }
      } else coverImageDataTemp = "";
    }
    setCoverImageData(coverImageDataTemp); */
    return item;
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const [, setAdventureId] = useGlobal("AdventrureId");
  const [myAdventureId] = useGlobal("myAdventureId");
  const [dateId, setDateId] = useGlobal("dateId");
  const [language] = useGlobal("language");
  const [, setUserId] = useGlobal("userId");

  const [isDatesEdited, setIsDatesEdited] = useState(false);
  const [isDateEdited, setIsDateEdited] = useState([false]);

  const [uploading, setUploading] = useState(false);
  const [uploading2, setUploading2] = useState(false);

  const [mark, setMark] = useState(0);
  const [indexOfDocs, setIndexOfDocs] = useState(0);

  const [itinerary, setItinerary] = useState<any>([
    {
      titleText: { CS: "", EN: "" },
      descriptionText: { CS: "", EN: "" },
      images: [""],
      id: "",
    },
  ]);

  const [images, setImages] = useState<any>([]);
  const [imagesDefault, setImagesDefault] = useState<any>([]);
  const [imagesData, setImagesData] = useState<any>([]);
  const [imagesDataDefault, setImagesDataDefault] = useState([]);

  const [itineraryDefault, setItineraryDefault] = useState([
    {
      titleText: { CS: "", EN: "" },
      descriptionText: { CS: "", EN: "" },
      images: [""],
      id: "",
    },
  ]);

  const [confirmModal, setConfirmModal] = useState(false);

  const [reorder, setReorder] = useState(true);

  const [advData, setAdvData] = useState<any>({
    id: "",
    image: "",
    imageData: "",
    rating: 0,
    ratingCount: 0,
    difficulty: 0,
    isLiked: false,
    documents: [""],
    partner: "",
    guide: {
      id: "",
      firstName: "",
      lastName: "",
      guide: false,
      admin: false,
      profileImage: "",
      profileImageData: "",
    },
    meals: "",
    transit: "",
    accommodation: "",
    categories: [""],
    country: "",
    locations: [
      {
        type: "",
        latitude: "",
        longitude: "",
        airport: "",
        country: "",
        city: "",
        address: "",
        countryText: {
          CS: "",
          EN: "",
        },
      },
    ],
    departure: {
      type: "",
      latitude: "",
      longitude: "",
      airport: "",
      country: "",
      city: "",
      address: "",
      countryText: "",
    },
    arrival: {
      type: "",
      latitude: "",
      longitude: "",
      airport: "",
      country: "",
      city: "",
      address: "",
      countryText: "",
    },
    dates: [
      {
        id: "",
        begin: "",
        end: "",
        prices: {
          EUR: 0,
          CZK: 0,
        },
        capacity: 0,
        free: 0,
        documents: [""],
      },
      {
        id: "",
        begin: "",
        end: "",
        prices: {
          EUR: 0,
          CZK: 0,
        },
        capacity: 0,
        free: 0,
        documents: [""],
      },
    ],
    itinerary: [
      {
        titleText: { CS: "", EN: "" },
        descriptionText: { CS: "", EN: "" },
        images: [""],
      },
    ],
    nameText: {
      CS: "",
      EN: "",
    },
    descriptionText: { CS: "", EN: "" },
    includedText: { CS: null, EN: null },
    notIncludedText: { CS: null, EN: null },
    accommodationText: { CS: null, EN: null },
    mealsText: { CS: null, EN: null },
    transitText: { CS: null, EN: null },
    difficultyText: { CS: null, EN: null },
    moreInfoText: { CS: null, EN: null },
  });

  const [editedAdventure, setEditedAdventure] = useState<any>({
    id: "",
    image: "",
    imageData: "",
    rating: 0,
    ratingCount: 0,
    difficulty: 0,
    isLiked: false,
    partner: "",
    guide: {
      id: "",
      firstName: "",
      lastName: "",
      guide: false,
      admin: false,
      profileImage: "",
      profileImageData: "",
    },
    meals: "",
    transit: "",
    accommodation: "",
    categories: [""],
    country: "",
    locations: [
      {
        type: "",
        latitude: "",
        longitude: "",
        airport: "",
        country: "",
        region: "",
        city: "",
        address: "",
        countryText: {
          CS: "",
          EN: "",
        },
      },
    ],
    departure: {
      type: "",
      latitude: "",
      longitude: "",
      airport: "",
      country: "",
      region: "",
      city: "",
      address: "",
      countryText: "",
    },
    arrival: {
      type: "",
      latitude: "",
      longitude: "",
      airport: "",
      region: "",
      country: "",
      city: "",
      address: "",
      countryText: "",
    },
    dates: [
      {
        id: "",
        begin: "",
        end: "",
        prices: {
          EUR: 0,
          CZK: 0,
        },
        capacity: 0,
        free: 0,
      },
      {
        id: "",
        begin: "",
        end: "",
        prices: {
          EUR: 0,
          CZK: 0,
        },
        capacity: 0,
        free: 0,
      },
    ],
    itinerary: [
      {
        titleText: { CS: "", EN: "" },
        descriptionText: { CS: "", EN: "" },
        images: [""],
      },
    ],
    nameText: {
      CS: "",
      EN: "",
    },
    descriptionText: { CS: "", EN: "" },
    includedText: { CS: null, EN: null },
    notIncludedText: { CS: null, EN: null },
    accommodationText: { CS: null, EN: null },
    mealsText: { CS: null, EN: null },
    transitText: { CS: null, EN: null },
    difficultyText: { CS: null, EN: null },
    moreInfoText: { CS: null, EN: null },
  });
  const [segment, setSegment] = useState<string | undefined>("default");

  const [adventurePreview, setAdventurePreview] = useState(false);
  const [adventureEdit, setAdventureEdit] = useState(false);
  const [documentsEdit, setDocumentsEdit] = useState(false);
  const [documentsEntireEdit, setDocumentsEntireEdit] = useState(false);

  const [documentsDateEdit, setDocumentsDateEdit] = useState([false]);

  const [newEntireDocuments, setNewEntireDocuments] = useState([
    {
      type: "",
      data: "",
      name: "",
    },
  ]);

  const [newDateDocuments, setNewDateDocuments] = useState([
    [
      {
        type: "",
        data: "",
        name: "",
      },
    ],
  ]);

  const [entireDocumentsDeletedId, setEntireDocumentsDeletedId] = useState([
    "",
  ]);

  const [dateDocumentsDeletedId, setDateDocumentsDeletedId] = useState([[""]]);

  const [documentsEntire, setDocumentsEntire] = useState<any>([]);
  const [documentsDate, setDocumentsDate] = useState<any>([[]]);
  const [documentsEntireDefault, setDocumentsEntireDefault] = useState<any>([]);
  const [documentsDateDefault, setDocumentsDateDefault] = useState<any>([[]]);

  function remDocument(type: string, index: number, dateIndex?: number) {
    let temp = [];
    switch (type) {
      case "ENTIRE":
        temp = [...documentsEntire];
        if (temp[index].id) {
          let tempDeletedId = [...entireDocumentsDeletedId];
          tempDeletedId = tempDeletedId.concat(temp[index].id);
          setEntireDocumentsDeletedId(tempDeletedId);
        }
        temp.splice(index, 1);
        setDocumentsEntire(temp);
        return;
      case "DATE":
        if (dateIndex !== undefined) {
          temp = [...documentsDate];
          if (temp[dateIndex][index].id) {
            let tempDeletedId2 = [...dateDocumentsDeletedId];
            tempDeletedId2[dateIndex] = tempDeletedId2[dateIndex].concat(
              temp[dateIndex][index].id
            );
            setDateDocumentsDeletedId(tempDeletedId2);
          }
          temp[dateIndex].splice(index, 1);
          setDocumentsDate(temp);
        }
        return;
      default:
        return;
    }
  }

  function parse(file: File) {
    // Always return a Promise
    return new Promise((resolve, reject) => {
      let content = "";
      const reader = new FileReader();
      // Wait till complete
      reader.onloadend = function (e: any) {
        content = e.target.result;
        /* const result = content.split(/\r\n|\n/); */
        resolve(content);
      };
      // Make sure to handle error states
      reader.onerror = function (e: any) {
        reject(e);
      };
      reader.readAsDataURL(file);
    });
  }

  const readFile = async (files: any, index?: number) => {
    let temp = [...files];
    for (var i = 0; i < files.length; i++) {
      temp[i] = {
        type: "DOCUMENT",
        name: files[i].name,
        data: await parse(files[i]),
      };
    }
    if (index !== undefined) {
      setIndexOfDocs(index);
      let temp2 = [...newDateDocuments];
      temp2[index] = temp;
      setNewDateDocuments(temp2);
    } else {
      setNewEntireDocuments(temp);
    }
  };

  const [showEditModal, setShowEditModal] = useState(false);

  const [showAddLangModal, setShowAddLangModal] = useState(false);

  const [dates, setDates] = useState([
    {
      id: "",
      begin: "",
      end: "",
      prices: {
        EUR: 0,
        CZK: 0,
      },
      capacity: 0,
      free: 0,
      documents: [""],
    },
  ]);

  const [dateData, setDateData] = useState({
    begin: "",
    end: "",
    prices: {
      EUR: 0,
      CZK: 0,
    },
    capacity: 0,
    documents: [""],
    reservations: [{ id: "", status: "" }],
  });
  const [emptyReservationsData] = useState([
    {
      id: "",
      status: "",
      people: [
        {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
      ],
      adventureDatesId: "",
      adventureId: "",
      success: false,
    },
  ]);
  const [reservationsData, setReservationsData] = useState(
    emptyReservationsData
  );

  const [newMainImage, setNewMainImage] = useState<string | undefined>("");

  const [isNameCSDisabled, setIsNameCSDisabled] = useState(true);
  const [isLanguagesDisabled, setIsLanguagesDisabled] = useState(true);
  const [isCategoriesDisabled, setIsCategoriesDisabled] = useState(true);
  const [isImageDisabled, setIsImageDisabled] = useState(true);
  const [isdescriptionTextDisabled, setIsdescriptionTextDisabled] =
    useState(true);
  const [isMealsDisabled, setIsMealsDisabled] = useState(true);
  const [isMealsTextDisabled, setIsMealsTextDisabled] = useState(true);
  const [isAccommodationDisabled, setIsAccommodationDisabled] = useState(true);
  const [isAccommodationTextDisabled, setIsAccommodationTextDisabled] =
    useState(true);
  const [isTransitDisabled, setIsTransitDisabled] = useState(true);
  const [isDifficultyDisabled, setIsDifficultyDisabled] = useState(true);
  const [isDifficultyTextDisabled, setIsDifficultyTextDisabled] =
    useState(true);
  const [isIncludedTextDisabled, setIsIncludedTextDisabled] = useState(true);
  const [isNotIncludedTextDisabled, setIsNotIncludedTextDisabled] =
    useState(true);
  const [isMoreInfoTextDisabled, setIsMoreInfoTextDisabled] = useState(true);
  const [isItineraryDisabled, setIsItineraryDisabled] = useState(true);
  const [isGalleryDisabled, setIsGalleryDisabled] = useState(true);
  const [isTransitTextDisabled, setIsTransitTextDisabled] = useState(true);

  useEffect(() => {
    if (dateData.begin !== "") {
      parseReservations();
    }
  }, [dateData]);

  const [croppedImage, setCroppedImage] = useState<null | string>(null);
  const [cameraImage, setCameraImage] = useState<string>();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any | null>(null);

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const [showCropModal, setShowCropModal] = useState(false);
  const [showSlow, setShowSlow] = useState(false);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        cameraImage,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
      setToastMessage(String(e));
      setShowToast(true);
      setUploading(false);
    }
  }, [croppedAreaPixels, rotation]);

  const handleInputChange = (event: any, i?: any) => {
    var helpx = { ...editedAdventure };

    switch (event.target.name) {
      case "name":
        helpx.nameText[newAdventureLangCurrent] = event.target.value;
        break;
      /* case "name" + i:
        helpx.nameText[Object.keys(editedAdventure.nameText)[1]] =
          event.target.value;
        break; */
      case "categories":
        if (event.target.value.length === 0) {
          helpx.categories = [""];
          break;
        }
        helpx.categories = event.target.value;
        break;
      case "descriptionText":
        helpx.descriptionText[newAdventureLangCurrent] = event.target.value;
        break;
      case "meals":
        helpx.meals = event.target.value;
        break;
      case "mealsText":
        helpx.mealsText[newAdventureLangCurrent] = event.target.value;
        break;
      case "accommodation":
        helpx.accommodation = event.target.value;
        break;
      case "accommodationText":
        helpx.accommodationText[newAdventureLangCurrent] = event.target.value;
        break;
      case "transit":
        helpx.transit = event.target.value;
        if (event.target.value === "AIRPLANE") {
          helpx.departure.type = "AIRPORT";
          helpx.departure.country = "";
          helpx.departure.region = "";
          helpx.departure.city = "";
          helpx.departure.address = "";
          helpx.arrival.type = "AIRPORT";
          helpx.arrival.type = "AIRPORT";
          helpx.arrival.country = "";
          helpx.arrival.region = "";
          helpx.arrival.city = "";
          helpx.arrival.address = "";
        } else {
          helpx.departure.type = "ADDRESS";
          helpx.departure.airport = "";
          helpx.arrival.type = "ADDRESS";
          helpx.arrival.airport = "";
        }
        break;
      case "transitText":
        helpx.transitText[newAdventureLangCurrent] = event.target.value;
        break;
      case "arrival":
        if (editedAdventure.transit === "AIRPLANE") {
          helpx.arrival.airport = event.target.value;
        } else {
          helpx.arrival.city = event.target.value;
        }
        break;
      case "departure":
        if (editedAdventure.transit === "AIRPLANE") {
          helpx.departure.airport = event.target.value;
        } else {
          helpx.departure.city = event.target.value;
        }
        break;
      case "difficulty":
        helpx.difficulty = Number(event.target.value);
        break;
      case "difficultyText":
        helpx.difficultyText[newAdventureLangCurrent] = event.target.value;
        break;
      case "includedText":
        helpx.includedText[newAdventureLangCurrent] = event.target.value;
        break;
      case "notIncludedText":
        helpx.notIncludedText[newAdventureLangCurrent] = event.target.value;
        break;
      case "moreInfoText":
        helpx.moreInfoText[newAdventureLangCurrent] = event.target.value;
        break;
      default:
        break;
    }
    setEditedAdventure(helpx);
  };

  useEffect(() => {
    if (!isFirstRender.current) {
      setShowCropModal(true);
      setTimeout(() => setShowSlow(true), 500);
    }
    isFirstRender.current = false;
  }, [cameraImage]);

  useEffect(() => {
    if (croppedImage !== null) {
      uploadImage(croppedImage);
    }
  }, [croppedImage]);

  useEffect(() => {
    if (uploading) showCroppedImage();
  }, [uploading]);

  useEffect(() => {
    let temp = [...documentsEntire];
    for (var i = 0; i < newEntireDocuments.length; i++) {
      temp.push(newEntireDocuments[i]);
    }
    setDocumentsEntire([...temp]);
  }, [newEntireDocuments]);

  useEffect(() => {
    let temp = JSON.parse(JSON.stringify(documentsDate));
    for (var i = 0; i < newDateDocuments[indexOfDocs].length; i++) {
      temp[indexOfDocs].push(newDateDocuments[indexOfDocs][i]);
    }
    setDocumentsDate(temp);
  }, [newDateDocuments]);

  const noop = () => {};

  const FileInput = ({
    index,
    value,
    onChange = noop,
    ...rest
  }: {
    index?: number;
    value: Array<{ type: string; data: string; name: string }>;
    onChange: any;
  }) => (
    <div style={{ height: "36px", width: "100%" }} className="text-center">
      <label>
        <PlusCircle size={36} /* className="add-date" */ style={{}} />
        <IonText
          style={{
            display: "table-caption",
            width: "max-content",
            marginBottom: "6px",
          }}
        >
          {lang.label316}
        </IonText>
        <input
          {...rest}
          style={{ display: "none" }}
          type="file"
          multiple
          onChange={(e: any) => {
            onChange([...e.target.files], index);
          }}
        />
      </label>
    </div>
  );

  function saveEditedLang() {
    let tempAdv = { ...advData };
    let tempEditedAdv = JSON.parse(JSON.stringify(editedAdventure));
    tempAdv.nameText = tempEditedAdv.nameText;
    tempAdv.descriptionText = tempEditedAdv.descriptionText;
    tempAdv.includedText = tempEditedAdv.includedText;
    tempAdv.notIncludedText = tempEditedAdv.notIncludedText;
    tempAdv.accommodationText = tempEditedAdv.accommodationText;
    tempAdv.mealsText = tempEditedAdv.mealsText;
    tempAdv.transitText = tempEditedAdv.transitText;
    tempAdv.difficultyText = tempEditedAdv.difficultyText;
    tempAdv.moreInfoText = tempEditedAdv.moreInfoText;
    tempAdv.itinerary = tempEditedAdv.itinerary;
    saveEditedAdventure(tempAdv, 20);
  }

  async function saveEditedAdventure(temp: any, mark: number) {
    let tempData = { ...temp };
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    return api
      .patch("/adventures/" + advData.id, tempData, config)
      .then((response) => {
        setAdvData(response.data);
        switch (mark) {
          case 1:
            return setIsNameCSDisabled(true);
          /* case 2:
            return setIsNameENDisabled(true); */
          case 3:
            return setIsCategoriesDisabled(true);
          case 4:
            const imgDataTemp = JSON.parse(JSON.stringify(newMainImage));
            setImageData(imgDataTemp);
            return setIsImageDisabled(true);
          case 5:
            return setIsdescriptionTextDisabled(true);
          case 6:
            return setIsMealsDisabled(true);
          case 7:
            return setIsMealsTextDisabled(true);
          case 8:
            return setIsAccommodationDisabled(true);
          case 9:
            return setIsAccommodationTextDisabled(true);
          case 10:
            return setIsTransitDisabled(true);
          case 11:
            return setIsDifficultyDisabled(true);
          case 12:
            return setIsDifficultyTextDisabled(true);
          case 13:
            return setIsIncludedTextDisabled(true);
          case 14:
            return setIsNotIncludedTextDisabled(true);
          case 15:
            return setIsMoreInfoTextDisabled(true);
          case 16:
            return setIsItineraryDisabled(true);
          case 17:
            return setIsTransitTextDisabled(true);
          case 18:
            setShowEditModal(false);
            setEntireDocumentsDeletedId([""]);
            setDocumentsEntireEdit(false);
            return;
          case 19:
            setShowEditModal(false);
            let tempix = [...dateDocumentsDeletedId];
            tempix[indexOfDocs] = [""];
            setDateDocumentsDeletedId(tempix);
            let tempiy = [...documentsDateEdit];
            tempiy[indexOfDocs] = false;
            setDocumentsDateEdit(tempiy);
            return;
          case 20:
            setShowAddLangModal(false);
            setIsLanguagesDisabled(true);
            return;
          case 28:
            setIsGalleryDisabled(true);
            return;
          default:
            break;
        }
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  const addIty = () => {
    let editedAdventureTemp = { ...editedAdventure };
    editedAdventureTemp.itinerary.push({
      titleText: { CS: "", EN: "" },
      descriptionText: { CS: "", EN: "" },
      images: [""],
    });
    setEditedAdventure(editedAdventureTemp);
    let arrIty = [...itinerary];
    arrIty.push({
      titleText: { CS: "", EN: "" },
      descriptionText: { CS: "", EN: "" },
      images: [""],
      id: nanoid(),
    });
    setItinerary(arrIty);
  };

  const remIty = (index: number) => {
    let editedAdventureTemp = { ...editedAdventure };
    editedAdventureTemp.itinerary.splice(index, 1);
    setEditedAdventure(editedAdventureTemp);

    let arrIty = [...itinerary];
    arrIty.splice(index, 1);
    setItinerary(arrIty);
  };

  const remImage = (index: number, indexis: number) => {
    let editedAdventureTemp = { ...editedAdventure };
    editedAdventureTemp.itinerary[index].images.splice(indexis, 1);
    if (editedAdventureTemp.itinerary[index].images.length === 0) {
      editedAdventureTemp.itinerary[index].images.push("");
    }
    setEditedAdventure(editedAdventureTemp);

    let itineraryTemp = [...itinerary];
    itineraryTemp[index].images.splice(indexis, 1);
    if (itineraryTemp[index].images.length === 0) {
      itineraryTemp[index].images.push("");
    }
    setItinerary(itineraryTemp);
  };

  const moved = (array: any, from: number, to: number, on = 1) => {
    return (
      (array = array.slice()),
      array.splice(to, 0, ...array.splice(from, on)),
      array
    );
  };

  function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
    event.detail.complete();
    let editedAdventureTemp = { ...editedAdventure };
    editedAdventureTemp.itinerary = moved(
      editedAdventureTemp.itinerary,
      event.detail.from,
      event.detail.to
    );

    let arrHelp = [...itinerary];
    arrHelp = moved(arrHelp, event.detail.from, event.detail.to);
    arrHelp.forEach((element) => (element.id = nanoid()));

    setItinerary(arrHelp);
    setEditedAdventure(editedAdventureTemp);
  }

  const handlePictureClick2 = async (index: number) => {
    const image = await takePicture();
    if (image) {
      setUploading(true);
      uploadImage2(image, index);
    }
  };

  async function uploadFiles() {
    let tempDocs = [...documentsEntire];
    Promise.all(
      documentsEntire.map(async (doc: any, index: number) => {
        if (!doc.id && doc.data !== "") {
          const api = axios.create({
            baseURL: `https://app.kangelo.com/`,
          });
          var config = {
            headers: {
              "X-Auth-Token": token,
            },
          };
          return api
            .post("/media", doc, config)
            .then((res) => {
              tempDocs[index] = res.data;
              return res.data.id;
            })
            .catch((err) => {
              // what now?
              console.log(err.data);
            });
        }
      })
    ).then((res) => {
      let temp = JSON.parse(JSON.stringify(advData));
      let tempDocuments = [""];
      tempDocuments = temp.documents.filter(
        (docu: string) => !entireDocumentsDeletedId.includes(docu)
      );
      temp.documents = tempDocuments;
      let tempRes = res.filter((d) => d !== undefined);
      temp.documents = temp.documents.concat(tempRes);
      /*       setAdvData(temp);*/

      setDocumentsEntire(tempDocs);
      saveEditedAdventure(temp, mark);
      /* setShowEditModal(false);
      setDocumentsEntireEdit(false); */
    });
  }

  async function deleteAdv() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    return api
      .delete("/adventures/" + myAdventureId, config)
      .then((res) => {
        if (res.data.success) {
          history.replace("/myadventures");
        } else {
          setConfirmModal(false);
          setToastMessage(res.data.message);
          setShowToast(true);
        }
      })
      .catch((err) => {
        // what now?
        setToastMessage("Test Failed");
        setShowToast(true);
        console.log(err.data);
      });
  }

  async function uploadFiles2() {
    let tempDocs = [...documentsDate[indexOfDocs]];
    Promise.all(
      documentsDate[indexOfDocs].map(async (doc: any, index: number) => {
        if (!doc.id && doc.data !== "") {
          const api = axios.create({
            baseURL: `https://app.kangelo.com/`,
          });
          var config = {
            headers: {
              "X-Auth-Token": token,
            },
          };
          return api
            .post("/media", doc, config)
            .then((res) => {
              tempDocs[index] = res.data;
              return res.data.id;
            })
            .catch((err) => {
              // what now?
              console.log(err.data);
            });
        }
      })
    ).then((res) => {
      let temp = JSON.parse(JSON.stringify(advData));
      let tempDocuments = [""];
      tempDocuments = temp.dates[indexOfDocs].documents.filter(
        (docu: string) => !dateDocumentsDeletedId[indexOfDocs].includes(docu)
      );
      temp.dates[indexOfDocs].documents = tempDocuments;
      let tempRes = res.filter((d) => d !== undefined);
      temp.dates[indexOfDocs].documents =
        temp.dates[indexOfDocs].documents.concat(tempRes);
      /*       setAdvData(temp);*/
      let helpTemp = [...documentsDate];
      helpTemp[indexOfDocs] = tempDocs;
      setDocumentsDate(helpTemp);
      saveEditedAdventure(temp, mark);
      /* setShowEditModal(false);
      setDocumentsEntireEdit(false); */
    });
  }

  async function uploadImage2(img: string, index: number) {
    let data = {
      type: "IMAGE",
      data: img,
    };
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .post("/media", data, config)
      .then((res) => {
        let mediaDataTemp = [...itinerary];
        if (mediaDataTemp[index].images[0] === "") {
          mediaDataTemp[index].images[0] = img;
        } else {
          mediaDataTemp[index].images.push(img);
        }
        setItinerary(mediaDataTemp);

        let editedAdventureTemp = { ...editedAdventure };
        if (editedAdventureTemp.itinerary[index].images[0] === "") {
          editedAdventureTemp.itinerary[index].images[0] = res.data.id;
        } else {
          editedAdventureTemp.itinerary[index].images.push(res.data.id);
        }
        setEditedAdventure(editedAdventureTemp);

        setUploading(false);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  const handleItiChange = (index: number, event: any) => {
    let arrIti = [...itinerary];
    let arrNewAd = { ...editedAdventure };
    switch (event.target.name) {
      case "title":
        arrIti[index].titleText[newAdventureLangCurrent] = event.target.value;
        arrNewAd.itinerary[index].titleText[newAdventureLangCurrent] =
          event.target.value;
        break;
      case "desc":
        arrIti[index].descriptionText[newAdventureLangCurrent] =
          event.target.value;
        arrNewAd.itinerary[index].descriptionText[newAdventureLangCurrent] =
          event.target.value;
        break;
      default:
        break;
    }

    /* arrNewAd.itinerary.forEach(function (v: any) {
      delete v.id;
    }); */
    setEditedAdventure(arrNewAd);
    setItinerary(arrIti);
  };

  async function parseReservations() {
    let reservationsDataTemp = [...emptyReservationsData];
    Promise.all(
      dateData.reservations.map(async (reservation, index) => {
        const api = axios.create({
          baseURL: `https://app.kangelo.com/`,
        });
        var config = {
          headers: {
            "X-Auth-Token": token,
          },
        };
        return api
          .get("/reservations/" + reservation.id, config)
          .then((response) => {
            reservationsDataTemp[index] = response.data;
          })
          .catch((err) => {
            // what now?
            console.log(err.data);
          });
      })
    ).then(() => {
      setReservationsData(reservationsDataTemp);
      history.push("/myadventures/" + myAdventureId + "/" + dateId);
    });
  }

  const handlePictureClick = async () => {
    const image = await takePicture();
    if (image === cameraImage) {
      setShowCropModal(true);
      setTimeout(() => setShowSlow(true), 500);
    } else setCameraImage(image);
  };

  async function uploadImage(img: string) {
    let data = {
      type: "IMAGE",
      data: img,
      name: "test",
    };
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .post("/media", data, config)
      .then(async (res) => {
        let newAdventureTemp = { ...editedAdventure };
        newAdventureTemp.image = res.data.id;
        await db.set(res.data.id, img);
        setNewMainImage(img);
        setEditedAdventure(newAdventureTemp);
        setUploading(false);
        setShowCropModal(false);
      })
      .catch((err) => {
        // what now?
        setUploading(false);
        setToastMessage(err.response.data);
        setShowToast(true);
        console.log(err.data);
      });
  }

  const remLang = (index: number) => {
    let tempArr = Object.keys(editedAdventure.nameText);

    let tempAdv = { ...editedAdventure };
    delete tempAdv.nameText[tempArr[index]];

    tempAdv.descriptionText[tempArr[index]] = "";
    tempAdv.includedText[tempArr[index]] = "";
    tempAdv.notIncludedText[tempArr[index]] = "";
    tempAdv.accommodationText[tempArr[index]] = "";
    tempAdv.mealsText[tempArr[index]] = "";
    tempAdv.transitText[tempArr[index]] = "";
    tempAdv.difficultyText[tempArr[index]] = "";
    tempAdv.moreInfoText[tempArr[index]] = "";
    tempAdv.itinerary.forEach((it: any) => {
      it.titleText[tempArr[index]] = "";
      it.descriptionText[tempArr[index]] = "";
    });
    if (tempArr[index] === newAdventureLangCurrent) {
      setNewAdventureLangCurrent(tempArr[index] === "CS" ? "EN" : "CS");
    }
    setEditedAdventure(tempAdv);
  };

  const handlePictureClickGall = async (index: number) => {
    const image = await takePicture();
    if (image) {
      setUploading2(true);
      uploadImageGall(image, index);
    }
  };

  async function uploadImageGall(img: string, index: number) {
    let data = {
      type: "IMAGE",
      data: img,
    };
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .post("/media", data, config)
      .then(async (res) => {
        await db.set(res.data.id, img);
        let mediaDataTemp = [...imagesData];
        mediaDataTemp[index] = img;
        setImagesData(mediaDataTemp);

        let mediaTemp = [...images];
        mediaTemp[index] = res.data.id;
        setImages(mediaTemp);
        setUploading2(false);
      })
      .catch((err) => {
        // what now?
        setUploading2(false);
        console.log(err.data);
      });
  }

  const addImage = () => {
    let arrImgData = [...imagesData];
    let arrImg = [...images];
    if (arrImg.length === 1 && arrImg[0] === "") {
      handlePictureClickGall(0);
    } else {
      arrImgData.push("");
      arrImg.push("");

      handlePictureClickGall(arrImg.length - 1);
    }
  };

  function renderSwitch(segment: any) {
    switch (segment) {
      case "setup":
        return (
          <IonGrid className="no-border" style={{ paddingBottom: "25px" }}>
            <IonRow
              onClick={() => setAdventurePreview((state) => !state)}
              style={{
                paddingLeft: "36px",
                textAlign: "left",
                justifyContent: "space-between",
              }}
            >
              <IonCol size="10" style={{ paddingLeft: 0, fontSize: "18px" }}>
                <IonCol style={{ paddingLeft: 0 }}>
                  <GlobeHemisphereWest
                    color="#3fd0d4"
                    size={24}
                    style={{ verticalAlign: "bottom" }}
                  />
                </IonCol>
                <IonCol>{lang.label268}</IonCol>
              </IonCol>
              <IonCol style={{ textAlign: "right" }}>
                {adventurePreview ? (
                  <CaretDown size={20} color="#3fd0d4" />
                ) : (
                  <CaretUp size={20} color="#3fd0d4" />
                )}
              </IonCol>
            </IonRow>
            {adventurePreview && (
              <div>
                <IonRow>
                  <IonCol style={{ textAlign: "left", paddingLeft: "36px" }}>
                    <IonText style={{ fontSize: "14px" }}>
                      {lang.label124}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonCard className="adventures-card">
                  <div className="card-cont">
                    <img
                      className="adventure-cover"
                      src={advData.image === "" ? defaultAdvImage : imageData}
                      onClick={() => {
                        return (
                          setAdventureId(advData.id),
                          history.push("/adventures/" + advData.id)
                        );
                      }}
                      alt="adventure background"
                    />
                    <IonChip className="adv-chip">
                      <IonLabel>
                        {setCountryName(advData.locations[0].country, language)}
                      </IonLabel>
                    </IonChip>
                    {advData.categories.map((item: string, i: number) => {
                      return (
                        <IonChip className={"adv-chip" + i} key={`${i}`}>
                          <IonLabel>{setEnumText(item, lang)}</IonLabel>
                        </IonChip>
                      );
                    })}
                    <IonChip className="adv-like">
                      <Heart
                        size={24}
                        weight={advData.isLiked ? "fill" : "duotone"}
                        color="#3fd0d4"
                        onClick={() => {
                          let itemTemp = { ...advData };
                          itemTemp.isLiked = !itemTemp.isLiked;
                          setAdvData(itemTemp);
                          setLike(
                            advData.id,
                            "adventures",
                            itemTemp.isLiked,
                            token
                          );
                        }}
                      />
                    </IonChip>
                    {/* <IonChip
                      className="adv-partner"
                      onClick={() => {
                        setPartnerName(advData.partner);
                        setShowModalPart(true);
                      }}
                    >
                      <Handshake size={24} />
                    </IonChip> */}
                    <IonChip
                      className="adv-guide-chip"
                      onClick={() => {
                        setUserId(advData.guide.id);
                        history.push("/userpage/" + advData.guide.id);
                      }}
                    >
                      <IonLabel>{advData.guide.firstName}</IonLabel>
                    </IonChip>
                    <IonAvatar
                      className="guide-avatar"
                      onClick={() => {
                        setUserId(advData.guide.id);
                        history.push("/userpage/" + advData.guide.id);
                      }}
                    >
                      {advData.guide.profileImage === null ? (
                        <UserCircle
                          size={78}
                          fill="black"
                          weight="fill"
                          style={{
                            marginLeft: "-7px",
                            marginTop: "-7px",
                          }}
                        />
                      ) : (
                        <img
                          src={profileImageData}
                          alt="guide"
                          className="guide-avatar-img"
                        />
                      )}
                    </IonAvatar>
                  </div>
                  <IonCardContent
                    style={{ textAlign: "left", paddingTop: "8px" }}
                  >
                    <IonFooter>
                      {advData.rating !== null && (
                        <div
                          style={{
                            display: "inline",
                            marginRight: "5px",
                          }}
                        >
                          <Rate
                            count={5}
                            value={advData.rating}
                            allowHalf={true}
                            disabled={true}
                            className="rating-stars"
                          />{" "}
                          {advData.rating} ({advData.ratingCount})
                        </div>
                      )}
                      <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "top",
                          width: "30px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="20"
                          fill="rgb(63, 208, 212)"
                          viewBox="0 0 400 256"
                        >
                          <rect width="52" height="256" fill="none"></rect>
                          <line
                            x1="340"
                            y1="208"
                            x2="28"
                            y2="208"
                            fill="none"
                            stroke="rgb(63, 208, 212)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          ></line>
                          <rect
                            x="42"
                            y="160"
                            width="56"
                            height="48"
                            strokeWidth="16"
                            stroke="rgb(63, 208, 212)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill={advData.difficulty >= 1 ? "true" : "none"}
                          ></rect>
                          <rect
                            x="100"
                            y="140"
                            width="56"
                            height="68"
                            strokeWidth="16"
                            stroke="rgb(63, 208, 212)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill={advData.difficulty >= 2 ? "true" : "none"}
                          ></rect>
                          <rect
                            x="158"
                            y="120"
                            width="56"
                            height="88"
                            strokeWidth="16"
                            stroke="rgb(63, 208, 212)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill={advData.difficulty >= 3 ? "true" : "none"}
                          ></rect>
                          <rect
                            x="216"
                            y="100"
                            width="56"
                            height="108"
                            strokeWidth="16"
                            stroke="rgb(63, 208, 212)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill={advData.difficulty >= 4 ? "true" : "none"}
                          ></rect>
                          <rect
                            x="274"
                            y="80"
                            width="56"
                            height="128"
                            strokeWidth="16"
                            stroke="rgb(63, 208, 212)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill={advData.difficulty >= 5 ? "true" : "none"}
                          ></rect>
                        </svg>
                      </div>
                      <IonCol>
                        {advData.difficulty}
                        /5
                      </IonCol>
                    </IonFooter>
                    <IonFooter style={{ fontWeight: "bold", color: "black" }}>
                      {checkProperty(advData.nameText, language)}
                    </IonFooter>
                    <IonFooter>
                      {moment(advData.dates[0].begin, "YYYY-MM-DD").format(
                        "DD.MM."
                      )}{" "}
                      -{" "}
                      {moment(advData.dates[0].end, "YYYY-MM-DD").format(
                        "DD.MM.YYYY"
                      )}{" "}
                      • {advData.dates.length}{" "}
                      {advData.dates.length > 1 ? lang.label194 : lang.label195}{" "}
                      • {getTripLength(advData)} {getLowestPrice(advData)}
                    </IonFooter>
                  </IonCardContent>
                </IonCard>
              </div>
            )}
            <IonRow
              onClick={() => setAdventureEdit((state) => !state)}
              style={{
                borderTop: "1px solid #DEDEDE",
                paddingLeft: "36px",
                textAlign: "left",
              }}
            >
              <IonCol size="10" style={{ paddingLeft: 0, fontSize: "18px" }}>
                <IonCol style={{ paddingLeft: 0 }}>
                  <Pencil
                    color="#3fd0d4"
                    size={24}
                    style={{ verticalAlign: "bottom" }}
                  />
                </IonCol>
                <IonCol>{lang.label270}</IonCol>
              </IonCol>
              {/* <IonCol size="10">{lang.label270}</IonCol> */}
              <IonCol style={{ textAlign: "right" }}>
                {adventureEdit ? (
                  <CaretDown size={20} color="#3fd0d4" />
                ) : (
                  <CaretUp size={20} color="#3fd0d4" />
                )}
              </IonCol>
            </IonRow>
            {adventureEdit && (
              <IonRow style={{ display: "flex", margin: "2px 10px" }}>
                <IonRow
                  style={{ display: "contents" }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label379}
                      </IonText>
                    </IonRow>
                    <IonRow
                      style={{
                        height: "35px",
                        width: "110px",
                        justifyContent: "space-between",
                      }}
                    >
                      <IonText
                        style={
                          isLanguagesDisabled
                            ? {
                                fontSize: "12px",
                                marginTop: "5px",
                                opacity: "0.5",
                                background: "transparent",
                              }
                            : {
                                fontSize: "12px",
                                marginTop: "5px",
                                opacity: "1",
                              }
                        }
                      >
                        {Object.keys(editedAdventure.nameText)[0] !== "EN" &&
                          lang.label383}
                        {Object.keys(editedAdventure.nameText)[0] !== "CS" &&
                          lang.label384}
                      </IonText>
                      {!isLanguagesDisabled &&
                        Object.keys(editedAdventure.nameText).length > 1 && (
                          <div
                            style={{
                              display: "inline-block",
                              float: "right",
                              position: "relative",
                              top: 0,
                              right: "-20px",
                              zIndex: 1000,
                            }}
                          >
                            <IonText
                              style={{
                                fontSize: "12px",
                                textDecoration: "underline",
                              }}
                              onClick={() => remLang(0)}
                            >
                              {lang.label386}
                            </IonText>
                          </div>
                        )}
                    </IonRow>
                    {Object.keys(editedAdventure.nameText).map((item, i) => {
                      if (i === 0) return;
                      return (
                        <div
                          key={i + item}
                          style={{
                            textAlign: "left",
                            width: "110px",
                            height: "35px",
                          }}
                        >
                          <IonText
                            style={
                              isLanguagesDisabled
                                ? {
                                    fontSize: "12px",
                                    opacity: "0.5",
                                    background: "transparent",
                                  }
                                : {
                                    fontSize: "12px",
                                    opacity: "1",
                                  }
                            }
                          >
                            {Object.keys(editedAdventure.nameText)[0] !==
                              "CS" && lang.label383}
                            {Object.keys(editedAdventure.nameText)[0] !==
                              "EN" && lang.label384}
                          </IonText>
                          {i > 0 && !isLanguagesDisabled && (
                            <div
                              style={{
                                display: "inline-block",
                                float: "right",
                                position: "relative",
                                top: 0,
                                right: "-20px",
                                zIndex: 1000,
                              }}
                            >
                              <IonText
                                style={{
                                  fontSize: "12px",
                                  textDecoration: "underline",
                                }}
                                onClick={() => remLang(i)}
                              >
                                {lang.label386}
                              </IonText>
                            </div>
                          )}
                        </div>
                      );
                    })}
                    {!isLanguagesDisabled &&
                      Object.keys(editedAdventure.nameText).length < 2 && (
                        <IonRow>
                          <IonText
                            style={{
                              fontSize: "12px",
                              textDecoration: "underline",
                              marginLeft: "0",
                            }}
                            className="user-info-label"
                            onClick={() => {
                              let temp = { ...editedAdventure };
                              let temp2 = "";
                              Object.keys(editedAdventure.nameText)[0] === "CS"
                                ? (temp2 = "EN")
                                : (temp2 = "CS");
                              temp.nameText[temp2] = "";
                              setEditedAdventure(temp);
                              setShowAddLangModal(true);
                              setTimeout(() => {
                                setNewAdventureLangCurrent(temp2);
                              }, 200);
                            }}
                          >
                            {lang.label385}
                          </IonText>
                        </IonRow>
                      )}
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isLanguagesDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };

                          temp.nameText = JSON.parse(
                            JSON.stringify(advData.nameText)
                          );

                          temp.descriptionText = JSON.parse(
                            JSON.stringify(advData.descriptionText)
                          );

                          temp.includedText = JSON.parse(
                            JSON.stringify(advData.includedText)
                          );

                          temp.notIncludedText = JSON.parse(
                            JSON.stringify(advData.notIncludedText)
                          );

                          temp.accommodationText = JSON.parse(
                            JSON.stringify(advData.accommodationText)
                          );

                          temp.mealsText = JSON.parse(
                            JSON.stringify(advData.mealsText)
                          );

                          temp.transitText = JSON.parse(
                            JSON.stringify(advData.transitText)
                          );

                          temp.difficultyText = JSON.parse(
                            JSON.stringify(advData.difficultyText)
                          );

                          temp.moreInfoText = JSON.parse(
                            JSON.stringify(advData.moreInfoText)
                          );

                          setNewAdventureLangCurrent(
                            Object.keys(temp.nameText)[0]
                          );
                          setEditedAdventure(temp);
                          setIsLanguagesDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isLanguagesDisabled
                          ? () => {
                              setIsLanguagesDisabled((state) => !state);
                            }
                          : () => {
                              setMark(20);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isLanguagesDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>
                <IonRow
                  style={{ display: "contents" }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label229}
                      </IonText>
                    </IonRow>
                    <IonRow style={{ height: "25px" }}>
                      <IonItem className="edit-adv-item">
                        <IonInput
                          value={
                            editedAdventure.nameText[newAdventureLangCurrent]
                          }
                          style={
                            isNameCSDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          name="name"
                          className={
                            isNameCSDisabled
                              ? "edit-adv-input2"
                              : "edit-adv-input"
                          }
                          disabled={isNameCSDisabled}
                          onIonChange={(event) => handleInputChange(event)}
                        ></IonInput>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isNameCSDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };

                          temp.nameText[newAdventureLangCurrent] = JSON.parse(
                            JSON.stringify(
                              advData.nameText[newAdventureLangCurrent]
                            )
                          );
                          setEditedAdventure(temp);
                          setIsNameCSDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isNameCSDisabled
                          ? () => {
                              setIsNameCSDisabled((state) => !state);
                            }
                          : () => {
                              setMark(1);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isNameCSDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>
                {/*                 <IonRow
                  style={{
                    display: "contents",
                  }}
                  
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label230}
                      </IonText>
                    </IonRow>
                    <IonRow style={{ height: "max-content" }}>
                      <IonItem className="edit-adv-item">
                        <IonInput
                          value={editedAdventure.nameText.EN}
                          style={
                            isNameENDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          name="nameEN"
                          className={
                            isNameENDisabled
                              ? "edit-adv-input2"
                              : "edit-adv-input"
                          }
                          disabled={isNameENDisabled}
                          onIonChange={(event) => handleInputChange(event)}
                        ></IonInput>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isNameENDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };
                          temp.nameText.EN = JSON.parse(
                            JSON.stringify(advData.nameText.EN)
                          );
                          setEditedAdventure(temp);
                          setIsNameENDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isNameENDisabled
                          ? () => {
                              setIsNameENDisabled((state) => !state);
                            }
                          : () => {
                              setMark(2);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isNameENDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow> */}
                <IonRow
                  style={{
                    display: "contents",
                  }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label8}
                      </IonText>
                    </IonRow>
                    <IonRow style={{ height: "max-content" }}>
                      <IonItem className="edit-adv-item">
                        <IonSelect
                          interface="alert"
                          multiple={true}
                          name="categories"
                          value={editedAdventure.categories}
                          onIonChange={(event) => handleInputChange(event)}
                          style={
                            isCategoriesDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          className={
                            isCategoriesDisabled
                              ? "edit-adv-select2"
                              : "edit-adv-select"
                          }
                          disabled={isCategoriesDisabled}
                        >
                          <IonSelectOption value="CROSS_COUNTRY_SKIING">
                            {lang.label99}
                          </IonSelectOption>
                          <IonSelectOption value="CANYONING">
                            {lang.label93}
                          </IonSelectOption>
                          <IonSelectOption value="BIKING">
                            {lang.label97}
                          </IonSelectOption>
                          <IonSelectOption value="EXPEDITION">
                            {lang.label78}
                          </IonSelectOption>
                          <IonSelectOption value="HELISKIING">
                            {lang.label89}
                          </IonSelectOption>
                          <IonSelectOption value="MOUNTAIN_ASCENTS">
                            {lang.label82}
                          </IonSelectOption>
                          <IonSelectOption value="BOATING">
                            {lang.label92}
                          </IonSelectOption>
                          <IonSelectOption value="ICEBERG">
                            {lang.label86}
                          </IonSelectOption>
                          <IonSelectOption value="CLIMBING">
                            {lang.label90}
                          </IonSelectOption>
                          <IonSelectOption value="SKIING">
                            {lang.label85}
                          </IonSelectOption>
                          <IonSelectOption value="OTHERS">
                            {lang.label98}
                          </IonSelectOption>
                          <IonSelectOption value="DIVING">
                            {lang.label91}
                          </IonSelectOption>
                          <IonSelectOption value="SIGHTSEEING">
                            {lang.label84}
                          </IonSelectOption>
                          <IonSelectOption value="MOUNTAIN_CROSSING">
                            {lang.label83}
                          </IonSelectOption>
                          <IonSelectOption value="RELAX_AND_CHILL">
                            {lang.label95}
                          </IonSelectOption>
                          <IonSelectOption value="ROADTRIP">
                            {lang.label80}
                          </IonSelectOption>
                          <IonSelectOption value="SKI_MOUNTAINEERING">
                            {lang.label88}
                          </IonSelectOption>
                          <IonSelectOption value="SNOWSHOES">
                            {lang.label87}
                          </IonSelectOption>
                          <IonSelectOption value="SNOWBOARDING">
                            {lang.label96}
                          </IonSelectOption>
                          <IonSelectOption value="HIKING">
                            {lang.label94}
                          </IonSelectOption>
                          <IonSelectOption value="VIA_FERRATA">
                            {lang.label81}
                          </IonSelectOption>
                          <IonSelectOption value="WEEKEND_TRIP">
                            {lang.label79}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isCategoriesDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };
                          temp.categories = JSON.parse(
                            JSON.stringify(advData.categories)
                          );
                          setEditedAdventure(temp);
                          setIsCategoriesDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isCategoriesDisabled
                          ? () => {
                              setIsCategoriesDisabled((state) => !state);
                            }
                          : () => {
                              setMark(3);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isCategoriesDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>
                <IonRow
                  style={{
                    display: "contents",
                  }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.8"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px", marginTop: "12px" }}
                      >
                        {lang.label231}
                      </IonText>
                    </IonRow>
                    <IonRow style={{ height: "max-content" }}>
                      <IonItem className="edit-adv-image">
                        <IonImg
                          onClick={() => {
                            if (!isImageDisabled) {
                              handlePictureClick();
                            }
                          }}
                          style={{ paddingTop: "15px" }}
                          src={
                            newMainImage !== null
                              ? newMainImage
                              : defaultAdvImage
                          }
                        ></IonImg>
                        {!isImageDisabled && (
                          <ArrowsClockwise
                            size={35}
                            color="#4ED4D7"
                            style={{
                              position: "absolute",
                              top: "16px",
                              right: "5px",
                            }}
                            onClick={() => handlePictureClick()}
                          />
                        )}
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.2"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isImageDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          const imageDataTemp = JSON.parse(
                            JSON.stringify(imageData)
                          );
                          setNewMainImage(imageDataTemp);
                          setIsImageDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isImageDisabled
                          ? () => {
                              setIsImageDisabled((state) => !state);
                            }
                          : () => {
                              setMark(4);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isImageDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRow
                  style={{ display: "contents" }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label236}
                      </IonText>
                    </IonRow>
                    <IonRow /* style={{ height: "25px" }} */>
                      <IonItem className="edit-adv-item-area">
                        <IonTextarea
                          value={
                            editedAdventure.descriptionText[
                              newAdventureLangCurrent
                            ]
                          }
                          autoGrow={true}
                          style={
                            isdescriptionTextDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          name="descriptionText"
                          className={
                            isdescriptionTextDisabled
                              ? "edit-adv-input2-area"
                              : "edit-adv-input-area"
                          }
                          disabled={isdescriptionTextDisabled}
                          onIonChange={(event) => handleInputChange(event)}
                        ></IonTextarea>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isdescriptionTextDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };

                          temp.descriptionText[newAdventureLangCurrent] =
                            JSON.parse(
                              JSON.stringify(
                                advData.descriptionText[newAdventureLangCurrent]
                              )
                            );
                          setEditedAdventure(temp);
                          setIsdescriptionTextDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isdescriptionTextDisabled
                          ? () => {
                              setIsdescriptionTextDisabled((state) => !state);
                            }
                          : () => {
                              setMark(5);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isdescriptionTextDisabled
                        ? lang.label269
                        : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRow
                  style={{
                    display: "contents",
                  }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label210}
                      </IonText>
                    </IonRow>
                    <IonRow style={{ height: "max-content" }}>
                      <IonItem className="edit-adv-item">
                        <IonSelect
                          interface="alert"
                          name="meals"
                          okText={lang.label167}
                          cancelText={lang.label166}
                          value={editedAdventure.meals}
                          onIonChange={(event) => handleInputChange(event)}
                          style={
                            isMealsDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          className={
                            isMealsDisabled
                              ? "edit-adv-select2"
                              : "edit-adv-select"
                          }
                          disabled={isMealsDisabled}
                        >
                          <IonSelectOption value="OWN">
                            {lang.label199}
                          </IonSelectOption>
                          <IonSelectOption value="BREAKFAST">
                            {lang.label219}
                          </IonSelectOption>
                          <IonSelectOption value="BREAKFAST_AND_DINNER">
                            {lang.label220}
                          </IonSelectOption>
                          <IonSelectOption value="ALL_INCLUSIVE">
                            {lang.label221}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isMealsDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };
                          temp.meals = JSON.parse(
                            JSON.stringify(advData.meals)
                          );
                          setEditedAdventure(temp);
                          setIsMealsDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isMealsDisabled
                          ? () => {
                              setIsMealsDisabled((state) => !state);
                            }
                          : () => {
                              setMark(6);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isMealsDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRow
                  style={{ display: "contents" }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label308}
                      </IonText>
                    </IonRow>
                    <IonRow /* style={{ height: "25px" }} */>
                      <IonItem className="edit-adv-item-area">
                        <IonTextarea
                          value={
                            editedAdventure.mealsText[newAdventureLangCurrent]
                          }
                          autoGrow={true}
                          style={
                            isMealsTextDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          name="mealsText"
                          className={
                            isMealsTextDisabled
                              ? "edit-adv-input2-area"
                              : "edit-adv-input-area"
                          }
                          disabled={isMealsTextDisabled}
                          onIonChange={(event) => handleInputChange(event)}
                        ></IonTextarea>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isMealsTextDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };

                          temp.mealsText[newAdventureLangCurrent] = JSON.parse(
                            JSON.stringify(
                              advData.mealsText[newAdventureLangCurrent]
                            )
                          );
                          setEditedAdventure(temp);
                          setIsMealsTextDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isMealsTextDisabled
                          ? () => {
                              setIsMealsTextDisabled((state) => !state);
                            }
                          : () => {
                              setMark(7);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isMealsTextDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRow
                  style={{
                    display: "contents",
                  }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label209}
                      </IonText>
                    </IonRow>
                    <IonRow style={{ height: "max-content" }}>
                      <IonItem className="edit-adv-item">
                        <IonSelect
                          interface="alert"
                          name="accommodation"
                          okText={lang.label167}
                          cancelText={lang.label166}
                          value={editedAdventure.accommodation}
                          onIonChange={(event) => handleInputChange(event)}
                          style={
                            isAccommodationDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          className={
                            isAccommodationDisabled
                              ? "edit-adv-select2"
                              : "edit-adv-select"
                          }
                          disabled={isAccommodationDisabled}
                        >
                          <IonSelectOption value="HOTEL">
                            {lang.label214}
                          </IonSelectOption>
                          <IonSelectOption value="HOUSE">
                            {lang.label215}
                          </IonSelectOption>
                          <IonSelectOption value="HOSTEL">
                            {lang.label216}
                          </IonSelectOption>
                          <IonSelectOption value="CAMPING">
                            {lang.label217}
                          </IonSelectOption>
                          <IonSelectOption value="BIVOUACKING">
                            {lang.label218}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isAccommodationDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };
                          temp.accommodation = JSON.parse(
                            JSON.stringify(advData.accommodation)
                          );
                          setEditedAdventure(temp);
                          setIsAccommodationDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isAccommodationDisabled
                          ? () => {
                              setIsAccommodationDisabled((state) => !state);
                            }
                          : () => {
                              setMark(8);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isAccommodationDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRow
                  style={{ display: "contents" }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label310}
                      </IonText>
                    </IonRow>
                    <IonRow /* style={{ height: "25px" }} */>
                      <IonItem className="edit-adv-item-area">
                        <IonTextarea
                          value={
                            editedAdventure.accommodationText[
                              newAdventureLangCurrent
                            ]
                          }
                          autoGrow={true}
                          style={
                            isAccommodationTextDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          name="accommodationText"
                          className={
                            isAccommodationTextDisabled
                              ? "edit-adv-input2-area"
                              : "edit-adv-input-area"
                          }
                          disabled={isAccommodationTextDisabled}
                          onIonChange={(event) => handleInputChange(event)}
                        ></IonTextarea>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isAccommodationTextDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };

                          temp.accommodationText[newAdventureLangCurrent] =
                            JSON.parse(
                              JSON.stringify(
                                advData.accommodationText[
                                  newAdventureLangCurrent
                                ]
                              )
                            );
                          setEditedAdventure(temp);
                          setIsAccommodationTextDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isAccommodationTextDisabled
                          ? () => {
                              setIsAccommodationTextDisabled((state) => !state);
                            }
                          : () => {
                              setMark(9);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isAccommodationTextDisabled
                        ? lang.label269
                        : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRow
                  style={{
                    display: "contents",
                  }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label206}
                      </IonText>
                    </IonRow>
                    <IonRow style={{ height: "max-content" }}>
                      <IonItem className="edit-adv-item">
                        <IonSelect
                          interface="alert"
                          name="transit"
                          okText={lang.label167}
                          cancelText={lang.label166}
                          value={editedAdventure.transit}
                          onIonChange={(event) => handleInputChange(event)}
                          style={
                            isTransitDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          className={
                            isTransitDisabled
                              ? "edit-adv-select2"
                              : "edit-adv-select"
                          }
                          disabled={isTransitDisabled}
                        >
                          <IonSelectOption value="OWN">
                            {lang.label199}
                          </IonSelectOption>
                          <IonSelectOption value="VAN">
                            {lang.label200}
                          </IonSelectOption>
                          <IonSelectOption value="BUS">
                            {lang.label201}
                          </IonSelectOption>
                          <IonSelectOption value="CAR">
                            {lang.label202}
                          </IonSelectOption>
                          <IonSelectOption value="AIRPLANE">
                            {lang.label203}
                          </IonSelectOption>
                          <IonSelectOption value="TRAIN">
                            {lang.label204}
                          </IonSelectOption>
                          <IonSelectOption value="BOAT">
                            {lang.label205}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                    }}
                  >
                    {!isTransitDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };
                          temp.transit = JSON.parse(
                            JSON.stringify(advData.transit)
                          );
                          temp.arrival = JSON.parse(
                            JSON.stringify(advData.arrival)
                          );
                          temp.departure = JSON.parse(
                            JSON.stringify(advData.departure)
                          );
                          setEditedAdventure(temp);
                          setIsTransitDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isTransitDisabled
                          ? () => {
                              setIsTransitDisabled((state) => !state);
                            }
                          : () => {
                              setMark(10);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isTransitDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                  <IonRow
                    style={{
                      width: "100%",
                      /* marginLeft: "10px", marginRight: "10px" */ borderBottom:
                        "1px solid #DEDEDE",
                    }}
                  >
                    <IonCol
                      style={{
                        textAlign: "left",
                      }}
                      size="6"
                    >
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {editedAdventure.departure.type === "AIRPORT"
                          ? lang.label129
                          : lang.label128}
                      </IonText>
                      <IonItem
                        className="edit-adv-item"
                        style={{ marginLeft: "0px", marginRight: "0px" }}
                      >
                        <IonInput
                          value={
                            editedAdventure.departure.type === "AIRPORT"
                              ? editedAdventure.departure.airport
                              : editedAdventure.departure.city
                          }
                          disabled={isTransitDisabled}
                          name="departure"
                          placeholder={lang.label241}
                          inputmode="text"
                          maxlength={25}
                          onIonChange={(event) => handleInputChange(event)}
                          className={
                            isTransitDisabled
                              ? "edit-adv-input2"
                              : "edit-adv-input"
                          }
                          style={
                            isTransitDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "0.4 !important",
                                }
                          }
                        ></IonInput>
                      </IonItem>
                    </IonCol>
                    <IonCol style={{ textAlign: "left" }}>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {editedAdventure.arrival.type === "AIRPORT"
                          ? lang.label131
                          : lang.label130}
                      </IonText>
                      <IonItem
                        className="edit-adv-item"
                        style={{ marginLeft: "0px", marginRight: "0px" }}
                      >
                        <IonInput
                          /* style={{ width: "100%" }} */
                          value={
                            editedAdventure.arrival.type === "AIRPORT"
                              ? editedAdventure.arrival.airport
                              : editedAdventure.arrival.city
                          }
                          disabled={isTransitDisabled}
                          name="arrival"
                          placeholder={lang.label241}
                          inputmode="text"
                          maxlength={25}
                          onIonChange={(event) => handleInputChange(event)}
                          className={
                            isTransitDisabled
                              ? "edit-adv-input2"
                              : "edit-adv-input"
                          }
                          style={
                            isTransitDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "0.4 !important",
                                }
                          }
                        ></IonInput>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonRow>

                <IonRow
                  style={{ width: "100%" }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label309}
                      </IonText>
                    </IonRow>
                    <IonRow /* style={{ height: "25px" }} */>
                      <IonItem className="edit-adv-item-area">
                        <IonTextarea
                          value={
                            editedAdventure.transitText[newAdventureLangCurrent]
                          }
                          autoGrow={true}
                          style={
                            isTransitTextDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          name="transitText"
                          className={
                            isTransitTextDisabled
                              ? "edit-adv-input2-area"
                              : "edit-adv-input-area"
                          }
                          disabled={isTransitTextDisabled}
                          onIonChange={(event) => handleInputChange(event)}
                        ></IonTextarea>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isTransitTextDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };

                          temp.transitText[newAdventureLangCurrent] =
                            JSON.parse(
                              JSON.stringify(
                                advData.transitText[newAdventureLangCurrent]
                              )
                            );
                          setEditedAdventure(temp);
                          setIsTransitTextDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isTransitTextDisabled
                          ? () => {
                              setIsTransitTextDisabled((state) => !state);
                            }
                          : () => {
                              setMark(17);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isTransitTextDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRow
                  style={{
                    display: "contents",
                  }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label211}
                      </IonText>
                    </IonRow>
                    <IonRow style={{ height: "max-content" }}>
                      <IonItem className="edit-adv-item">
                        <IonSelect
                          interface="alert"
                          name="difficulty"
                          okText={lang.label167}
                          cancelText={lang.label166}
                          value={String(editedAdventure.difficulty)}
                          onIonChange={(event) => handleInputChange(event)}
                          style={
                            isDifficultyDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          className={
                            isDifficultyDisabled
                              ? "edit-adv-select2"
                              : "edit-adv-select"
                          }
                          disabled={isDifficultyDisabled}
                        >
                          <IonSelectOption value="1">
                            1 - {lang.label242}
                          </IonSelectOption>
                          <IonSelectOption value="2">
                            2 - {lang.label243}
                          </IonSelectOption>
                          <IonSelectOption value="3">
                            3 - {lang.label244}
                          </IonSelectOption>
                          <IonSelectOption value="4">
                            4 - {lang.label245}
                          </IonSelectOption>
                          <IonSelectOption value="5">
                            5 - {lang.label246}
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isDifficultyDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };
                          temp.difficulty = JSON.parse(
                            JSON.stringify(advData.difficulty)
                          );
                          setEditedAdventure(temp);
                          setIsDifficultyDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isDifficultyDisabled
                          ? () => {
                              setIsDifficultyDisabled((state) => !state);
                            }
                          : () => {
                              setMark(11);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isDifficultyDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRow
                  style={{ display: "contents" }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label311}
                      </IonText>
                    </IonRow>
                    <IonRow /* style={{ height: "25px" }} */>
                      <IonItem className="edit-adv-item-area">
                        <IonTextarea
                          value={
                            editedAdventure.difficultyText[
                              newAdventureLangCurrent
                            ]
                          }
                          autoGrow={true}
                          style={
                            isDifficultyTextDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          name="difficultyText"
                          className={
                            isDifficultyTextDisabled
                              ? "edit-adv-input2-area"
                              : "edit-adv-input-area"
                          }
                          disabled={isDifficultyTextDisabled}
                          onIonChange={(event) => handleInputChange(event)}
                        ></IonTextarea>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isDifficultyTextDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };

                          temp.difficultyText[newAdventureLangCurrent] =
                            JSON.parse(
                              JSON.stringify(
                                advData.difficultyText[newAdventureLangCurrent]
                              )
                            );
                          setEditedAdventure(temp);
                          setIsDifficultyTextDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isDifficultyTextDisabled
                          ? () => {
                              setIsDifficultyTextDisabled((state) => !state);
                            }
                          : () => {
                              setMark(12);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isDifficultyTextDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRow
                  style={{ display: "contents" }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label247}
                      </IonText>
                    </IonRow>
                    <IonRow /* style={{ height: "25px" }} */>
                      <IonItem className="edit-adv-item-area">
                        <IonTextarea
                          value={
                            editedAdventure.includedText[
                              newAdventureLangCurrent
                            ]
                          }
                          autoGrow={true}
                          style={
                            isIncludedTextDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          name="includedText"
                          className={
                            isIncludedTextDisabled
                              ? "edit-adv-input2-area"
                              : "edit-adv-input-area"
                          }
                          disabled={isIncludedTextDisabled}
                          onIonChange={(event) => handleInputChange(event)}
                        ></IonTextarea>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isIncludedTextDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };

                          temp.includedText[newAdventureLangCurrent] =
                            JSON.parse(
                              JSON.stringify(
                                advData.includedText[newAdventureLangCurrent]
                              )
                            );
                          setEditedAdventure(temp);
                          setIsIncludedTextDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isIncludedTextDisabled
                          ? () => {
                              setIsIncludedTextDisabled((state) => !state);
                            }
                          : () => {
                              setMark(13);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isIncludedTextDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRow
                  style={{ display: "contents" }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label248}
                      </IonText>
                    </IonRow>
                    <IonRow /* style={{ height: "25px" }} */>
                      <IonItem className="edit-adv-item-area">
                        <IonTextarea
                          value={
                            editedAdventure.notIncludedText[
                              newAdventureLangCurrent
                            ]
                          }
                          autoGrow={true}
                          style={
                            isNotIncludedTextDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          name="notIncludedText"
                          className={
                            isNotIncludedTextDisabled
                              ? "edit-adv-input2-area"
                              : "edit-adv-input-area"
                          }
                          disabled={isNotIncludedTextDisabled}
                          onIonChange={(event) => handleInputChange(event)}
                        ></IonTextarea>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isNotIncludedTextDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };

                          temp.notIncludedText[newAdventureLangCurrent] =
                            JSON.parse(
                              JSON.stringify(
                                advData.notIncludedText[newAdventureLangCurrent]
                              )
                            );
                          setEditedAdventure(temp);
                          setIsNotIncludedTextDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isNotIncludedTextDisabled
                          ? () => {
                              setIsNotIncludedTextDisabled((state) => !state);
                            }
                          : () => {
                              setMark(14);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isNotIncludedTextDisabled
                        ? lang.label269
                        : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRow
                  style={{ display: "contents" }}
                  /* className="user-info-item item-input-grey" */
                >
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label249}
                      </IonText>
                    </IonRow>
                    <IonRow /* style={{ height: "25px" }} */>
                      <IonItem className="edit-adv-item-area">
                        <IonTextarea
                          value={
                            editedAdventure.moreInfoText[
                              newAdventureLangCurrent
                            ]
                          }
                          autoGrow={true}
                          style={
                            isMoreInfoTextDisabled
                              ? {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                  background: "transparent",
                                }
                              : {
                                  fontSize: "12px",
                                  color: "black !important",
                                  opacity: "1 !important",
                                }
                          }
                          name="moreInfoText"
                          className={
                            isMoreInfoTextDisabled
                              ? "edit-adv-input2-area"
                              : "edit-adv-input-area"
                          }
                          disabled={isMoreInfoTextDisabled}
                          onIonChange={(event) => handleInputChange(event)}
                        ></IonTextarea>
                      </IonItem>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    {!isMoreInfoTextDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = { ...editedAdventure };

                          temp.moreInfoText[newAdventureLangCurrent] =
                            JSON.parse(
                              JSON.stringify(
                                advData.moreInfoText[newAdventureLangCurrent]
                              )
                            );
                          setEditedAdventure(temp);
                          setIsMoreInfoTextDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isMoreInfoTextDisabled
                          ? () => {
                              setIsMoreInfoTextDisabled((state) => !state);
                            }
                          : () => {
                              setMark(15);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isMoreInfoTextDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRow style={{ display: "contents" }}>
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label198}
                      </IonText>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                    }}
                  >
                    {!isItineraryDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = JSON.parse(
                            JSON.stringify(itineraryDefault)
                          );
                          setItinerary(temp);
                          setIsItineraryDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isItineraryDisabled
                          ? () => {
                              setIsItineraryDisabled((state) => !state);
                            }
                          : () => {
                              setMark(16);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isItineraryDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>
                  <IonList style={{ padding: "0 5px", width: "100%" }}>
                    <IonReorderGroup
                      disabled={reorder}
                      onIonItemReorder={doReorder}
                    >
                      {itinerary &&
                        itinerary.map((oneItinerary: any, index: number) => {
                          return (
                            <IonReorder key={oneItinerary.id}>
                              <div
                                style={{
                                  position: "relative",
                                  textAlign: "left",
                                }}
                              >
                                {index > 0 && !isItineraryDisabled && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "0px",
                                      top: "-8px",
                                      zIndex: 1000,
                                    }}
                                  >
                                    <MinusCircle
                                      size={34}
                                      onClick={() => remIty(index)}
                                    />
                                  </div>
                                )}
                                <IonText
                                  className="text-bold"
                                  style={{ fontSize: "14px" }}
                                >
                                  {lang.label250} {index + 1}
                                </IonText>
                                <IonItem className="edit-adv-item">
                                  <IonInput
                                    value={
                                      oneItinerary.titleText[
                                        newAdventureLangCurrent
                                      ]
                                    }
                                    name="title"
                                    placeholder={lang.label241}
                                    inputmode="text"
                                    maxlength={100}
                                    disabled={isItineraryDisabled}
                                    onIonChange={(event) =>
                                      handleItiChange(index, event)
                                    }
                                    className={
                                      isItineraryDisabled
                                        ? "edit-adv-input2"
                                        : "edit-adv-input"
                                    }
                                    style={
                                      isItineraryDisabled
                                        ? {
                                            fontSize: "12px",
                                            color: "black !important",
                                            opacity: "1 !important",
                                            background: "transparent",
                                          }
                                        : {
                                            fontSize: "12px",
                                            color: "black !important",
                                            opacity: "1 !important",
                                          }
                                    }
                                  ></IonInput>
                                </IonItem>
                                <IonItem
                                  className="edit-adv-item-area"
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <IonTextarea
                                    value={
                                      oneItinerary.descriptionText[
                                        newAdventureLangCurrent
                                      ]
                                    }
                                    disabled={
                                      oneItinerary.titleText[
                                        newAdventureLangCurrent
                                      ] === "" || isItineraryDisabled
                                    }
                                    name="desc"
                                    autoGrow={true}
                                    placeholder={lang.label105}
                                    inputmode="text"
                                    maxlength={1200}
                                    onIonChange={(event) =>
                                      handleItiChange(index, event)
                                    }
                                    className={
                                      isItineraryDisabled
                                        ? "edit-adv-input2-area"
                                        : "edit-adv-input-area"
                                    }
                                    style={
                                      isItineraryDisabled
                                        ? {
                                            fontSize: "12px",
                                            color: "black !important",
                                            opacity: "1 !important",
                                            background: "transparent",
                                          }
                                        : {
                                            fontSize: "12px",
                                            color: "black !important",
                                            opacity: "1 !important",
                                          }
                                    }
                                  ></IonTextarea>
                                </IonItem>
                                <IonItem
                                  className="edit-adv-item-area last-itinerary"
                                  style={{
                                    marginBottom: "20px",
                                    paddingTop: "0 !important",
                                  }}
                                >
                                  {oneItinerary.images[0] === "" ? (
                                    <div
                                      style={{
                                        marginTop: "15px",
                                        width: "100%",
                                      }}
                                    >
                                      <IonButton
                                        style={{
                                          display: "inline-block",
                                          height: "100px",
                                          width: "100%",
                                          margin: "0 0 20px 0",
                                          "--background": "#ebebeb",
                                          "--border-radius": "10px",
                                          "--box-shadow": "none",
                                          color: "#3fd0d4",
                                        }}
                                        onClick={() =>
                                          handlePictureClick2(index)
                                        }
                                      >
                                        <IonGrid>
                                          <IonRow>
                                            <IonCol>
                                              <IonRow
                                                style={{
                                                  justifyContent: "center",
                                                  marginBottom: "5px",
                                                }}
                                              >
                                                <Image
                                                  size={35}
                                                  style={{ width: "80%" }}
                                                />
                                              </IonRow>
                                              <IonRow
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <IonText
                                                  style={{
                                                    textTransform: "initial",
                                                  }}
                                                >
                                                  {lang.label333}
                                                </IonText>
                                              </IonRow>
                                            </IonCol>
                                          </IonRow>
                                        </IonGrid>
                                      </IonButton>
                                    </div>
                                  ) : (
                                    <IonGrid
                                      style={{ padding: 0, width: "100%" }}
                                    >
                                      {oneItinerary.images.map(
                                        (img: any, indexis: number) => {
                                          return (
                                            <IonRow key={indexis}>
                                              <IonCol
                                                style={{
                                                  padding: 0,
                                                  width: "100%",
                                                }}
                                              >
                                                {img.substring(0, 4) ===
                                                "data" ? (
                                                  <div>
                                                    <IonThumbnail
                                                      style={{
                                                        "--border-radius":
                                                          "20px",
                                                        width: "100%",
                                                        height: "auto",
                                                        marginBottom: "10px",
                                                      }}
                                                    >
                                                      <IonImg
                                                        /* onClick={() => {
                                                          if (
                                                            isItineraryDisabled
                                                          )
                                                            return;
                                                          handlePictureClick2(
                                                            index
                                                          );
                                                        }} */
                                                        style={{
                                                          paddingTop: "0px",
                                                        }}
                                                        src={img}
                                                        alt={
                                                          oneItinerary.titleText
                                                            .CS
                                                        }
                                                      ></IonImg>
                                                    </IonThumbnail>

                                                    {!isItineraryDisabled && (
                                                      <XCircle
                                                        size={35}
                                                        color="#d21e1e"
                                                        style={{
                                                          position: "absolute",
                                                          top: "5px",
                                                          right: "5px",
                                                        }}
                                                        onClick={() =>
                                                          remImage(
                                                            index,
                                                            indexis
                                                          )
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                ) : (
                                                  <IonSpinner
                                                    className="spinner-loading"
                                                    color="primary"
                                                  />
                                                )}
                                              </IonCol>
                                            </IonRow>
                                          );
                                        }
                                      )}
                                      {!isItineraryDisabled && (
                                        <IonRow
                                          style={{
                                            height: "35px",
                                            paddingTop: "8px",
                                          }}
                                        >
                                          <IonText
                                            onClick={() => {
                                              if (isItineraryDisabled) return;
                                              handlePictureClick2(index);
                                            }}
                                            style={{
                                              fontSize: "12px",
                                              textDecoration: "underline",
                                            }}
                                          >
                                            {lang.label374}
                                          </IonText>
                                        </IonRow>
                                      )}
                                    </IonGrid>
                                  )}
                                </IonItem>
                              </div>
                            </IonReorder>
                          );
                        })}
                    </IonReorderGroup>
                    {!isItineraryDisabled && (
                      <IonButton
                        onClick={() => addIty()}
                        style={{ float: "left", margin: "0 0 15px 0" }}
                      >
                        <PlusCircle
                          size={30}
                          className="add-date"
                          style={{ paddingTop: "7px" }}
                        />
                        <IonLabel style={{ textTransform: "Initial" }}>
                          {lang.label375}
                        </IonLabel>
                      </IonButton>
                    )}
                  </IonList>
                </IonRow>

                <IonRow style={{ display: "contents" }}>
                  <IonCol
                    size="7.5"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <IonRow>
                      <IonText
                        className="text-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {lang.label273}
                      </IonText>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size="4.5"
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                    }}
                  >
                    {!isGalleryDisabled && (
                      <IonButton
                        style={{
                          height: "25px",
                          marginTop: "10px",
                          "--padding-start": "5px !important",
                        }}
                        className="edit-adv-button2"
                        onClick={() => {
                          let temp = JSON.parse(JSON.stringify(imagesDefault));
                          let temp2 = JSON.parse(
                            JSON.stringify(imagesDataDefault)
                          );
                          setImages(temp);
                          setImagesData(temp2);
                          setIsGalleryDisabled((state) => !state);
                        }}
                        color="danger"
                      >
                        X
                      </IonButton>
                    )}
                    <IonButton
                      style={{
                        height: "25px",
                        marginTop: "10px",
                        "--padding-start": "5px !important",
                      }}
                      className="edit-adv-button"
                      onClick={
                        isGalleryDisabled
                          ? () => {
                              setIsGalleryDisabled((state) => !state);
                            }
                          : () => {
                              setMark(28);
                              setShowEditModal(true);
                            }
                      }
                    >
                      {isGalleryDisabled ? lang.label269 : lang.label167}
                    </IonButton>
                  </IonCol>

                  <IonList style={{ padding: "0 5px", width: "100%" }}>
                    {imagesData.map((oneImage: any, index: number) => {
                      console.log(oneImage);
                      if (isGalleryDisabled && oneImage === "") return "";
                      return (
                        <IonItem
                          key={index}
                          className="gallery-edit" /* className="user-info-item item-input-grey" */
                        >
                          <IonLabel
                            position="stacked"
                            className="text-bold"
                            style={{ marginBottom: "15px", fontSize: "18px" }}
                          >
                            {lang.label274} {index + 1}
                          </IonLabel>
                          {oneImage === "" ? (
                            <div style={{ marginTop: "0px", width: "100%" }}>
                              <IonButton
                                style={{
                                  display: "inline-block",
                                  height: "100px",
                                  width: "100%",
                                  margin: "0 0 20px 0",
                                  "--background": "#ebebeb",
                                  "--border-radius": "10px",
                                  "--box-shadow": "none",
                                  color: "#3fd0d4",
                                }}
                                disabled={isGalleryDisabled}
                                onClick={() => {
                                  /* if (!isGalleryDisabled) */
                                  handlePictureClickGall(index);
                                }}
                              >
                                <IonGrid>
                                  <IonRow>
                                    <IonCol>
                                      <IonRow
                                        style={{
                                          justifyContent: "center",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <Image
                                          size={35}
                                          style={{ width: "80%" }}
                                        />
                                      </IonRow>
                                      <IonRow
                                        style={{ justifyContent: "center" }}
                                      >
                                        <IonText
                                          style={{ textTransform: "initial" }}
                                        >
                                          {lang.label333}
                                        </IonText>
                                      </IonRow>
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              </IonButton>
                            </div>
                          ) : oneImage.substring(0, 4) === "data" ? (
                            <IonThumbnail
                              style={{
                                "--border-radius": "20px",
                                width: "98%",
                                height: "auto",
                                marginLeft: "1%",
                              }}
                            >
                              <IonImg
                                /* onClick={() => handlePictureClick(index)} */
                                /* className="gal-img" */
                                src={oneImage}
                              ></IonImg>
                            </IonThumbnail>
                          ) : (
                            <IonSpinner
                              className="spinner-loading"
                              color="primary"
                            />
                          )}
                          {(index > 0 ||
                            (images.length > 0 && images[index] !== "")) &&
                            !isGalleryDisabled && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "8px",
                                  right: "0px",
                                  zIndex: 1000,
                                }}
                              >
                                <IonText
                                  style={{
                                    fontSize: "12px",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() => remImg(index)}
                                >
                                  {lang.label378}
                                </IonText>
                                {/* <MinusCircle
                            size={34}
                            onClick={() => remDate(index)}
                          /> */}
                              </div>
                            )}
                        </IonItem>
                      );
                    })}

                    {!isGalleryDisabled && (
                      <IonText
                        style={{
                          textTransform: "Initial",
                          /* marginLeft: "36px", */
                          marginTop: "10px",
                          marginBottom: "10px",
                          textDecoration: "underline",
                          fontSize: "12px",
                          textAlign: "left",
                          display: "block",
                        }}
                        onClick={() => addImage()}
                      >
                        {lang.label374}
                      </IonText>
                    )}
                  </IonList>
                </IonRow>
              </IonRow>
            )}

            <IonRow
              onClick={() => setDocumentsEdit((state) => !state)}
              style={{
                borderTop: "1px solid #DEDEDE",
                paddingLeft: "36px",
                textAlign: "left",
              }}
            >
              <IonCol size="10" style={{ paddingLeft: 0, fontSize: "18px" }}>
                <IonCol style={{ paddingLeft: 0 }}>
                  <Files
                    color="#3fd0d4"
                    size={24}
                    style={{ verticalAlign: "bottom" }}
                  />
                </IonCol>
                <IonCol>{lang.label313}</IonCol>
              </IonCol>
              <IonCol style={{ textAlign: "right" }}>
                {documentsEdit ? (
                  <CaretDown size={20} color="#3fd0d4" />
                ) : (
                  <CaretUp size={20} color="#3fd0d4" />
                )}
              </IonCol>
            </IonRow>
            {documentsEdit && (
              <div>
                <IonRow style={{ borderTop: "1px solid #DEDEDE" }}>
                  <IonCol
                    style={{
                      paddingLeft: 0,
                      marginLeft: "36px",
                      textAlign: "left",
                      paddingTop: "10px",
                    }}
                  >
                    {lang.label314}
                  </IonCol>
                  <IonCol style={{ textAlign: "right" }}>
                    {documentsEntireEdit ? (
                      <div>
                        <IonButton
                          style={{
                            height: "25px",
                            "--padding-start": "5px !important",
                          }}
                          className="edit-adv-button2"
                          onClick={() => {
                            let documentsTemp = [...documentsEntireDefault];
                            setDocumentsEntire(documentsTemp);
                            setDocumentsEntireEdit((state) => !state);
                          }}
                          color="danger"
                        >
                          X
                        </IonButton>
                        <IonButton
                          style={{
                            height: "25px",
                            "--padding-start": "5px !important",
                          }}
                          className="edit-adv-button"
                          onClick={() => {
                            setMark(18);
                            setShowEditModal(true);
                          }}
                        >
                          {lang.label167}
                        </IonButton>
                      </div>
                    ) : (
                      <IonButton
                        style={{ height: "25px" }}
                        onClick={() =>
                          setDocumentsEntireEdit((state) => !state)
                        }
                      >
                        {lang.label269}
                      </IonButton>
                    )}
                  </IonCol>
                </IonRow>
                {documentsEntireEdit && (
                  <IonList>
                    {documentsEntire.map((document: any, indexon: number) => {
                      if (document.name === "") return null;
                      return (
                        <IonItem
                          key={indexon}
                          style={{
                            marginLeft: "36px",
                            marginRight: "36px",
                            "--padding-start": "0",
                            "--inner-padding-end": "0",
                          }}
                        >
                          <IonGrid
                            style={{
                              height: "40px",
                              paddingInline: 0,
                              width: "100%",
                            }}
                          >
                            <IonRow style={{ height: "40px" }}>
                              <IonCol
                                size="10"
                                style={{
                                  height: "40px",
                                  paddingLeft: 0,
                                  paddingTop: 0,
                                }}
                              >
                                <IonButton
                                  download={document.name}
                                  href={document.data}
                                  style={{
                                    width: "100%",
                                    height: "30px",
                                    marginTop: 2,
                                    marginLeft: 0,
                                  }}
                                >
                                  <IonText
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {document.name}
                                  </IonText>
                                </IonButton>
                              </IonCol>
                              <IonCol
                                style={{
                                  height: "40px",
                                  paddingTop: "0",
                                }}
                              >
                                <MinusCircle
                                  size={36}
                                  /* className="add-date" */
                                  onClick={() => remDocument("ENTIRE", indexon)}
                                />
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonItem>
                      );
                    })}
                    <IonItem className="no-border">
                      <FileInput
                        value={newEntireDocuments}
                        onChange={readFile}
                      />
                    </IonItem>
                  </IonList>
                )}
                <IonList>
                  {dates.map((date, index) => {
                    return (
                      <IonItem
                        key={index}
                        className="user-info-item item-input-grey"
                      >
                        <IonGrid style={{ padding: 0, width: "100%" }}>
                          <IonRow
                          /* onClick={() => {
                              if (!isDatesEdited) {
                                setDateId(date.id);
                                showDate(date.id);
                              } else {
                                let tempEdit = [...isDateEdited];
                                tempEdit[index] = !tempEdit[index];
                                setIsDateEdited(tempEdit);
                              }
                            }} */
                          >
                            <IonCol
                              style={{
                                width: "50vw",
                                display: "inline-block",
                                padding: 0,
                                height: "3em",
                              }}
                            >
                              <IonRow>
                                <IonText
                                  className="text-bold"
                                  style={{ fontSize: "14px" }}
                                >
                                  {lang.label127}
                                </IonText>
                              </IonRow>
                              <IonRow>
                                <IonText style={{ fontSize: "12px" }}>
                                  {moment(date.begin, "YYYY-MM-DD").format(
                                    "DD.MM.YYYY"
                                  )}{" "}
                                  -{" "}
                                  {moment(date.end, "YYYY-MM-DD").format(
                                    "DD.MM.YYYY"
                                  )}
                                </IonText>
                              </IonRow>
                            </IonCol>
                            <IonCol
                              style={{ paddingRight: 0, textAlign: "right" }}
                            >
                              {documentsDateEdit[index] ? (
                                <div>
                                  <IonButton
                                    style={{
                                      height: "25px",
                                      "--padding-start": "5px !important",
                                    }}
                                    className="edit-adv-button2"
                                    onClick={() => {
                                      let documentsTemp = [
                                        ...documentsDateDefault,
                                      ];
                                      setDocumentsDate(documentsTemp);
                                      let documentsTempEdit = [
                                        ...documentsDateEdit,
                                      ];
                                      documentsTempEdit[index] =
                                        !documentsTempEdit[index];
                                      setDocumentsDateEdit(documentsTempEdit);
                                    }}
                                    color="danger"
                                  >
                                    X
                                  </IonButton>
                                  <IonButton
                                    style={{
                                      height: "25px",
                                      "--padding-start": "5px !important",
                                    }}
                                    className="edit-adv-button"
                                    onClick={() => {
                                      setMark(19);
                                      setIndexOfDocs(index);
                                      setShowEditModal(true);
                                    }}
                                  >
                                    {lang.label167}
                                  </IonButton>
                                </div>
                              ) : (
                                <IonButton
                                  style={{ height: "25px" }}
                                  onClick={() => {
                                    let documentsTempEdit = [
                                      ...documentsDateEdit,
                                    ];
                                    documentsTempEdit[index] =
                                      !documentsTempEdit[index];
                                    setDocumentsDateEdit(documentsTempEdit);
                                  }}
                                >
                                  {lang.label269}
                                </IonButton>
                              )}
                            </IonCol>
                            {/* {date.capacity - date.free}/{date.capacity}
                              {isDatesEdited && isDateEdited[index] && (
                                <CaretDown
                                  size={22}
                                  color="#3fd0d4"
                                  style={{ verticalAlign: "bottom" }}
                                />
                              )}
                              {isDatesEdited && !isDateEdited[index] && (
                                <CaretUp
                                  size={22}
                                  color="#3fd0d4"
                                  style={{ verticalAlign: "bottom" }}
                                />
                              )} */}
                            {/* </IonCol> */}
                          </IonRow>
                          {documentsDateEdit[index] && (
                            <IonList>
                              {documentsDate[index].map(
                                (document: any, indexon: number) => {
                                  if (document.name === "") return null;
                                  return (
                                    <IonItem
                                      key={indexon}
                                      style={{
                                        /* marginLeft: "36px",
                                        marginRight: "36px", */
                                        "--padding-start": "0",
                                        "--inner-padding-end": "0",
                                      }}
                                    >
                                      <IonGrid
                                        style={{
                                          height: "40px",
                                          paddingInline: 0,
                                          width: "100%",
                                        }}
                                      >
                                        <IonRow style={{ height: "40px" }}>
                                          <IonCol
                                            size="10"
                                            style={{
                                              height: "40px",
                                              paddingLeft: 0,
                                              paddingTop: 0,
                                            }}
                                          >
                                            <IonButton
                                              download={document.name}
                                              href={document.data}
                                              style={{
                                                width: "100%",
                                                height: "30px",
                                                marginTop: 2,
                                                marginLeft: 0,
                                              }}
                                            >
                                              <IonText
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                }}
                                              >
                                                {document.name}
                                              </IonText>
                                            </IonButton>
                                          </IonCol>
                                          <IonCol
                                            style={{
                                              height: "40px",
                                              paddingTop: "0",
                                            }}
                                          >
                                            <MinusCircle
                                              size={36}
                                              /* className="add-date" */
                                              onClick={() =>
                                                remDocument(
                                                  "DATE",
                                                  indexon,
                                                  index
                                                )
                                              }
                                            />
                                          </IonCol>
                                        </IonRow>
                                      </IonGrid>
                                    </IonItem>
                                  );
                                }
                              )}
                              <IonItem className="no-border">
                                <FileInput
                                  value={newDateDocuments[index]}
                                  index={index}
                                  onChange={readFile}
                                />
                              </IonItem>
                            </IonList>
                          )}
                        </IonGrid>
                      </IonItem>
                    );
                  })}
                </IonList>
              </div>
            )}
            <IonRow style={{ borderTop: "1px solid #DEDEDE" }}>
              <IonCol>
                <IonButton
                  style={{ height: "25px", textTransform: "Initial" }}
                  onClick={() => setConfirmModal(true)}
                  color="danger"
                >
                  {lang.label422}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        );
      /* case "itinerary":
        return <AdvItinerary advData={advData} />;
      case "gallery":
        return <AdvGallery advData={advData} />;
      case "ratings":
        return <AdvRatings advData={advData} />; */
      default:
        return (
          <IonList>
            {dates.map((date, index) => {
              return (
                <IonItem
                  key={index + date.begin}
                  className="user-info-item item-input-grey"
                >
                  <IonGrid style={{ padding: 0 }}>
                    <IonRow
                      style={{ justifyContent: "space-between" }}
                      onClick={() => {
                        if (!isDatesEdited) {
                          setDateId(date.id);
                          showDate(date.id);
                        } else {
                          let tempEdit = [...isDateEdited];
                          tempEdit[index] = !tempEdit[index];
                          setIsDateEdited(tempEdit);
                        }
                      }}
                    >
                      <IonCol
                        style={{
                          width: "50vw",
                          maxWidth: "600px",
                          display: "inline-block",
                          padding: 0,
                          height: "3em",
                        }}
                      >
                        <IonRow>
                          <IonText
                            className="text-bold"
                            style={{ fontSize: "14px" }}
                          >
                            {lang.label127}
                          </IonText>
                        </IonRow>
                        <IonRow>
                          <IonText style={{ fontSize: "12px" }}>
                            {moment(date.begin, "YYYY-MM-DD").format(
                              "DD.MM.YYYY"
                            )}{" "}
                            -{" "}
                            {moment(date.end, "YYYY-MM-DD").format(
                              "DD.MM.YYYY"
                            )}
                          </IonText>
                        </IonRow>
                      </IonCol>
                      <IonCol
                        style={{
                          width: "27vw",
                          maxWidth: "370px",
                          display: "inline-block",
                          padding: 0,
                          textAlign: "right",
                          paddingTop: "1.5%",
                        }}
                      >
                        {(advData.dates[index]?.id !== undefined
                          ? advData.dates[index]?.capacity
                          : 0) - date.free}
                        /{date.capacity}
                        {isDatesEdited && isDateEdited[index] && (
                          <CaretDown
                            size={22}
                            color="#3fd0d4"
                            style={{ verticalAlign: "bottom" }}
                          />
                        )}
                        {isDatesEdited && !isDateEdited[index] && (
                          <CaretUp
                            size={22}
                            color="#3fd0d4"
                            style={{ verticalAlign: "bottom" }}
                          />
                        )}
                      </IonCol>
                    </IonRow>
                    {isDateEdited[index] && (
                      <IonRow
                        key={index}
                        className="date-row"
                        style={{ borderBlockEnd: "solid 1px black !important" }}
                      >
                        <IonCol size="6" style={{ height: "140px" }}>
                          <IonRow>
                            <IonLabel
                              className="user-info-label"
                              style={{ display: "contents" }}
                            >
                              {lang.label238} *
                            </IonLabel>
                          </IonRow>
                          <IonRow>
                            <IonItem
                              className="user-info-item item-input-grey"
                              style={{
                                marginLeft: "0px",
                                marginRight: "0px",
                                width: "100%",
                              }}
                            >
                              <IonDatetime
                                min={moment(new Date()).format("YYYY-MM-DD")}
                                max={moment(new Date())
                                  .add(2, "year")
                                  .format("YYYY-MM")}
                                displayFormat="DD.MM.YYYY"
                                value={date.begin}
                                name="dob"
                                placeholder={lang.label104}
                                onIonChange={(event) =>
                                  handleDateChange(index, event)
                                }
                                style={{
                                  paddingLeft: "0",
                                  height: "30px",
                                  width: "100%",
                                }}
                                className="contact-input"
                                disabled={
                                  advData.dates[index]?.capacity !==
                                  advData.dates[index]?.free
                                    ? true
                                    : false
                                }
                              ></IonDatetime>
                            </IonItem>
                          </IonRow>
                          <IonRow>
                            <IonLabel
                              className="user-info-label"
                              style={{ display: "contents" }}
                            >
                              {lang.label240}
                            </IonLabel>
                          </IonRow>
                          <IonRow>
                            <IonItem
                              className="user-info-item item-input-grey"
                              style={{
                                marginLeft: "0px",
                                marginRight: "0px",
                                width: "100%",
                              }}
                            >
                              <IonInput
                                value={date.capacity}
                                name="capacity"
                                onIonChange={(event) =>
                                  handleDateChange(index, event)
                                }
                                inputmode="numeric"
                                type="number"
                                className="contact-input"
                                style={{
                                  paddingLeft: "0",
                                  height: "30px",
                                  width: "100%",
                                  textAlign: "center",
                                }}
                              ></IonInput>
                            </IonItem>
                          </IonRow>
                        </IonCol>
                        <IonCol size="6" style={{ height: "100px" }}>
                          <IonRow>
                            <IonLabel
                              className="user-info-label"
                              style={{ display: "contents" }}
                            >
                              {lang.label239} *
                            </IonLabel>
                          </IonRow>
                          <IonRow>
                            <IonItem
                              className="user-info-item item-input-grey"
                              style={{
                                marginLeft: "0px",
                                marginRight: "0px",
                                width: "100%",
                              }}
                            >
                              <IonDatetime
                                min={
                                  date.begin === ""
                                    ? moment(new Date()).format("YYYY-MM-DD")
                                    : moment(date.begin, "YYYY-MM-DD").format(
                                        "YYYY-MM-DD"
                                      )
                                }
                                max={moment(new Date())
                                  .add(2, "year")
                                  .format("YYYY-MM")}
                                displayFormat="DD.MM.YYYY"
                                value={date.end}
                                name="dob2"
                                placeholder={lang.label104}
                                onIonChange={(event) =>
                                  handleDateChange(index, event)
                                }
                                style={{
                                  paddingLeft: "0",
                                  height: "30px",
                                  width: "100%",
                                }}
                                disabled={
                                  advData.dates[index]?.capacity !==
                                  advData.dates[index]?.free
                                    ? true
                                    : false
                                }
                                className="contact-input"
                              ></IonDatetime>
                            </IonItem>
                          </IonRow>
                          <IonRow>
                            <IonLabel
                              className="user-info-label"
                              style={{ display: "contents" }}
                            >
                              {lang.label207} *
                            </IonLabel>
                          </IonRow>
                          <IonRow>
                            <IonItem
                              className="user-info-item item-input-grey"
                              style={{
                                marginLeft: "0px",
                                marginRight: "0px",
                                width: "100%",
                              }}
                            >
                              <IonInput
                                value={date.prices.CZK}
                                name="price"
                                onIonChange={(event) =>
                                  handleDateChange(index, event)
                                }
                                inputmode="numeric"
                                type="number"
                                className="price-input kc-input"
                                style={{
                                  height: "30px",
                                  width: "100%",
                                  textAlign: "center",
                                }}
                              ></IonInput>
                            </IonItem>
                          </IonRow>
                        </IonCol>
                        <div
                          style={{
                            height: "inherit",
                            width: "36px",
                            lineHeight: "36px",
                            margin: "0 auto 5px auto",
                          }}
                        >
                          {advData.dates[index]?.capacity ===
                            advData.dates[index]?.free && (
                            <MinusCircle
                              size={36}
                              onClick={() => remDate(index)}
                              style={{ verticalAlign: "middle" }}
                            />
                          )}
                        </div>
                      </IonRow>
                    )}
                  </IonGrid>
                </IonItem>
              );
            })}
            {isDatesEdited && (
              <IonItem className="no-border">
                <PlusCircle
                  size={36}
                  className="add-date"
                  onClick={() => addDate()}
                />
              </IonItem>
            )}
          </IonList>
        );
    }
  }

  function showDate(id: string) {
    getReservations(id);
  }

  async function getReservations(dateIdLocal: string) {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    let dateTemp = { ...dateData };
    api
      .get("/adventures/my", config)
      .then((response) => {
        response.data.data.map((adventure: any) => {
          adventure.dates.map((date: any) => {
            if (date.id === dateIdLocal) {
              dateTemp = date;
            }
          });
        });
        setDateData(dateTemp);
      })
      .catch((err) => {
        // what now?
        console.log(err);
      });
  }

  const handleDateChange = (index: number, event: any) => {
    let arrDates = [...dates];
    switch (event.target.name) {
      case "dob":
        arrDates[index].begin = moment(event.target.value).format("YYYY-MM-DD");
        if (arrDates[index].end < arrDates[index].begin) {
          arrDates[index].end = "";
          setToastMessage(lang.label459);
          setShowToast(true);
        }
        break;
      case "dob2":
        arrDates[index].end = moment(event.target.value).format("YYYY-MM-DD");
        break;
      case "capacity":
        if (Number(event.target.value) < 1001) {
          arrDates[index].capacity = Number(event.target.value);
        } else {
          arrDates[index].capacity = 1000;
        }
        break;
      case "price":
        if (Number(event.target.value) < 1000000) {
          arrDates[index].prices.CZK = Number(event.target.value);
        } else {
          arrDates[index].prices.CZK = 999999;
        }
        break;
      default:
        break;
    }
    setDates(arrDates);
  };

  const remDate = (index: number) => {
    let arrDateEdit = [...isDateEdited];
    arrDateEdit.splice(index, 1);
    setIsDateEdited(arrDateEdit);
    let arrDates = [...dates];
    arrDates.splice(index, 1);
    setDates(arrDates);
  };

  const remImg = (index: number) => {
    let arrImgData = JSON.parse(JSON.stringify(imagesData));
    arrImgData.splice(index, 1);
    if (
      arrImgData.length ===
      0 /* .length === 1 && arrImgData[0] === ("" || null) */
    ) {
      arrImgData = [""];
    }
    let arrImg = [...images];
    arrImg.splice(index, 1);
    if (arrImg.length === 0) {
      arrImg.push("");
    }
    /* setNewAdventure(arrDate); */
    setImagesData(arrImgData);
    setImages(arrImg);
  };

  const addDate = () => {
    let arrDates = [...dates];
    arrDates.push({
      id: "",
      begin: "",
      end: "",
      prices: {
        EUR: 0,
        CZK: 0,
      },
      free: 0,
      capacity: 0,
      documents: [""],
    });
    setDates(arrDates);
  };

  async function saveDates() {
    let tempData = { ...advData };
    tempData.dates = [...dates];
    tempData.dates.forEach(
      function (v: any, index: number) {
        if (v.id === "") {
          delete tempData.dates[index].id;
        }
        /* delete tempData.dates[index].free; */
        /* delete tempData.dates[index].documents; */
      } /* (date, index) => {
      if (date.id === "") {
        delete tempData?.dates[index]?.id;
      }
      delete tempData?.dates[index]?.free;
      delete tempData?.dates[index]?.documents;
    } */
    );
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    return api
      .patch("/adventures/" + advData.id, tempData, config)
      .then((response) => {
        setAdvData(response.data);
        let temp = JSON.parse(JSON.stringify(response.data.dates));
        setDates([...temp]);
        let dateEditedTemp = [...isDateEdited];
        dateEditedTemp.forEach((date, index) => {
          dateEditedTemp[index] = false;
        });
        setIsDateEdited(dateEditedTemp);
        setIsDatesEdited(false);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  function isActive() {
    let sign = false;
    let timeMark = moment(new Date());
    advData.dates.forEach((date: any) => {
      if (moment(date.end, "YYYY-MM-DD") >= timeMark) {
        sign = true;
      }
    });
    return sign;
  }

  const getLowestPrice = (item: any) => {
    let min = 100000000;
    for (var i = 0; i < item.dates.length; i++) {
      if (item.dates[i].prices.CZK < min) {
        min = item.dates[i].prices.CZK;
      }
    }
    let config = language;
    language === "cs" ? (config = config + "-CZ") : (config = config + "-GB");
    return (
      <div style={{ display: "inline-block" }}>
        {" "}
        • {min.toLocaleString(config) + " Kč"}
      </div>
    );
  };

  const getTripLength = (item: any) => {
    var start = moment(item.dates[0].begin, "YYYY-MM-DD");
    var end = moment(item.dates[0].end, "YYYY-MM-DD");
    var length = end.diff(start, "days") + 1;
    if (length === 1) {
      return "1 " + lang.label191;
    } else if (length < 5) {
      let message = length.toString() + " " + lang.label192;
      return message;
    } else {
      let message = length.toString() + " " + lang.label193;
      return message;
    }
  };

  useEffect(() => {
    if (isFirstRender2.current === true) {
      isFirstRender2.current = false;
      return;
    }
    /* if (isSecondRender.current === true) {
      isSecondRender.current = false;
      return;
    } */
    if (isThirdRender.current === true) {
      return;
    }
    let helpX = JSON.parse(JSON.stringify(itinerary));
    Promise.all(
      helpX.map(async (itiner: any, index: number) => {
        await Promise.all(
          itiner.images.map(async (image: string) => {
            if (image.substring(0, 4) !== "data") {
              if (await checkImageCache(image)) {
                image = await db.get(image);
              } else {
                const getImageData = async () => {
                  const api = axios.create({
                    baseURL: `https://app.kangelo.com/`,
                  });
                  var config = {
                    headers: {
                      "X-Auth-Token": token,
                    },
                  };
                  if (image) {
                    return api
                      .get("/media/" + image, config)
                      .then(async (res) => {
                        await db.set(image, res.data.data);
                        image = await res.data.data;
                      })
                      .catch((err) => {
                        // what now?
                        console.log(err.data);
                      });
                  }
                };
                await getImageData();
              }
              return image;
            }
          })
        ).then((images) => {
          itiner.images = images;
        });
      })
    ).then(() => {
      isThirdRender.current = true;
      setItinerary(helpX);
      /* fetchImages(); */
      setItineraryDefault(JSON.parse(JSON.stringify(helpX)));
    });

    /* setItinerary(helpX); */
  }, [itinerary]);

  useEffect(() => {
    if (isFirstRender5.current) {
      isFirstRender5.current = false;
      return;
    }
    if (isThirdRender5.current) {
      return;
    }
    fetchImages();
    isThirdRender5.current = true;
  }, [images]);

  async function fetchImages() {
    let helpImages = JSON.parse(JSON.stringify(images));
    Promise.all(
      helpImages.map(async (image: string) => {
        if (image.substring(0, 4) !== "data" && image !== "") {
          if (await checkImageCache(image)) {
            image = await db.get(image);
          } else {
            const getImageData = async () => {
              const api = axios.create({
                baseURL: `https://app.kangelo.com/`,
              });
              var config = {
                headers: {
                  "X-Auth-Token": token,
                },
              };
              if (image) {
                return api
                  .get("/media/" + image, config)
                  .then(async (res) => {
                    await db.set(image, res.data.data);
                    image = await res.data.data;
                  })
                  .catch((err) => {
                    // what now?
                    console.log(err.data);
                  });
              }
            };
            await getImageData();
          }
          return image;
        } else return "";
      })
    ).then((imgs) => {
      helpImages = imgs;
      setImagesData(helpImages);
      /* setImagesDefault(JSON.parse(JSON.stringify(images))); */
      let helpDefaultImg = JSON.parse(JSON.stringify(imgs));
      setImagesDataDefault(helpDefaultImg);
    });
  }

  useEffect(() => {
    if (isFirstRender4.current === true) {
      isFirstRender4.current = false;
      return;
    }
    /* if (isSecondRender.current === true) {
      isSecondRender.current = false;
      return;
    } */
    if (isThirdRender4.current === true) {
      return;
    }
    let documentsDateTemp = [...documentsDate];
    let helpX = JSON.parse(JSON.stringify(advData.dates));
    Promise.all(
      helpX.map(async (date: any, index: number) => {
        await Promise.all(
          date.documents.map(async (docu: string) => {
            if (docu.substring(0, 4) !== "data") {
              const getImageData = async () => {
                const api = axios.create({
                  baseURL: `https://app.kangelo.com/`,
                });
                var config = {
                  headers: {
                    "X-Auth-Token": token,
                  },
                };
                if (docu) {
                  return api
                    .get("/media/" + docu, config)
                    .then(async (res) => {
                      return (docu = await res.data);
                    })
                    .catch((err) => {
                      // what now?
                      console.log(err.data);
                    });
                }
              };
              await getImageData();
              /* return image; */
            }
            return docu;
          })
        ).then((doc) => {
          documentsDateTemp[index] = doc;
        });
      })
    ).then(() => {
      isThirdRender4.current = true;
      let helpTemp = [[""]];
      for (var i = 0; i < documentsDateTemp.length; i++) {
        helpTemp[i] = [""];
      }
      setDateDocumentsDeletedId(helpTemp);
      setDocumentsDate(documentsDateTemp);
      setDocumentsDateDefault(documentsDateTemp);
    });

    /* setItinerary(helpX); */
  }, [itinerary]);

  useEffect(() => {
    if (isFirstRender3.current === true) {
      isFirstRender3.current = false;
      return;
    }
    /* if (isSecondRender.current === true) {
      isSecondRender.current = false;
      return;
    } */
    if (isThirdRender3.current === true) {
      return;
    }
    let helpX = JSON.parse(JSON.stringify(advData.documents));
    Promise.all(
      helpX.map(async (doc: any, index: number) => {
        if (doc) {
          const getDocData = async () => {
            const api = axios.create({
              baseURL: `https://app.kangelo.com/`,
            });
            var config = {
              headers: {
                "X-Auth-Token": token,
              },
            };
            if (doc) {
              return api
                .get("/media/" + doc, config)
                .then(async (res) => {
                  return (doc = await res.data);
                })
                .catch((err) => {
                  // what now?
                  console.log(err.data);
                });
            }
          };
          await getDocData();
        }
        return doc;
      })
    ).then((resData) => {
      isThirdRender3.current = true;
      setDocumentsEntire(resData);
      setDocumentsEntireDefault(resData);
    });

    /* setItinerary(helpX); */
  }, [itinerary]);

  return showLoading ? (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="back-butt web-box">
        <IonToolbar className="back-butt">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tab1"
              className="back-button"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSpinner
          className="spinner-loading"
          color="primary"
          style={{ marginTop: "10vh" }}
        />
        <IonText className="text-center" style={{ display: "block" }}>
          {lang.label222}
        </IonText>
      </IonContent>
    </IonPage>
  ) : (
    <IonPage id="main">
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader
        className="ud-header bottom-border web-box"
        /* style={{ borderBottom: "1px solid #DEDEDE" }} */
      >
        <IonToolbar /* className="back-butt" */>
          <IonButtons slot="start" onClick={() => history.goBack()}>
            <IonButton className="modal-search-back">
              <ArrowLeft size={24} color="black" />
              <IonText>{checkProperty(advData.nameText, language)}</IonText>
            </IonButton>
            {/* <IonBackButton defaultHref="tab1" text= /> */}
          </IonButtons>
          {adventureEdit && Object.keys(advData.nameText).length > 1 && (
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setNewAdventureLangCurrent(
                    newAdventureLangCurrent === "CS" ? "EN" : "CS"
                  );
                  setToastMessage2(
                    newAdventureLangCurrent === "CS"
                      ? lang.label426
                      : lang.label425
                  );
                  setShowToast2(true);
                }}
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                  width: "50px",
                }}
              >
                {newAdventureLangCurrent === "CS" ? "CZ" : "ENG"}
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="text-center">
        <div className="web-box">
          <IonText
            className="text-bold display-block"
            style={{
              fontSize: "20px",
              marginBlockEnd: "10px",
              marginBlockStart: "10px",
            }}
          >
            {lang.label111}
          </IonText>
          <IonText
            className={
              isActive()
                ? "display-block text-bold active-sign"
                : "display-block text-bold inactive-sign"
            }
            style={{
              fontSize: "16px",
              padding: "4px 25px",
              borderRadius: "15px",
              width: "min-content",
              marginLeft: "auto",
              marginRight: "auto",
              marginBlockEnd: "10px",
            }}
          >
            {isActive() ? lang.label251 : lang.label252}
          </IonText>
          <IonText>ID: {advData.id}</IonText>
          <IonSegment
            /* scrollable */
            onIonChange={(e) => {
              if (e.detail.value !== segment) {
                setSegment(e.detail.value);
              }
            }}
            value={segment}
            style={{ borderBottom: "1px solid #DEDEDE" }}
          >
            <IonSegmentButton value="default" className="segment-picker">
              <IonLabel>{lang.label253}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="setup" className="segment-picker">
              <IonLabel>{lang.label254}</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {renderSwitch(segment)}
        </div>
      </IonContent>
      {segment === "default" && (
        <IonToolbar slot="bottom " className="text-center safe-zone">
          {!isDatesEdited && (
            <IonButton onClick={() => setIsDatesEdited(true)}>
              {lang.label312}
            </IonButton>
          )}
          {isDatesEdited && (
            <div>
              <IonButton
                color="danger"
                onClick={() => {
                  let datesTemp = [...dates];
                  datesTemp = JSON.parse(JSON.stringify(advData.dates));
                  let dateEditedTemp = [...isDateEdited];
                  dateEditedTemp.forEach((date, index) => {
                    dateEditedTemp[index] = false;
                  });
                  setIsDateEdited(dateEditedTemp);
                  setDates(datesTemp);
                  setIsDatesEdited(false);
                }}
              >
                {lang.label166}
              </IonButton>
              <IonButton
                color="primary"
                onClick={() => {
                  let check = false;
                  dates.forEach((d: any, i: number) => {
                    if (d.begin === "" || d.end === "" || d.prices.CZK === 0) {
                      check = true;
                    }
                  });
                  if (check) {
                    setToastMessage(lang.label233);
                    setShowToast(true);
                  } else {
                    saveDates();
                  }
                }}
              >
                {lang.label167}
              </IonButton>
            </div>
          )}
        </IonToolbar>
      )}
      <IonModal
        isOpen={showEditModal}
        cssClass="small-modal"
        backdropDismiss
        onDidDismiss={() => {
          setShowEditModal(false);
        }}
      >
        <IonContent fullscreen>
          <IonGrid
            style={{
              /* width: "max-content", */
              marginLeft: "auto",
              marginRight: "auto",
              /* height: "max-content", */
              textAlign: "center",
              marginTop: "10vh",
            }}
          >
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonText style={{ width: "100%" }}>{lang.label266}</IonText>
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonButton
                  onClick={() => {
                    setShowEditModal(false);
                  }}
                  color="danger"
                  style={{ marginRight: "15px" }}
                >
                  {lang.label166}
                </IonButton>
                <IonButton
                  onClick={() => {
                    if (mark === 28) {
                      let temp = JSON.parse(JSON.stringify(editedAdventure));
                      if (images.length === 1 && images[0] === "") {
                        temp.images = [];
                      } else {
                        temp.images = [...images];
                      }
                      saveEditedAdventure(temp, mark);
                      setShowEditModal(false);
                    } else if (mark !== 18 && mark !== 19) {
                      let temp = JSON.parse(JSON.stringify(editedAdventure));
                      /* if (mark===16) {
                      temp.itinerary =
                    } */
                      saveEditedAdventure(temp, mark);
                      setShowEditModal(false);
                    } else if (mark === 18) {
                      uploadFiles();
                    } else {
                      uploadFiles2();
                    }
                  }}
                  color="primary"
                  style={{ marginLeft: "15px" }}
                >
                  {lang.label121}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showAddLangModal}
        /* cssClass="modal-fullscreen" */
        backdropDismiss
        onDidDismiss={() => {
          setShowAddLangModal(false);
        }}
      >
        <IonHeader className="main-header-search safe-zone-header bottom-border">
          <IonToolbar>
            <IonButtons
              slot="start"
              style={{
                width: "100%",
                /* justifyContent: "space-between",
                marginInlineEnd: "4px", */
              }}
            >
              <IonButton
                onClick={() => {
                  let temp = { ...editedAdventure };

                  temp.nameText = JSON.parse(JSON.stringify(advData.nameText));

                  temp.descriptionText = JSON.parse(
                    JSON.stringify(advData.descriptionText)
                  );

                  temp.includedText = JSON.parse(
                    JSON.stringify(advData.includedText)
                  );

                  temp.notIncludedText = JSON.parse(
                    JSON.stringify(advData.notIncludedText)
                  );

                  temp.accommodationText = JSON.parse(
                    JSON.stringify(advData.accommodationText)
                  );

                  temp.mealsText = JSON.parse(
                    JSON.stringify(advData.mealsText)
                  );

                  temp.transitText = JSON.parse(
                    JSON.stringify(advData.transitText)
                  );

                  temp.difficultyText = JSON.parse(
                    JSON.stringify(advData.difficultyText)
                  );

                  temp.moreInfoText = JSON.parse(
                    JSON.stringify(advData.moreInfoText)
                  );

                  setNewAdventureLangCurrent(Object.keys(temp.nameText)[0]);
                  setEditedAdventure(temp);
                  setIsLanguagesDisabled((state) => !state);
                  setShowAddLangModal(false);
                }}
                className="modal-search-back"
              >
                <ArrowLeft size={24} color="black" />
              </IonButton>
              <IonText>
                {Object.keys(editedAdventure.nameText)[0] !== "CS" &&
                  lang.label383}
                {Object.keys(editedAdventure.nameText)[0] !== "EN" &&
                  lang.label384}
              </IonText>
            </IonButtons>

            {
              <IonButtons slot="end">
                <IonButton
                  onClick={() => {
                    setNewAdventureLangCurrent(
                      newAdventureLangCurrent === "CS" ? "EN" : "CS"
                    );
                    setToastMessage2(
                      newAdventureLangCurrent === "CS"
                        ? lang.label426
                        : lang.label425
                    );
                    setShowToast2(true);
                  }}
                  style={{
                    textDecoration: "underline",
                    fontWeight: "bold",
                    width: "50px",
                  }}
                >
                  {newAdventureLangCurrent === "CS" ? "CZ" : "ENG"}
                </IonButton>
              </IonButtons>
            }
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid
            style={{
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              height: "max-content",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonText style={{ width: "100%" }}>{lang.label442}</IonText>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonLabel
            style={{ display: "block", marginTop: "5px" }}
            className="user-info-label"
          >
            {lang.label229} *
          </IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonInput
              value={editedAdventure.nameText[newAdventureLangCurrent]}
              name="name"
              placeholder={lang.label373}
              inputmode="text"
              maxlength={50}
              onIonChange={(event) => handleInputChange(event)}
              className="user-info-input"
            ></IonInput>
          </IonItem>

          {editedAdventure.descriptionText[
            Object.keys(editedAdventure.nameText)[0]
          ] !== "" && (
            <div>
              <IonLabel
                style={{ display: "block", marginTop: "5px" }}
                className="user-info-label"
              >
                {lang.label396} *
              </IonLabel>
              <IonItem className="user-info-item item-input-grey">
                <IonTextarea
                  value={
                    editedAdventure.descriptionText[newAdventureLangCurrent]
                  }
                  name="descriptionText"
                  autoGrow={true}
                  placeholder={lang.label397}
                  inputmode="text"
                  minlength={100}
                  maxlength={500}
                  onIonChange={(event) => {
                    handleInputChange(event);
                  }}
                  className="contact-input"
                  style={{ height: "auto", fontSize: "14px" }}
                ></IonTextarea>
              </IonItem>
            </div>
          )}

          {editedAdventure.mealsText[
            Object.keys(editedAdventure.nameText)[0]
          ] !== "" && (
            <div>
              <IonLabel className="user-info-label">{lang.label210} *</IonLabel>
              <IonItem className="user-info-item item-input-grey">
                <IonTextarea
                  value={editedAdventure.mealsText[newAdventureLangCurrent]}
                  name="mealsText"
                  autoGrow={true}
                  placeholder={lang.label398}
                  inputmode="text"
                  maxlength={1000}
                  onIonChange={(event) => handleInputChange(event)}
                  className="create-info-input"
                  style={{ height: "auto", fontSize: "14px" }}
                ></IonTextarea>
              </IonItem>
            </div>
          )}

          {editedAdventure.accommodationText[
            Object.keys(editedAdventure.nameText)[0]
          ] !== "" && (
            <div>
              <IonLabel className="user-info-label">{lang.label209} *</IonLabel>
              <IonItem className="user-info-item item-input-grey">
                <IonTextarea
                  value={
                    editedAdventure.accommodationText[newAdventureLangCurrent]
                  }
                  name="accommodationText"
                  autoGrow={true}
                  placeholder={lang.label399}
                  inputmode="text"
                  maxlength={1000}
                  onIonChange={(event) => handleInputChange(event)}
                  className="create-info-input"
                  style={{ height: "auto", fontSize: "14px" }}
                ></IonTextarea>
              </IonItem>
            </div>
          )}

          {editedAdventure.transitText[
            Object.keys(editedAdventure.nameText)[0]
          ] !== "" && (
            <div>
              <IonLabel className="user-info-label">{lang.label206} *</IonLabel>
              <IonItem className="user-info-item item-input-grey">
                <IonTextarea
                  value={editedAdventure.transitText[newAdventureLangCurrent]}
                  name="transitText"
                  autoGrow={true}
                  placeholder={lang.label400}
                  inputmode="text"
                  maxlength={1000}
                  onIonChange={(event) => handleInputChange(event)}
                  className="create-info-input"
                  style={{ height: "auto", fontSize: "14px" }}
                ></IonTextarea>
              </IonItem>
            </div>
          )}

          {editedAdventure.difficultyText[
            Object.keys(editedAdventure.nameText)[0]
          ] !== "" && (
            <div>
              <IonLabel className="user-info-label">{lang.label211} *</IonLabel>
              <IonItem className="user-info-item item-input-grey">
                <IonTextarea
                  value={
                    editedAdventure.difficultyText[newAdventureLangCurrent]
                  }
                  name="difficultyText"
                  autoGrow={true}
                  placeholder={lang.label403}
                  inputmode="text"
                  maxlength={1000}
                  onIonChange={(event) => handleInputChange(event)}
                  style={{ height: "auto", fontSize: "14px" }}
                  className="create-info-input"
                ></IonTextarea>
              </IonItem>
            </div>
          )}

          {editedAdventure.includedText[
            Object.keys(editedAdventure.nameText)[0]
          ] !== "" && (
            <div>
              <IonLabel className="user-info-label">{lang.label247} *</IonLabel>
              <IonItem className="user-info-item item-input-grey">
                <IonTextarea
                  value={editedAdventure.includedText[newAdventureLangCurrent]}
                  name="includedText"
                  autoGrow={true}
                  placeholder={lang.label404}
                  inputmode="text"
                  maxlength={1000}
                  onIonChange={(event) => handleInputChange(event)}
                  style={{ height: "auto", fontSize: "14px" }}
                  className="create-info-input"
                ></IonTextarea>
              </IonItem>
            </div>
          )}

          {editedAdventure.notIncludedText[
            Object.keys(editedAdventure.nameText)[0]
          ] !== "" && (
            <div>
              <IonLabel className="user-info-label">{lang.label248} *</IonLabel>
              <IonItem className="user-info-item item-input-grey">
                <IonTextarea
                  value={
                    editedAdventure.notIncludedText[newAdventureLangCurrent]
                  }
                  name="notIncludedText"
                  autoGrow={true}
                  placeholder={lang.label405}
                  inputmode="text"
                  maxlength={1000}
                  onIonChange={(event) => handleInputChange(event)}
                  style={{ height: "auto", fontSize: "14px" }}
                  className="create-info-input"
                ></IonTextarea>
              </IonItem>
            </div>
          )}

          {editedAdventure.moreInfoText[
            Object.keys(editedAdventure.nameText)[0]
          ] !== "" && (
            <div>
              <IonLabel className="user-info-label">{lang.label249} *</IonLabel>
              <IonItem className="user-info-item item-input-grey">
                <IonTextarea
                  value={editedAdventure.moreInfoText[newAdventureLangCurrent]}
                  name="moreInfoText"
                  autoGrow={true}
                  placeholder={lang.label406}
                  inputmode="text"
                  maxlength={1000}
                  onIonChange={(event) => handleInputChange(event)}
                  style={{ height: "auto !important", fontSize: "14px" }}
                  className="create-info-input"
                ></IonTextarea>
              </IonItem>
            </div>
          )}

          <IonLabel className="user-info-label">{lang.label198}</IonLabel>
          <IonList style={{ padding: "0 5px", width: "100%" }}>
            {/*             <IonReorderGroup disabled={reorder} onIonItemReorder={doReorder}>
             */}{" "}
            {itinerary.map((oneItinerary: any, index: number) => {
              return (
                /*                   <IonReorder key={oneItinerary.id}>
                 */ <div key={oneItinerary.id}>
                  <IonLabel
                    className="user-info-label"
                    style={{ fontSize: "14px" }}
                  >
                    {lang.label250} {lang.label389} {index + 1} *
                  </IonLabel>
                  <IonItem className="user-info-item item-input-grey">
                    <IonInput
                      value={oneItinerary.titleText[newAdventureLangCurrent]}
                      name="title"
                      placeholder={lang.label410}
                      inputmode="text"
                      maxlength={100}
                      style={{ fontSize: "14px" }}
                      onIonChange={(event) => handleItiChange(index, event)}
                      className="user-info-input"
                    ></IonInput>
                  </IonItem>
                  <IonItem className="user-info-item item-input-grey">
                    <IonTextarea
                      value={
                        oneItinerary.descriptionText[newAdventureLangCurrent]
                      }
                      disabled={
                        oneItinerary.titleText[newAdventureLangCurrent] === ""
                      }
                      name="desc"
                      autoGrow={true}
                      placeholder={lang.label411}
                      inputmode="text"
                      maxlength={1200}
                      onIonChange={(event) => handleItiChange(index, event)}
                      className="create-info-input"
                      style={{ fontSize: "14px" }}
                    ></IonTextarea>
                  </IonItem>
                </div>
                /*                   </IonReorder>
                 */
              );
            })}
            {/*             </IonReorderGroup>
             */}{" "}
          </IonList>

          {/* </div> */}
        </IonContent>
        <IonToolbar slot="bottom" className="safe-zone">
          <IonButton
            className="ud-save-butt"
            onClick={() => {
              let check = false;
              itinerary.forEach((it: any) => {
                if (
                  it.titleText[Object.keys(editedAdventure.nameText)[1]] ===
                    "" ||
                  it.descriptionText[
                    Object.keys(editedAdventure.nameText)[1]
                  ] === ""
                ) {
                  check = true;
                }
              });

              if (
                editedAdventure.nameText[
                  Object.keys(editedAdventure.nameText)[0]
                ] === "" ||
                editedAdventure.nameText[
                  Object.keys(editedAdventure.nameText)[1]
                ] === "" ||
                (editedAdventure.descriptionText[
                  Object.keys(editedAdventure.nameText)[0]
                ] !== "" &&
                  editedAdventure.descriptionText[
                    Object.keys(editedAdventure.nameText)[1]
                  ] === "") ||
                (editedAdventure.mealsText[
                  Object.keys(editedAdventure.nameText)[0]
                ] !== "" &&
                  editedAdventure.mealsText[
                    Object.keys(editedAdventure.nameText)[1]
                  ] === "") ||
                (editedAdventure.accommodationText[
                  Object.keys(editedAdventure.nameText)[0]
                ] !== "" &&
                  editedAdventure.accommodationText[
                    Object.keys(editedAdventure.nameText)[1]
                  ] === "") ||
                (editedAdventure.transitText[
                  Object.keys(editedAdventure.nameText)[0]
                ] !== "" &&
                  editedAdventure.transitText[
                    Object.keys(editedAdventure.nameText)[1]
                  ] === "") ||
                (editedAdventure.difficultyText[
                  Object.keys(editedAdventure.nameText)[0]
                ] !== "" &&
                  editedAdventure.difficultyText[
                    Object.keys(editedAdventure.nameText)[1]
                  ] === "") ||
                (editedAdventure.includedText[
                  Object.keys(editedAdventure.nameText)[0]
                ] !== "" &&
                  editedAdventure.includedText[
                    Object.keys(editedAdventure.nameText)[1]
                  ] === "") ||
                (editedAdventure.notIncludedText[
                  Object.keys(editedAdventure.nameText)[0]
                ] !== "" &&
                  editedAdventure.notIncludedText[
                    Object.keys(editedAdventure.nameText)[1]
                  ] === "") ||
                (editedAdventure.moreInfoText[
                  Object.keys(editedAdventure.nameText)[0]
                ] !== "" &&
                  editedAdventure.moreInfoText[
                    Object.keys(editedAdventure.nameText)[1]
                  ] === "") ||
                check
              ) {
                setToastMessage(lang.label412);
                setShowToast(true);
              } else {
                saveEditedLang();
              }
            }}
          >
            {lang.label167}
          </IonButton>
        </IonToolbar>
      </IonModal>

      <IonModal
        isOpen={confirmModal}
        cssClass="small-modal"
        backdropDismiss
        onDidDismiss={() => {
          setConfirmModal(false);
        }}
      >
        <IonContent fullscreen>
          <IonGrid
            style={{
              /* width: "max-content", */
              marginLeft: "auto",
              marginRight: "auto",
              /* height: "max-content", */
              textAlign: "center",
              marginTop: "10vh",
            }}
          >
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonText style={{ width: "100%" }}>{lang.label266}</IonText>
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonButton
                  onClick={() => {
                    setConfirmModal(false);
                  }}
                  color="danger"
                  style={{ marginRight: "15px" }}
                >
                  {lang.label166}
                </IonButton>
                <IonButton
                  onClick={() => {
                    deleteAdv();
                  }}
                  color="primary"
                  style={{ marginLeft: "15px" }}
                >
                  {lang.label121}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          {/* </div> */}
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showCropModal}
        cssClass="modal-cropper"
        backdropDismiss
        onDidDismiss={() => {
          setShowCropModal(false);
          setShowSlow(false);
        }}
      >
        <IonContent>
          <div className="crop-container">
            {showCropModal && showSlow && (
              <Cropper
                image={cameraImage}
                crop={crop}
                zoom={zoom}
                cropShape="rect"
                aspect={16 / 10}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </div>

          <div style={{ justifyContent: "center", display: "flex" }}>
            <IonButton
              onClick={() => {
                setShowCropModal(false);
              }}
              color="danger"
            >
              {lang.label166}
            </IonButton>
            <IonButton
              onClick={() => {
                setUploading(true);
              }}
              color="primary"
            >
              {lang.label167}
            </IonButton>
          </div>
        </IonContent>
      </IonModal>
      <IonLoading
        cssClass="loading-spinner"
        isOpen={uploading}
        onDidDismiss={() => setUploading(false)}
        message={lang.label222}
      />
      <IonLoading
        cssClass="loading-spinner"
        isOpen={uploading2}
        onDidDismiss={() => setUploading2(false)}
        message={lang.label222}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        color="danger"
        cssClass="toast-pass-change"
      />
      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message={toastMessage2}
        duration={2000}
        color="success"
        cssClass="toast-pass-change"
      />
    </IonPage>
  );
};
export default MyAdventure;
async function takePicture() {
  const image = await Camera.getPhoto({
    quality: 75,
    width: 2048,
    direction: CameraDirection.Front,
    resultType: CameraResultType.DataUrl,
  });
  return image.dataUrl;
}

/* async function selectFile() {
  const file = await Filesystem.GetUriResult({
    quality: 100,
    width: 300,
    direction: CameraDirection.Front,
    resultType: CameraResultType.Uri,
  });
  return image.webPath;
}  */
