import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonItem,
  IonList,
  IonButtons,
  IonLabel,
  IonBackButton,
} from "@ionic/react";

import "./Tab1.css";

import "./adventure.css";

import AuthContext from "../my-context";
import React from "react";
import { useHistory } from "react-router";

import {
  Article,
  Compass,
  Heart,
  MapTrifold,
  SpeakerSimpleHigh,
} from "phosphor-react";
import { useGlobal } from "reactn";
import Header from "./header";

const Create: React.FC = () => {
  const { authValues, lang } = React.useContext(AuthContext);
  const [showGuestModal, setShowGuestModal] = useGlobal("showGuestModal");
  const history = useHistory();
  const [appWidth] = useGlobal("appWidth");

  return (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar /* className="back-butt" */>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tab1"
              text={lang.label317}
              className="back-button"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="web-box">
          <IonList>
            {authValues.user.guide && (
              <IonItem
                onClick={() => {
                  return history.replace("trip/0");
                }}
                style={{ marginRight: "20px", marginLeft: "20px" }}
                className="create-list"
              >
                <MapTrifold size={35} color="#3fd0d4" />
                <IonLabel style={{ marginLeft: "10px" }}>
                  {lang.label21}
                </IonLabel>
              </IonItem>
            )}

            {/* {
            <IonItem
              style={{ marginRight: "20px", marginLeft: "20px" }}
              className="create-list"
            >
              <SpeakerSimpleHigh size={35} color="#3fd0d4" />
              <IonLabel style={{ marginLeft: "10px" }}>{lang.label22}</IonLabel>
            </IonItem>
          }
          {
            <IonItem
              style={{ marginRight: "20px", marginLeft: "20px" }}
              className="create-list"
            >
              <Article size={35} color="#3fd0d4" />
              <IonLabel style={{ marginLeft: "10px" }}>{lang.label23}</IonLabel>
            </IonItem>
          } */}
            {!authValues.user.guide && (
              <IonItem
                onClick={
                  authValues.user.id === "guest"
                    ? (e) => {
                        e.preventDefault();
                        setShowGuestModal(true);
                      }
                    : () => {
                        return history.replace("/becomeaguide/");
                      }
                }
                style={{ marginRight: "20px", marginLeft: "20px" }}
                className="create-list"
              >
                <Compass size={35} color="#3fd0d4" />
                <IonLabel style={{ marginLeft: "10px" }}>
                  {lang.label26}
                </IonLabel>
              </IonItem>
            )}
            {/* {!authValues.user.guide && (
            <IonItem
              style={{ marginRight: "20px", marginLeft: "20px" }}
              className="create-list"
            >
              <SpeakerSimpleHigh size={35} color="#3fd0d4" />
              <IonLabel style={{ marginLeft: "10px" }}>{lang.label27}</IonLabel>
            </IonItem>
          )} */}
            {/* {!authValues.user.guide && (
            <IonItem
              style={{ marginRight: "20px", marginLeft: "20px" }}
              className="create-list"
            >
              <Heart size={35} weight="fill" color="#3fd0d4" />
              <IonLabel style={{ marginLeft: "10px" }}>{lang.label28}</IonLabel>
            </IonItem>
          )} */}
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Create;
