import {
  IonButton,
  IonContent,
  IonPage,
  IonCard,
  IonCardContent,
  IonFooter,
  IonChip,
  IonAvatar,
  IonLabel,
  IonModal,
  IonText,
  IonHeader,
  IonTitle,
  IonItem,
  IonList,
  IonRow,
  IonButtons,
  IonToolbar,
  IonToggle,
  IonRange,
  IonGrid,
  IonCol,
  IonInput,
  IonCheckbox,
  IonLoading,
  IonSpinner,
  IonThumbnail,
  IonImg,
  useIonViewWillEnter,
  IonSelect,
  IonSelectOption,
  useIonViewWillLeave,
} from "@ionic/react";

import "./Tab1.css";
import "./adventures.scss";
import "./calendar.css";
import Calendar from "react-calendar";
import axios from "axios";

import AuthContext from "../my-context";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router";

import SwiperCore, { FreeMode, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import "swiper/swiper.scss"; // core Swiper
/* import "swiper/scss/navigation"; */ // Navigation module
import "swiper//modules/pagination/pagination.scss"; // Pagination module

import Header from "./header";

import airplane from "../assets/categories/Transportation/noun_Airplane_3441881.png";
/* import bicycle from "../assets/categories/Transportation/noun_Bicycle_1487782.png"; */
import boat2 from "../assets/categories/Transportation/noun_Boat_1333200.png";
import bus from "../assets/categories/Transportation/noun_Bus_1382166.png";
import camperVan from "../assets/categories/Transportation/noun_Camper van_1791967.png";
import car from "../assets/categories/Transportation/noun_Car_1890765.png";
import circle from "../assets/categories/Transportation/noun_Circle_2659155.png";
/* import motorbike from "../assets/categories/Transportation/noun_motorbike_1973204.png";
import spaceX from "../assets/categories/Transportation/noun_space-x_4249491.png"; */
import train from "../assets/categories/Transportation/noun_Train_4028735.png";

import hostel from "../assets/categories/Accommodation/noun_hostel_3467299.png";
import house from "../assets/categories/Accommodation/noun_House_1243534.png";
import hotel from "../assets/categories/Accommodation/noun_Hotel_4336126.png";
import sleepingBag from "../assets/categories/Accommodation/noun_Sleeping Bag_2851819.png";
import tent from "../assets/categories/Accommodation/noun_Tent_1686582.png";

import breakfastDinner from "../assets/categories/Meals/noun_dinner_3243090.png";
import breakfast from "../assets/categories/Meals/noun_Breakfast_1620771.png";
import allInc from "../assets/categories/Meals/noun_Food_2072852.png";

import climbing from "../assets/categories/AdventureCategories/noun_Climbing_380699.svg";
import ferrata from "../assets/categories/AdventureCategories/noun_Via_ferrata_886685.svg";
import ccskiing from "../assets/categories/AdventureCategories/noun_Cross-Country_Skiing_510.svg";
import mtb from "../assets/categories/AdventureCategories/noun_MTB_1272650.svg";
import snowboarding from "../assets/categories/AdventureCategories/noun_snowboarding_1667528.svg";
import skier from "../assets/categories/AdventureCategories/noun_skier_2218013.svg";
import dive from "../assets/categories/AdventureCategories/noun_dive_1427211.svg";
import hiker from "../assets/categories/AdventureCategories/noun_Hiker_3810655.svg";
import relax from "../assets/categories/AdventureCategories/noun_relax_566382.svg";
import canyoning from "../assets/categories/AdventureCategories/noun_canyoning_893735.svg";
import boat from "../assets/categories/AdventureCategories/noun_Boat_5263.svg";
import helicopter from "../assets/categories/AdventureCategories/noun_Helicopter_72877.svg";
import hikerskies from "../assets/categories/AdventureCategories/noun_Hiker_with_Skis_1503554.svg";
import mountainclimb from "../assets/categories/AdventureCategories/noun_Mountain_Climbing_45240.svg";
import accessory from "../assets/categories/AdventureCategories/noun_accessory_3518721.svg";
import van from "../assets/categories/AdventureCategories/noun_Van_704014.svg";
import camp from "../assets/categories/AdventureCategories/noun_camp_2024078.svg";
import monument from "../assets/categories/AdventureCategories/noun_Monument_7146.svg";
import hiking from "../assets/categories/AdventureCategories/noun_Hiking_1779379.svg";
import mangoal from "../assets/categories/AdventureCategories/noun_man_goal_1814058.svg";
import map from "../assets/categories/AdventureCategories/noun_Map_4243273.svg";
import snowshoes from "../assets/categories/AdventureCategories/noun_snowshoer_1484245.svg";

import africa from "../assets/conti/noun_africa_2161025.svg";
import antarctica from "../assets/conti/noun_Antarctica_1314618.svg";
import asia from "../assets/conti/noun_asia_194338.svg";
import australia from "../assets/conti/noun_Oceania_301972.svg";
import europe from "../assets/conti/noun_europe_194340.svg";
import northAmerica from "../assets/conti/noun_North America_416786.svg";
import southAmerica from "../assets/conti/noun_South America_740450.svg";

import { useEffect, useGlobal } from "reactn";
import {
  Heart,
  FadersHorizontal,
  ArrowLeft,
  MagnifyingGlass,
  UserCircle,
  MapPin,
} from "phosphor-react";

import Rate from "rc-rate";
import "rc-rate/assets/index.css";

import moment from "moment";
import setLike from "../functions/setLike";
import showImage from "../functions/showImage";
import defaultAdvImage from "../assets/images/default_adventure_image.jpg";
import setCountryName from "../functions/setCountryName";
import setEnumText from "../functions/setEnumText";
import setCountriesOfContinent from "../functions/setCountriesOfContinent";
import checkProperty from "../functions/checkProperty";
import { nanoid } from "nanoid";
import FooterPageMap from "./FooterPageMap";

const Adventures: React.FC = () => {
  const isContinentFilterLoaded = useRef(false);
  const isFirstRender = useRef(true);
  const renderCheck = useRef(false);
  const saving = useRef(false);
  const counter = useRef(0);
  SwiperCore.use([FreeMode, Pagination]);
  useEffect(() => {
    setAppUrl("/adventures");
    fetchData();
    // eslint-disable-next-line
    /*     console.log(advFilters.destinations);

    let destinationsTemp = [...advFilters.destinations];
    setDestinationsFilter(destinationsTemp);

    let jsonData = require("../assets/countries_states_cities/countries+states+cities.json");
    let tempX = [""];

    for (var i = 0; advFilters.destinations.length > i; i++) {
      tempX[i] = jsonData.filter(
        (c: any) => c.iso2 === advFilters.destinations[i]
      )[0]?.region;
      if (tempX[i] === undefined) {
        tempX[i] = "";
      }
    }
    console.log(tempX);
    if (destinationsTemp.includes("World") && destinationsTemp.length === 1) {
      setShowDestination(false);
      setContinentsFilter([""]);
    } else if (tempX.length > 1) {
      console.log("if 2");
      setShowDestination(true);
      setContinentsFilter(tempX);
      for (var j = 0; tempX.length > j; j++) {
        if (tempX[j] !== "") {
          setCountriesFilter(tempX[j]);
          console.log(tempX[j]);
          return;
        }
      }
    } else {
      console.log("if 3");
      setContinentsFilter([""]);
      setShowDestination(false);
    } */
  }, []);

  const [searchTextModalContinent, setSearchTextModalContinent] = useState([
    "",
  ]);
  const [searchTextModalContinentNumber, setSearchTextModalContinentNumber] =
    useState([0]);

  const [searchTextModalCountry, setSearchTextModalCountry] = useState([
    ["", ""],
  ]);
  const [searchTextModalCountryNumber, setSearchTextModalCountryNumber] =
    useState([0]);

  const [showLoading, setShowLoading] = useState(true);
  const [, setAdventureId] = useGlobal("AdventrureId");
  const [advFilters, setAdvFilters] = useGlobal("advFilter");
  const [advFiltersOriginal, setAdvFiltersOriginal] = useState({
    destinations: [""],
    categories: [""],
    dateFrom: null,
    dateTo: null,
    persons: 1,
    priceFrom: 0,
    priceTo: 0,
    language: [""],
    transit: [""],
    accommodation: [""],
    meal: [""],
    difficulty: { lower: 0, upper: 0 },
    departure: [""],
  });
  const [showFilters, setShowFilters] = useState({
    showDestination: false,
    showCategories: false,
    showDates: false,
    showPersons: false,
    showPrices: false,
    showTransits: false,
    showAccomodation: false,
    showMeals: false,
    showDifficulty: false,
  });

  const [language] = useGlobal("language");

  const [userId, setUserId] = useGlobal("userId");
  const [showGuestModal, setShowGuestModal] = useGlobal("showGuestModal");

  const [, setAppUrl] = useGlobal("appUrl");

  const [continentsFilter, setContinentsFilter] = useState([""]);

  const [countriesFilter, setCountriesFilter] = useState("");

  const [contriesFilter, setContriesFilter] = useState([[""]]);

  const [dummyCountries, setDummyCountries] = useState([[""]]);

  const [countHelper, setCountHelper] = useState(0);

  const [continentFilter, setContinentFilter] = useState<any>({
    africa: false,
    antarctica: false,
    asia: false,
    australia: false,
    europe: false,
    northAmerica: false,
    southAmerica: false,
  });

  const [destinationsFilter, setDestinationsFilter] = useState([""]);

  const setProperText = (text: string) => {
    switch (text) {
      case "europe":
        return "Europe";
      case "asia":
        return "Asia";
      case "africa":
        return "Africa";
      case "australia":
        return "Oceania";
      case "southAmerica":
        return "South America";
      case "northAmerica":
        return "North America";
      case "antarctica":
        return "Polar";
    }
  };

  const filterContinents = (temp: any) => {
    let temporary = JSON.parse(JSON.stringify(continentFilter));
    temp.map((item: any) => {
      item.locations.forEach((location: any) => {
        if (location.contitent === "Africa") {
          temporary.africa = true;
        } else if (location.contitent === "Antarctica") {
          temporary.antarctica = true;
        } else if (location.continent === "Asia") {
          temporary.asia = true;
        } else if (location.continent === "Australia") {
          temporary.australia = true;
        } else if (location.continent === "Europe") {
          temporary.europe = true;
        } else if (location.continent === "North America") {
          temporary.northAmerica = true;
        } else if (location.continent === "South America") {
          temporary.southAmerica = true;
        }
      });
    });
    let tempContinentsFilter = [""];
    let filteredTemp = temp.filter((item: any) => {
      /* console.log(item); */
      /* if (advFilters.destinations.includes("World")) return true; */
      let isIncluded = false;
      item.locations.forEach((location: any) => {
        if (advFilters.destinations.includes(location.country)) {
          isIncluded = true;
        }
      });
      return isIncluded;
      /* advFilters.destinations.includes(item.location.country); */
    });
    filteredTemp.forEach((itemX: any) => {
      if (!tempContinentsFilter.includes(itemX.locations[0].continent)) {
        tempContinentsFilter = tempContinentsFilter.concat(
          itemX.locations[0].continent
        );
      }
    });

    setContinentsFilter(tempContinentsFilter);
    isContinentFilterLoaded.current = true;
    setContinentFilter(temporary);
  };

  useEffect(() => {
    if (isContinentFilterLoaded) {
      let temporary = JSON.parse(JSON.stringify(continentFilter));
      let tempX = [[""]];
      let count = 1;
      Object.keys(temporary).forEach((key) => {
        if (temporary[key]) {
          let contriesFilterTemp = itemsOriginal.filter((item: any) => {
            return (
              item.locations.filter((location: any) => {
                return location.continentText.EN === setProperText(key);
              }).length > 0
            );
          });
          let propArray = [key];
          contriesFilterTemp.forEach((item: any) => {
            for (var i = 0; i < item.locations.length; i++) {
              propArray.push(item.locations[i].country);
            }
          });
          tempX[count - 1] = propArray.filter((v, i, a) => a.indexOf(v) === i);
          count = count + 1;
        }
        setDummyCountries([...tempX]);
      });
      setCountHelper(count);
    }
  }, [continentFilter]);

  const findContinent = (prop: any) => {
    let jsonData = require("../assets/countries_states_cities/countries+states+cities.json");
    let propArray = [""];
    for (var i = 0; i < prop.locations.length; i++) {
      propArray[i] = prop.locations[i].country;
    }
    let jsonDataFiltered = jsonData.filter(
      (data: any) =>
        propArray.includes(data.iso2) /* data.iso2 === prop.locations.country */
    );
    if (jsonDataFiltered.length > 0) {
      /*       if (jsonDataFiltered[0].iso2 === "ES") {
              return "Africa"
            } */
      let jsonDataFilteredRegions = [""];
      for (var j = 0; j < jsonDataFiltered.length; j++) {
        jsonDataFilteredRegions[j] = jsonDataFiltered[j].region;
      }
      return jsonDataFilteredRegions;
    } else return [""];
  };

  const setContinents = (data: any) => {
    let temp = JSON.parse(JSON.stringify(data));
    let contiTemp = [""];
    temp.map((item: any) => {
      contiTemp = findContinent(item);
      item.locations.map((location: any, index: number) => {
        location.continent = contiTemp[index];
        return location;
      });
      return item;
    });
    isFirstRender.current = true;
    setItemsOriginal(temp);
    filterContinents(temp);
  };

  const [isContriesShown, setIsCountriesShown] = useState(false);

  const slideOpts = {
    slidesPerView: 3,
    initialSlide: 0,
    speed: 400,
  };

  const selectCountry = (name: string) => {
    let tempSelect = [...destinationsFilter];

    if (tempSelect.includes(name)) {
      if (name === "World") {
        tempSelect = [""];
      } else {
        for (var i = 0; i < tempSelect.length; i++) {
          if (tempSelect[i] === name) {
            tempSelect.splice(i, 1);
            i--;
          }
        }
      }
    } else {
      if (name === "World") {
        tempSelect = [name];
      } else {
        tempSelect = tempSelect.concat(name);
      }
    }
    setDestinationsFilter(tempSelect);
  };

  function setFilterOfContinents(conti: string, continentsFil?: any) {
    let tempSelect = [...continentsFilter];
    if (continentsFilter.includes("World")) {
      if (conti === "World") {
        tempSelect = [""];
        selectCountry(conti);
        setContinentsFilter(tempSelect);
        return;
      } else {
        tempSelect = ["", conti];
        setContinentsFilter(tempSelect);
      }
    } else if (conti === "World" && continentsFil === undefined) {
      tempSelect = ["World"];
      selectCountry(conti);
      setContinentsFilter(tempSelect);
      return;
    } else {
      if (tempSelect.includes(conti) /* && countriesFilter === conti */) {
        for (var i = 0; i < tempSelect.length; i++) {
          if (tempSelect[i] === conti) {
            tempSelect.splice(i, 1);
            i--;
          }
        }
      } else {
        /* if (tempSelect.includes(conti)) {
          return;
        } else { */
        tempSelect = tempSelect.concat(conti);
        /* } */
      }
      setContinentsFilter(tempSelect);
      return;
    }

    /* if (tempSelect.includes(conti) && countriesFilter === conti) {
      for (var i = 0; i < tempSelect.length; i++) {
        if (tempSelect[i] === conti) {
          tempSelect.splice(i, 1);
          i--;
        }
      }
    } else {
      if (tempSelect.includes(conti)) {
        return;
      } else {
        if (conti === "World") selectCountry(conti);
        tempSelect = tempSelect.concat(conti);
      }
    }
    setContinentsFilter(tempSelect); */
  }

  const { token, lang, db, checkImageCache, authValues, setShowTabBars } =
    React.useContext(AuthContext);

  /*   const [showModalPart, setShowModalPart] = useState(false); //partner modal
  const [partnerName, setPartnerName] = useState<null | undefined | string>(""); */
  const [empty] = useState([
    {
      id: "",
      image: "",
      imageData: "",
      rating: 0,
      ratingCount: 0,
      difficulty: 0,
      isLiked: false,
      guide: {
        id: "",
        firstName: "",
        lastName: "",
        profileImage: "",
        profileImageData: "",
        guide: false,
        admin: false,
      },
      meals: "",
      transit: "",
      accommodation: "",
      categories: [""],
      country: "",
      locations: [
        {
          type: "",
          latitude: "",
          longitude: "",
          airport: "",
          country: "",
          continent: "",
          continentText: { CS: "", EN: "" },
          region: "",
          city: "",
          address: "",
          countryText: {
            CS: "",
            EN: "",
          },
        },
      ],
      departure: {
        type: "",
        latitude: "",
        longitude: "",
        airport: "",
        country: "",
        city: "",
        address: "",
        countryText: "",
      },
      arrival: {
        type: "",
        latitude: "",
        longitude: "",
        airport: "",
        country: "",
        city: "",
        address: "",
        countryText: "",
      },
      dates: [
        {
          id: "",
          begin: "",
          end: "",
          prices: {
            EUR: 0,
            CZK: 0,
          },
          capacity: 0,
          free: 0,
        },
        {
          id: "",
          begin: "",
          end: "",
          prices: {
            EUR: 0,
            CZK: 0,
          },
          capacity: 0,
          free: 0,
        },
      ],
      itinerary: [
        {
          title: "",

          description: "",
          image: "",
        },
      ],
      nameText: {
        CS: "",
        EN: "",
      },
      partner: null,
      descriptionText: null,
      includedText: null,
      notIncludedText: null,
      accommodationText: null,
      mealsText: null,
      transitText: null,
      difficultyText: null,
      moreInfoText: null,
      /*       countryText: {
                CS: "",
                EN: "",
              }, */
    },
  ]);

  /* const [searchText, setSearchText] = useState(""); */

  /*   const [searchTextModalAdv, setSearchTextModalAdv] = useState(empty);
    const [searchTextModalCont, setSearchTextModalCont] = useState(empty);
    const [searchTextModalDest, setSearchTextModalDest] = useState(empty); */
  const [showSearch, setShowSearch] = useState(false); //search modal
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const [showDestination, setShowDestination] = useState(false);
  const [showDates, setShowDates] = useState(false);
  const [showPersons, setShowPersons] = useState(false);
  const [showPrices, setShowPrices] = useState(false);
  const [showTransits, setShowTransits] = useState(false);
  const [showAccomodation, setShowAccomodation] = useState(false);
  const [showMeals, setShowMeals] = useState(false);
  const [showDifficulty, setShowDifficulty] = useState(false);

  const [showCategories, setShowCategories] = useState(false);

  const [showDestinationModal, setShowDestinationModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showPersonsModal, setShowPersonsModal] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [showTransportModal, setShowTransportModal] = useState(false);
  const [showAccomodationModal, setShowAccomodationModal] = useState(false);
  const [showMealModal, setShowMealModal] = useState(false);
  const [showDifficultyModal, setShowDifficultyModal] = useState(false);

  const [transitsCategories, setTransitsCategories] = useState([
    { transit: "", id: "" },
  ]);
  const [transitsFilters, setTransitsFilters] = useState([""]);

  const [categoryCategories, setCategoryCategories] = useState([""]);
  const [categoriesFilters, setCategoriesFilters] = useState([""]);

  const [accomodationsCategories, setAccomodationsCategories] = useState([
    { accommodation: "", id: "" },
  ]);
  const [accomodationsFilters, setAccomodationsFilters] = useState([""]);
  const [mealsCategories, setMealsCategories] = useState([{ meals: "" }]);
  const [mealsFilters, setMealsFilters] = useState([""]);

  const [diffMinMax, setDiffMinMax] = useState({ min: 5, max: 1 });
  const [diffRange, setDiffRange] = useState<{
    lower: number;
    upper: number;
  }>({ lower: 1, upper: 5 });
  const [diffActive, setDiffActive] = useState(false);

  const [datesRange, setDatesRange] = useState<Date[] | null | undefined>(null);
  const [rangeActive, setRangeActive] = useState(false);
  const [pricesRange, setPricesRange] = useState<{
    lower: any;
    upper: any;
  }>({ lower: 0, upper: 0 });
  const [minMax, setMinMax] = useState({ min: 0, max: 0 });

  const [persons, setPersons] = useState(1);
  const [freeCapacity] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25,
  ]);

  const [itemsOriginal, setItemsOriginal] = useState(empty);
  const [items, setItems] = useState({
    success: true,
    data: [
      {
        id: "",
        image: "",
        imageData: "",
        rating: 0,
        ratingCount: 0,
        difficulty: 0,
        isLiked: false,
        guide: {
          id: "",
          firstName: "",
          lastName: "",
          profileImage: "",
          profileImageData: "",
          guide: false,
          admin: false,
        },
        meals: "",
        transit: "",
        accommodation: "",
        categories: [""],
        country: "",
        locations: [
          {
            type: "",
            latitude: "",
            longitude: "",
            airport: "",
            country: "",
            city: "",
            region: "",
            address: "",
            continentText: { CS: "", EN: "" },
            continent: "",
            countryText: {
              CS: "",
              EN: "",
            },
          },
        ],
        departure: {
          type: "",
          latitude: "",
          longitude: "",
          airport: "",
          country: "",
          city: "",
          address: "",
          countryText: "",
        },
        arrival: {
          type: "",
          latitude: "",
          longitude: "",
          airport: "",
          country: "",
          city: "",
          address: "",
          countryText: "",
        },
        dates: [
          {
            id: "",
            begin: "",
            end: "",
            prices: {
              EUR: 0,
              CZK: 0,
            },
            capacity: 0,
            free: 0,
          },
          {
            id: "",
            begin: "",
            end: "",
            prices: {
              EUR: 0,
              CZK: 0,
            },
            capacity: 0,
            free: 0,
          },
        ],
        itinerary: [
          {
            title: "",
            description: "",
            image: "",
          },
        ],
        nameText: {
          CS: "",
          EN: "",
        },
        partner: null,
        descriptionText: null,
        includedText: null,
        notIncludedText: null,
        accommodationText: null,
        mealsText: null,
        transitText: null,
        difficultyText: null,
        moreInfoText: null,
      },
    ],
  });

  const history = useHistory();

  const getTripLength = (item: any) => {
    var start = moment(item.dates[0].begin, "YYYY-MM-DD");
    var end = moment(item.dates[0].end, "YYYY-MM-DD");
    var length = end.diff(start, "days") + 1;
    if (length === 1) {
      return "1 " + lang.label191;
    } else if (length < 5) {
      let message = length.toString() + " " + lang.label192;
      return message;
    } else {
      let message = length.toString() + " " + lang.label193;
      return message;
    }
  };

  async function fetchData() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {};
    if (authValues.user.id !== "guest") {
      config = {
        headers: {
          "X-Auth-Token": token,
        },
      };
    }
    api
      .get("/adventures/current", config)
      .then(async (res) => {
        await Promise.all(
          res.data.data.map(async (item: any) => {
            if (await checkImageCache(item.image)) {
              item.imageData = await db.get(item.image);
            } else {
              if (item.image !== "" || item.image !== null) {
                item.imageData = await showImage(item.image, token);
                await db.set(item.image, item.imageData);
              } else item.imageData = "";
            }
            if (await checkImageCache(item.guide.profileImage)) {
              item.guide.profileImageData = await db.get(
                item.guide.profileImage
              );
            } else {
              if (item.guide.profileImage !== "") {
                if (item.guide.profileImage !== null) {
                  item.guide.profileImageData = await showImage(
                    item.guide.profileImage,
                    token
                  );
                  await db.set(
                    item.guide.profileImage,
                    item.guide.profileImageData
                  );
                }
              } else item.guide.profileImageData = "";
            }
            return item;
          })
        ).then((resp) => {
          let respTemp: any = { data: [] };
          respTemp.data = resp;
          if (isFirstRender.current) {
            setMinLow(respTemp.data);
            setContinents(respTemp.data);
            setCategories(respTemp.data);
            isFirstRender.current = false;
          }
          filterItems(respTemp);
        });
      })
      .then(() => setShowLoading(false))
      .catch((err) => {
        // what now?
        console.log(err.data);
        console.log(err.status);
        console.log(err.statusText);
        console.log(err.headers);
        console.log(err.config);
        console.log(err);
      });

    /*     const url: string = 'https://app.kangelo.com/adventures';
        const res: Response = await fetch(url);
        res
          .json()
          .then(async (res) => {
            if (res && res.message && res.message.length > 0) {
              setItems([...items, ...res.message]);
    
              setDisableInfiniteScroll(res.message.length < 10);
            } else {
              setDisableInfiniteScroll(true);
            }
          })
          .catch(err => console.error(err)); */
  }

  const filterItems = (itemix: any) => {
    if (
      advFilters.destinations.length > 1 ||
      (advFilters.destinations[0] !== "" &&
        advFilters.destinations[0] !== "World" &&
        advFilters.destinations.length !== 0)
    ) {
      itemix.data = itemix.data.filter((item: any) => {
        /* console.log(item); */
        /* if (advFilters.destinations.includes("World")) return true; */
        let isIncluded = false;
        item.locations.forEach((location: any) => {
          if (advFilters.destinations.includes(location.country)) {
            isIncluded = true;
          }
        });
        return isIncluded;
        /* advFilters.destinations.includes(item.location.country); */
      });
    }
    if (advFilters.categories.length > 1) {
      itemix.data = itemix.data.filter((item: any) => {
        if (advFilters.categories.includes("All")) return true;
        let isIncluded2 = false;
        item.categories.forEach((category: any) => {
          if (advFilters.categories.includes(category)) {
            isIncluded2 = true;
          }
        });
        return isIncluded2;
        /* advFilters.destinations.includes(item.location.country); */
      });
    }
    if (advFilters.dateFrom !== null) {
      itemix.data = itemix.data.filter((item: any) => {
        for (var i = 0; i < item.dates.length; i++) {
          if (
            advFilters.dateFrom <= moment(item.dates[i].begin, "YYYY-MM-DD")
          ) {
            return true;
          }
        }
      });
      itemix.data = itemix.data.filter((item: any) => {
        for (var i = 0; i < item.dates.length; i++) {
          if (moment(item.dates[i].end, "YYYY-MM-DD") <= advFilters.dateTo) {
            return true;
          }
        }
      });
    }
    if (advFilters.persons !== 1) {
      itemix.data = itemix.data.filter((item: any) => {
        for (var i = 0; i < item.dates.length; i++) {
          if (advFilters.persons <= item.dates[i].free) {
            return true;
          }
        }
      });
    }
    if (advFilters.priceFrom !== null && advFilters.priceFrom !== 0) {
      itemix.data = itemix.data.filter((item: any) => {
        for (var i = 0; i < item.dates.length; i++) {
          if (advFilters.priceFrom <= item.dates[i].prices.CZK) {
            return true;
          }
        }
      });
      itemix.data = itemix.data.filter((item: any) => {
        for (var i = 0; i < item.dates.length; i++) {
          if (item.dates[i].prices.CZK <= advFilters.priceTo) {
            return true;
          }
        }
      });
    }
    if (advFilters.transit.length > 1) {
      itemix.data = itemix.data.filter((item: any) =>
        advFilters.transit.includes(item.transit)
      );
    }
    if (advFilters.accommodation.length > 1) {
      itemix.data = itemix.data.filter((item: any) =>
        advFilters.accommodation.includes(item.accommodation)
      );
    }
    if (advFilters.meal.length > 1) {
      itemix.data = itemix.data.filter((item: any) =>
        advFilters.meal.includes(item.meals)
      );
    }
    if (advFilters.difficulty.lower !== 0) {
      itemix.data = itemix.data.filter(
        (item: any) => advFilters.difficulty.lower <= item.difficulty
      );
      itemix.data = itemix.data.filter(
        (item: any) => item.difficulty <= advFilters.difficulty.upper
      );
    }
    setItems(itemix);
  };

  const setMinLow = (data: any) => {
    let dates = [{ dates: [{ prices: { CZK: 0 } }], difficulty: 0 }];
    /* if (!data) { */
    dates = JSON.parse(JSON.stringify(data));
    /* } else {
      dates = itemsOriginal;
    } */
    let min = 0;
    let max = 0;
    let minDiff = 0;
    let maxDiff = 0;
    for (var i = 0; i < dates.length; i++) {
      for (var j = 0; j < dates[i].dates.length; j++) {
        if (min === 0) {
          min = dates[i].dates[j].prices.CZK;
        }
        if (max === 0) {
          max = dates[i].dates[j].prices.CZK;
        }
        if (dates[i].dates[j].prices.CZK < min) {
          min = dates[i].dates[j].prices.CZK;
        }
        if (dates[i].dates[j].prices.CZK > max) {
          max = dates[i].dates[j].prices.CZK;
        }
      }
      if (minDiff === 0) {
        minDiff = dates[i].difficulty;
      }
      if (maxDiff === 0) {
        maxDiff = dates[i].difficulty;
      }
      if (dates[i].difficulty < minDiff) {
        minDiff = dates[i].difficulty;
      }
      if (dates[i].difficulty > maxDiff) {
        maxDiff = dates[i].difficulty;
      }
    }
    setMinMax({
      min: JSON.parse(JSON.stringify(min)),
      max: JSON.parse(JSON.stringify(max)),
    });
    if (advFilters.priceFrom === 0) {
      setPricesRange({
        lower: JSON.parse(JSON.stringify(min)),
        upper: JSON.parse(JSON.stringify(max)),
      });
    } else {
      setPricesRange({
        lower: JSON.parse(JSON.stringify(advFilters.priceFrom)),
        upper: JSON.parse(JSON.stringify(advFilters.priceTo)),
      });
    }
    if (
      advFilters.difficulty.lower === 0 &&
      advFilters.difficulty.upper === 0
    ) {
      setDiffRange({ lower: minDiff, upper: maxDiff });
    } else {
      setDiffRange(JSON.parse(JSON.stringify(advFilters.difficulty)));
    }
  };

  const setCategories = (data: any) => {
    let dates = JSON.parse(JSON.stringify(data));
    const transitIds = dates.map((o: any) => o.transit);
    const filteredTransitIds = dates.filter(
      ({ transit }: any, index: number) =>
        !transitIds.includes(transit, index + 1)
    );
    setTransitsCategories([...filteredTransitIds]);

    let tempCategories = [""];
    dates.forEach((tempX: any) => {
      tempX.categories.forEach((tempY: string) => {
        tempCategories.push(tempY);
      });
    });
    /* const categoryIds = dates.map((o: any) => o.categories); */
    const filteredCategoryIds = tempCategories.filter(
      (category: any, index: number) =>
        !tempCategories.includes(category, index + 1)
    );
    setCategoryCategories([...filteredCategoryIds]);

    const accomodationIds = dates.map((o: any) => o.accommodation);
    const filteredAccomodationIds = dates.filter(
      ({ accommodation }: any, index: number) =>
        !accomodationIds.includes(accommodation, index + 1)
    );
    setAccomodationsCategories([...filteredAccomodationIds]);

    const mealIds = dates.map((o: any) => o.meals);
    const filteredMealIds = dates.filter(
      ({ meals }: any, index: number) => !mealIds.includes(meals, index + 1)
    );
    setMealsCategories([...filteredMealIds]);

    const diffIds = dates.map((o: any) => o.difficulty);
    const filteredDiffIds = dates.filter(
      ({ difficulty }: any, index: number) =>
        !diffIds.includes(difficulty, index + 1)
    );
    let temp = JSON.parse(JSON.stringify(diffMinMax));
    for (var i = 0; i < filteredDiffIds.length; i++) {
      if (filteredDiffIds[i].difficulty < temp.min) {
        temp.min = filteredDiffIds[i].difficulty;
      }
      if (filteredDiffIds[i].difficulty > temp.max) {
        temp.max = filteredDiffIds[i].difficulty;
      }
    }
    setDiffMinMax(temp);
  };

  const setFilters = () => {
    let tempFilters = { ...advFilters };
    if (showCategories) {
      if (categoriesFilters[0] === "All" && categoriesFilters.length > 1) {
        let temp = [...categoriesFilters];
        temp.splice(0, 1);
        tempFilters.categories = categoriesFilters;
      } else {
        tempFilters.categories = categoriesFilters;
      }
    } else {
      tempFilters.categories = [""];
      setCategoriesFilters([""]);
    }

    if (showDestination) {
      if (destinationsFilter.includes("World")) {
        let temp = [...destinationsFilter];
        for (var i = 0; i < temp.length; i++) {
          if (temp[i] === "World") {
            temp.splice(i, 1);
            i--;
          }
        }
        tempFilters.destinations = temp;
        /* setDestinationsFilter(temp); */
        /* setShowDestination(false); */
      } else {
        let tempDesti = [...destinationsFilter];
        continentsFilter.forEach((conti, i) => {
          if (conti === "") return;
          let check = false;

          contriesFilter[i].forEach((cf) => {
            if (tempDesti.includes(cf)) {
              check = true;
            }
          });

          if (!check) {
            tempDesti = tempDesti.concat(contriesFilter[i]);
          }

          /* if (contriesFilter[i][0] === "") {
            tempDesti = tempDesti.concat(setCountriesOfContinent(conti));
          } */
        });
        if (destinationsFilter !== tempDesti) setDestinationsFilter(tempDesti);
        tempFilters.destinations = tempDesti;
        if (tempFilters.destinations.length === 0) {
          setShowDestination(false);
        }
        /* setDestinationsFilter(tempFilters.destinations);  */
      }
    } else {
      tempFilters.destinations = [""];
      setDestinationsFilter([""]);
      setContriesFilter([[""]]);
    }

    if (showDates) {
      if (datesRange !== null && datesRange !== undefined) {
        tempFilters.dateFrom = datesRange[0];
        tempFilters.dateTo = datesRange[1];
      } else {
        tempFilters.dateFrom = null;
        tempFilters.dateTo = null;
        setShowDates(false);
      }
    } else {
      tempFilters.dateFrom = null;
      tempFilters.dateTo = null;
    }

    if (showPersons) {
      if (persons === 1) {
        tempFilters.persons = 1;
        setShowPersons(false);
      } else {
        tempFilters.persons = persons;
      }
    } else {
      tempFilters.persons = 1;
    }

    if (showPrices) {
      if (
        pricesRange.lower === minMax.min &&
        pricesRange.upper === minMax.max
      ) {
        tempFilters.priceFrom = 0;
        tempFilters.priceTo = 0;
        setShowPrices(false);
      } else {
        tempFilters.priceFrom = pricesRange.lower;
        tempFilters.priceTo = pricesRange.upper;
      }
    } else {
      tempFilters.priceFrom = 0;
      tempFilters.priceTo = 0;
    }
    if (showTransits) {
      if (transitsFilters.length === 1 && transitsFilters[0] === "") {
        setShowTransits(false);
        tempFilters.transit = JSON.parse(JSON.stringify(transitsFilters));
      } else {
        tempFilters.transit = JSON.parse(JSON.stringify(transitsFilters));
      }
    } else {
      tempFilters.transit = [""];
    }
    if (showAccomodation) {
      if (accomodationsFilters.length === 1 && accomodationsFilters[0] === "") {
        setShowAccomodation(false);
        tempFilters.accommodation = JSON.parse(
          JSON.stringify(accomodationsFilters)
        );
      } else {
        tempFilters.accommodation = JSON.parse(
          JSON.stringify(accomodationsFilters)
        );
      }
    } else {
      tempFilters.accommodation = [""];
    }
    if (showMeals) {
      if (mealsFilters.length === 1 && mealsFilters[0] === "") {
        setShowMeals(false);
        tempFilters.meal = JSON.parse(JSON.stringify(mealsFilters));
      } else {
        tempFilters.meal = JSON.parse(JSON.stringify(mealsFilters));
      }
    } else {
      tempFilters.meal = [""];
    }
    if (showDifficulty) {
      if (diffRange.lower === 1 && diffRange.upper === 5) {
        tempFilters.difficulty.lower = 0;
        tempFilters.difficulty.upper = 0;
        setShowDifficulty(false);
      } else {
        tempFilters.difficulty.lower = diffRange.lower;
        tempFilters.difficulty.upper = diffRange.upper;
      }
    } else {
      tempFilters.difficulty = { lower: 0, upper: 0 };
    }
    setAdvFilters(tempFilters);
  };

  const getLowestPrice = (item: any) => {
    let min = 100000000;
    for (var i = 0; i < item.dates.length; i++) {
      if (item.dates[i].prices.CZK < min) {
        min = item.dates[i].prices.CZK;
      }
    }
    let config = language;
    language === "cs" ? (config = config + "-CZ") : (config = config + "-GB");
    return (
      <div style={{ display: "inline-block" }}>
        {" "}
        • {min.toLocaleString(config) + " Kč"}
      </div>
    );
  };

  useEffect(() => {
    if (!isFirstRender.current) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [advFilters]);

  function whatCountriesShow(continent: string) {
    if (continentsFilter.includes(continent)) {
      /* setCountriesFilter(""); */
      let temp = [...destinationsFilter];
      let jsonData = require("../assets/countries_states_cities/countries+states+cities.json");
      let tempX = jsonData.filter((c: any) => c.region === continent);
      temp = temp.filter((country) => {
        let check = false;
        tempX.forEach((t: any) => {
          if (t.iso2 === country) {
            check = true;
          }
        });

        return !check;
      });
      setDestinationsFilter(temp);
      setCountriesFilter("");
      /* setIsCountriesShown((state) => !state); */
    } else if (countriesFilter === "") {
      setCountriesFilter(continent);
      /* setIsCountriesShown((state) => !state);
      setFilterOfContinents(continent); */
    } else setCountriesFilter(continent);
    setFilterOfContinents(continent);
  }

  const setCategoriesFilter = (e: any) => {
    switch (e.target.name) {
      case "transit":
        let tempTransitFilter = transitsFilters;
        if (e.detail.checked) {
          tempTransitFilter = tempTransitFilter.concat(e.detail.value);
        } else {
          for (var i = 0; i < tempTransitFilter.length; i++) {
            if (tempTransitFilter[i] === e.detail.value) {
              tempTransitFilter.splice(i, 1);
              i--;
            }
          }
        }
        setTransitsFilters(tempTransitFilter);
        break;
      case "accommodation":
        let tempAccommodationFilter = accomodationsFilters;
        if (e.detail.checked) {
          tempAccommodationFilter = tempAccommodationFilter.concat(
            e.detail.value
          );
        } else {
          for (var i = 0; i < tempAccommodationFilter.length; i++) {
            if (tempAccommodationFilter[i] === e.detail.value) {
              tempAccommodationFilter.splice(i, 1);
              i--;
            }
          }
        }
        setAccomodationsFilters(tempAccommodationFilter);
        break;
      case "meal":
        let tempMealFilter = mealsFilters;
        if (e.detail.checked) {
          tempMealFilter = tempMealFilter.concat(e.detail.value);
        } else {
          for (var i = 0; i < tempMealFilter.length; i++) {
            if (tempMealFilter[i] === e.detail.value) {
              tempMealFilter.splice(i, 1);
              i--;
            }
          }
        }
        setMealsFilters(tempMealFilter);
        break;
      case "category":
        let tempCategoryFilter = categoriesFilters;
        if (e.detail.checked) {
          tempCategoryFilter = tempCategoryFilter.concat(e.detail.value);
        } else {
          for (var i = 0; i < tempCategoryFilter.length; i++) {
            if (tempCategoryFilter[i] === e.detail.value) {
              tempCategoryFilter.splice(i, 1);
              i--;
            }
          }
        }
        setCategoriesFilters(tempCategoryFilter);
        break;
      default:
        return;
    }
  };

  const setIcon = (name: string) => {
    switch (name) {
      case "OWN":
        return circle;
      case "VAN":
        return camperVan;
      case "BUS":
        return bus;
      case "CAR":
        return car;
      case "AIRPLANE":
        return airplane;
      case "TRAIN":
        return train;
      case "BOAT":
        return boat2;
      case "HOTEL":
        return hotel;
      case "HOUSE":
        return house;
      case "HOSTEL":
        return hostel;
      case "CAMPING":
        return tent;
      case "BIVOUACKING":
        return sleepingBag;
      case "OWNm":
        return circle;
      case "BREAKFASTm":
        return breakfast;
      case "BREAKFAST_AND_DINNERm":
        return breakfastDinner;
      case "ALL_INCLUSIVEm":
        return allInc;
      case "CROSS_COUNTRY_SKIING":
        return ccskiing;
      case "BIKING":
        return mtb;
      case "EXPEDITION":
        return accessory;
      case "HELISKIING":
        return helicopter;
      case "MOUNTAIN_ASCENTS":
        return mangoal;
      case "BOATING":
        return boat;
      case "ICEBERG":
        return mountainclimb;
      case "CLIMBING":
        return climbing;
      case "SKIING":
        return skier;
      case "OTHERS":
        return map;
      case "DIVING":
        return dive;
      case "SIGHTSEEING":
        return monument;
      case "MOUNTAIN_CROSSING":
        return hiker;
      case "RELAX_AND_CHILL":
        return relax;
      case "ROADTRIP":
        return van;
      case "SKI_MOUNTAINEERING":
        return hikerskies;
      case "SNOWSHOES":
        return snowshoes;
      case "SNOWBOARDING":
        return snowboarding;
      case "HIKING":
        return hiking;
      case "VIA_FERRATA":
        return ferrata;
      case "WEEKEND_TRIP":
        return camp;
      case "CANYONING":
        return canyoning;
      default:
        return;
    }
  };

  const setText = (name: string) => {
    switch (name) {
      case "OWN":
        return lang.label199;
      case "VAN":
        return lang.label200;
      case "BUS":
        return lang.label201;
      case "CAR":
        return lang.label202;
      case "AIRPLANE":
        return lang.label203;
      case "TRAIN":
        return lang.label204;
      case "BOAT":
        return lang.label205;
      case "HOTEL":
        return lang.label214;
      case "HOUSE":
        return lang.label215;
      case "HOSTEL":
        return lang.label216;
      case "CAMPING":
        return lang.label217;
      case "BIVOUACKING":
        return lang.label218;
      case "OWNm":
        return lang.label199;
      case "BREAKFASTm":
        return lang.label219;
      case "BREAKFAST_AND_DINNERm":
        return lang.label220;
      case "ALL_INCLUSIVEm":
        return lang.label221;
      default:
        return;
    }
  };

  const [searchTextModal, setSearchTextModal] = useState("");

  const [searchTextModalAdv, setSearchTextModalAdv] = useState(empty);
  /* const [searchTextModalCont, setSearchTextModalCont] = useState(empty); */
  const [searchTextModalDest, setSearchTextModalDest] = useState(empty);

  function searchFunction(e: string) {
    setSearchTextModal(e);
    if (e.length > 2) {
      const help = itemsOriginal.filter((item) => {
        return (
          item.nameText?.CS?.toLowerCase().includes(e.toLowerCase()) ||
          item.nameText?.EN?.toLowerCase().includes(e.toLowerCase()) ||
          item?.locations[0]?.countryText?.EN.toLowerCase().includes(
            e.toLowerCase()
          ) ||
          item?.locations[0]?.countryText?.CS.toLowerCase().includes(
            e.toLowerCase()
          ) ||
          (item?.locations[0]?.region !== null
            ? item?.locations[0]?.region.toLowerCase().includes(e.toLowerCase())
            : false) ||
          (item?.locations[0]?.address !== null
            ? item?.locations[0]?.address
                .toLowerCase()
                .includes(e.toLowerCase())
            : false) ||
          item?.locations[0]?.continentText.EN.toLowerCase().includes(
            e.toLowerCase()
          ) ||
          item?.locations[0]?.continentText.CS.toLowerCase().includes(
            e.toLowerCase()
          )
        );
      });

      if (help.length === 0) {
        setSearchTextModalAdv(empty);
      } else {
        setSearchTextModalAdv(help);
      }

      let helpContinents = JSON.parse(JSON.stringify(itemsOriginal));
      helpContinents.forEach((item: any) => {
        item.locations = item.locations.filter(
          (location: any) =>
            location.continentText?.CS?.toLowerCase().includes(
              e.toLowerCase()
            ) ||
            location.continentText?.EN?.toLowerCase().includes(e.toLowerCase())
        );
      });
      helpContinents = helpContinents.filter(
        (item: any) => item.locations.length !== 0
      );

      let helpCountries = JSON.parse(JSON.stringify(itemsOriginal));
      helpCountries.forEach((item: any) => {
        item.locations = item.locations.filter(
          (location: any) =>
            location.countryText.CS?.toLowerCase().includes(e.toLowerCase()) ||
            location.countryText.EN?.toLowerCase().includes(e.toLowerCase())
        );
      });
      helpCountries = helpCountries.filter(
        (item: any) => item.locations.length !== 0
      );

      let helpDest = JSON.parse(JSON.stringify(itemsOriginal));
      helpDest.forEach((item: any) => {
        item.locations = item.locations.filter(
          (location: any) =>
            location.address?.toLowerCase().includes(e.toLowerCase()) ||
            location.city?.toLowerCase().includes(e.toLowerCase()) ||
            location.region?.toLowerCase().includes(e.toLowerCase()) ||
            location.countryText.CS?.toLowerCase().includes(e.toLowerCase()) ||
            location.countryText.EN?.toLowerCase().includes(e.toLowerCase())
        );
      });
      helpDest = helpDest.filter((item: any) => item.locations.length !== 0);

      if (helpCountries.length === 0) {
        setSearchTextModalCountry([["", ""]]);
        setSearchTextModalCountryNumber([0]);
      } else {
        let tempCountry = [["", ""]];
        let tempCountryNumber = [0];
        helpCountries.forEach((itemConti: any) => {
          if (
            !tempCountry.includes([
              itemConti.locations[0].countryText.EN,
              itemConti.locations[0].country,
            ]) &&
            !tempCountry.includes([
              itemConti.locations[0].countryText.CS,
              itemConti.locations[0].country,
            ])
          ) {
            tempCountryNumber.push(
              helpCountries.filter(
                (loc: any) =>
                  loc.locations[0].countryText.CS ===
                  itemConti.locations[0].countryText.CS
              ).length
            );
            tempCountry.push(
              language === "cs"
                ? [
                    itemConti.locations[0].countryText.CS,
                    itemConti.locations[0].country,
                  ]
                : [
                    itemConti.locations[0].countryText.EN,
                    itemConti.locations[0].country,
                  ]
            );
          }
        });
        setSearchTextModalCountry(tempCountry);
        setSearchTextModalCountryNumber(tempCountryNumber);
      }

      if (helpContinents.length === 0) {
        setSearchTextModalContinent([""]);
        setSearchTextModalContinentNumber([0]);
      } else {
        let tempConti = [""];
        let tempNumber = [0];
        helpContinents.forEach((itemConti: any) => {
          if (
            !tempConti.includes(itemConti.locations[0].continentText.EN) &&
            !tempConti.includes(itemConti.locations[0].continentText.CS)
          ) {
            tempNumber.push(
              helpContinents.filter(
                (loc: any) =>
                  loc.locations[0].continentText.CS ===
                  itemConti.locations[0].continentText.CS
              ).length
            );
            tempConti.push(
              language === "cs"
                ? itemConti.locations[0].continentText.CS
                : itemConti.locations[0].continentText.EN
            );
          }
        });
        setSearchTextModalContinent(tempConti);
        setSearchTextModalContinentNumber(tempNumber);
      }

      if (helpDest.length === 0) {
        setSearchTextModalDest(empty);
      } else {
        setSearchTextModalDest(helpDest);
      }
    } else {
      setSearchTextModalContinent([""]);
      setSearchTextModalContinentNumber([0]);
      setSearchTextModalCountry([["", ""]]);
      setSearchTextModalCountryNumber([0]);
      setSearchTextModalAdv(empty);
      setSearchTextModalDest(empty);
    }
  }

  useEffect(() => {
    if (itemsOriginal[0].id === "") {
      return;
    }
    if (renderCheck.current) {
      renderCheck.current = false;
      return;
    }
    /* let contriesFilterTemp = itemsOriginal.filter((item) => {
      return (
        item.locations.filter((location) => {
          return location.continent === countriesFilter;
        }).length > 0
      );
    });
    let propArray = [""];
    contriesFilterTemp.forEach((item) => {
      for (var i = 0; i < item.locations.length; i++) {
        if (propArray[0] === "") {
          propArray[0] = item.locations[i].country;
        } else propArray.push(item.locations[i].country);
      }
    });

    const filtered = propArray.filter((v, i, a) => a.indexOf(v) === i); 
    setContriesFilter([...filtered]);*/

    var filtered = [[""]];
    continentsFilter.forEach((conti: string, i: number) => {
      let contriesFilterTemp = itemsOriginal.filter((item) => {
        return (
          item.locations.filter((location) => {
            return location.continentText.EN === /* countriesFilter */ conti;
          }).length > 0
        );
      });
      let propArray = [""];
      contriesFilterTemp.forEach((item) => {
        for (var i = 0; i < item.locations.length; i++) {
          if (propArray[0] === "") {
            propArray[0] = item.locations[i].country;
          } else propArray.push(item.locations[i].country);
        }
      });

      filtered[i] = propArray.filter((v, i, a) => a.indexOf(v) === i);
    });
    setContriesFilter([...filtered]);
  }, [itemsOriginal]);

  /*   useIonViewDidEnter(() => {
    setCategoriesFilters([...advFilters.categories]);
    console.log(advFilters.categories);
    if (advFilters.categories[0] === "All") {
      setShowCategories(false);
    } else if (advFilters.categories.length > 1) {
      setShowCategories(true);
    } else {
      console.log("here");
      setShowCategories(false);
    }
  }); */

  useEffect(() => {
    setCategoriesFilters([...advFilters.categories]);
    if (advFilters.categories[0] === "All") {
      setShowCategories(false);
    } else if (advFilters.categories.length > 1) {
      setShowCategories(true);
    } else {
      setShowCategories(false);
    }
  }, [advFilters.categories]);

  useEffect(() => {
    var filtered = [[""]];
    continentsFilter.forEach((conti: string, i: number) => {
      let contriesFilterTemp = itemsOriginal.filter((item) => {
        return (
          item.locations.filter((location) => {
            return location.continentText.EN === /* countriesFilter */ conti;
          }).length > 0
        );
      });
      let propArray = [""];
      contriesFilterTemp.forEach((item) => {
        for (var i = 0; i < item.locations.length; i++) {
          if (propArray[0] === "") {
            propArray[0] = item.locations[i].country;
          } else propArray.push(item.locations[i].country);
        }
      });

      filtered[i] = propArray.filter((v, i, a) => a.indexOf(v) === i);
    });
    setContriesFilter([...filtered]);
  }, [countriesFilter]);

  useEffect(() => {
    /* setCountriesFilter(""); */

    let destinationsTemp = [...advFilters.destinations];
    /* setContriesFilter(destinationsTemp); */
    setDestinationsFilter(destinationsTemp);

    let jsonData = require("../assets/countries_states_cities/countries+states+cities.json");
    let tempX = [""];

    for (var i = 0; advFilters.destinations.length > i; i++) {
      tempX[i] = jsonData.filter(
        (c: any) => c.iso2 === advFilters.destinations[i]
      )[0]?.region;
      if (tempX[i] === undefined) {
        tempX[i] = "";
      }
    }
    let uniqueTempX = [""];
    tempX.forEach((t) => {
      if (!uniqueTempX.includes(t)) {
        uniqueTempX = uniqueTempX.concat(t);
      }
    });
    if (destinationsTemp.includes("World") && destinationsTemp.length === 1) {
      setShowDestination(false);
      setContinentsFilter([""]);
      setCountriesFilter("");
    } else if (uniqueTempX.length > 1) {
      setShowDestination(true);
      setContinentsFilter(uniqueTempX);
      for (var j = 0; tempX.length > j; j++) {
        if (uniqueTempX[j] !== "") {
          setCountriesFilter(uniqueTempX[j]);
          return;
        }
      }
    } else if (
      destinationsTemp.length === 0 ||
      (destinationsTemp.length === 1 && destinationsTemp[0] === "")
    ) {
      setShowDestination(false);
      setContinentsFilter([""]);
      setCountriesFilter("");
    } else {
      setContinentsFilter(uniqueTempX);
      setShowDestination(true);
      setCountriesFilter(uniqueTempX[0]);
    }
  }, [advFilters.destinations]);

  function resetDefault() {
    let destinationsTemp = [...advFilters.destinations];
    /* setContriesFilter(destinationsTemp); */
    setDestinationsFilter(destinationsTemp);

    let jsonData = require("../assets/countries_states_cities/countries+states+cities.json");
    let tempX = [""];

    for (var i = 0; advFilters.destinations.length > i; i++) {
      tempX[i] = jsonData.filter(
        (c: any) => c.iso2 === advFilters.destinations[i]
      )[0]?.region;
      if (tempX[i] === undefined) {
        tempX[i] = "";
      }
    }
    if (destinationsTemp.includes("World") && destinationsTemp.length === 1) {
      setShowDestination(false);
      setContinentsFilter([""]);
      setCountriesFilter("");
    } else if (tempX.length > 1) {
      setShowDestination(true);
      setContinentsFilter(tempX);
      for (var j = 0; tempX.length > j; j++) {
        if (tempX[j] !== "") {
          setCountriesFilter(tempX[j]);
          return;
        }
      }
    } else if (destinationsTemp.length === 0) {
      setShowDestination(false);
      setContinentsFilter([""]);
      setCountriesFilter("");
    } else if (
      destinationsTemp.length === 1 &&
      destinationsTemp[0] === "" &&
      tempX.length === 1 &&
      tempX[0] === ""
    ) {
      setContinentsFilter(tempX);
      /*       setShowDestination(true);
       */ setCountriesFilter(tempX[0]);
    } else {
      setContinentsFilter(tempX);
      setShowDestination(true);
      setCountriesFilter(tempX[0]);
    }
  }

  /*   useEffect(() => {
    console.log(advFilters.destinations);



    let jsonData = require("../assets/countries_states_cities/countries+states+cities.json");
    let tempX = [""];

    for (var i = 0; advFilters.destinations.length > i; i++) {
      tempX[i] = jsonData.filter(
        (c: any) => c.iso2 === advFilters.destinations[i]
      )[0]?.region;
      if (tempX[i] === undefined) {
        tempX[i] = "";
      }
    }
    console.log(tempX);
    setContinentsFilter(tempX);
  }, [showDestinationModal]); */
  /*   useEffect(() => {
    if (showDestinationModal === true) {
      setContinentsFilterOriginal(JSON.parse(JSON.stringify(continentsFilter)));
      setContriesFilterOriginal(JSON.parse(JSON.stringify(contriesFilter)));
      setCountriesFilterOriginal(JSON.parse(JSON.stringify(countriesFilter)));
    }
  }, [showDestinationModal]); */

  function continentImage(text: string) {
    switch (text) {
      case "Afrika":
      case "Africa":
      case "africa":
        return africa;
      case "Severní Amerika":
      case "North America":
      case "northAmerica":
        return northAmerica;
      case "Jižní Amerika":
      case "South America":
      case "southAmerica":
        return southAmerica;
      case "Evropa":
      case "Europe":
      case "europe":
        return europe;
      case "Austrálie a Oceánie":
      case "Australia and Oceania":
      case "australia":
        return australia;
      case "Asie":
      case "Asia":
      case "asia":
        return asia;
      case "Antarktida":
      case "Antarctica":
      case "antarctica":
        return antarctica;
    }
  }

  useIonViewWillEnter(() => {
    setAppUrl("/adventures");
    setShowTabBars(true);

    let temp = { ...advFilters };

    if (temp.dateFrom !== null && temp.dateTo !== null) {
      setDatesRange([new Date(temp.dateFrom), new Date(temp.dateTo)]);
      setShowDates(true);
    } else setDatesRange(null);

    if (temp.persons !== 1) {
      setPersons(temp.persons);
      setShowPersons(true);
    }

    if (temp.priceFrom !== 0) {
      isFirstRender.current = true;
      fetchData();
      setShowPrices(true);
    }

    if (temp.transit.length > 1 || temp.transit[0] !== "") {
      setShowTransits(true);
      setTransitsFilters(temp.transit);
    }

    if (temp.accommodation.length > 1 || temp.accommodation[0] !== "") {
      setShowAccomodation(true);
      setAccomodationsFilters(temp.accommodation);
    }

    if (temp.meal.length > 1 || temp.meal[0] !== "") {
      setShowMeals(true);
      setMealsFilters(temp.meal);
    }

    if (temp.difficulty.lower !== 0) {
      isFirstRender.current = true;
      fetchData();
      setShowDifficulty(true);
    }

    /* 
    setDiffRange(advFiltersOriginal.difficulty);
    setShowDifficulty(showFilters.showDifficulty ? true : false); */
  });

  useIonViewWillLeave(() => {
    setAppUrl("");
    setShowTabBars(false);
  });

  return (
    <IonPage id="main">
      <Header styles="shadow-box" />
      <IonContent>
        <div className="web-box">
          <IonRow style={{ padding: "0 25px 0 25px" }}>
            <MagnifyingGlass
              className="search-icon"
              size={24}
              color="white"
              weight="bold"
              style={{ paddingLeft: "5px", marginLeft: "5px" }}
            />
            <IonInput
              className="advent-search"
              value=""
              placeholder={lang.label25}
              onClick={() => setShowSearch(true)}
              readonly={true}
            ></IonInput>
            <IonButton
              className="filters-butt"
              onClick={() => {
                let temp = JSON.parse(JSON.stringify(advFilters));
                setAdvFiltersOriginal(temp);
                let temp2 = {
                  showDestination: showDestination ? true : false,
                  showCategories: showCategories ? true : false,
                  showDates: showDates ? true : false,
                  showPersons: showPersons ? true : false,
                  showPrices: showPrices ? true : false,
                  showTransits: showTransits ? true : false,
                  showAccomodation: showAccomodation ? true : false,
                  showMeals: showMeals ? true : false,
                  showDifficulty: showDifficulty ? true : false,
                };
                setShowFilters(temp2);
                setShowFiltersModal(true);
              }}
            >
              <FadersHorizontal size={30} className="filter-icon" />
            </IonButton>
          </IonRow>
          <Swiper
            style={{ /* height: "auto" */ width: "95%" }}
            /* options={{
            
            initialSlide: 0,
            speed: 400,
            loop: true,
          }} */
            /* initialSlide={0} */
            /* loop={true} */
            className="chip-filter"
            speed={400}
            slidesPerView={"auto"}
          >
            <SwiperSlide className="filter-chip">
              <IonChip
                onClick={() => {
                  setShowDestinationModal(true);
                  if (showDestination) {
                    counter.current = 0;
                  } else {
                    counter.current = 1;
                    setShowDestination(true);
                  }
                }}
                style={{ marginInline: "3px" }}
                className={
                  showDestination ? "active-filter" : "non-active-filter"
                }
              >
                {lang.label7}
              </IonChip>
            </SwiperSlide>
            <SwiperSlide className="filter-chip">
              <IonChip
                onClick={() => {
                  setShowCategoryModal(true);
                  if (showCategories) {
                    counter.current = 0;
                  } else {
                    counter.current = 1;
                    setShowCategories(true);
                  }
                }}
                style={{ marginInline: "3px" }}
                className={
                  showCategories ? "active-filter" : "non-active-filter"
                }
              >
                {lang.label8}
              </IonChip>
            </SwiperSlide>
            <SwiperSlide className="filter-chip">
              <IonChip
                onClick={() => {
                  setShowDateModal(true);
                  if (showDates) {
                    counter.current = 0;
                  } else {
                    counter.current = 1;
                    setShowDates(true);
                  }
                }}
                style={{ marginInline: "3px" }}
                className={showDates ? "active-filter" : "non-active-filter"}
              >
                {lang.label127}
              </IonChip>
            </SwiperSlide>
            <SwiperSlide className="filter-chip">
              <IonChip
                onClick={() => {
                  setShowPersonsModal(true);
                  if (showPersons) {
                    counter.current = 0;
                  } else {
                    counter.current = 1;
                    setShowPersons(true);
                  }
                }}
                style={{ marginInline: "3px" }}
                className={showPersons ? "active-filter" : "non-active-filter"}
              >
                {lang.label287}
              </IonChip>
            </SwiperSlide>
            <SwiperSlide className="filter-chip">
              <IonChip
                onClick={() => {
                  setShowPriceModal(true);
                  if (showPrices) {
                    counter.current = 0;
                  } else {
                    counter.current = 1;
                    setShowPrices(true);
                    setMinLow(itemsOriginal);
                  }
                }}
                style={{ marginInline: "3px" }}
                className={showPrices ? "active-filter" : "non-active-filter"}
              >
                {lang.label207}
              </IonChip>
            </SwiperSlide>
            <SwiperSlide className="filter-chip">
              <IonChip
                onClick={() => {
                  setShowTransportModal(true);
                  if (showTransits) {
                    counter.current = 0;
                  } else {
                    counter.current = 1;
                    setShowTransits(true);
                  }
                }}
                style={{ marginInline: "3px" }}
                className={showTransits ? "active-filter" : "non-active-filter"}
              >
                {lang.label206}
              </IonChip>
            </SwiperSlide>
            <SwiperSlide className="filter-chip">
              <IonChip
                onClick={() => {
                  setShowAccomodationModal(true);
                  if (showAccomodation) {
                    counter.current = 0;
                  } else {
                    counter.current = 1;
                    setShowAccomodation(true);
                  }
                }}
                style={{ marginInline: "3px" }}
                className={
                  showAccomodation ? "active-filter" : "non-active-filter"
                }
              >
                {lang.label209}
              </IonChip>
            </SwiperSlide>
            <SwiperSlide className="filter-chip">
              <IonChip
                onClick={() => {
                  setShowMealModal(true);
                  if (showMeals) {
                    counter.current = 0;
                  } else {
                    counter.current = 1;
                    setShowMeals(true);
                  }
                }}
                style={{ marginInline: "3px" }}
                className={showMeals ? "active-filter" : "non-active-filter"}
              >
                {lang.label210}
              </IonChip>
            </SwiperSlide>
            <SwiperSlide className="filter-chip">
              <IonChip
                onClick={() => {
                  setShowDifficultyModal(true);
                  if (showDifficulty) {
                    counter.current = 0;
                  } else {
                    counter.current = 1;
                    setShowDifficulty(true);
                    setMinLow(itemsOriginal);
                  }
                }}
                /* color={showDifficulty ? "primary" : ""} */
                style={{ marginInline: "3px" }}
                className={
                  showDifficulty ? "active-filter" : "non-active-filter"
                }
              >
                {lang.label211}
              </IonChip>
            </SwiperSlide>
          </Swiper>
          {showLoading ? (
            <IonSpinner className="spinner-loading" color="primary" />
          ) : (
            <div className="text-center">
              {items.data.length === 0 ? (
                <IonItem
                  className="no-border text-center"
                  style={{ display: "inline-block" }}
                >
                  <IonText>{lang.label460}</IonText>
                </IonItem>
              ) : (
                items.data.map((item, i) => {
                  return (
                    <IonCard key={`${i}`} className="adventures-card">
                      <div className="card-cont">
                        <img
                          className="adventure-cover"
                          src={
                            item.image === "" ? defaultAdvImage : item.imageData
                          }
                          onClick={() => {
                            return (
                              setAdventureId(item.id),
                              history.push("/adventures/" + item.id)
                            );
                          }}
                          alt="adventure background"
                        />
                        <IonChip className="adv-chip">
                          <IonLabel>
                            {setCountryName(
                              item.locations[0].country,
                              language
                            )}
                          </IonLabel>
                        </IonChip>
                        {item.categories.map((item, i) => {
                          return (
                            <IonChip className={"adv-chip" + i} key={`${i}`}>
                              <IonLabel>{setEnumText(item, lang)}</IonLabel>
                            </IonChip>
                          );
                        })}
                        <IonChip className="adv-like">
                          <Heart
                            size={24}
                            weight={item.isLiked ? "fill" : "duotone"}
                            color="#3fd0d4"
                            onClick={
                              authValues.user.id === "guest"
                                ? (e) => {
                                    e.preventDefault();
                                    setShowGuestModal(true);
                                  }
                                : () => {
                                    let itemsTemp = { ...items };
                                    itemsTemp.data[i].isLiked = !item.isLiked;
                                    setItems(itemsTemp);
                                    setLike(
                                      item.id,
                                      "adventures",
                                      itemsTemp.data[i].isLiked,
                                      token
                                    );
                                  }
                            }
                          />
                        </IonChip>
                        {/* <IonChip
                    className="adv-partner"
                    onClick={() => {
                      setPartnerName(item.partner);
                      setShowModalPart(true);
                    }}
                  >
                    <Handshake size={24} color="#3fd0d4" />
                  </IonChip> */}
                        <IonChip
                          className="adv-guide-chip"
                          onClick={
                            authValues.user.id === "guest"
                              ? (e) => {
                                  e.preventDefault();
                                  setShowGuestModal(true);
                                }
                              : () => {
                                  setUserId(item.guide.id);
                                  history.push("/userpage/" + item.guide.id);
                                }
                          }
                        >
                          <IonLabel>{item.guide.firstName}</IonLabel>
                        </IonChip>
                        <IonAvatar
                          className="guide-avatar"
                          onClick={
                            authValues.user.id === "guest"
                              ? (e) => {
                                  e.preventDefault();
                                  setShowGuestModal(true);
                                }
                              : () => {
                                  setUserId(item.guide.id);
                                  history.push("/userpage/" + item.guide.id);
                                }
                          }
                        >
                          {item.guide.profileImage === null ? (
                            <UserCircle
                              size={78}
                              fill="black"
                              weight="fill"
                              style={{
                                marginLeft: "-7px",
                                marginTop: "-7px",
                              }}
                            />
                          ) : (
                            <img
                              src={item.guide.profileImageData}
                              alt="guide"
                            />
                          )}
                        </IonAvatar>
                      </div>
                      <IonCardContent style={{ paddingTop: "8px" }}>
                        <IonFooter>
                          {item.rating !== null && (
                            <div
                              style={{
                                display: "inline",
                                marginRight: "5px",
                              }}
                            >
                              <Rate
                                count={5}
                                value={item.rating}
                                allowHalf={true}
                                disabled={true}
                                className="rating-stars"
                              />{" "}
                              {item.rating} ({item.ratingCount})
                            </div>
                          )}
                          <div
                            style={{
                              display: "inline-block",
                              verticalAlign: "top",
                              width: "30px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="20"
                              fill="rgb(63, 208, 212)"
                              viewBox="0 0 400 256"
                            >
                              <rect width="52" height="256" fill="none"></rect>
                              <line
                                x1="340"
                                y1="208"
                                x2="28"
                                y2="208"
                                fill="none"
                                stroke="rgb(63, 208, 212)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              ></line>
                              <rect
                                x="42"
                                y="160"
                                width="56"
                                height="48"
                                strokeWidth="16"
                                stroke="rgb(63, 208, 212)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                fill={item.difficulty >= 1 ? "true" : "none"}
                              ></rect>
                              <rect
                                x="100"
                                y="140"
                                width="56"
                                height="68"
                                strokeWidth="16"
                                stroke="rgb(63, 208, 212)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                fill={item.difficulty >= 2 ? "true" : "none"}
                              ></rect>
                              <rect
                                x="158"
                                y="120"
                                width="56"
                                height="88"
                                strokeWidth="16"
                                stroke="rgb(63, 208, 212)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                fill={item.difficulty >= 3 ? "true" : "none"}
                              ></rect>
                              <rect
                                x="216"
                                y="100"
                                width="56"
                                height="108"
                                strokeWidth="16"
                                stroke="rgb(63, 208, 212)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                fill={item.difficulty >= 4 ? "true" : "none"}
                              ></rect>
                              <rect
                                x="274"
                                y="80"
                                width="56"
                                height="128"
                                strokeWidth="16"
                                stroke="rgb(63, 208, 212)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                fill={item.difficulty >= 5 ? "true" : "none"}
                              ></rect>
                            </svg>
                          </div>
                          <IonCol>
                            {item.difficulty}
                            /5
                          </IonCol>
                        </IonFooter>
                        <IonFooter
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          {checkProperty(item.nameText, language)}
                        </IonFooter>
                        <IonFooter>
                          {moment(item.dates[0].begin, "YYYY-MM-DD").format(
                            "DD.MM."
                          )}{" "}
                          -{" "}
                          {moment(item.dates[0].end, "YYYY-MM-DD").format(
                            "DD.MM.YYYY"
                          )}{" "}
                          • {item.dates.length}{" "}
                          {item.dates.length > 1
                            ? lang.label194
                            : lang.label195}{" "}
                          • {getTripLength(item)} {getLowestPrice(item)}
                        </IonFooter>
                      </IonCardContent>
                    </IonCard>
                  );
                })
              )}
            </div>
          )}
        </div>
        <FooterPageMap />
      </IonContent>

      {/* <IonModal
        isOpen={showModalPart}
        cssClass="part-modal"
        onDidDismiss={() => setShowModalPart(false)}
      >
        <IonText>
          <p>{lang.label196}</p>
          <p>{partnerName}</p>
        </IonText>
      </IonModal> */}

      <IonModal
        isOpen={showSearch}
        cssClass="search-modala"
        onDidDismiss={() => setShowSearch(false)}
      >
        <IonHeader className="main-header-search safe-zone-header">
          <IonButton
            onClick={() => setShowSearch(false)}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
          <IonItem
            className="search-item searchbar-input"
            style={{ marginTop: "5px" }}
          >
            <MagnifyingGlass
              size={24}
              style={{ paddingLeft: "5px", marginLeft: "5px" }}
            />
            <IonInput
              className="modal-search"
              value={searchTextModal}
              autofocus={true}
              placeholder={lang.label223}
              onIonChange={(e) => searchFunction(e.detail.value!)}
            ></IonInput>
          </IonItem>
        </IonHeader>
        <IonContent>
          {/* <IonTitle>Poslední vyhledávání</IonTitle>
            <IonList></IonList> */}
          {/* <IonTitle>{lang.label224}</IonTitle> */}

          {searchTextModalContinent[1] && (
            <div>
              {/* <IonTitle>{lang.label279}</IonTitle> */}
              <IonList>
                {searchTextModalContinent.map((item, i) => {
                  if (item === "") return;
                  return (
                    <IonItem
                      className="user-info-item item-input-grey"
                      key={`${i}`}
                      onClick={() => {
                        setShowLoading(true);
                        let tempFilters = JSON.parse(
                          JSON.stringify(advFilters)
                        );
                        tempFilters.destinations = [""];
                        tempFilters.destinations =
                          tempFilters.destinations.concat(
                            setCountriesOfContinent(item)
                          );
                        /* console.log(setCountriesOfContinent(item)); */
                        tempFilters.categories = [""];
                        setAdvFilters(tempFilters);
                        setShowSearch(false);
                        setSearchTextModal("");
                        /* history.push("/adventures/"); */
                      }}
                    >
                      <IonImg
                        style={{
                          width: "5em",
                          objectFit: "cover",
                          height: "50px",
                        }}
                        src={continentImage(item)}
                      ></IonImg>
                      <IonText style={{ marginLeft: "10px" }}>
                        {item}, {searchTextModalContinentNumber[i]}&nbsp;
                        {lang.label21.toLowerCase()}
                      </IonText>
                    </IonItem>
                  );
                })}
              </IonList>
            </div>
          )}
          {searchTextModalCountry[1] && (
            <div>
              {/* <IonTitle>{lang.label279}</IonTitle> */}
              <IonList>
                {searchTextModalCountry.map((item, i) => {
                  if (item[0] === "") return;
                  return (
                    <IonItem
                      className="user-info-item item-input-grey"
                      key={`${i}`}
                      onClick={() => {
                        setShowLoading(true);
                        let tempFilters = { ...advFilters };
                        tempFilters.destinations = ["", item[1]];
                        tempFilters.categories = [""];
                        setAdvFilters(tempFilters);
                        setShowSearch(false);
                        setSearchTextModal("");
                        /* history.push("/adventures/"); */
                      }}
                    >
                      {/* <IonImg
                          style={{
                            width: "5em",
                            objectFit: "cover",
                            height: "50px",
                          }}
                          src={continentImage(item)}
                        ></IonImg> */}
                      <MapPin size={32} style={{ minWidth: "32px" }} />
                      <IonText style={{ marginLeft: "10px" }}>
                        {item[0]}, {searchTextModalCountryNumber[i]}&nbsp;
                        {lang.label21.toLowerCase()}
                      </IonText>
                    </IonItem>
                  );
                })}
              </IonList>
            </div>
          )}
          {searchTextModalAdv[0].id !== "" && (
            <div>
              <IonTitle>{lang.label224}</IonTitle>
              <IonList>
                {searchTextModalAdv.map((item, i) => (
                  <IonItem
                    key={`${i}`}
                    onClick={() => {
                      return (
                        setAdventureId(item.id),
                        setShowSearch(false),
                        setSearchTextModal(""),
                        history.push("/adventures/" + item.id)
                      );
                    }}
                    className="user-info-item item-input-grey"
                  >
                    <IonImg
                      style={{
                        width: "5em",
                        objectFit: "cover",
                        height: "50px",
                      }}
                      src={item.image === "" ? defaultAdvImage : item.imageData}
                      alt={
                        item.nameText.hasOwnProperty("CS")
                          ? item.nameText.CS
                          : item.nameText.EN
                      }
                    ></IonImg>
                    <IonLabel style={{ marginLeft: "10px" }}>
                      {checkProperty(item.nameText, language)}
                    </IonLabel>
                  </IonItem>
                ))}
              </IonList>
            </div>
          )}

          {searchTextModal.length > 2 &&
            searchTextModalAdv[0].id === "" &&
            !searchTextModalCountry[1] &&
            !searchTextModalContinent[1] && (
              <div
                style={{
                  borderBottom: "1px solid #DEDEDE",
                  marginLeft: "25px",
                  marginRight: "25px",
                  padding: "0 0 10px 0",
                }}
              >
                <IonTitle
                  style={{
                    marginTop: "10px",
                    padding: 0,
                  }}
                >
                  {lang.label445}
                </IonTitle>
              </div>
            )}
          {searchTextModalAdv[0].id === "" &&
            searchTextModalDest[0].id === "" &&
            !searchTextModalContinent[1] &&
            !searchTextModalCountry[1] && (
              <div
                style={{
                  marginLeft: "25px",
                  marginRight: "25px",
                  padding: 0,
                }}
              >
                <IonTitle
                  style={{
                    marginTop: "10px",
                    padding: 0,
                  }}
                >
                  {lang.label446}
                </IonTitle>
              </div>
            )}
          {searchTextModalAdv[0].id === "" &&
            searchTextModalDest[0].id === "" &&
            !searchTextModalContinent[1] &&
            !searchTextModalCountry[1] && (
              <IonList style={{ paddingBottom: 0 }}>
                <IonItem
                  style={{ marginBottom: 0 }}
                  className="user-info-item item-input-grey"
                  onClick={() => {
                    setShowLoading(true);
                    let tempFilt = JSON.parse(JSON.stringify(advFilters));
                    tempFilt.destinations = [""];
                    setShowSearch(false);
                    setShowDestination(false);
                    setSearchTextModal("");
                    setAdvFilters(tempFilt);
                  }}
                >
                  <div style={{ width: "5em", textAlign: "center" }}>
                    <MapPin size={32} style={{ minWidth: "32px" }} />
                  </div>
                  <IonText style={{ marginLeft: "10px" }}>
                    {lang.label369}
                  </IonText>
                </IonItem>
              </IonList>
            )}
          {searchTextModalAdv[0].id === "" &&
            searchTextModalDest[0].id === "" &&
            !searchTextModalContinent[1] &&
            !searchTextModalCountry[1] &&
            Object.keys(continentFilter).map((key: string) => {
              if (continentFilter[key]) {
                return (
                  <div key={`${key}`}>
                    <IonList style={{ paddingBottom: 0 }}>
                      <IonItem
                        style={{ marginBottom: 0 }}
                        className="user-info-item item-input-grey"
                        onClick={() => {
                          setShowLoading(true);
                          let tempFilters = JSON.parse(
                            JSON.stringify(advFilters)
                          );
                          tempFilters.destinations = [""];
                          tempFilters.destinations =
                            tempFilters.destinations.concat(
                              setCountriesOfContinent(key)
                            );
                          /* console.log(setCountriesOfContinent(item)); */
                          tempFilters.categories = [""];
                          setAdvFilters(tempFilters);
                          setShowSearch(false);
                          setSearchTextModal("");
                          history.push("/adventures/");
                        }}
                      >
                        <IonImg
                          style={{
                            width: "5em",
                            objectFit: "cover",
                            height: "50px",
                          }}
                          src={continentImage(key)}
                          alt={key}
                        ></IonImg>
                        <IonLabel style={{ marginLeft: "10px" }}>
                          {setEnumText(key, lang)}
                        </IonLabel>
                      </IonItem>
                    </IonList>
                    {dummyCountries.map((countries, i) => {
                      if (countries[0] === key) {
                        return (
                          <IonList
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            key={i}
                          >
                            {countries.map((country, y) => {
                              if (y === 0 || y > 9) return;

                              return (
                                <IonItem
                                  key={y}
                                  style={{ marginBottom: 0 }}
                                  className="user-info-item item-input-grey"
                                  onClick={() => {
                                    setShowLoading(true);
                                    let tempFilters = JSON.parse(
                                      JSON.stringify(advFilters)
                                    );
                                    tempFilters.destinations = [""];
                                    tempFilters.destinations =
                                      tempFilters.destinations.concat(country);
                                    tempFilters.categories = [""];
                                    setAdvFilters(tempFilters);
                                    setShowSearch(false);
                                    setSearchTextModal("");
                                    history.push("/adventures/");
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "5em",
                                      textAlign: "center",
                                    }}
                                  >
                                    <MapPin
                                      size={32}
                                      style={{ minWidth: "32px" }}
                                    />
                                  </div>
                                  <IonLabel style={{ marginLeft: "10px" }}>
                                    {setCountryName(country, language)}
                                  </IonLabel>
                                </IonItem>
                              );
                            })}
                          </IonList>
                        );
                      } else return;
                    })}
                  </div>
                );
              }
            })}

          {/* {searchTextModalDest[0].id !== "" && (
            <div>
              <IonTitle>Shodný název destinace</IonTitle>
              <IonList>
                {searchTextModalDest.map((item, i) => (
                  <IonItem
                    key={`${i}`}
                    onClick={() => {
                      return (
                        setAdventureId(item.id),
                        setShowSearch(false),
                        setSearchTextModal(""),
                        history.push("/adventures/" + item.id)
                      );
                    }}
                  >
                    <IonImg
                      style={{
                        width: "5em",
                        objectFit: "cover",
                        height: "50px",
                      }}
                      src={item.image === "" ? defaultAdvImage : item.imageData}
                      alt={
                        item.nameText.hasOwnProperty("CS")
                          ? item.nameText.CS
                          : item.nameText.EN
                      }
                    ></IonImg>
                    <IonLabel style={{ marginLeft: "10px" }}>
                      {item.nameText.CS}
                    </IonLabel>
                  </IonItem>
                ))}
              </IonList>
            </div>
          )} */}
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showFiltersModal}
        cssClass="filters-modal"
        onDidDismiss={() => {
          if (!saving.current) {
            setShowDestination(showFilters.showDestination ? true : false);
            let temp = JSON.parse(
              JSON.stringify(advFiltersOriginal.destinations)
            );
            setDestinationsFilter(temp);

            setShowCategories(showFilters.showCategories ? true : false);
            setCategoriesFilters(advFiltersOriginal.categories);

            setShowDates(showFilters.showDates ? true : false);
            if (
              advFiltersOriginal.dateFrom !== null &&
              advFiltersOriginal.dateTo !== null
            ) {
              setDatesRange([
                new Date(advFiltersOriginal.dateFrom),
                new Date(advFiltersOriginal.dateTo),
              ]);
            } else setDatesRange(null);

            setShowPersons(showFilters.showPersons ? true : false);
            setPersons(advFiltersOriginal.persons);

            setPricesRange({
              lower: advFiltersOriginal.priceFrom,
              upper: advFiltersOriginal.priceTo,
            });
            setShowPrices(showFilters.showPrices ? true : false);
            setTransitsFilters(advFiltersOriginal.transit);
            setShowTransits(showFilters.showTransits ? true : false);
            setAccomodationsFilters(advFiltersOriginal.accommodation);
            setShowAccomodation(showFilters.showAccomodation ? true : false);
            setMealsFilters(advFiltersOriginal.meal);
            setShowMeals(showFilters.showMeals ? true : false);
            setDiffRange(advFiltersOriginal.difficulty);
            setShowDifficulty(showFilters.showDifficulty ? true : false);
            resetDefault();
          } else {
            saving.current = false;
          }
          setShowFiltersModal(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons>
              <IonButton>
                <ArrowLeft
                  size={32}
                  onClick={() => setShowFiltersModal(false)}
                />
              </IonButton>
              <IonText
                className="cancel-selection"
                onClick={() => {
                  /* setContinentsFilter([""]);
                  setContriesFilter([[""]]);
                  setDestinationsFilter([""]);
                  setCountriesFilter("");
                  counter.current = counter.current + 1; */
                  setShowDestination(false);
                  setShowCategories(false);
                  setShowDates(false);
                  setDatesRange(null);
                  setPersons(1);
                  setShowPersons(false);
                  setShowPrices(false);
                  setMinLow(itemsOriginal);
                  setShowTransits(false);
                  setShowAccomodation(false);
                  setShowMeals(false);
                  setShowDifficulty(false);
                  setMinLow(itemsOriginal);
                }}
              >
                {lang.label423}
              </IonText>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem>
              <IonLabel>{lang.label7}</IonLabel>
              <IonToggle
                checked={showDestination}
                onIonChange={(e) => {
                  setShowDestination(e.detail.checked);
                  /*                   setDatesRange(null);
                   */
                }}
                className="filter-toggle"
              />
            </IonItem>
            {showDestination && (
              <div>
                <Swiper
                  /* className="dest-swiper" */
                  style={{ width: "95%" }}
                  speed={400}
                  slidesPerView={4.3}
                  className={countHelper < 5 ? "count-fix" : ""}
                  /* centeredSlides={true} */
                  /* loop={true} */
                >
                  {/* <IonSlide
              className="conti-group"
              onClick={() => {
                whatCountriesShow("World");
              }}
            >
              <div className="destination-map-img">
                <IonImg
                  src={world}
                  class="conti-img"
                  style={
                    continentsFilter.includes("World")
                      ? { opacity: 1 }
                      : { opacity: 0.3 }
                  }
                />
              </div>
              <IonText
                className="text-main-filter" 
              >
                {lang.label9}
              </IonText>
            </IonSlide> */}
                  {continentFilter.africa && (
                    <SwiperSlide
                      className="conti-group"
                      onClick={() => {
                        counter.current = counter.current + 1;
                        whatCountriesShow("Africa");
                      }}
                    >
                      <div className="destination-map-img">
                        <IonImg
                          src={africa}
                          class="conti-img"
                          style={
                            continentsFilter.includes("Africa")
                              ? { opacity: 1 }
                              : { opacity: 0.3 }
                          }
                        />
                      </div>
                      <IonText
                        className="text-main-filter" /* style={{ fontSize: "14px" }} */
                      >
                        {lang.label10}
                      </IonText>
                    </SwiperSlide>
                  )}
                  {continentFilter.northAmerica && (
                    <SwiperSlide
                      className="conti-group"
                      onClick={() => {
                        counter.current = counter.current + 1;
                        whatCountriesShow("North America");
                      }}
                    >
                      <div className="destination-map-img">
                        <IonImg
                          src={northAmerica}
                          class="conti-img"
                          style={
                            continentsFilter.includes("North America")
                              ? { opacity: 1 }
                              : { opacity: 0.3 }
                          }
                        />
                      </div>
                      <IonText
                        className="text-main-filter" /* style={{ fontSize: "14px" }} */
                      >
                        {lang.label11}
                      </IonText>
                    </SwiperSlide>
                  )}
                  {continentFilter.southAmerica && (
                    <SwiperSlide
                      className="conti-group"
                      onClick={() => {
                        counter.current = counter.current + 1;
                        whatCountriesShow("South America");
                      }}
                    >
                      <div className="destination-map-img">
                        <IonImg
                          src={southAmerica}
                          class="conti-img"
                          style={
                            continentsFilter.includes("South America")
                              ? { opacity: 1 }
                              : { opacity: 0.3 }
                          }
                        />
                      </div>
                      <IonText
                        className="text-main-filter" /* style={{ fontSize: "14px" }} */
                      >
                        {lang.label12}
                      </IonText>
                    </SwiperSlide>
                  )}
                  {continentFilter.europe && (
                    <SwiperSlide
                      className="conti-group"
                      onClick={() => {
                        counter.current = counter.current + 1;
                        whatCountriesShow("Europe");
                      }}
                    >
                      <div className="destination-map-img">
                        <IonImg
                          src={europe}
                          class="conti-img"
                          style={
                            continentsFilter.includes("Europe")
                              ? { opacity: 1 }
                              : { opacity: 0.3 }
                          }
                        />
                      </div>
                      <IonText
                        className="text-main-filter" /* style={{ fontSize: "14px" }} */
                      >
                        {lang.label13}
                      </IonText>
                    </SwiperSlide>
                  )}
                  {continentFilter.asia && (
                    <SwiperSlide
                      className="conti-group"
                      onClick={() => {
                        counter.current = counter.current + 1;
                        whatCountriesShow("Asia");
                      }}
                    >
                      <div className="destination-map-img">
                        <IonImg
                          src={asia}
                          class="conti-img"
                          style={
                            continentsFilter.includes("Asia")
                              ? { opacity: 1 }
                              : { opacity: 0.3 }
                          }
                        />
                      </div>
                      <IonText
                        className="text-main-filter" /* style={{ fontSize: "14px" }} */
                      >
                        {lang.label15}
                      </IonText>
                    </SwiperSlide>
                  )}
                  {continentFilter.australia && (
                    <SwiperSlide
                      className="conti-group"
                      onClick={() => {
                        counter.current = counter.current + 1;
                        whatCountriesShow("Australia");
                      }}
                    >
                      <div className="destination-map-img">
                        <IonImg
                          src={australia}
                          class="conti-img"
                          style={
                            continentsFilter.includes("Australia")
                              ? { opacity: 1 }
                              : { opacity: 0.3 }
                          }
                        />
                      </div>
                      <IonText
                        className="text-main-filter" /* style={{ fontSize: "14px" }} */
                      >
                        {lang.label14}
                      </IonText>
                    </SwiperSlide>
                  )}
                  {continentFilter.antarctica && (
                    <SwiperSlide
                      className="conti-group"
                      onClick={() => {
                        counter.current = counter.current + 1;
                        whatCountriesShow("Antarctica");
                      }}
                    >
                      <div className="destination-map-img">
                        <IonImg
                          src={antarctica}
                          class="conti-img"
                          style={
                            continentsFilter.includes("Antarctica")
                              ? { opacity: 1 }
                              : { opacity: 0.3 }
                          }
                        />
                      </div>
                      <IonText
                        className="text-main-filter" /* style={{ fontSize: "14px" }} */
                      >
                        {lang.label76}
                      </IonText>
                    </SwiperSlide>
                  )}
                </Swiper>
                <IonGrid
                  style={{
                    /* borderTop: "1px solid grey",
                borderBottom: "1px solid grey", */
                    padding: "2px 25px 2px 25px",
                  }}
                >
                  {showDestination &&
                    !continentsFilter.includes("World") &&
                    (continentsFilter.length > 1 ||
                      (continentsFilter.length === 1 &&
                        continentsFilter[0] !== "")) && (
                      <IonRow
                        style={{
                          borderTop: "1px solid #DEDEDE",
                          justifyContent: "center",
                        }}
                      >
                        {contriesFilter.map((conti, i) => {
                          if (conti[0] === "") return;
                          return (
                            <IonRow
                              key={i}
                              style={{
                                width: "100%",
                                display: "block",
                                borderBottom: "1px solid #DEDEDE",
                              }}
                            >
                              <IonRow style={{ justifyContent: "center" }}>
                                <IonText>
                                  {setEnumText(continentsFilter[i], lang)}
                                </IonText>
                              </IonRow>
                              <IonRow style={{ justifyContent: "center" }}>
                                {conti.map((country, j) => {
                                  if (country === "") return;

                                  return (
                                    <div style={{ padding: 0 }} key={j}>
                                      <IonChip
                                        onClick={() => {
                                          counter.current = counter.current + 1;
                                          selectCountry(country);
                                        }}
                                        className={
                                          destinationsFilter.includes(country)
                                            ? "selected-country"
                                            : "nonselected-country"
                                        }
                                      >
                                        <IonLabel>
                                          {
                                            setCountryName(
                                              country,
                                              language
                                            ) /* .location.countryText.hasOwnProperty("CS")
                                      ? country.location.countryText.CS
                                      : country.location.countryText.EN */
                                          }
                                        </IonLabel>
                                      </IonChip>
                                    </div>
                                  );
                                })}
                              </IonRow>
                            </IonRow>
                          );
                        })}
                      </IonRow>
                    )}
                </IonGrid>
              </div>
            )}

            <IonItem>
              <IonLabel>{lang.label8}</IonLabel>
              <IonToggle
                checked={showCategories}
                onIonChange={(e) => {
                  setShowCategories(e.detail.checked);
                }}
                className="filter-toggle"
              />
            </IonItem>
            {showCategories && (
              <IonList>
                {categoryCategories.map((item, i) => {
                  if (item === "") {
                    return null;
                  } else {
                    return (
                      <IonItem
                        key={i}
                        style={{
                          marginRight: "20px",
                          marginLeft: "20px",
                          height: "54px",
                          borderBottom: "1px solid #DEDEDE",
                          /* "--max-height:": "48px !important", */
                        }}
                        className="filter-item"
                      >
                        <IonThumbnail
                          style={{
                            height: "40px",
                            width: "40px",
                            margin: "0 5px 0 0",
                          }}
                        >
                          <IonImg
                            src={setIcon(item)}
                            style={{ width: "40px", height: "40px" }}
                          />
                        </IonThumbnail>
                        <IonCheckbox
                          /* style={{ height: "40px" }} */
                          name="category"
                          slot="end"
                          checked={categoriesFilters.includes(item)}
                          value={item}
                          onIonChange={(e) => {
                            setCategoriesFilter(e);
                          }}
                        />
                        <IonText style={{ maxHeight: "48px" }}>
                          {setEnumText(item, lang)}
                        </IonText>
                      </IonItem>
                    );
                  }
                })}
              </IonList>
            )}

            <IonItem>
              <IonLabel>{lang.label208}</IonLabel>
              <IonToggle
                checked={showDates}
                onIonChange={(e) => {
                  setShowDates(e.detail.checked);
                  setDatesRange(null);
                }}
                className="filter-toggle"
              />
            </IonItem>
            {showDates && (
              <IonItem>
                <Calendar
                  minDate={new Date()}
                  selectRange={true}
                  value={datesRange}
                  onChange={(value: any) => setDatesRange(value)}
                  className="calendar-filter"
                  locale={language === "cs" ? "cs-CZ" : "en-GB"}
                />
              </IonItem>
            )}
            <IonItem>
              <IonLabel>{lang.label287}</IonLabel>
              <IonToggle
                checked={showPersons}
                onIonChange={(e) => {
                  setShowPersons(e.detail.checked);
                }}
                className="filter-toggle"
              />
            </IonItem>
            {showPersons && (
              <IonItem
                className="padding-start"
                style={{ justifyContent: "center" }}
              >
                <IonSelect
                  value={String(persons)}
                  onIonChange={(e) => {
                    setPersons(Number(e.detail.value));
                  }}
                  placeholder="1"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                >
                  {freeCapacity.map((item, index) => {
                    return (
                      <IonSelectOption key={index} value={String(item)}>
                        {String(item)}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            )}
            <IonItem>
              <IonLabel>{lang.label207}</IonLabel>
              <IonToggle
                checked={showPrices}
                onIonChange={(e) => {
                  setShowPrices(e.detail.checked);
                  setMinLow(itemsOriginal);
                }}
                className="filter-toggle"
              />
            </IonItem>
            {showPrices && (
              <IonGrid>
                <IonRow>
                  <IonRange
                    className="range-price"
                    dualKnobs={true}
                    pin={true}
                    min={minMax.min}
                    max={minMax.max}
                    value={pricesRange}
                    debounce={200}
                    /* step={500}
                    ticks={true}
                    snaps={true} */
                    onIonFocus={() => setRangeActive(true)}
                    onIonBlur={() => setRangeActive(false)}
                    onIonChange={(e) => {
                      if (rangeActive) {
                        setPricesRange(e.detail.value as any);
                      }
                    }}
                    style={{ paddingBottom: 0 }}
                  />
                </IonRow>
                <IonRow style={{ justifyContent: "center" }}>
                  <IonCol size="3.5">
                    <IonItem className="price-item item-input-grey">
                      <IonInput
                        className="input-prices"
                        style={{ textAlign: "center" }}
                        inputmode="numeric"
                        type="number"
                        value={pricesRange.lower}
                        onIonChange={(e) =>
                          setPricesRange((prevState) => ({
                            ...prevState,
                            lower: Number(e.detail.value),
                          }))
                        }
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol
                    size="1"
                    style={{ textAlign: "center", paddingTop: "15px" }}
                  >
                    -
                  </IonCol>
                  <IonCol size="3.5">
                    <IonItem className="price-item item-input-grey">
                      <IonInput
                        className="input-prices"
                        style={{ textAlign: "center" }}
                        inputmode="numeric"
                        type="number"
                        value={pricesRange.upper}
                        onIonChange={(e) =>
                          setPricesRange((prevState) => ({
                            ...prevState,
                            upper: Number(e.detail.value),
                          }))
                        }
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )}
            {/* <IonItem>
              <IonLabel>Jazyk</IonLabel>
              <IonToggle checked={showLanguages} onIonChange={e => { setShowLanguages(e.detail.checked) }} />
            </IonItem>
            {showLanguages &&
              <IonList>
                <IonItem>
                  <IonCheckbox slot="end" value="english" />
                  <IonLabel>English</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Čeština</IonLabel>
                  <IonCheckbox slot="end" value="czech" />
                </IonItem>
              </IonList>
            } */}
            <IonItem>
              <IonLabel>{lang.label206}</IonLabel>
              <IonToggle
                checked={showTransits}
                onIonChange={(e) => {
                  setShowTransits(e.detail.checked);
                }}
                className="filter-toggle"
              />
            </IonItem>
            {showTransits && (
              <IonList>
                {transitsCategories.map((item, i) => {
                  return (
                    <IonItem
                      key={i}
                      style={{ marginRight: "20px", marginLeft: "20px" }}
                      className="filter-item"
                    >
                      <IonThumbnail>
                        <IonImg src={setIcon(item.transit)} />
                      </IonThumbnail>
                      <IonCheckbox
                        name="transit"
                        slot="end"
                        checked={transitsFilters.includes(item.transit)}
                        value={item.transit}
                        onIonChange={(e) => {
                          setCategoriesFilter(e);
                        }}
                      />
                      <IonLabel>{setText(item.transit)}</IonLabel>
                    </IonItem>
                  );
                })}
              </IonList>
            )}
            <IonItem>
              <IonLabel>{lang.label209}</IonLabel>
              <IonToggle
                checked={showAccomodation}
                onIonChange={(e) => {
                  setShowAccomodation(e.detail.checked);
                }}
                className="filter-toggle"
              />
            </IonItem>
            {showAccomodation && (
              <IonList>
                {accomodationsCategories.map((item, i) => {
                  return (
                    <IonItem
                      key={i}
                      style={{ marginRight: "20px", marginLeft: "20px" }}
                      className="filter-item"
                    >
                      <IonThumbnail>
                        <IonImg src={setIcon(item.accommodation)} />
                      </IonThumbnail>
                      <IonCheckbox
                        name="accommodation"
                        slot="end"
                        checked={accomodationsFilters.includes(
                          item.accommodation
                        )}
                        value={item.accommodation}
                        onIonChange={(e) => {
                          setCategoriesFilter(e);
                        }}
                      />
                      <IonLabel>{setText(item.accommodation)}</IonLabel>
                    </IonItem>
                  );
                })}
              </IonList>
            )}
            <IonItem>
              <IonLabel>{lang.label210}</IonLabel>
              <IonToggle
                checked={showMeals}
                onIonChange={(e) => {
                  setShowMeals(e.detail.checked);
                }}
                className="filter-toggle"
              />
            </IonItem>
            {showMeals && (
              <IonList>
                {mealsCategories.map((item, i) => {
                  return (
                    <IonItem
                      key={i}
                      style={{ marginRight: "20px", marginLeft: "20px" }}
                      className="filter-item"
                    >
                      <IonThumbnail>
                        <IonImg src={setIcon(item.meals + "m")} />
                      </IonThumbnail>
                      <IonCheckbox
                        name="meal"
                        slot="end"
                        checked={mealsFilters.includes(item.meals)}
                        value={item.meals}
                        onIonChange={(e) => {
                          setCategoriesFilter(e);
                        }}
                      />
                      <IonLabel>{setText(item.meals + "m")}</IonLabel>
                    </IonItem>
                  );
                })}
              </IonList>
            )}
            <IonItem>
              <IonLabel>{lang.label211}</IonLabel>
              <IonToggle
                checked={showDifficulty}
                onIonChange={(e) => {
                  setShowDifficulty(e.detail.checked);
                  setMinLow(itemsOriginal);
                }}
                className="filter-toggle"
              />
            </IonItem>
            {showDifficulty && (
              <IonItem>
                <IonGrid>
                  <IonRow style={{ paddingLeft: "9px", paddingRight: "9px" }}>
                    <IonCol style={{ padding: "5px 0 0 0" }} size="1.2">
                      1
                    </IonCol>
                    <IonCol
                      style={{ padding: "5px 0 0 0", textAlign: "center" }}
                      size="3.7"
                    >
                      2
                    </IonCol>
                    <IonCol
                      style={{ padding: "5px 0 0 0", textAlign: "center" }}
                      size="2.2"
                    >
                      3
                    </IonCol>
                    <IonCol
                      style={{ padding: "5px 0 0 0", textAlign: "center" }}
                      size="3.7"
                    >
                      4
                    </IonCol>
                    <IonCol
                      style={{ padding: "5px 0 0 0", textAlign: "right" }}
                      size="1.2"
                    >
                      5
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonRange
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      value={diffRange}
                      dualKnobs={true}
                      min={1}
                      max={5}
                      step={1}
                      ticks={true}
                      snaps={true}
                      onIonChange={(e) => {
                        setDiffActive(true);
                        if (diffActive) {
                          setDiffRange(e.detail.value as any);
                          setDiffActive(false);
                        }
                      }}
                    />
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonText>{lang.label212}</IonText>
                    </IonCol>
                    <IonCol style={{ textAlign: "right" }}>
                      <IonText>{lang.label213}</IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
            )}
          </IonList>
        </IonContent>

        <IonToolbar slot="bottom" className="safe-zone">
          <IonButton
            className="filter-search-butt"
            onClick={() => {
              counter.current = 0;
              saving.current = true;
              setFilters();
              setShowLoading(true);
              setShowFiltersModal(false);
            }}
          >
            {lang.label162}
          </IonButton>
        </IonToolbar>
      </IonModal>

      <IonModal
        isOpen={showDestinationModal}
        /* cssClass="search-modala" */
        cssClass={showDestination ? "" : "date-filter-short"}
        onDidDismiss={() => {
          if (
            advFilters.destinations.length === 1 &&
            advFilters.destinations[0] === ""
          ) {
            setShowDestination(false);
          } else if (advFilters.destinations.length === 0) {
            setShowDestination(false);
          } else if (counter.current > 0) {
            let temp = JSON.parse(JSON.stringify(advFilters.destinations));
            setDestinationsFilter(temp);
            setShowDestination((state) => !state);
            resetDefault();
            /*             setContinentsFilter([...continentsFilterOriginal]);
            setContriesFilter([...contriesFilterOriginal]);
            renderCheck.current = true;
            setCountriesFilter(countriesFilterOriginal); */
            /* setIsCountriesShown(false); */
            /* setContriesFilter([""]); */
          }
          counter.current = 0;
          setShowDestinationModal(false);
        }}
      >
        <IonHeader className="main-header-search safe-zone-header">
          <IonButton
            onClick={() => {
              let temp = JSON.parse(JSON.stringify(advFilters.destinations));
              setDestinationsFilter(temp);
              setShowDestinationModal(false);
              /* resetDefault(); */
            }}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
        </IonHeader>
        <IonContent>
          <IonItem className="no-border">
            <IonLabel>{lang.label7}</IonLabel>
            <IonText
              className="cancel-selection"
              onClick={() => {
                setContinentsFilter([""]);
                setContriesFilter([[""]]);
                setDestinationsFilter([""]);
                setCountriesFilter("");
                counter.current = counter.current + 1;
              }}
            >
              {lang.label423}
            </IonText>
            {/* <IonToggle
              checked={showDestination}
              onIonChange={(e) => {
                counter.current = counter.current + 1;
                setShowDestination(e.detail.checked);
              }}
              className="filter-toggle"
            /> */}
          </IonItem>
          {showDestination && (
            <Swiper
              /* className="dest-swiper" */
              style={{ width: "95%" }}
              speed={400}
              slidesPerView={4.3}
              className={countHelper < 5 ? "count-fix" : ""}
              /* centeredSlides={true} */
              /* loop={true} */
            >
              {/* <IonSlide
              className="conti-group"
              onClick={() => {
                whatCountriesShow("World");
              }}
            >
              <div className="destination-map-img">
                <IonImg
                  src={world}
                  class="conti-img"
                  style={
                    continentsFilter.includes("World")
                      ? { opacity: 1 }
                      : { opacity: 0.3 }
                  }
                />
              </div>
              <IonText
                className="text-main-filter" 
              >
                {lang.label9}
              </IonText>
            </IonSlide> */}
              {continentFilter.africa && (
                <SwiperSlide
                  className="conti-group"
                  onClick={() => {
                    counter.current = counter.current + 1;
                    whatCountriesShow("Africa");
                  }}
                >
                  <div className="destination-map-img">
                    <IonImg
                      src={africa}
                      class="conti-img"
                      style={
                        continentsFilter.includes("Africa")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText
                    className="text-main-filter" /* style={{ fontSize: "14px" }} */
                  >
                    {lang.label10}
                  </IonText>
                </SwiperSlide>
              )}
              {continentFilter.northAmerica && (
                <SwiperSlide
                  className="conti-group"
                  onClick={() => {
                    counter.current = counter.current + 1;
                    whatCountriesShow("North America");
                  }}
                >
                  <div className="destination-map-img">
                    <IonImg
                      src={northAmerica}
                      class="conti-img"
                      style={
                        continentsFilter.includes("North America")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText
                    className="text-main-filter" /* style={{ fontSize: "14px" }} */
                  >
                    {lang.label11}
                  </IonText>
                </SwiperSlide>
              )}
              {continentFilter.southAmerica && (
                <SwiperSlide
                  className="conti-group"
                  onClick={() => {
                    counter.current = counter.current + 1;
                    whatCountriesShow("South America");
                  }}
                >
                  <div className="destination-map-img">
                    <IonImg
                      src={southAmerica}
                      class="conti-img"
                      style={
                        continentsFilter.includes("South America")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText
                    className="text-main-filter" /* style={{ fontSize: "14px" }} */
                  >
                    {lang.label12}
                  </IonText>
                </SwiperSlide>
              )}
              {continentFilter.europe && (
                <SwiperSlide
                  className="conti-group"
                  onClick={() => {
                    counter.current = counter.current + 1;
                    whatCountriesShow("Europe");
                  }}
                >
                  <div className="destination-map-img">
                    <IonImg
                      src={europe}
                      class="conti-img"
                      style={
                        continentsFilter.includes("Europe")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText
                    className="text-main-filter" /* style={{ fontSize: "14px" }} */
                  >
                    {lang.label13}
                  </IonText>
                </SwiperSlide>
              )}
              {continentFilter.asia && (
                <SwiperSlide
                  className="conti-group"
                  onClick={() => {
                    counter.current = counter.current + 1;
                    whatCountriesShow("Asia");
                  }}
                >
                  <div className="destination-map-img">
                    <IonImg
                      src={asia}
                      class="conti-img"
                      style={
                        continentsFilter.includes("Asia")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText
                    className="text-main-filter" /* style={{ fontSize: "14px" }} */
                  >
                    {lang.label15}
                  </IonText>
                </SwiperSlide>
              )}
              {continentFilter.australia && (
                <SwiperSlide
                  className="conti-group"
                  onClick={() => {
                    counter.current = counter.current + 1;
                    whatCountriesShow("Australia");
                  }}
                >
                  <div className="destination-map-img">
                    <IonImg
                      src={australia}
                      class="conti-img"
                      style={
                        continentsFilter.includes("Australia")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText
                    className="text-main-filter" /* style={{ fontSize: "14px" }} */
                  >
                    {lang.label14}
                  </IonText>
                </SwiperSlide>
              )}
              {continentFilter.antarctica && (
                <SwiperSlide
                  className="conti-group"
                  onClick={() => {
                    counter.current = counter.current + 1;
                    whatCountriesShow("Antarctica");
                  }}
                >
                  <div className="destination-map-img">
                    <IonImg
                      src={antarctica}
                      class="conti-img"
                      style={
                        continentsFilter.includes("Antarctica")
                          ? { opacity: 1 }
                          : { opacity: 0.3 }
                      }
                    />
                  </div>
                  <IonText
                    className="text-main-filter" /* style={{ fontSize: "14px" }} */
                  >
                    {lang.label76}
                  </IonText>
                </SwiperSlide>
              )}
            </Swiper>
          )}
          {/* {!continentsFilter.includes("World") && continentsFilter.length > 1 && ( */}
          <IonGrid
            style={{
              /* borderTop: "1px solid grey",
              borderBottom: "1px solid grey", */
              padding: "2px 25px 2px 25px",
            }}
          >
            {showDestination &&
              !continentsFilter.includes("World") &&
              (continentsFilter.length > 1 ||
                (continentsFilter.length === 1 &&
                  continentsFilter[0] !== "")) && (
                <IonRow
                  style={{
                    borderTop: "1px solid #DEDEDE",
                    justifyContent: "center",
                  }}
                >
                  {contriesFilter.map((conti, i) => {
                    if (conti[0] === "") return;
                    return (
                      <IonRow
                        key={i}
                        style={{
                          width: "100%",
                          display: "block",
                          borderBottom: "1px solid #DEDEDE",
                        }}
                      >
                        <IonRow style={{ justifyContent: "center" }}>
                          <IonText>
                            {setEnumText(continentsFilter[i], lang)}
                          </IonText>
                        </IonRow>
                        <IonRow style={{ justifyContent: "center" }}>
                          {conti.map((country, j) => {
                            if (country === "") return;

                            return (
                              <div style={{ padding: 0 }} key={j}>
                                <IonChip
                                  onClick={() => {
                                    counter.current = counter.current + 1;
                                    selectCountry(country);
                                  }}
                                  className={
                                    destinationsFilter.includes(country)
                                      ? "selected-country"
                                      : "nonselected-country"
                                  }
                                >
                                  <IonLabel>
                                    {
                                      setCountryName(
                                        country,
                                        language
                                      ) /* .location.countryText.hasOwnProperty("CS")
                                    ? country.location.countryText.CS
                                    : country.location.countryText.EN */
                                    }
                                  </IonLabel>
                                </IonChip>
                              </div>
                            );
                          })}
                        </IonRow>
                      </IonRow>
                    );
                  })}
                </IonRow>
              )}

            <IonRow style={{ justifyContent: "center" }}>
              <IonButton
                className="filter-butt"
                onClick={() => {
                  counter.current = 0;
                  /* let tempFilters = JSON.parse(JSON.stringify(advFilters)); */
                  /* setAdvFilters(tempFilters); */
                  setFilters();
                  setShowLoading(true);
                  setShowDestinationModal(false);
                }}
              >
                {lang.label162}
              </IonButton>
            </IonRow>
          </IonGrid>
          {/* )} */}
          {/* {continentsFilter.includes("World") && (
            <IonGrid
              style={{
                borderTop: "1px solid grey",
                borderBottom: "1px solid grey",
                padding: "2px 25px 2px 25px",
              }}
            >
              <IonRow style={{ justifyContent: "center" }}>
                <IonButton
                  className="filter-butt"
                  onClick={() => {
                    let tempFilters = JSON.parse(JSON.stringify(advFilters));
                    tempFilters.categories = [""];
                    setAdvFilters(tempFilters);
                    history.push("/adventures/");
                  }}
                >
                  {lang.label162}
                </IonButton>
              </IonRow>
            </IonGrid>
          )} */}
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showCategoryModal}
        cssClass={showCategories ? "" : "date-filter-short"}
        onDidDismiss={() => {
          if (
            advFilters.categories.length === 1 &&
            advFilters.categories[0] === ""
          ) {
            setShowCategories(false);
          } else if (counter.current % 2 == 1) {
            setShowCategories((state) => !state);
          }
          counter.current = 0;
          setShowCategoryModal(false);
        }}
      >
        <IonHeader className="main-header-search safe-zone-header">
          <IonButton
            onClick={() => {
              setShowCategoryModal(false);
              let temp = JSON.parse(JSON.stringify(advFilters.categories));
              setCategoriesFilters(temp);
            }}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
        </IonHeader>
        <IonContent>
          <IonItem className="no-border">
            <IonText>{lang.label8}</IonText>
            <IonText
              className="cancel-selection"
              onClick={() => {
                setCategoriesFilters([""]);
              }}
            >
              {lang.label423}
            </IonText>
          </IonItem>
          {showCategories && (
            <IonList>
              {categoryCategories.map((item, i) => {
                if (item === "") {
                  return null;
                } else {
                  return (
                    <IonItem
                      key={i}
                      style={{
                        marginRight: "20px",
                        marginLeft: "20px",
                        height: "54px",
                        borderBottom: "1px solid #DEDEDE",
                        /* "--max-height:": "48px !important", */
                      }}
                      className="filter-item"
                    >
                      <IonThumbnail
                        style={{
                          height: "40px",
                          width: "40px",
                          margin: "0 5px 0 0",
                        }}
                      >
                        <IonImg
                          src={setIcon(item)}
                          style={{ width: "40px", height: "40px" }}
                        />
                      </IonThumbnail>
                      <IonCheckbox
                        /* style={{ height: "40px" }} */
                        name="category"
                        slot="end"
                        checked={categoriesFilters.includes(item)}
                        value={item}
                        onIonChange={(e) => {
                          setCategoriesFilter(e);
                        }}
                      />
                      <IonText style={{ maxHeight: "48px" }}>
                        {setEnumText(item, lang)}
                      </IonText>
                    </IonItem>
                  );
                }
              })}
            </IonList>
          )}
          <IonFooter>
            <IonToolbar>
              <IonButton
                className="filter-search-butt"
                onClick={() => {
                  counter.current = 0;

                  setFilters();
                  setShowLoading(true);
                  setShowCategoryModal(false);
                }}
              >
                {lang.label424}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showDateModal}
        cssClass={showDates ? "date-filter" : "date-filter-short"}
        onDidDismiss={() => {
          if (counter.current % 2 == 1) {
            setShowDates((state) => !state);
            setDatesRange(null);
          }

          counter.current = 0;
          setShowDateModal(false);
        }}
      >
        <IonHeader className="main-header-search safe-zone-header">
          <IonButton
            onClick={() => {
              let temp = JSON.parse(JSON.stringify(advFilters));
              setDatesRange([new Date(temp.dateFrom), new Date(temp.dateTo)]);
              setShowDateModal(false);
            }}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
        </IonHeader>
        <IonContent>
          <IonItem className="no-border">
            <IonLabel>{lang.label208}</IonLabel>
            <IonText
              className="cancel-selection"
              onClick={() => {
                setDatesRange(null);
              }}
            >
              {lang.label423}
            </IonText>
          </IonItem>
          {showDates && (
            <IonItem className="no-border">
              <Calendar
                minDate={new Date()}
                selectRange={true}
                value={datesRange}
                onChange={(value: any) => {
                  setDatesRange(value);
                }}
                className="calendar-filter"
                locale={language === "cs" ? "cs-CZ" : "en-GB"}
              />
            </IonItem>
          )}
          <IonFooter>
            <IonToolbar>
              <IonButton
                className="filter-search-butt"
                onClick={() => {
                  counter.current = 0;
                  setFilters();
                  setShowLoading(true);
                  setShowDateModal(false);
                }}
              >
                {lang.label424}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showPersonsModal}
        cssClass="persons-filter"
        onDidDismiss={() => {
          if (counter.current % 2 == 1) {
            setShowPersons((state) => !state);
            setPersons(1);
          }
          let temp = JSON.parse(JSON.stringify(advFilters));
          setPersons(temp.persons);
          counter.current = 0;
          setShowPersonsModal(false);
        }}
      >
        <IonHeader className="main-header-search safe-zone-header">
          <IonButton
            onClick={() => {
              let temp = JSON.parse(JSON.stringify(advFilters));
              /* setDatesRange([new Date(temp.dateFrom), new Date(temp.dateTo)]); */
              setPersons(temp.persons);
              setShowPersonsModal(false);
            }}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
        </IonHeader>
        <IonContent>
          <IonItem className="no-border">
            <IonLabel>{lang.label287}</IonLabel>
            <IonText
              className="cancel-selection"
              onClick={() => {
                setPersons(1);
              }}
            >
              {lang.label423}
            </IonText>
          </IonItem>
          {showPersons && (
            <IonItem className="no-border" style={{ justifyContent: "center" }}>
              <IonSelect
                value={String(persons)}
                onIonChange={(e) => {
                  setPersons(Number(e.detail.value));
                  /* counter.current = counter.current + 1; */
                }}
                placeholder="1"
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                {freeCapacity.map((item, index) => {
                  return (
                    <IonSelectOption key={index} value={String(item)}>
                      {String(item)}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </IonItem>
          )}
          <IonFooter>
            <IonToolbar>
              <IonButton
                className="filter-search-butt"
                onClick={() => {
                  counter.current = 0;
                  setFilters();
                  setShowLoading(true);
                  setShowPersonsModal(false);
                }}
              >
                {lang.label424}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showPriceModal}
        cssClass="price-filter"
        onDidDismiss={() => {
          if (counter.current % 2 == 1) {
            setShowPrices((state) => !state);
          }
          counter.current = 0;
          setShowPriceModal(false);
        }}
      >
        <IonHeader className="main-header-search safe-zone-header">
          <IonButton
            onClick={() => {
              setShowPriceModal(false);
              let temp = JSON.parse(JSON.stringify(advFilters));
              setPricesRange({ lower: temp.priceFrom, upper: temp.priceTo });
            }}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
        </IonHeader>
        <IonContent>
          <IonItem className="no-border">
            <IonLabel>{lang.label207}</IonLabel>
            <IonText
              className="cancel-selection"
              onClick={() => {
                setPricesRange({ lower: minMax.min, upper: minMax.max });
              }}
            >
              {lang.label423}
            </IonText>
          </IonItem>
          {showPrices && (
            <IonGrid>
              <IonRow
                style={{
                  paddingLeft: "9px",
                  paddingRight: "9px",
                  fontSize: "14px",
                }}
              >
                <IonCol style={{ padding: "5px 0 0 0" }} size="2.9">
                  {minMax.min}
                  {" Kč"}
                </IonCol>
                <IonCol
                  style={{ padding: "5px 0 0 0", textAlign: "center" }}
                  size="2"
                ></IonCol>
                <IonCol
                  style={{ padding: "5px 0 0 0", textAlign: "center" }}
                  size="2.2"
                ></IonCol>
                <IonCol
                  style={{ padding: "5px 0 0 0", textAlign: "center" }}
                  size="2"
                ></IonCol>
                <IonCol
                  style={{
                    padding: "5px 0 0 0",
                    textAlign: "right",
                    fontSize: "14px",
                  }}
                  size="2.9"
                >
                  {minMax.max}
                  {" Kč"}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonRange
                  dualKnobs={true}
                  pin={true}
                  min={minMax.min}
                  max={minMax.max}
                  value={pricesRange}
                  debounce={200}
                  /* step={10}
                  ticks={true}
                  snaps={true} */ onIonFocus={() => setRangeActive(true)}
                  onIonBlur={() => setRangeActive(false)}
                  onIonChange={(e: any) => {
                    if (rangeActive) {
                      setPricesRange(e.detail.value as any);
                    }
                  }}
                />
              </IonRow>
              <IonRow style={{ justifyContent: "center" }}>
                <IonCol size="3.5">
                  <IonItem className="price-item item-input-grey">
                    <IonInput
                      className="input-prices"
                      style={{ textAlign: "center" }}
                      inputmode="numeric"
                      type="number"
                      value={pricesRange.lower}
                      onIonChange={(e) =>
                        setPricesRange((prevState) => ({
                          ...prevState,
                          lower: Number(e.detail.value),
                        }))
                      }
                    ></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol
                  size="1"
                  style={{ textAlign: "center", paddingTop: "15px" }}
                >
                  -
                </IonCol>
                <IonCol size="3.5">
                  <IonItem className="price-item item-input-grey">
                    <IonInput
                      className="input-prices"
                      style={{ textAlign: "center" }}
                      inputmode="numeric"
                      type="number"
                      value={pricesRange.upper}
                      onIonChange={(e) =>
                        setPricesRange((prevState) => ({
                          ...prevState,
                          upper: Number(e.detail.value),
                        }))
                      }
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
          <IonFooter>
            <IonToolbar>
              <IonButton
                className="filter-search-butt"
                onClick={() => {
                  counter.current = 0;
                  setFilters();
                  setShowLoading(true);
                  setShowPriceModal(false);
                }}
              >
                {lang.label424}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showTransportModal}
        cssClass={showTransits ? "" : "price-filter-short"}
        onDidDismiss={() => {
          if (counter.current % 2 == 1) {
            setShowTransits((state) => !state);
            setTransitsFilters([""]);
          }
          counter.current = 0;
          setShowTransportModal(false);
        }}
      >
        <IonHeader className="main-header-search safe-zone-header">
          <IonButton
            onClick={() => {
              let temp = JSON.parse(JSON.stringify(advFilters.transit));
              setTransitsFilters(temp);
              setShowTransportModal(false);
            }}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
        </IonHeader>
        <IonContent>
          <IonItem className="no-border">
            <IonLabel>{lang.label206}</IonLabel>
            <IonText
              className="cancel-selection"
              onClick={() => {
                setTransitsFilters([""]);
              }}
            >
              {lang.label423}
            </IonText>
            {/* <IonToggle
              checked={showTransits}
              onIonChange={(e) => {
                counter.current = counter.current + 1;
                setShowTransits(e.detail.checked);
              }}
              className="filter-toggle"
            /> */}
          </IonItem>
          {showTransits && (
            <IonList>
              {transitsCategories.map((item, i) => {
                return (
                  <IonItem
                    key={nanoid()}
                    style={{ marginRight: "20px", marginLeft: "20px" }}
                    className="filter-item"
                  >
                    <IonThumbnail>
                      <IonImg src={setIcon(item.transit)} />
                    </IonThumbnail>
                    <IonCheckbox
                      name="transit"
                      slot="end"
                      checked={transitsFilters.includes(item.transit)}
                      value={item.transit}
                      onIonChange={(e) => {
                        setCategoriesFilter(e);
                      }}
                    />
                    <IonLabel>{setText(item.transit)}</IonLabel>
                  </IonItem>
                );
              })}
            </IonList>
          )}
          <IonFooter>
            <IonToolbar>
              <IonButton
                className="filter-search-butt"
                onClick={() => {
                  counter.current = 0;
                  setFilters();
                  setShowLoading(true);
                  setShowTransportModal(false);
                }}
              >
                {lang.label424}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showAccomodationModal}
        cssClass={showAccomodation ? "" : "price-filter-short"}
        onDidDismiss={() => {
          if (counter.current % 2 == 1) {
            setShowAccomodation((state) => !state);
            setAccomodationsFilters([""]);
          }
          counter.current = 0;
          setShowAccomodationModal(false);
        }}
      >
        <IonHeader className="main-header-search safe-zone-header">
          <IonButton
            onClick={() => {
              let temp = JSON.parse(JSON.stringify(advFilters.accommodation));
              setAccomodationsFilters(temp);
              setShowAccomodationModal(false);
            }}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
        </IonHeader>
        <IonContent>
          <IonItem className="no-border">
            <IonLabel>{lang.label209}</IonLabel>
            <IonText
              className="cancel-selection"
              onClick={() => {
                setAccomodationsFilters([""]);
              }}
            >
              {lang.label423}
            </IonText>
          </IonItem>
          {showAccomodation && (
            <IonList>
              {accomodationsCategories.map((item, i) => {
                return (
                  <IonItem
                    key={nanoid()}
                    style={{ marginRight: "20px", marginLeft: "20px" }}
                    className="filter-item"
                  >
                    <IonThumbnail>
                      <IonImg src={setIcon(item.accommodation)} />
                    </IonThumbnail>
                    <IonCheckbox
                      name="accommodation"
                      slot="end"
                      checked={accomodationsFilters.includes(
                        item.accommodation
                      )}
                      value={item.accommodation}
                      onIonChange={(e) => {
                        setCategoriesFilter(e);
                      }}
                    />
                    <IonLabel>{setText(item.accommodation)}</IonLabel>
                  </IonItem>
                );
              })}
            </IonList>
          )}
          <IonFooter>
            <IonToolbar>
              <IonButton
                className="filter-search-butt"
                onClick={() => {
                  counter.current = 0;
                  setFilters();
                  setShowLoading(true);
                  setShowAccomodationModal(false);
                }}
              >
                {lang.label424}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showMealModal}
        cssClass={showMeals ? "" : "price-filter-short"}
        onDidDismiss={() => {
          if (counter.current % 2 == 1) {
            setShowMeals((state) => !state);
            setMealsFilters([""]);
          }
          counter.current = 0;
          setShowMealModal(false);
        }}
      >
        <IonHeader className="main-header-search safe-zone-header">
          <IonButton
            onClick={() => {
              let temp = JSON.parse(JSON.stringify(advFilters.meal));
              setMealsFilters(temp);
              setShowMealModal(false);
            }}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
        </IonHeader>
        <IonContent>
          <IonItem className="no-border">
            <IonLabel>{lang.label210}</IonLabel>
            <IonText
              className="cancel-selection"
              onClick={() => {
                setMealsFilters([""]);
              }}
            >
              {lang.label423}
            </IonText>
          </IonItem>
          {showMeals && (
            <IonList>
              {mealsCategories.map((item, i) => {
                return (
                  <IonItem
                    key={nanoid()}
                    style={{ marginRight: "20px", marginLeft: "20px" }}
                    className="filter-item"
                  >
                    <IonThumbnail>
                      <IonImg src={setIcon(item.meals + "m")} />
                    </IonThumbnail>
                    <IonCheckbox
                      name="meal"
                      slot="end"
                      checked={mealsFilters.includes(item.meals)}
                      value={item.meals}
                      onIonChange={(e) => {
                        setCategoriesFilter(e);
                      }}
                    />
                    <IonLabel>{setText(item.meals + "m")}</IonLabel>
                  </IonItem>
                );
              })}
            </IonList>
          )}
          <IonFooter>
            <IonToolbar>
              <IonButton
                className="filter-search-butt"
                onClick={() => {
                  counter.current = 0;
                  setFilters();
                  setShowLoading(true);
                  setShowMealModal(false);
                }}
              >
                {lang.label424}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showDifficultyModal}
        cssClass={"diff-filter"}
        onDidDismiss={() => {
          if (counter.current % 2 == 1) {
            setShowDifficulty((state) => !state);
          }
          counter.current = 0;
          setShowDifficultyModal(false);
        }}
      >
        <IonHeader className="main-header-search safe-zone-header">
          <IonButton
            onClick={() => {
              let temp = JSON.parse(JSON.stringify(advFilters.difficulty));
              setDiffRange(temp);
              setShowDifficultyModal(false);
            }}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
        </IonHeader>
        <IonContent>
          <IonItem className="no-border">
            <IonLabel>{lang.label211}</IonLabel>
            <IonText
              className="cancel-selection"
              onClick={() => {
                setDiffRange({ lower: 1, upper: 5 });
              }}
            >
              {lang.label423}
            </IonText>
          </IonItem>
          {showDifficulty && (
            <IonItem>
              <IonGrid>
                <IonRow style={{ paddingLeft: "9px", paddingRight: "9px" }}>
                  <IonCol style={{ padding: "5px 0 0 0" }} size="1.2">
                    1
                  </IonCol>
                  <IonCol
                    style={{ padding: "5px 0 0 0", textAlign: "center" }}
                    size="3.7"
                  >
                    2
                  </IonCol>
                  <IonCol
                    style={{ padding: "5px 0 0 0", textAlign: "center" }}
                    size="2.2"
                  >
                    3
                  </IonCol>
                  <IonCol
                    style={{ padding: "5px 0 0 0", textAlign: "center" }}
                    size="3.7"
                  >
                    4
                  </IonCol>
                  <IonCol
                    style={{ padding: "5px 0 0 0", textAlign: "right" }}
                    size="1.2"
                  >
                    5
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonRange
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    value={diffRange}
                    dualKnobs={true}
                    min={1}
                    max={5}
                    step={1}
                    ticks={true}
                    snaps={true}
                    onIonChange={(e) => {
                      setDiffActive(true);
                      if (diffActive) {
                        setDiffRange(e.detail.value as any);
                        setDiffActive(false);
                      }
                    }}
                  />
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText>{lang.label212}</IonText>
                  </IonCol>
                  <IonCol style={{ textAlign: "right" }}>
                    <IonText>{lang.label213}</IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          )}
          <IonFooter>
            <IonToolbar>
              <IonButton
                className="filter-search-butt"
                onClick={() => {
                  counter.current = 0;
                  setFilters();
                  setShowLoading(true);
                  setShowDifficultyModal(false);
                }}
              >
                {lang.label424}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </IonContent>
      </IonModal>

      <IonLoading
        cssClass="loading-spinner"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={lang.label222}
      />
    </IonPage>
  );
};

export default Adventures;
