import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonButton,
  IonGrid,
  IonLabel,
  IonModal,
  IonRouterOutlet,
  IonRow,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonText,
  isPlatform,
  setupConfig,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Tab1 from "./pages/Tab1";
/* import Tab2 from "./pages/Tab2";
import Tab3 from "./pages/Tab3";
import Tab4 from "./pages/Tab4"; */
import Adventures from "./pages/Adventures";
import Menu from "./components/Menu";
import Adventure from "./pages/Adventure";
import Reservations from "./pages/Reservations";

/* import { SplashScreen } from "@capacitor/splash-screen"; */

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
/* import "@ionic/react/css/text-transformation.css"; */
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import "./global.css";

import AuthProvider from "./my-context";
import { LoginPage } from "./pages/LoginPage";

import React, { useEffect, useRef, useState } from "react";

import {
  GlobeHemisphereEast,
  MonitorPlay,
  Newspaper,
  Users,
} from "phosphor-react";
import Userdetails from "./pages/Userdetails";
import Create from "./pages/Create";
import CreateTrip from "./pages/Create/CreateTrip";
import TripSetup from "./pages/Create/TripSetup";
import CreateTrip2 from "./pages/Create/CreateTrip2";
import Changepassword from "./pages/changepassword";
import CreateTrip3 from "./pages/Create/CreateTrip3";
import Contact from "./pages/contact";
import Personaldataprotection from "./pages/personaldataprotection";
import Changelanguage from "./pages/changelanguage";
import Mypage from "./pages/mypage";
import CreateTrip4 from "./pages/Create/CreateTrip4";
import CreateTrip5 from "./pages/Create/CreateTrip5";
import BecomeAGuide from "./pages/BeGuide/becomeAguide";
import MyAdventures from "./pages/MyAdventures";
import Travellist from "./pages/Travellist";
import MyAdventure from "./pages/MyAdventure";
import DateReservations from "./pages/DateReservations";
import Reservation from "./pages/Reservation";
import { ScreenOrientation } from "@ionic-native/screen-orientation/";
import { useGlobal } from "reactn";
import Userpage from "./pages/userpage";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Device } from "@capacitor/device";
import { getPlatforms } from "@ionic/react";
import Termsandconditions from "./pages/termsandconditions";
import Becomeatraveler from "./pages/becomeatraveler";
import Becomeaguidestatic from "./pages/becomeaguidestatic";
import FooterPage from "./pages/FooterPage";

const App: React.FC = () => {
  const isFirstRender = useRef(true);
  const mark = useRef(0);

  /*   const showTabBar = useRef(false);
   */
  const { authValues, lang, logout, initialized, showTabBars } =
    React.useContext(AuthProvider);

  const [, setOritation] = useState<string>("");

  const [showGuestModal, setShowGuestModal] = useGlobal("showGuestModal");
  const [showLoginModal, setShowLoginModal] = useGlobal("showLoginModal");

  const [, setShowLoginPage] = useGlobal("showLoginPage");
  const [, setShowCreateAcc] = useGlobal("showCreateAcc");
  const [deviceInfo, setDeviceInfo] = useGlobal("deviceInfo");
  const [platformInfo, setPlatformInfo] = useGlobal("platformInfo");
  const [appWidth, setAppWidth] = useGlobal("appWidth");
  /* const [showModal2, setShowModal2] = useGlobal("showLoginPage"); */

  /*   const [appUrl] = useGlobal("appUrl");
   */
  /* useEffect(() => {
    if (appUrl === "/tab1" || appUrl === "/adventures") {
      showTabBar.current = true;
    } else showTabBar.current = false;
  }, [appUrl]); */

  setupConfig({ mode: "md" });

  useEffect(() => {
    if (isPlatform("capacitor")) {
      setOritation(ScreenOrientation.type);
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
      setOritation(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }
    const logDeviceInfo = async () => {
      const info = await Device.getInfo();
      setDeviceInfo(info);
    };
    logDeviceInfo();
    setPlatformInfo(JSON.stringify(getPlatforms()));
    setAppWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setAppWidth(window.innerWidth);
    });
    /* window.addEventListener("popstate", () => {
      console.log(window.innerWidth);
    }); */
    /* window.addEventListener("onhashchange", () => {
      console.log(window.location.pathname);
      console.log(window.location);
      setAppUrl(window.location.pathname);
    }); */
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (deviceInfo.platform !== "web") {
        const setStatusBarStyleDark = async () => {
          if (deviceInfo.platform === "android") {
            await StatusBar.setBackgroundColor({ color: "#000000" });
            await StatusBar.setStyle({ style: Style.Dark });
          } else {
            await StatusBar.setStyle({ style: Style.Light });
          }
        };
        setStatusBarStyleDark();
      }
    }
  }, [deviceInfo]);

  useEffect(() => {
    if (showLoginModal) {
      if (mark.current === 1) {
        setShowLoginPage(true);
      } else if (mark.current === 2) {
        setShowCreateAcc(true);
      }
    }
  }, [showLoginModal]);

  return (
    <IonApp style={{ lineHeight: "1.5" }}>
      {authValues.authenticated ? (
        <IonReactRouter>
          <Route exact path="/login">
            <Redirect to="/tab1" />
          </Route>
          <IonRouterOutlet animated={false}>
            <IonSplitPane when="none" contentId="main">
              <IonRouterOutlet id="main" animated={false}>
                <IonTabs>
                  <IonRouterOutlet id="main" animated={false}>
                    <Route exact path="/tab1">
                      <Tab1 />
                    </Route>
                    <Route exact path="/adventures">
                      <Adventures />
                    </Route>
                    <Route exact path="/">
                      <Redirect to="/tab1" />
                    </Route>
                    <Route
                      exact
                      path="/adventures/:adventureId"
                      component={Adventure}
                    />

                    <Route
                      exact
                      path="/myadventures/:adventureId"
                      component={MyAdventure}
                    />

                    <Route
                      exact
                      path="/myadventures/:adventureId/:dateId"
                      component={DateReservations}
                    />

                    <Route exact path="/userdetails">
                      <Userdetails />
                    </Route>

                    <Route exact path="/becomeatraveler">
                      <Becomeatraveler />
                    </Route>

                    <Route exact path="/becomeaguide">
                      <BecomeAGuide />
                    </Route>

                    <Route exact path="/becaguide">
                      <Becomeaguidestatic />
                    </Route>

                    <Route exact path="/create">
                      <Create />
                    </Route>

                    <Route exact path="/create/trip/0">
                      <TripSetup />
                    </Route>

                    <Route exact path="/create/trip/1">
                      <CreateTrip />
                    </Route>

                    <Route exact path="/create/trip/2">
                      <CreateTrip2 />
                    </Route>

                    <Route exact path="/create/trip/3">
                      <CreateTrip3 />
                    </Route>

                    <Route exact path="/create/trip/4">
                      <CreateTrip4 />
                    </Route>

                    <Route exact path="/create/trip/5">
                      <CreateTrip5 />
                    </Route>

                    <Route exact path="/reservations">
                      <Reservations />
                    </Route>

                    <Route
                      exact
                      path="/reservations/:reservationId/"
                      component={Reservation}
                    />

                    <Route exact path="/password">
                      <Changepassword />
                    </Route>

                    <Route exact path="/contact">
                      <Contact />
                    </Route>

                    <Route exact path="/myadventures">
                      <MyAdventures />
                    </Route>

                    <Route exact path="/travellist">
                      <Travellist />
                    </Route>

                    <Route exact path="/personaldataprotection">
                      <Personaldataprotection />
                    </Route>

                    <Route exact path="/termsandconditions">
                      <Termsandconditions />
                    </Route>

                    <Route exact path="/language">
                      <Changelanguage />
                    </Route>

                    <Route exact path="/mypage">
                      <Mypage />
                    </Route>

                    <Route
                      exact
                      path="/userpage/:userId/"
                      component={Userpage}
                    />
                  </IonRouterOutlet>
                  <IonTabBar
                    slot="bottom"
                    className="tab-bar"
                    style={
                      /* showTabBars
                        ? */ {
                        display: "contents",
                      }
                      /* : { display: "none" } */
                    }
                  >
                    <IonTabButton
                      tab="tab1"
                      href="/tab1"
                      className="tab-button safe-zone"
                    >
                      <GlobeHemisphereEast size={30} />
                      <IonLabel>{lang.label21}</IonLabel>
                    </IonTabButton>
                  </IonTabBar>
                </IonTabs>
              </IonRouterOutlet>
              <Menu />
              {/* <FooterPage /> */}
            </IonSplitPane>
            {/* {appWidth < 720 && variableHeader()} */}
          </IonRouterOutlet>
        </IonReactRouter>
      ) : (
        <IonReactRouter>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          {!authValues.authetication && initialized && (
            <Route exact path="/*">
              <Redirect to="/login" />
            </Route>
          )}
        </IonReactRouter>
      )}
      <IonModal
        isOpen={showGuestModal}
        cssClass="guest-modal"
        onDidDismiss={() => setShowGuestModal(false)}
      >
        <div style={{ height: "100%" }}>
          <IonText style={{ display: "block", marginTop: "10px" }}>
            {lang.label339}!
            <br />
          </IonText>
          <IonGrid style={{ marginBottom: "15px" }}>
            <IonRow style={{ justifyContent: "center" }}>
              <IonButton
                className="sign-up-butt2"
                onClick={() => {
                  if (appWidth > 920) {
                    mark.current = 1;
                    setShowLoginModal(true);
                    /* setShowLoginPage(true); */
                    setShowGuestModal(false);
                    /* setShowLoginPage(true);*/
                  } else {
                    setShowLoginPage(true);
                    setShowGuestModal(false);
                    logout();
                  }
                }}
                style={{
                  marginLeft: 0,
                  marginRight: "8px",
                  marginBottom: "25px",
                }}
              >
                {lang.label160}
              </IonButton>
              <IonButton
                className="sign-up-butt2"
                onClick={() => {
                  if (appWidth > 920) {
                    mark.current = 2;
                    setShowLoginModal(true);
                    setShowGuestModal(false);
                  } else {
                    setShowCreateAcc(true);
                    setShowGuestModal(false);
                    logout();
                  }
                }}
                style={{
                  marginLeft: "8px",
                  marginRight: 0,
                  marginBottom: "25px",
                }}
              >
                {lang.label161}
              </IonButton>
            </IonRow>
          </IonGrid>
        </div>
      </IonModal>
      <IonModal
        isOpen={showLoginModal}
        cssClass="login-modal"
        /* style={{zIndex:0}} */
        onDidDismiss={() => setShowLoginModal(false)}
      >
        <LoginPage />
      </IonModal>
    </IonApp>
  );
};

export default App;
