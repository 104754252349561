import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonItem,
  IonButtons,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonCol,
  IonGrid,
  IonRow,
  IonInput,
  IonToast,
} from "@ionic/react";
import "../Tab1.css";

import "../create.css";

import AuthContext from "../../my-context";
import React, { useState, useEffect, useGlobal } from "reactn";

import { ArrowLeft } from "phosphor-react";
import { useHistory } from "react-router";
import Header from "../header";

const CreateTrip3: React.FC = () => {
  const { lang, saveNewAdventure } = React.useContext(AuthContext);

  const history = useHistory();

  const [newAdventure, setNewAdventure] = useGlobal("newAdventure");
  const [newAdventureLang] = useGlobal("newAdventureLang");
  const [newAdventureLangCurrent, setNewAdventureLangCurrent] = useGlobal(
    "newAdventureLangCurrent"
  );
  const [appWidth] = useGlobal("appWidth");

  useEffect(() => {
    const timer = setInterval(() => {
      saveNewAdventure();
    }, 120000);
    // clearing interval
    return () => clearInterval(timer);
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [showToast2, setShowToast2] = useState(false);
  const [toastMessage2, setToastMessage2] = useState("");

  const handleInputChange = (event: any) => {
    var helpx = { ...newAdventure };
    switch (event.target.name) {
      case "desc":
        helpx.descriptionText[newAdventureLangCurrent] = event.target.value;
        break;
      case "meals":
        helpx.meals = event.target.value;
        break;
      case "mealstext":
        helpx.mealsText[newAdventureLangCurrent] = event.target.value;
        break;
      case "accommodation":
        helpx.accommodation = event.target.value;
        break;
      case "accommodationText":
        helpx.accommodationText[newAdventureLangCurrent] = event.target.value;
        break;
      case "transit":
        helpx.transit = event.target.value;
        if (event.target.value === "AIRPLANE") {
          helpx.departure.type = "AIRPORT";
          helpx.departure.city = null;
          helpx.departure.address = null;
          helpx.arrival.type = "AIRPORT";
          helpx.arrival.city = null;
        } else {
          helpx.departure.type = "ADDRESS";
          helpx.departure.airport = null;
          helpx.arrival.type = "ADDRESS";
          helpx.arrival.airport = null;
        }
        break;
      case "transitText":
        helpx.transitText[newAdventureLangCurrent] = event.target.value;
        break;
      case "arrival":
        if (newAdventure.transit === "AIRPLANE") {
          helpx.arrival.airport = event.target.value;
        } else {
          helpx.arrival.city = event.target.value;
        }
        break;
      case "departure":
        if (newAdventure.transit === "AIRPLANE") {
          helpx.departure.airport = event.target.value;
        } else {
          helpx.departure.city = event.target.value;
        }
        break;
      case "difficulty":
        helpx.difficulty = Number(event.target.value);
        break;
      case "difficultyText":
        helpx.difficultyText[newAdventureLangCurrent] = event.target.value;
        break;
      case "includedText":
        helpx.includedText[newAdventureLangCurrent] = event.target.value;
        break;
      case "notIncludedText":
        helpx.notIncludedText[newAdventureLangCurrent] = event.target.value;
        break;
      case "moreInfoText":
        helpx.moreInfoText[newAdventureLangCurrent] = event.target.value;
        break;
      default:
        break;
    }
    setNewAdventure(helpx);
  };

  return (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={async () => {
                await saveNewAdventure();
                history.goBack();
              }}
              className="modal-search-back"
            >
              <ArrowLeft size={24} color="black" />
              <IonLabel className="page-title">{lang.label395}</IonLabel>
            </IonButton>
          </IonButtons>
          {newAdventureLang.length > 1 && (
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setNewAdventureLangCurrent(
                    newAdventureLangCurrent === "CS" ? "EN" : "CS"
                  );
                  setToastMessage2(
                    newAdventureLangCurrent === "CS"
                      ? lang.label426
                      : lang.label425
                  );
                  setShowToast2(true);
                }}
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                  width: "50px",
                }}
              >
                {newAdventureLangCurrent === "CS" ? "CZ" : "ENG"}
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="web-box">
          <IonLabel
            style={{ display: "block", marginTop: "5px" }}
            className="user-info-label"
          >
            {lang.label396}
            {" *"}
          </IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonTextarea
              value={newAdventure.descriptionText[newAdventureLangCurrent]}
              name="desc"
              autoGrow={true}
              placeholder={lang.label397}
              inputmode="text"
              minlength={100}
              maxlength={500}
              onIonChange={(event) => {
                handleInputChange(event);
              }}
              className="contact-input"
              style={{ height: "auto", fontSize: "14px" }}
            ></IonTextarea>
          </IonItem>

          <IonLabel className="user-info-label">
            {lang.label210}
            {" *"}
          </IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonSelect
              value={newAdventure.meals}
              name="meals"
              okText={lang.label167}
              cancelText={lang.label166}
              placeholder={lang.label104}
              onIonChange={(e) => handleInputChange(e)}
              className="user-info-input"
            >
              <IonSelectOption value="OWN">{lang.label199}</IonSelectOption>
              <IonSelectOption value="BREAKFAST">
                {lang.label219}
              </IonSelectOption>
              <IonSelectOption value="BREAKFAST_AND_DINNER">
                {lang.label220}
              </IonSelectOption>
              <IonSelectOption value="ALL_INCLUSIVE">
                {lang.label221}
              </IonSelectOption>
            </IonSelect>
          </IonItem>

          <IonItem className="user-info-item item-input-grey">
            <IonTextarea
              value={newAdventure.mealsText[newAdventureLangCurrent]}
              name="mealstext"
              autoGrow={true}
              placeholder={lang.label398}
              inputmode="text"
              maxlength={1000}
              onIonChange={(event) => handleInputChange(event)}
              className="create-info-input"
              style={{ height: "auto", fontSize: "14px" }}
            ></IonTextarea>
          </IonItem>

          <IonLabel className="user-info-label">
            {lang.label209}
            {" *"}
          </IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonSelect
              value={newAdventure.accommodation}
              name="accommodation"
              okText={lang.label167}
              placeholder={lang.label104}
              cancelText={lang.label166}
              onIonChange={(e) => handleInputChange(e)}
              className="user-info-input"
            >
              <IonSelectOption value="HOTEL">{lang.label214}</IonSelectOption>
              <IonSelectOption value="HOUSE">{lang.label215}</IonSelectOption>
              <IonSelectOption value="HOSTEL">{lang.label216}</IonSelectOption>
              <IonSelectOption value="CAMPING">{lang.label217}</IonSelectOption>
              <IonSelectOption value="BIVOUACKING">
                {lang.label218}
              </IonSelectOption>
              <IonSelectOption value="BOAT">{lang.label205}</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem className="user-info-item item-input-grey">
            <IonTextarea
              value={newAdventure.accommodationText[newAdventureLangCurrent]}
              name="accommodationText"
              autoGrow={true}
              placeholder={lang.label399}
              inputmode="text"
              maxlength={1000}
              onIonChange={(event) => handleInputChange(event)}
              className="create-info-input"
              style={{ height: "auto", fontSize: "14px" }}
            ></IonTextarea>
          </IonItem>

          <IonLabel className="user-info-label">
            {lang.label206}
            {" *"}
          </IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonSelect
              value={newAdventure.transit}
              name="transit"
              okText={lang.label167}
              placeholder={lang.label104}
              cancelText={lang.label166}
              onIonChange={(e) => handleInputChange(e)}
              className="user-info-input"
            >
              <IonSelectOption value="OWN">{lang.label199}</IonSelectOption>
              <IonSelectOption value="VAN">{lang.label200}</IonSelectOption>
              <IonSelectOption value="BUS">{lang.label201}</IonSelectOption>
              <IonSelectOption value="CAR">{lang.label202}</IonSelectOption>
              <IonSelectOption value="AIRPLANE">
                {lang.label203}
              </IonSelectOption>
              <IonSelectOption value="TRAIN">{lang.label204}</IonSelectOption>
              <IonSelectOption value="BOAT">{lang.label205}</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem className="user-info-item item-input-grey">
            <IonTextarea
              value={newAdventure.transitText[newAdventureLangCurrent]}
              name="transitText"
              autoGrow={true}
              placeholder={lang.label400}
              inputmode="text"
              maxlength={1000}
              onIonChange={(event) => handleInputChange(event)}
              className="create-info-input"
              style={{ height: "auto", fontSize: "14px" }}
            ></IonTextarea>
          </IonItem>

          <IonItem className="no-border">
            <IonGrid>
              <IonRow style={{ marginLeft: "10px", marginRight: "10px" }}>
                <IonCol>
                  <IonLabel
                    className="user-info-label"
                    style={{ display: "contents" }}
                  >
                    {newAdventure.departure.type === "AIRPORT"
                      ? lang.label129
                      : lang.label128}
                    {" *"}
                  </IonLabel>
                  <IonItem
                    className="user-info-item item-input-grey"
                    style={{
                      marginLeft: "0px",
                      marginRight: "0px",
                      fontSize: "14px",
                    }}
                  >
                    <IonInput
                      value={
                        newAdventure.departure.type === "AIRPORT"
                          ? newAdventure.departure.airport
                          : newAdventure.departure.city
                      }
                      disabled={!newAdventure.transit}
                      name="departure"
                      placeholder={lang.label401}
                      inputmode="text"
                      maxlength={50}
                      onIonChange={(event) => handleInputChange(event)}
                      className="user-info-input"
                    ></IonInput>
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonLabel
                    className="user-info-label"
                    style={{ display: "contents" }}
                  >
                    {newAdventure.arrival.type === "AIRPORT"
                      ? lang.label131
                      : lang.label130}
                    {" *"}
                  </IonLabel>
                  <IonItem
                    className="user-info-item item-input-grey"
                    style={{ marginLeft: "0px", marginRight: "0px" }}
                  >
                    <IonInput
                      style={{ width: "100%", fontSize: "14px" }}
                      value={
                        newAdventure.arrival.type === "AIRPORT"
                          ? newAdventure.arrival.airport
                          : newAdventure.arrival.city
                      }
                      disabled={!newAdventure.transit}
                      name="arrival"
                      placeholder={lang.label402}
                      inputmode="text"
                      maxlength={50}
                      onIonChange={(event) => handleInputChange(event)}
                      className="user-info-input"
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonLabel className="user-info-label">
            {lang.label211}
            {" *"}
          </IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonSelect
              value={String(newAdventure.difficulty)}
              name="difficulty"
              placeholder={lang.label104}
              okText={lang.label167}
              cancelText={lang.label166}
              onIonChange={(e) => handleInputChange(e)}
              className="user-info-input"
            >
              <IonSelectOption value="1">1 - {lang.label242}</IonSelectOption>
              <IonSelectOption value="2">2 - {lang.label243}</IonSelectOption>
              <IonSelectOption value="3">3 - {lang.label244}</IonSelectOption>
              <IonSelectOption value="4">4 - {lang.label245}</IonSelectOption>
              <IonSelectOption value="5">5 - {lang.label246}</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem className="user-info-item item-input-grey">
            <IonTextarea
              value={newAdventure.difficultyText[newAdventureLangCurrent]}
              name="difficultyText"
              autoGrow={true}
              placeholder={lang.label403}
              inputmode="text"
              maxlength={1000}
              onIonChange={(event) => handleInputChange(event)}
              style={{ height: "auto", fontSize: "14px" }}
              className="create-info-input"
            ></IonTextarea>
          </IonItem>

          <IonLabel className="user-info-label">{lang.label247}</IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonTextarea
              value={newAdventure.includedText[newAdventureLangCurrent]}
              name="includedText"
              autoGrow={true}
              placeholder={lang.label404}
              inputmode="text"
              maxlength={1000}
              onIonChange={(event) => handleInputChange(event)}
              style={{ height: "auto", fontSize: "14px" }}
              className="create-info-input"
            ></IonTextarea>
          </IonItem>
          <IonLabel className="user-info-label">{lang.label248}</IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonTextarea
              value={newAdventure.notIncludedText[newAdventureLangCurrent]}
              name="notIncludedText"
              autoGrow={true}
              placeholder={lang.label405}
              inputmode="text"
              maxlength={1000}
              onIonChange={(event) => handleInputChange(event)}
              style={{ height: "auto", fontSize: "14px" }}
              className="create-info-input"
            ></IonTextarea>
          </IonItem>

          <IonLabel className="user-info-label">{lang.label249}</IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonTextarea
              value={newAdventure.moreInfoText[newAdventureLangCurrent]}
              name="moreInfoText"
              autoGrow={true}
              placeholder={lang.label406}
              inputmode="text"
              maxlength={1000}
              onIonChange={(event) => handleInputChange(event)}
              style={{ height: "auto !important", fontSize: "14px" }}
              className="create-info-input"
            ></IonTextarea>
          </IonItem>
        </div>
      </IonContent>
      <IonToolbar slot="bottom" className="text-center safe-zone web-box">
        <IonButton
          className="footer-button"
          onClick={async () => {
            let check1 = false;
            let check2 = false;
            if (newAdventureLang.length > 1) {
              if (
                newAdventure.descriptionText?.CS === "" ||
                newAdventure.descriptionText?.CS === null ||
                newAdventure.descriptionText?.CS?.length < 100 ||
                newAdventure.descriptionText?.EN === "" ||
                newAdventure.descriptionText?.EN === null ||
                newAdventure.descriptionText?.EN?.length < 100
              ) {
                check1 = true;
              } else if (
                newAdventure.descriptionText?.CS?.length > 500 ||
                newAdventure.descriptionText?.EN?.length > 500
              ) {
                check2 = true;
              }
            } else {
              if (
                newAdventure.descriptionText[newAdventureLangCurrent] === "" ||
                newAdventure.descriptionText[newAdventureLangCurrent] ===
                  null ||
                newAdventure.descriptionText[newAdventureLangCurrent] < 100
              ) {
                check1 = true;
              } else if (
                newAdventure.descriptionText[newAdventureLangCurrent] > 500
              ) {
                check2 = true;
              }
            }

            if (check1) {
              setToastMessage(lang.label427);
              setShowToast(true);
            } else if (check2) {
              setToastMessage(lang.label428);
              setShowToast(true);
            } else if (
              newAdventure.meals === "" ||
              newAdventure.accommodation === "" ||
              newAdventure.transit === "" ||
              (newAdventure.departure.type === "AIRPORT"
                ? newAdventure.departure.airport === "" ||
                  newAdventure.departure.airport === null
                : newAdventure.departure.city === "" ||
                  newAdventure.departure.city === null) ||
              (newAdventure.arrival.type === "AIRPORT"
                ? newAdventure.arrival.airport === "" ||
                  newAdventure.arrival.airport === null
                : newAdventure.arrival.city === "" ||
                  newAdventure.arrival.city === null) ||
              newAdventure.difficulty === 0
            ) {
              setToastMessage(lang.label233);
              setShowToast(true);
            } else {
              await saveNewAdventure();
              history.push("/create/trip/4");
            }
          }}
        >
          <IonLabel style={{ textTransform: "initial" }} color="white">
            {lang.label407}
          </IonLabel>
        </IonButton>
      </IonToolbar>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        color="danger"
        cssClass="toast-pass-change"
      />
      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message={toastMessage2}
        duration={2000}
        color="success"
        cssClass="toast-pass-change"
      />
    </IonPage>
  );
};

export default CreateTrip3;
