import {
  IonPage,
  IonHeader,
  IonBackButton,
  IonButtons,
  IonToolbar,
  IonContent,
  IonText,
  IonButton,
  IonItem,
  IonInput,
  IonTextarea,
  IonToast,
} from "@ionic/react";

import "./Tab1.css";
import "./contact.css";

import axios from "axios";

import AuthContext from "../my-context";
import React, { useState } from "react";
import Header from "./header";
import { useGlobal } from "reactn";

const Contact: React.FC = () => {
  const { token, lang } = React.useContext(AuthContext);

  const [headline, setHeadline] = useState("");
  const [message, setMessage] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [appWidth] = useGlobal("appWidth");

  async function sendMail() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .post(
        "/contact",
        {
          subject: headline,
          message: message,
        },
        config
      )
      .then((res) => {
        setToastColor("success");
        setToastMessage("Zpráva odeslána.");
        setMessage("");
        setHeadline("");
        setShowToast(true);
      })
      .catch((err) => {
        setToastColor("danger");
        setToastMessage("Zpráva nebyla doručena. Zkontrolujte přípojení.");
        setShowToast(true);
        console.log(err.data);
      });
  }

  const handleInputChange = (event: any) => {
    switch (event.target.name) {
      case "headline":
        setHeadline(event.target.value);
        break;
      case "message":
        setMessage(event.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <IonPage id="main">
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar /* className="back-butt" */>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tab1"
              text={lang.label45}
              className="back-button"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="web-box">
          <IonItem
            style={{ padding: "0px 20px 0px 20px" }}
            className="contact-text"
          >
            <IonText>
              <p>{lang.label169}</p>
              <p>{lang.label170}</p>
              <p>{lang.label171}</p>
            </IonText>
          </IonItem>
          <IonItem className="contact-item item-input-grey">
            <IonInput
              value={headline}
              name="headline"
              placeholder={lang.label172}
              inputmode="text"
              maxlength={25}
              onIonChange={(event) => handleInputChange(event)}
              className="contact-input"
            ></IonInput>
          </IonItem>
          <IonItem
            className="contact-item item-input-grey"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <IonTextarea
              value={message}
              name="message"
              autoGrow={true}
              placeholder={lang.label105}
              inputmode="text"
              maxlength={1000}
              onIonChange={(event) => handleInputChange(event)}
              className="contact-input"
              style={{ height: "auto" }}
            ></IonTextarea>
          </IonItem>
        </div>
      </IonContent>
      <IonToolbar slot="bottom" className="safe-zone web-box">
        <IonButton
          onClick={() => {
            if (headline.length === 0 && message.length === 0) {
              setToastColor("danger");
              setToastMessage(lang.label174);
              setShowToast(true);
            } else if (message.length === 0) {
              setToastColor("danger");
              setToastMessage(lang.label175);
              setShowToast(true);
            } else if (headline.length === 0) {
              setToastColor("danger");
              setToastMessage(lang.label176);
              setShowToast(true);
            } else {
              sendMail();
            }
          }}
          className="send-butt"
        >
          {lang.label173}
        </IonButton>
      </IonToolbar>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={1200}
        color={toastColor}
        cssClass="toast-pass-change"
      />
    </IonPage>
  );
};

export default Contact;
