import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonFooter,
  IonImg,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonBackButton,
  IonModal,
  IonButton,
  useIonLoading,
  IonSpinner,
  IonText,
} from "@ionic/react";

import "./Tab1.css";

import "./adventure.css";
import "./mypage.css";

import { Camera, CameraDirection, CameraResultType } from "@capacitor/camera";

import axios from "axios";

import AuthContext from "../my-context";
import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router";

import { useEffect, useGlobal } from "reactn";

import MyStory from "./MyPage/MyStory";
import MyTravel from "./MyPage/MyTravel";

import dumb_photo_guide_cover from "../assets/images/dumb_photo_guide_cover.jpg";

import { ArrowLeft, ChatsTeardrop, DotsThreeCircle } from "phosphor-react";
import getCroppedImg from "../components/cropImage";
import Cropper from "react-easy-crop";
import showImage from "../functions/showImage";
import Header from "./header";

const Mypage: React.FC = () => {
  const { token, authValues, setAuthValues, lang, db, checkImageCache } =
    React.useContext(AuthContext);

  const [language] = useGlobal("language");
  const [appWidth] = useGlobal("appWidth");

  const [present] = useIonLoading();
  const isFirstRender = useRef(true);

  const [showLoading, setShowLoading] = useState(true);

  const [newCoverImage, setNewCoverImage] = useState<string | undefined>("");

  const [languages, setLanguages] = useState("");

  const [croppedImage, setCroppedImage] = useState<null | string>(null);
  const [cameraImage, setCameraImage] = useState<string>();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any | null>(null);

  const [editModal, setEditModal] = useState(false);

  const [myStoryText, setMyStoryText] = useState({ CS: "" });

  const [isStoryEdit, setIsStoryEdit] = useState(false);

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  async function uploadImage(img: string) {
    let data = {
      type: "IMAGE",
      data: img,
      name: "test",
    };
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .post("/media", data, config)
      .then(async (res) => {
        let authValuesTemp = { ...authValues };
        authValuesTemp.user.coverImage = res.data.id;
        await db.set(res.data.id, img);
        await db.set("authValues", authValuesTemp);
        setNewCoverImage(img);
        setAuthValues(authValuesTemp);
        setShowCropModal(false);
        ulozUserData(res.data.id);
        setShowLoading(false);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
        setShowLoading(false);
      });
  }

  async function ulozUserData(id: string) {
    /* present("Ukládám", 500, "dots"); */
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .patch(
        "/user",
        {
          coverImage: id,
        },
        config
      )
      .then()
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  const [showCropModal, setShowCropModal] = useState(false);
  const [showSlow, setShowSlow] = useState(false);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        cameraImage,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
    } catch (e) {
      setShowLoading(false);
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const handlePictureClick = async () => {
    const image = await takePicture();
    setCameraImage(image);
  };

  useEffect(() => {
    let langJSON = require("../assets/language/languages.json");
    let langKeys = Object.keys(authValues.user.languages);
    langKeys.forEach((key, index) => {
      langKeys[index] = langJSON[0][key][language];
    });

    let tempText = "";
    for (var i = 0; i < langKeys.length; i++) {
      if (i === 0) {
        tempText = langKeys[i];
      } else {
        tempText = tempText + ", " + langKeys[i];
      }
    }
    setLanguages(tempText);
    setMyStoryText(authValues.user.myStoryText);
  }, []);

  useEffect(() => {
    async function getImageData() {
      let dataTemp = "";

      if (await checkImageCache(authValues.user.coverImage)) {
        dataTemp = await db.get(authValues.user.coverImage);
      } else {
        if (
          authValues.user.coverImage !== "" &&
          authValues.user.coverImage !== null
        ) {
          dataTemp = await showImage(authValues.user.coverImage, token);
          await db.set(authValues.user.coverImage, dataTemp);
        } else dataTemp = "";
      }
      setNewCoverImage(dataTemp);
    }
    getImageData().then(() => setShowLoading(false));
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      setShowCropModal(true);
      setTimeout(() => setShowSlow(true), 500);
    }
    isFirstRender.current = false;
  }, [cameraImage]);

  useEffect(() => {
    if (croppedImage !== null) {
      uploadImage(croppedImage);
    } else {
      setShowLoading(false);
    }
  }, [croppedImage]);

  const [segment, setSegment] = useState<string | undefined>("default");

  const handleInputChange = async (event: any) => {
    var helpx = { ...myStoryText };

    switch (event.target.name) {
      case "myStoryText":
        helpx.CS = event.target.value;
        break;
    }
    setMyStoryText(helpx);
  };

  const handleButton = async (mark: number) => {
    if (mark === 0) {
      setMyStoryText(authValues.user.myStoryText);
      setIsStoryEdit(false);
    } else {
      let authValuesTemp = { ...authValues };
      authValuesTemp.user.myStoryText = myStoryText;
      const api = axios.create({
        baseURL: `https://app.kangelo.com/`,
      });
      var config = {
        headers: {
          "X-Auth-Token": token,
        },
      };
      api
        .patch("/user", authValuesTemp.user, config)
        .then(async () => {
          await db.set("authValues", authValuesTemp);
          setAuthValues(authValuesTemp);
          setIsStoryEdit(false);
        })
        .catch((err) => {
          // what now?
          console.log(err.data);
        });
    }
  };

  function renderSwitch(segment: any) {
    switch (segment) {
      case "travelogue":
        return <MyTravel />;

      default:
        return (
          <MyStory
            myStoryText={myStoryText}
            handleInputChange={handleInputChange}
            isDisabled={isStoryEdit}
            handleButton={handleButton}
          />
        );
    }
  }

  return showLoading ? (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="back-butt web-box">
        <IonToolbar className="back-butt">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tab1"
              className="back-button"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSpinner
          className="spinner-loading"
          color="primary"
          style={{ marginTop: "10vh" }}
        />
        <IonText className="text-center" style={{ display: "block" }}>
          {lang.label222}
        </IonText>
      </IonContent>
    </IonPage>
  ) : (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonContent /* scrollY={true} */ className="adventure-content">
        <div className="web-box" style={{ position: "relative" }}>
          <IonHeader className="back-butt web-box">
            <IonToolbar className="back-butt" style={{ position: "relative" }}>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/tab1" />
              </IonButtons>
              <div
                slot="end"
                onClick={() => setEditModal(true)}
                style={{
                  /* float: "right",
                  display: "inline-flex", */
                  marginRight: "20px",
                  width: "30px",
                  height: "30px",
                }}
              >
                <DotsThreeCircle size={30} weight="duotone" />
              </div>
            </IonToolbar>
          </IonHeader>
          <IonImg
            className="adv-img adventure-slides"
            style={{
              objectFit: "cover",
              width: "100vw",
            }}
            src={newCoverImage === "" ? dumb_photo_guide_cover : newCoverImage}
          ></IonImg>
          <IonCard
            style={{
              marginInline: "0",
              borderRadius: "25px",
              marginBlockStart: "-40px",
            }}
          >
            <IonCardContent>
              <IonHeader style={{ height: "35px" }}>
                <IonGrid>
                  <IonRow
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h1 style={{ color: "black" }}>
                      {authValues.user.firstName}
                    </h1>
                  </IonRow>
                </IonGrid>
              </IonHeader>
              <IonFooter className="user-card-footer">
                <IonGrid>
                  <IonRow style={{ lineHeight: "25px", height: "25px" }}>
                    <ChatsTeardrop
                      size={21}
                      style={{ verticalAlign: "middle", marginRight: "5px" }}
                      color="#3FD0D4"
                    />
                    {languages} {/* {} */}
                  </IonRow>
                </IonGrid>
              </IonFooter>
            </IonCardContent>
          </IonCard>
          <IonSegment
            scrollable
            value={segment}
            style={{ justifyContent: "center" }}
            onIonChange={(e) => setSegment(e.detail.value)}
          >
            <IonSegmentButton value="default">
              <IonLabel>{lang.label413}</IonLabel>
            </IonSegmentButton>
            {authValues.user.guide && (
              <IonSegmentButton value="travelogue">
                <IonLabel>{lang.label197}</IonLabel>
              </IonSegmentButton>
            )}
          </IonSegment>

          {renderSwitch(segment)}
        </div>
      </IonContent>
      <IonModal
        isOpen={showCropModal}
        cssClass="modal-cropper"
        backdropDismiss
        onDidDismiss={() => {
          setShowCropModal(false);
          setShowSlow(false);
        }}
      >
        <IonContent>
          <div className="crop-container">
            {showCropModal && showSlow && (
              <Cropper
                image={cameraImage}
                crop={crop}
                zoom={zoom}
                cropShape="rect"
                aspect={16 / 10}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </div>

          <div style={{ justifyContent: "center", display: "flex" }}>
            <IonButton
              onClick={() => {
                setShowCropModal(false);
              }}
              color="danger"
            >
              {lang.label166}
            </IonButton>
            <IonButton
              onClick={() => {
                setShowLoading(true);
                showCroppedImage();
              }}
              color="primary"
            >
              {lang.label167}
            </IonButton>
          </div>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={editModal}
        backdropDismiss
        onDidDismiss={() => {
          setEditModal(false);
        }}
      >
        <IonHeader className="main-header-search safe-zone-header">
          <IonButton
            onClick={() => setEditModal(false)}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
        </IonHeader>
        <IonContent fullscreen>
          <IonGrid
            style={{
              width: "max-content",
              marginLeft: "auto",
              marginRight: "auto",
              /* height: "max-content", */
              textAlign: "center",
              marginTop: "35%",
            }}
          >
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonButton
                onClick={() => {
                  setEditModal(false);
                  handlePictureClick();
                }}
                color="primary"
                /* style={{ marginRight: "15px" }} */
              >
                {lang.label318}
              </IonButton>
            </IonRow>
            <IonRow style={{ justifyContent: "center" }}>
              <IonButton
                onClick={() => {
                  setIsStoryEdit(true);
                  setEditModal(false);
                }}
                color="primary"
                /* style={{ marginLeft: "15px" }} */
              >
                {lang.label319}
              </IonButton>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default Mypage;

async function takePicture() {
  const image = await Camera.getPhoto({
    quality: 75,
    width: 2048,
    direction: CameraDirection.Front,
    resultType: CameraResultType.DataUrl,
  });
  return image.dataUrl;
}
