import {
  IonPage,
  IonHeader,
  IonBackButton,
  IonButtons,
  IonToolbar,
  IonContent,
  IonItem,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonListHeader,
  IonRadio,
  IonLoading,
} from "@ionic/react";

import "./Tab1.css";

import AuthContext from "../my-context";
import React, { useEffect, useState } from "react";
import { useGlobal } from "reactn";
import axios from "axios";
import Header from "./header";

const Changelanguage: React.FC = () => {
  const { token, lang, db, setAuthValues } = React.useContext(AuthContext);

  const [language, setLanguage] = useGlobal("language");
  const [tempLanguage, setTempLanguage] = useState(language);
  const [appWidth] = useGlobal("appWidth");

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setShowLoading(true);
    setLanguage(tempLanguage);
    saveLangToUser(tempLanguage);
    // eslint-disable-next-line
  }, [tempLanguage]);

  async function saveLangToUser(tempLang: string) {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .patch("/user", { appLanguage: tempLang.toUpperCase() }, config)
      .then(async () => {
        setAuthValues((prevState: any) => ({
          ...prevState,
          user: {
            ...prevState.user,
            appLanguage: tempLang.toUpperCase(),
          },
        }));
        let tempData = await db.get("authValues");
        tempData.user.appLanguage = tempLang.toUpperCase();
        await db.set("authValues", tempData);
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err.data);
        console.log(err.status);
        console.log(err.statusText);
        console.log(err.headers);
        console.log(err.config);
        console.log(err);
        setShowLoading(false);
      });
  }

  return (
    <IonPage id="main">
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar /* className="back-butt" */>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tab1"
              text={lang.label42}
              className="back-button"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="web-box">
          <IonLoading
            message={lang.label331}
            isOpen={showLoading}
            /* onDidDismiss={() => user != null ?? history.replace("/tab1")} */
          />
          <IonList>
            <IonRadioGroup
              value={tempLanguage}
              onIonChange={(e) => setTempLanguage(e.detail.value)}
            >
              <IonListHeader>
                <IonLabel>{lang.label168}:</IonLabel>
              </IonListHeader>

              <IonItem>
                <IonLabel>English</IonLabel>
                <IonRadio slot="start" value="en" />
              </IonItem>

              <IonItem>
                <IonLabel>Čeština</IonLabel>
                <IonRadio slot="start" value="cs" />
              </IonItem>
            </IonRadioGroup>
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Changelanguage;
