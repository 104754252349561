import {
  IonPage,
  IonHeader,
  IonBackButton,
  IonButtons,
  IonToolbar,
  IonContent,
  IonText,
  IonItem,
  IonImg,
  IonTitle,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from "@ionic/react";

import "./Tab1.css";

import React, { useState } from "react";
import AuthContext from "../my-context";
import Header from "./header";
import becomeatrav from "../assets/images/becomeatrav.jpg";
import { useGlobal } from "reactn";
import FooterPageMap from "./FooterPageMap";
import { useHistory } from "react-router";

const Becomeaguidestatic: React.FC = () => {
  const { lang, logout } = React.useContext(AuthContext);

  const [, setShowCreateAcc] = useGlobal("showCreateAcc");
  const [, setShowGuestModal] = useGlobal("showGuestModal");
  const [showLoginModal, setShowLoginModal] = useGlobal("showLoginModal");

  const [segment, setSegment] = useState<string | undefined>("default");

  const history = useHistory();

  const [appWidth] = useGlobal("appWidth");

  function renderSwitch(segment: any) {
    switch (segment) {
      case "prof":
        return (
          <div>
            <IonItem className="text-item no-border">
              <IonText className="legal-text">{lang.label472}</IonText>
            </IonItem>
            <IonImg src={becomeatrav}></IonImg>
            <IonTitle className="text-item" style={{ padding: 0 }}>
              {lang.label473}
            </IonTitle>
            <IonText style={{ display: "block" }}>{lang.label474}</IonText>
            <IonText style={{ display: "block" }}>{lang.label475}</IonText>
            <IonText style={{ display: "block" }}>{lang.label476}</IonText>
            <div
              style={{ width: "100%", marginTop: "10px" }}
              className="text-center"
            >
              <IonButton
                onClick={() => {
                  history.push("/becomeaguide");
                  /* setShowLoginModal(true); */
                  /* setShowCreateAcc(true);
                  setShowGuestModal(false);
                  logout(); */
                }}
                className="footer-button"
              >
                {lang.label26}
              </IonButton>
            </div>
          </div>
        );
      default:
        return (
          <div>
            <IonItem className="text-item no-border">
              <IonText className="legal-text">{lang.label477}</IonText>
            </IonItem>
            <IonImg src={becomeatrav}></IonImg>
            <IonTitle className="text-item" style={{ padding: 0 }}>
              {lang.label478}
            </IonTitle>
            <IonText style={{ display: "block" }}>{lang.label479}</IonText>
            <IonText style={{ display: "block" }}>{lang.label480}</IonText>
            <IonText style={{ display: "block" }}>{lang.label481}</IonText>
            <div
              style={{ width: "100%", marginTop: "10px" }}
              className="text-center"
            >
              <IonButton
                onClick={() => {
                  history.push("/becomeaguide");
                  /* setShowLoginModal(true); */
                  /* setShowCreateAcc(true);
                  setShowGuestModal(false);
                  logout(); */
                }}
                className="footer-button"
              >
                {lang.label26}
              </IonButton>
            </div>
          </div>
        );
    }
  }

  return (
    <IonPage id="main">
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar /* className="back-butt" */>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tab1"
              text={lang.label26}
              className="back-button"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="web-box">
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <IonSegment
              scrollable
              onIonChange={(e) => setSegment(e.detail.value)}
              value={segment}
              style={{ justifyContent: "center" }}
            >
              <IonSegmentButton value="prof" className="segment-picker">
                <IonLabel>{lang.label340}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                value="default"
                className="segment-picker"
                /* disabled={true} */
              >
                <IonLabel>{lang.label341}</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            {renderSwitch(segment)}
          </div>
        </div>
        <FooterPageMap />
      </IonContent>
    </IonPage>
  );
};

export default Becomeaguidestatic;
