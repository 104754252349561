import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonFooter,
  IonImg,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  IonBackButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonItem,
  IonSlides,
  IonSlide,
  IonSpinner,
  useIonViewDidEnter,
} from "@ionic/react";

import "./Tab1.css";

import "./adventure.css";

import moment from "moment";

import axios from "axios";

import Rate from "rc-rate";
import "rc-rate/assets/index.css";

import AuthContext from "../my-context";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router";

import defaultAdvImage from "../assets/images/default_adventure_image.jpg";

import { useEffect, useGlobal } from "reactn";

import AdvGuide from "./Adventure/AdvGuide";
import AdvDefault from "./Adventure/AdvDefault";
/* import AdvGallery from "./Adventure/AdvGallery"; */
import AdvItinerary from "./Adventure/AdvItinerary";
/* import AdvRatings from "./Adventure/AdvRatings"; */

import { ArrowLeft, Handshake, Heart, MapPin } from "phosphor-react";
import setLike from "../functions/setLike";
/* import showImage from "../functions/showImage"; */
import setCountryName from "../functions/setCountryName";
import checkProperty from "../functions/checkProperty";
import Header from "./header";
import FooterPageMap from "./FooterPageMap";

const Adventure: React.FC = () => {
  const { token, lang, db, checkImageCache, authValues } =
    React.useContext(AuthContext);

  const [showModalPart, setShowModalPart] = useState(false); //partner modal

  const [images, setImages] = useState<any>([]);
  const [showLoading, setShowLoading] = useState(true);

  const [language] = useGlobal("language");
  const [showGuestModal, setShowGuestModal] = useGlobal("showGuestModal");
  const [, setMyAdventureId] = useGlobal("myAdventureId");
  const [appWidth] = useGlobal("appWidth");

  const history = useHistory();

  const isSecondRender = useRef(false);

  useIonViewDidEnter(() => {
    setShowLoading(true);
    setAdventureId(window.location.pathname.split("/")[2]);
    /* getUserInfo(window.location.pathname.split("/")[2]);
    setUserId(window.location.pathname.split("/")[2]); */
    fetchData();
  });

  async function fetchData() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    /* var config = {
      headers: {
        "X-Auth-Token": token,
      },
    }; */
    let advItem = { ...advData };
    api
      .get("/adventures/" + window.location.pathname.split("/")[2])
      .then(async (resp) => {
        /* setAdvData(resp.data); */
        advItem = await getImageData(resp.data);
      })
      .then((res) => {
        isSecondRender.current = true;
        setAdvData(advItem);
        let freeTemp = 0;
        advItem.dates.forEach((date) => {
          if (date.free > freeTemp) {
            freeTemp = date.free;
          }
        });
        let temp: [number] = [0];
        if (freeTemp > 0) {
          for (var i = 0; i < freeTemp; i++) {
            temp[i] = i + 1;
          }
        }
        setFreeCapacity(temp);
        setShowLoading(false);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  async function showImage(id: string, token: string) {
    if (id === null) return;
    if (id.substr(0, 4) === "http") {
      return id;
    }
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    return api
      .get("/media/" + id /* , config */)
      .then(async (res) => {
        await db.set(id, res.data.data);
        return res.data.data;
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  async function getImages() {
    let tempImgs: any[] = [];
    if (advData.images[0] !== "" && advData.images.length !== 0) {
      return await Promise.all(
        advData.images.map(async (image: any, index: number) => {
          if (await checkImageCache(image)) {
            return (image = await db.get(image));
          }
          if (image.substring(0, 4) !== "data") {
            const getImageData = async () => {
              const api = axios.create({
                baseURL: `https://app.kangelo.com/`,
              });
              var config = {
                headers: {
                  "X-Auth-Token": token,
                },
              };
              if (image) {
                return api
                  .get("/media/" + image /* , config */)
                  .then(async (res) => {
                    await db.set(image, res.data.data);
                    image = await res.data.data;
                  })
                  .catch((err) => {
                    // what now?
                    console.log(err.data);
                  });
              }
            };
            await getImageData();
            return image;
          }
        })
      ).then((res) => {
        tempImgs = res;
        advData.itinerary.forEach((it) => {
          if (it.images.length > 0) {
            tempImgs = tempImgs.concat(it.images);
          }
        });
        if (tempImgs.length > 0) {
          setImages(tempImgs);
        }
      });
    } else {
      advData.itinerary.forEach((it) => {
        if (it.images.length > 0) {
          tempImgs = tempImgs.concat(it.images);
        }
      });
      if (tempImgs.length > 0) {
        setImages(tempImgs);
      }
    }
  }

  async function getImageData(item: any) {
    return await Promise.all([
      (await checkImageCache(item.image))
        ? (item.imageData = await db.get(item.image))
        : (item.imageData = await showImage(item.image, token)),
      (await checkImageCache(item.guide.coverImage))
        ? (item.guide.coverImageData = await db.get(item.guide.coverImage))
        : (item.guide.coverImageData = await showImage(
            item.guide.coverImage,
            token
          )),
      (await checkImageCache(item.guide.profileImage))
        ? (item.guide.profileImageData = await db.get(item.guide.profileImage))
        : (item.guide.profileImageData = await showImage(
            item.guide.profileImage,
            token
          )),
      await Promise.all(
        item.itinerary.map(async (itiner: any, index: number) => {
          await Promise.all(
            itiner.images.map(async (image: string) => {
              if (await checkImageCache(image)) {
                return (image = await db.get(image));
              }
              if (image.substring(0, 4) !== "data") {
                const getImageData = async () => {
                  const api = axios.create({
                    baseURL: `https://app.kangelo.com/`,
                  });
                  var config = {
                    headers: {
                      "X-Auth-Token": token,
                    },
                  };
                  if (image) {
                    return api
                      .get("/media/" + image /* , config */)
                      .then(async (res) => {
                        await db.set(image, res.data.data);
                        image = await res.data.data;
                      })
                      .catch((err) => {
                        // what now?
                        console.log(err.data);
                      });
                  }
                };
                await getImageData();
                return image;
              }
            })
          ).then((images) => {
            itiner.images = images;
          });
        })
      ),
    ]).then(() => {
      return item;
    });
  }

  /* useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []); */

  const [showModalNeniDatum, setShowModalNeniDatum] = useState(false);
  const [showModalNoPhone, setShowModalNoPhone] = useState(false);

  const [showModalEmptyField, setShowModalEmptyField] = useState(false);
  const [showModalDates, setShowModalDates] = useState(false);
  const [showModalReserv, setShowModalReserv] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const [adventureId, setAdventureId] = useGlobal("AdventrureId");

  const [advData, setAdvData] = useState({
    id: "",
    image: "",
    images: [],
    imageData: "",
    rating: 0,
    ratingCount: 0,
    difficulty: 0,
    partner: null,
    isLiked: false,
    guide: {
      id: "",
      firstName: "",
      lastName: "",
      image: "",
      guide: false,
      admin: false,
    },
    meals: "",
    transit: "",
    accommodation: "",
    categories: [""],
    country: "",
    locations: [
      {
        type: "",
        latitude: "",
        longitude: "",
        airport: "",
        country: "",
        city: "",
        address: "",
        countryText: {
          CS: "",
          EN: "",
        },
      },
    ],
    departure: {
      type: "",
      latitude: "",
      longitude: "",
      airport: "",
      country: "",
      city: "",
      address: "",
      countryText: { CS: "", EN: "" },
    },
    arrival: {
      type: "",
      latitude: "",
      longitude: "",
      airport: "",
      country: "",
      city: "",
      address: "",
      countryText: { CS: "", EN: "" },
    },
    dates: [
      {
        id: "",
        begin: "",
        end: "",
        prices: {
          EUR: 0,
          CZK: 0,
        },
        capacity: 0,
        free: 0,
      },
      {
        id: "",
        begin: "",
        end: "",
        prices: {
          EUR: 0,
          CZK: 0,
        },
        capacity: 0,
        free: 0,
      },
    ],
    itinerary: [
      {
        titleText: { CS: "", EN: "" },
        descriptionText: { CS: "", EN: "" },
        images: [],
      },
    ],
    nameText: {
      CS: "",
      EN: "",
    },
    descriptionText: { CS: null, EN: null },
    includedText: { CS: null, EN: null },
    notIncludedText: { CS: null, EN: null },
    accommodationText: { CS: null, EN: null },
    mealsText: { CS: null, EN: null },
    transitText: { CS: null, EN: null },
    difficultyText: { CS: null, EN: null },
    moreInfoText: { CS: null, EN: null },
    documents: [],
  });

  useEffect(() => {
    if (isSecondRender) {
      getImages();
    }
  }, [advData]);

  const [inputFields, setInputFields] = useState([
    { firstName: "", lastName: "", email: "", phone: "" },
  ]);

  const handleInputChange = (index: number, event: any) => {
    const values = [...inputFields];

    switch (event.target.name) {
      case "firstName":
        values[index].firstName = event.target.value;
        break;
      case "lastName":
        values[index].lastName = event.target.value;
        break;
      case "email":
        values[index].email = event.target.value;
        break;
      case "phone":
        values[index].phone = event.target.value;
        break;
      default:
        break;
    }

    setInputFields(values);
  };

  const handleFields = (value: number) => {
    if (value > persons) {
      const values = [...inputFields];
      for (var i = 0; i < value - persons; i++) {
        values.push({ firstName: "", lastName: "", email: "", phone: "" });
      }
      setInputFields(values);
    } else {
      const values = [...inputFields];
      for (i = 0; i < persons - value; i++) {
        values.splice(values.length - 1, 1);
      }
      setInputFields(values);
    }
  };

  function pickDate(item: any) {
    /*     setSelectedDate(item.id);
    setSelectedPrice(item.prices.CZK); */
    setShowModalDates(true);
  }

  /*   const [segment, setSegment] = useState<string | undefined>("default"); */
  const [personsGlobal] = useGlobal("advFilter");
  const [persons, setPersons] = useState<number>(1);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedPrice, setSelectedPrice] = useState<number>(0);

  const [minPrice, setMinPrice] = useState(0);

  useEffect(() => {
    setPersons(personsGlobal.persons);
  }, [personsGlobal]);

  useEffect(() => {
    if (advData.dates.length > 0) {
      let minTemp = 1000000000;
      advData.dates.forEach((date) => {
        if (date.prices.CZK < minTemp) {
          minTemp = date.prices.CZK;
        }
      });
      setMinPrice(minTemp);
    }
  }, [advData]);

  const [freeCapacity, setFreeCapacity] = useState([0]);

  /*   useEffect(() => {
    let dateFilter = advData.dates.filter((item) => item.id === selectedDate);
    let temp: [number] = [0];
    if (dateFilter.length > 0) {
      for (var i = 0; i < dateFilter[0].free; i++) {
        temp[i] = i + 1;
      }
    }
    setFreeCapacity(temp);
  }, [selectedDate]); */

  /*   useEffect(() => {
    let dateFilter = advData.dates.filter((item) => item.id === selectedDate);
    let temp: [number] = [0];
    if (dateFilter.length > 0) {
      for (var i = 0; i < dateFilter[0].free; i++) {
        temp[i] = i + 1;
      }
    }
    setFreeCapacity(temp);
  }, [selectedDate]); */

  /*   function renderSwitch(segment: any) {
    switch (segment) {
      case "guide":
        return <AdvGuide advData={advData} />;
      case "itinerary":
        return <AdvItinerary advData={advData} />;
            case "gallery":
        return <AdvGallery advData={advData} />;
      case "ratings":
        return <AdvRatings advData={advData} />; 
      default:
        return <AdvDefault advData={advData} pickDate={pickDate} />;
    }
  } */

  async function apiReservCall(apiData: any) {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .post("/reservations", apiData, config)
      .then((res) => {
        setInputFields([{ firstName: "", lastName: "", email: "", phone: "" }]);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  async function makeReserv() {
    const personsDetails = [...inputFields];
    personsDetails.splice(inputFields.length - 1, 1);
    var apiData = { dates: selectedDate, people: personsDetails };
    if (personsDetails.length === 0) {
      apiReservCall(apiData);
      setShowModalReserv(false);
      setShowModalConfirm(true);
    } else if (
      personsDetails.filter(
        (item) =>
          item.firstName === "" ||
          item.lastName === "" ||
          item.email === "" ||
          item.phone === ""
      ).length === 0
    ) {
      apiReservCall(apiData);
      setShowModalReserv(false);
      setShowModalConfirm(true);
    } else {
      setShowModalEmptyField(true);
    }
  }

  return showLoading ? (
    <IonPage className="web-box">
      <IonHeader className="back-butt">
        <IonToolbar className="back-butt">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tab1"
              className="back-button"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSpinner
          className="spinner-loading"
          color="primary"
          style={{ marginTop: "10vh" }}
        />
        <IonText className="text-center" style={{ display: "block" }}>
          {lang.label222}
        </IonText>
      </IonContent>
    </IonPage>
  ) : (
    <IonPage className="safe-zone-header ">
      {appWidth > 719 && <Header styles="shadow-box" />}
      <IonContent /* scrollY={true} */ className="adventure-content">
        <div className="web-box">
          <IonHeader>
            <IonToolbar className="back-butt2" style={{ top: "0.2em" }}>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/tab1" className="back-button" />
              </IonButtons>
              <div
                style={{
                  float: "right",
                  display: "inline-flex",
                  marginRight: "25px",
                }}
              >
                <Handshake
                  size={24}
                  color="#3FD0D4"
                  style={{ margin: "0 15 0 10" }}
                  weight="duotone"
                  onClick={
                    authValues.user.id === "guest"
                      ? (e) => {
                          e.preventDefault();
                          setShowGuestModal(true);
                        }
                      : () => {
                          setShowModalPart(true);
                        }
                  }
                />
                <Heart
                  size={24}
                  color="#3FD0D4"
                  weight={advData.isLiked ? "fill" : "duotone"}
                  onClick={
                    authValues.user.id === "guest"
                      ? (e) => {
                          e.preventDefault();
                          setShowGuestModal(true);
                        }
                      : () => {
                          let advDataTemp = { ...advData };
                          advDataTemp.isLiked = !advData.isLiked;
                          setAdvData(advDataTemp);
                          setLike(
                            advData.id,
                            "adventures",
                            advDataTemp.isLiked,
                            token
                          );
                        }
                  }
                />
              </div>
            </IonToolbar>
          </IonHeader>
          {images.length === 0 && (
            <IonImg
              className="adv-img"
              style={{ height: "32vh", objectFit: "cover" }}
              src={
                advData.imageData === "" || advData.imageData === null
                  ? defaultAdvImage
                  : advData.imageData
              }
            ></IonImg>
          )}
          {images.length > 0 && (
            <IonSlides
              pager={true}
              style={{ /* height: "32vh", */ width: "100%" }}
              options={{
                slidesPerView: 1,
                initialSlide: 0,
                speed: 400,
              }}
              className="adventure-slides"
            >
              <IonSlide style={{ width: "100vw" }}>
                <IonImg
                  className="adv-img"
                  style={{
                    objectFit: "cover",
                    width: "100vw",
                  }}
                  src={
                    advData.imageData === "" || advData.imageData === null
                      ? defaultAdvImage
                      : advData.imageData
                  }
                ></IonImg>
              </IonSlide>
              {images.map((image: string, index: number) => {
                return (
                  <IonSlide key={index}>
                    <IonImg
                      className="adv-img"
                      style={{ objectFit: "cover", width: "100vw" }}
                      src={
                        advData.imageData === "" || advData.imageData === null
                          ? defaultAdvImage
                          : images[index]
                      }
                    ></IonImg>
                  </IonSlide>
                );
              })}
            </IonSlides>
          )}
          <IonCard
            style={{
              marginInline: "0",
              borderRadius: "25px",
              marginBlockStart: "-35px",
              zIndex: 10,
            }}
          >
            <IonCardContent
              style={{ paddingTop: "8px", paddingBottom: "16px" }}
            >
              <IonHeader style={{ height: "auto" }}>
                <IonGrid>
                  <IonRow
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h2 style={{ color: "black", fontWeight: "bold" }}>
                      {checkProperty(advData.nameText, language)}
                    </h2>
                  </IonRow>
                </IonGrid>
              </IonHeader>
              <IonFooter
                className="adventure-card-footer"
                style={{ height: "25px" }}
              >
                <IonGrid>
                  <IonRow
                    style={{
                      lineHeight: "25px",
                      height: "25px",
                      display: "block",
                    }}
                  >
                    <MapPin
                      size={21}
                      style={{
                        verticalAlign: "middle",
                        marginRight: "5px",
                        marginTop: "-2px",
                      }}
                      color="#3FD0D4"
                    />
                    {advData.locations[0].city !== null &&
                      advData.locations[0].city}
                    {advData.locations[0].city !== null && ","}
                    {setCountryName(advData.locations[0].country, language)}
                    {advData.rating !== null && (
                      <div style={{ float: "right" }}>
                        <Rate
                          count={5}
                          value={advData.rating}
                          allowHalf={true}
                          disabled={true}
                          className="rating-stars"
                        />
                        <div
                          style={{
                            width: "max-content",
                            display: "inline-block",
                          }}
                        >
                          {advData.rating} ({advData.ratingCount})
                        </div>
                      </div>
                    )}
                  </IonRow>
                </IonGrid>
              </IonFooter>
            </IonCardContent>
          </IonCard>
          {/* <IonSegment
        scrollable
        onIonChange={(e) => setSegment(e.detail.value)}
        value={segment}
      >
        <IonSegmentButton value="default" className="segment-picker">
          <IonLabel>Základní informace</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="guide" className="segment-picker">
          <IonLabel>Průvodce</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="itinerary" className="segment-picker">
          <IonLabel>Itinerář</IonLabel>
        </IonSegmentButton>
        {/*         <IonSegmentButton value="gallery">
          <IonLabel>Galerie</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="ratings">
          <IonLabel>Hodnocení</IonLabel>
        </IonSegmentButton> 
      </IonSegment> */}

          <IonGrid>
            <IonRow>
              <IonCol className="left-col-adv">
                {checkProperty(advData.descriptionText, language) !== "" && (
                  <IonItem
                    style={{
                      "--padding-end": "20px",
                      "--padding-start": "20px",
                    }}
                  >
                    <IonText
                      className="desc-text"
                      style={{
                        fontSize: "16px",
                        paddingBottom: "10px",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {checkProperty(advData.descriptionText, language)}
                    </IonText>
                  </IonItem>
                )}
                <AdvGuide advData={advData} />
                <AdvDefault advData={advData} pickDate={pickDate} />
              </IonCol>
              <IonCol className="right-col-adv">
                <IonCard className="dates-card">
                  <IonGrid /* className="persons-grid" */>
                    <IonRow>
                      <IonCol
                        className="persons-col"
                        style={{ fontWeight: "bold" }}
                      >
                        {lang.label287}
                      </IonCol>
                      <IonCol
                        className="persons-col"
                        style={{ fontWeight: "bold" }}
                      >
                        {lang.label133}
                      </IonCol>
                    </IonRow>
                    <IonRow style={{ height: "25px" }}>
                      <IonCol
                        className="persons-col"
                        style={{ height: "25px", padding: 0 }}
                      >
                        {freeCapacity.length === 1 && freeCapacity[0] === 0 ? (
                          <IonText style={{ color: "red" }}>
                            {lang.label482}
                          </IonText>
                        ) : (
                          <IonSelect
                            value={String(persons)}
                            onIonChange={async (e) => {
                              await handleFields(e.detail.value);
                              setPersons(Number(e.detail.value));
                            }}
                            placeholder="1"
                          >
                            {freeCapacity.map((item, index) => {
                              if (item === 0) return;
                              return (
                                <IonSelectOption
                                  key={index}
                                  value={String(item)}
                                >
                                  {String(item)}
                                </IonSelectOption>
                              );
                            })}
                          </IonSelect>
                        )}
                        {/* <IonSelect
                          value={String(persons)}
                          onIonChange={async (e) => {
                            await handleFields(e.detail.value);
                            setPersons(Number(e.detail.value));
                          }}
                          placeholder="1"
                        >
                          {freeCapacity.map((item, index) => {
                            if (item === 0) return;
                            return (
                              <IonSelectOption key={index} value={String(item)}>
                                {String(item)}
                              </IonSelectOption>
                            );
                          })}
                        </IonSelect> */}
                      </IonCol>
                      <IonCol
                        className="persons-col"
                        style={{
                          fontWeight: "bold",
                          color: "#3fd0d4",
                          height: "25px",
                          padding: 0,
                          textTransform: "lowercase",
                        }}
                      >
                        {lang.label238}{" "}
                        {(persons * minPrice).toLocaleString(
                          language === "cs" ? "cs-CZ" : "en-GB"
                        )}{" "}
                        Kč
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  {/* <IonContent style={{ maxHeight: "225px" }}> */}
                  <IonGrid /* className="dates-grid" */>
                    {advData.dates.map((date: any, i: number) => {
                      if (date.free === 0) return;
                      return (
                        <IonRow
                          key={`${i}`}
                          className={
                            /* date.id === selectedDate
                    ? */ "selected-date"
                            /* : "non-selected-date" */
                          }
                        >
                          <IonCol size="8.8" className="dates-col">
                            <IonRow
                              className="text-bold"
                              style={{ justifyContent: "center" }}
                            >
                              {moment(date.begin, "YYYY-MM-DD").format(
                                "DD.MM.YYYY"
                              )}{" "}
                              -{" "}
                              {moment(date.end, "YYYY-MM-DD").format(
                                "DD.MM.YYYY"
                              )}
                            </IonRow>
                            <IonRow style={{ justifyContent: "center" }}>
                              {date.prices.CZK.toLocaleString(
                                language === "cs" ? "cs-CZ" : "en-GB"
                              )}{" "}
                              Kč / {lang.label323} ({lang.label324}{" "}
                              {(persons * date.prices.CZK).toLocaleString(
                                language === "cs" ? "cs-CZ" : "en-GB"
                              )}{" "}
                              Kč)
                            </IonRow>
                          </IonCol>
                          {/* <IonCol className="dates-col">
                  
                </IonCol> */}
                          <IonCol size="3.2" className="dates-col">
                            <IonButton
                              disabled={
                                persons > date.free ||
                                moment(date.end, "YYYY-MM-DD") <
                                  moment(new Date())
                              }
                              className="dates-button2"
                              style={{
                                paddingLeft: "5px",
                                paddingRight: "5px",
                              }}
                              onClick={
                                authValues.user.id === "guest"
                                  ? (e) => {
                                      e.preventDefault();
                                      setShowGuestModal(true);
                                    }
                                  : () => {
                                      if (authValues.user.phone === "") {
                                        setShowModalDates(false);
                                        setShowModalNoPhone(true);
                                      } else {
                                        setSelectedDate(date.id);
                                        setSelectedPrice(date.prices.CZK);
                                        setShowModalDates(false);
                                        setShowModalReserv(true);
                                      }
                                    }
                              }
                            >
                              {lang.label104}
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      );
                    })}
                  </IonGrid>
                  {/* </IonContent> */}
                  <IonText
                    style={{ paddingInline: "15px" }}
                    className="reserv-text safe-zone"
                  >
                    {lang.label289}
                  </IonText>
                </IonCard>
                {/*  <IonButton
          className="reserv-button footer-button"
          onClick={() => {
            if (selectedDate.length === 0) {
              setShowModalNeniDatum(true);
            } else {
              setShowModalDates(false);
              setShowModalReserv(true);
            }
          }}
        >
          {lang.label288}
        </IonButton> */}
              </IonCol>
            </IonRow>
            <IonRow>
              <AdvItinerary advData={advData} />
            </IonRow>
          </IonGrid>
        </div>
        <FooterPageMap />
      </IonContent>
      {appWidth < 925 && (
        <IonToolbar
          slot="bottom"
          className="bottom-toolbar"
          style={{
            paddingBottom: "15px",
          }}
        >
          <IonGrid className="adv-footer">
            {advData.guide.id === authValues.user.id ? (
              <IonCol
                style={{
                  height: "50px",
                  lineHeight: "22px",
                  display: "grid",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 0,
                }}
              >
                <IonButton
                  onClick={() => {
                    setMyAdventureId(advData.id);
                    history.push("/myadventures/" + advData.id);
                  }}
                  className="footer-button"
                >
                  {lang.label270}
                </IonButton>
              </IonCol>
            ) : (
              <div style={{ display: "inherit" }}>
                <IonCol
                  style={{
                    height: "50px",
                    lineHeight: "22px",
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 0,
                  }}
                >
                  <IonRow
                    style={{
                      color: "#3FD0D4",
                      fontWeight: "bold",
                      justifyContent: "center",
                    }}
                  >
                    <IonText
                      style={{
                        textTransform: "lowercase",
                        paddingRight: "5px",
                      }}
                    >
                      {lang.label238}
                    </IonText>
                    {minPrice.toLocaleString(
                      language === "cs" ? "cs-CZ" : "en-GB"
                    )}{" "}
                    Kč
                  </IonRow>

                  <IonRow style={{ justifyContent: "center" }}>
                    <IonText>{lang.label285}</IonText>
                  </IonRow>
                </IonCol>

                <IonCol>
                  <IonButton
                    onClick={() => setShowModalDates(true)}
                    className="footer-button"
                    style={
                      freeCapacity.length === 1 && freeCapacity[0] === 0
                        ? {
                            textTransform: "initial",
                            "--background": "red",
                          }
                        : { textTransform: "initial" }
                    }
                    disabled={
                      freeCapacity.length === 1 && freeCapacity[0] === 0
                    }
                  >
                    {freeCapacity.length === 1 && freeCapacity[0] === 0
                      ? lang.label482
                      : lang.label286}
                  </IonButton>
                </IonCol>
              </div>
            )}
          </IonGrid>
        </IonToolbar>
      )}

      <IonModal
        isOpen={showModalDates}
        cssClass={
          advData.dates.length <= 1
            ? "dates-modal2"
            : advData.dates.length <= 2
            ? "dates-modal3"
            : advData.dates.length <= 3
            ? "dates-modal4"
            : "dates-modal"
        }
        backdropDismiss={true}
        onDidDismiss={() => setShowModalDates(false)}
      >
        <IonGrid className="persons-grid">
          <IonRow>
            <IonCol className="persons-col" style={{ fontWeight: "bold" }}>
              {lang.label287}
            </IonCol>
            <IonCol className="persons-col" style={{ fontWeight: "bold" }}>
              {lang.label133}
            </IonCol>
          </IonRow>
          <IonRow style={{ height: "25px" }}>
            <IonCol
              className="persons-col"
              style={{ height: "25px", padding: 0 }}
            >
              {freeCapacity.length === 1 && freeCapacity[0] === 0 ? (
                lang.label482
              ) : (
                <IonSelect
                  value={String(persons)}
                  onIonChange={async (e) => {
                    await handleFields(e.detail.value);
                    setPersons(Number(e.detail.value));
                  }}
                  placeholder="1"
                >
                  {freeCapacity.map((item, index) => {
                    return (
                      <IonSelectOption key={index} value={String(item)}>
                        {String(item)}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              )}
            </IonCol>
            <IonCol
              className="persons-col"
              style={{
                fontWeight: "bold",
                color: "#3fd0d4",
                height: "25px",
                padding: 0,
                textTransform: "lowercase",
              }}
            >
              {lang.label238}{" "}
              {(persons * minPrice).toLocaleString(
                language === "cs" ? "cs-CZ" : "en-GB"
              )}{" "}
              Kč
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonContent style={{ maxHeight: "225px" }}>
          <IonGrid className="dates-grid">
            {advData.dates.map((date: any, i: number) => {
              if (date.free === 0) return;
              return (
                <IonRow
                  key={`${i}`}
                  className={
                    /* date.id === selectedDate
                    ? */ "selected-date"
                    /* : "non-selected-date" */
                  }
                >
                  <IonCol size="8.5" className="dates-col">
                    <IonRow
                      className="text-bold"
                      style={{ justifyContent: "center" }}
                    >
                      {moment(date.begin, "YYYY-MM-DD").format("DD.MM.YYYY")} -{" "}
                      {moment(date.end, "YYYY-MM-DD").format("DD.MM.YYYY")}
                    </IonRow>
                    <IonRow style={{ justifyContent: "center" }}>
                      {date.prices.CZK.toLocaleString(
                        language === "cs" ? "cs-CZ" : "en-GB"
                      )}{" "}
                      Kč / {lang.label323} ({lang.label324}{" "}
                      {(persons * date.prices.CZK).toLocaleString(
                        language === "cs" ? "cs-CZ" : "en-GB"
                      )}{" "}
                      Kč)
                    </IonRow>
                  </IonCol>
                  {/* <IonCol className="dates-col">
                  
                </IonCol> */}
                  <IonCol size="3.5" className="dates-col">
                    <IonButton
                      disabled={
                        persons > date.free ||
                        moment(date.end, "YYYY-MM-DD") < moment(new Date())
                      }
                      className="dates-button"
                      onClick={
                        authValues.user.id === "guest"
                          ? (e) => {
                              e.preventDefault();
                              setShowGuestModal(true);
                            }
                          : () => {
                              if (authValues.user.phone === "") {
                                setShowModalDates(false);
                                setShowModalNoPhone(true);
                              } else {
                                setSelectedDate(date.id);
                                setSelectedPrice(date.prices.CZK);
                                setShowModalDates(false);
                                setShowModalReserv(true);
                              }
                            }
                      }
                    >
                      {lang.label104}
                    </IonButton>
                  </IonCol>
                </IonRow>
              );
            })}
          </IonGrid>
        </IonContent>
        {/*  <IonButton
          className="reserv-button footer-button"
          onClick={() => {
            if (selectedDate.length === 0) {
              setShowModalNeniDatum(true);
            } else {
              setShowModalDates(false);
              setShowModalReserv(true);
            }
          }}
        >
          {lang.label288}
        </IonButton> */}
        <IonText
          style={{ paddingInline: "15px" }}
          className="reserv-text safe-zone"
        >
          {lang.label289}
        </IonText>
      </IonModal>

      <IonModal
        isOpen={showModalNoPhone}
        cssClass="part-modal2"
        backdropDismiss={true}
        onDidDismiss={() => setShowModalNoPhone(false)}
      >
        <IonText
          style={{ display: "block", padding: "10px 5px 0 5px" }}
          className="reserv-text"
        >
          {lang.label415}
        </IonText>
        <IonRow
          style={{
            display: "block",
            width: "100%",
            paddingBottom: "5px",
          }}
        >
          <IonCol size="12">
            <IonButton
              color="primary"
              onClick={() => {
                history.push("/userdetails");
              }}
            >
              {lang.label354}
            </IonButton>
            <IonButton
              color="danger"
              onClick={() => {
                setShowModalNoPhone(false);
              }}
            >
              {lang.label166}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonModal>

      <IonModal
        isOpen={showModalNeniDatum}
        cssClass="part-modal"
        backdropDismiss={true}
        onDidDismiss={() => setShowModalNeniDatum(false)}
      >
        <IonText className="reserv-text">{lang.label290}</IonText>
        <IonButton
          onClick={() => {
            setShowModalNeniDatum(false);
          }}
        >
          {lang.label166}
        </IonButton>
      </IonModal>

      <IonModal
        isOpen={showModalEmptyField}
        cssClass="part-modal"
        backdropDismiss={true}
        onDidDismiss={() => setShowModalEmptyField(false)}
      >
        <IonText className="reserv-text">{lang.label291}</IonText>
        <IonButton
          onClick={() => {
            setShowModalEmptyField(false);
          }}
        >
          {lang.label166}
        </IonButton>
      </IonModal>
      <IonModal
        isOpen={showModalReserv}
        cssClass={persons === 1 ? "reserv-modal-small" : "reserv-modal"}
        backdropDismiss={true}
        onDidDismiss={() => setShowModalReserv(false)}
      >
        <IonButton
          fill="clear"
          className="back-reserv-butt"
          onClick={() => {
            if (appWidth < 925) {
              setShowModalDates(true);
            }
            setShowModalReserv(false);
          }}
        >
          {" "}
          <ArrowLeft size={24} />
        </IonButton>
        <IonGrid
          style={{
            /* maxHeight: "70%", */
            overflow: "auto",
            padding: "0 25px 0 25px",
          }}
        >
          <IonRow>
            <IonLabel style={{ fontWeight: "bold" }}>
              {lang.label264} 1 - {lang.label297}
            </IonLabel>
          </IonRow>
          {inputFields.map((inputField: any, index: number) => (
            <div
              style={
                inputFields.length - 1 === index
                  ? { display: "none" }
                  : { display: "block", marginTop: "5px" }
              }
              key={index}
            >
              <IonRow key={`${inputField}~${index}`}>
                <IonLabel style={{ fontWeight: "bold" }}>
                  {lang.label264} {index + 2}
                </IonLabel>
              </IonRow>
              <IonRow>
                <IonCol style={{ padding: "5px 2.5px 0 0" }}>
                  <IonInput
                    value={inputField.firstName}
                    name="firstName"
                    placeholder={lang.label49}
                    inputmode="text"
                    maxlength={40}
                    onIonChange={(event) => handleInputChange(index, event)}
                    className="reserv-input"
                  ></IonInput>
                </IonCol>
                <IonCol style={{ padding: "5px 0 0 2.5px" }}>
                  <IonInput
                    value={inputField.lastName}
                    name="lastName"
                    placeholder={lang.label50}
                    inputmode="text"
                    maxlength={40}
                    onIonChange={(event) => handleInputChange(index, event)}
                    className="reserv-input"
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol style={{ padding: "5px 0 0 0" }}>
                  <IonInput
                    value={inputField.email}
                    name="email"
                    placeholder="email"
                    inputmode="email"
                    maxlength={30}
                    onIonChange={(event) => handleInputChange(index, event)}
                    className="reserv-input"
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol style={{ padding: "5px 0 0 0" }}>
                  <IonInput
                    value={inputField.phone}
                    name="phone"
                    placeholder={lang.label57}
                    inputmode="tel"
                    maxlength={16}
                    onIonChange={(event) => handleInputChange(index, event)}
                    className="reserv-input"
                  ></IonInput>
                </IonCol>
              </IonRow>
            </div>
          ))}
        </IonGrid>
        {/* <IonToolbar> */}
        <IonFooter style={{ display: "flex" }} className="safe-zone">
          <IonGrid>
            <IonRow>
              <IonButton
                className="reserv-button footer-button"
                style={{ width: "100%", padding: "0 20px 0 20px" }}
                /* style={{ marginLeft: "auto", marginRight: "auto", width: "50%" }} */
                onClick={() => {
                  makeReserv();
                }}
              >
                {lang.label292}
              </IonButton>
            </IonRow>
            <IonRow>
              <IonText className="reserv-text">{lang.label289}</IonText>
            </IonRow>
          </IonGrid>
        </IonFooter>
        {/* </IonToolbar> */}
      </IonModal>
      <IonModal
        isOpen={showModalConfirm}
        cssClass="confirm-modal"
        backdropDismiss={true}
        onDidDismiss={() => setShowModalConfirm(false)}
      >
        <div style={{ padding: "10px 15px" }}>
          <IonTitle style={{ paddingInline: "15px" }}>{lang.label293}</IonTitle>
          <IonText style={{ display: "block" }}>{lang.label294}</IonText>
          <IonText>{lang.label295}</IonText>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol>{lang.label287}</IonCol>
            <IonCol>{lang.label133}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{persons}</IonCol>
            <IonCol>
              {(persons * selectedPrice).toLocaleString(
                language === "cs" ? "cs-CZ" : "en-GB"
              )}{" "}
              Kč
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonFooter style={{ display: "flex" }} className="safe-zone">
          <IonGrid>
            <IonRow>
              <IonButton
                className="reserv-button footer-button"
                style={{ width: "100%", padding: "0 20px 0 20px" }}
                onClick={() => {
                  history.push("/reservations");
                }}
              >
                {lang.label296}
              </IonButton>
            </IonRow>
          </IonGrid>
        </IonFooter>
      </IonModal>
      <IonModal
        isOpen={showModalPart}
        cssClass="part-modal"
        onDidDismiss={() => setShowModalPart(false)}
      >
        <div style={{ height: "100%" }}>
          <IonText style={{ display: "block", marginTop: "10px" }}>
            {lang.label196}
            <br /> {advData.partner}.
          </IonText>
        </div>
      </IonModal>
    </IonPage>
  );
};

export default Adventure;
