import {
  IonAvatar,
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonModal,
  IonNote,
} from "@ionic/react";

import { Camera, CameraDirection, CameraResultType } from "@capacitor/camera";
import Cropper from "react-easy-crop";

import getCroppedImg from "./cropImage";

/* import showImage from "../functions/showImage"; */

import { useHistory } from "react-router-dom";
import {
  calendarOutline,
  calendarSharp,
  documentLockOutline,
  documentLockSharp,
  languageOutline,
  languageSharp,
  lockClosedOutline,
  lockClosedSharp,
  logOutOutline,
  logOutSharp,
  mailOutline,
  mailSharp,
  listOutline,
  listSharp,
  personOutline,
  personSharp,
  globeSharp,
  globeOutline,
} from "ionicons/icons";
import "./menu.css";
import React, { useState, useCallback, useEffect, useRef } from "react";

import AuthContext from "../my-context";
import {
  /* Eye, EyeSlash, */ PlusCircle,
  UserCirclePlus,
} from "phosphor-react";
import axios from "axios";
import { useGlobal } from "reactn";
import { isPlatform } from "@ionic/core";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

/* const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders']; */

const Menu: React.FC = () => {
  const isFirstRender = useRef(true);
  const history = useHistory();

  const {
    authValues,
    logout,
    token,
    setAuthValues,
    lang,
    db,
    checkImageCache,
  } = React.useContext(AuthContext);

  const [showGuestModal, setShowGuestModal] = useGlobal("showGuestModal");
  const [appWidth] = useGlobal("appWidth");
  const [, setShowLoginPage] = useGlobal("showLoginPage");
  const [, setShowCreateAcc] = useGlobal("showCreateAcc");
  const [, setShowLoginModal] = useGlobal("showLoginModal");

  const [isVisible, setIsVisible] = useState(true);

  const changeVision = () => {
    setIsVisible(!isVisible);
  };

  const [croppedImage, setCroppedImage] = useState<null | string>(null);
  const [cameraImage, setCameraImage] = useState<string>();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any | null>(null);

  const [mediaData, setMediaData] = useState("");

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const [showCropModal, setShowCropModal] = useState(false);
  const [showSlow, setShowSlow] = useState(false);

  const handlePictureClick = async () => {
    const image = await takePicture();
    setCameraImage(image);
  };

  useEffect(() => {
    if (!isFirstRender.current) {
      setShowCropModal(true);
      setTimeout(() => setShowSlow(true), 600);
    }
    isFirstRender.current = false;
  }, [cameraImage]);

  useEffect(() => {
    showImage(authValues.user.profileImage, token);
  }, [authValues.user.profileImage]);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        cameraImage,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  useEffect(() => {
    if (croppedImage !== null) {
      saveAvatar();
    }
  }, [croppedImage]);

  async function saveAvatar() {
    let media = { type: "IMAGE", data: croppedImage };
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .post("/media", media, config)
      .then(async (res) => {
        await db.set(res.data.id, croppedImage);
        updateUser(res.data);
      })
      .catch((err) => {
        console.log(err.data);
        console.log(err.status);
        console.log(err.statusText);
        console.log(err.headers);
        console.log(err.config);
        console.log(err);
      });
  }

  async function updateUser(mediaId: any) {
    let data = { profileImage: mediaId.id };
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .patch("/user", data, config)
      .then(async () => {
        setAuthValues((prevState: any) => ({
          ...prevState,
          user: {
            ...prevState.user,
            profileImage: data.profileImage,
          },
        }));
        let tempAuthData = await db.get("authValues");
        tempAuthData.user.profileImage = data.profileImage;
        await db.set("authValues", tempAuthData);
        setShowCropModal(false);
      })
      .catch((err) => {
        console.log(err.data);
        console.log(err.status);
        console.log(err.statusText);
        console.log(err.headers);
        console.log(err.config);
        console.log(err);
      });
  }

  async function showImage(id: string, token: string) {
    if (await checkImageCache(id)) {
      let temp = await db.get(id);
      setMediaData(temp);
      return;
    }
    if (id === null) return;
    if (id.substr(0, 4) === "http") {
      return id;
    }
    async function getImageData() {
      const api = axios.create({
        baseURL: `https://app.kangelo.com/`,
      });
      var config = {
        headers: {
          "X-Auth-Token": token,
        },
      };
      api
        .get("/media/" + id, config)
        .then(async (res) => {
          await db.set(id, res.data.data);
          setMediaData(res.data.data);
        })
        .catch((err) => {
          // what now?
          console.log(err.data);
        });
    }
    getImageData();
  }

  const appPages: AppPage[] = [
    {
      title: lang.label35,
      url: "/userdetails",
      iosIcon: personOutline,
      mdIcon: personSharp,
    },
    /*   {
        title: lang.label36,
        url: "/page/traveldetails",
        iosIcon: paperPlaneOutline,
        mdIcon: paperPlaneSharp,
      }, */
    {
      title: lang.label37,
      url: "/reservations",
      iosIcon: calendarOutline,
      mdIcon: calendarSharp,
    },
    /*   {
        title: lang.label38,
        url: "/page/travellist",
        iosIcon: archiveOutline,
        mdIcon: archiveSharp,
      }, */
  ];

  const appPages2: AppPage[] = [
    {
      title: lang.label101,
      url: "/myadventures",
      iosIcon: globeOutline,
      mdIcon: globeSharp,
    } /* 
      {
        title: lang.label102,
        url: "/page/mylectures",
        iosIcon: paperPlaneOutline,
        mdIcon: paperPlaneSharp,
      },
      {
        title: lang.label103,
        url: "/page/fanbase",
        iosIcon: heartOutline,
        mdIcon: heartSharp,
      },
      {
        title: lang.label165,
        url: "/page/payout",
        iosIcon: archiveOutline,
        mdIcon: archiveSharp,
      }, */,
  ];

  const appPages3: AppPage[] = [
    /*   {
        title: lang.label39,
        url: "/page/notification",
        iosIcon: mailOutline,
        mdIcon: mailSharp,
      },
      {
        title: lang.label41,
        url: "/page/paymethods",
        iosIcon: paperPlaneOutline,
        mdIcon: paperPlaneSharp,
      },*/
    {
      title: lang.label42,
      url: "/language",
      iosIcon: languageOutline,
      mdIcon: languageSharp,
    },
    {
      title: lang.label43,
      url: "/password",
      iosIcon: lockClosedOutline,
      mdIcon: lockClosedSharp,
    },
  ];

  const appPages4: AppPage[] = [
    {
      title: lang.label45,
      url: "/contact",
      iosIcon: mailOutline,
      mdIcon: mailSharp,
    },
    {
      title: lang.label457,
      url: "/termsandconditions",
      iosIcon: listOutline,
      mdIcon: listSharp,
    },
    {
      title: lang.label46,
      url: "/personaldataprotection",
      iosIcon: documentLockOutline,
      mdIcon: documentLockSharp,
    },
    /*{
        title: lang.label47,
        url: "/page/deleteacc",
        iosIcon: heartOutline,
        mdIcon: heartSharp,
      }, */
  ];

  const appPages5: AppPage[] = [
    {
      title: lang.label48,
      url: "/page/logout",
      iosIcon: logOutOutline,
      mdIcon: logOutSharp,
    },
  ];

  return (
    <IonMenu contentId="main" type="overlay" side="end">
      <div className="safe-zone-header-menu">
        <span></span>
      </div>
      <IonContent className="safe-zone-header">
        <IonList id="inbox-list">
          <IonListHeader
            style={{ marginLeft: "auto", marginRight: "auto", width: "130px" }}
          >
            <IonMenuToggle autoHide={false} className="top-menu">
              <IonAvatar
                className="header-avatar-menu hover-anime"
                onClick={
                  authValues.user.id === "guest"
                    ? (e) => {
                        e.preventDefault();
                        setShowGuestModal(true);
                      }
                    : handlePictureClick
                }
              >
                {authValues.user.profileImage === null ? (
                  <UserCirclePlus size={128} />
                ) : (
                  <img src={mediaData} alt="user" />
                )}
                {authValues.user.profileImage !== null && (
                  <PlusCircle
                    size={20}
                    style={{
                      position: "absolute",
                      bottom: "15%",
                      right: "27.5%",
                      background: "white",
                      borderRadius: "10px",
                    }}
                    weight="bold"
                  />
                )}
              </IonAvatar>
            </IonMenuToggle>
          </IonListHeader>
          <IonNote className="user-name">
            {authValues.user.firstName + " " + authValues.user.lastName}
          </IonNote>
          <div className="top-container">
            <IonMenuToggle autoHide={false} className="top-menu">
              {authValues.user.id === "guest" ? (
                <div style={{ height: "100%" }}>
                  <IonButton
                    className="login-button"
                    onClick={() => {
                      if (appWidth > 920) {
                        setShowLoginPage(false);
                        setShowCreateAcc(false);
                        setShowLoginModal(true);
                      } else {
                        setShowGuestModal(false);
                        logout();
                      }
                    }}
                  >
                    {lang.label160}
                  </IonButton>
                </div>
              ) : (
                <IonItem
                  routerLink="/mypage"
                  routerDirection="forward"
                  lines="none"
                  detail={false}
                  onClick={
                    authValues.user.id === "guest"
                      ? (e) => {
                          e.preventDefault();
                          setShowGuestModal(true);
                        }
                      : () => console.log("")
                  }
                >
                  <IonLabel>{lang.label34}</IonLabel>
                </IonItem>
              )}
            </IonMenuToggle>
            {/* <div className="show-icon" onClick={changeVision}>
              {isVisible && <Eye size={24} />}
              {!isVisible && <EyeSlash size={24} />}
            </div> */}
          </div>
        </IonList>

        <IonList id="inbox-list">
          <IonNote className="category">{lang.label33}</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  /* className={
                    location.pathname === appPage.url ? "selected" : ""
                  } */
                  routerLink={appPage.url}
                  routerDirection="forward"
                  lines="none"
                  detail={false}
                  onClick={
                    authValues.user.id === "guest"
                      ? (e) => {
                          e.preventDefault();
                          setShowGuestModal(true);
                        }
                      : () => console.log("")
                  }
                >
                  <IonIcon
                    slot="start"
                    style={{ marginRight: "12px" }}
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        {/* <IonList id="labels-list">
                    <IonListHeader>Labels</IonListHeader>
                    {labels.map((label, index) => (
                        <IonItem lines="none" key={index}>
                            <IonIcon slot="start" icon={bookmarkOutline} />
                            <IonLabel>{label}</IonLabel>
                        </IonItem>
                    ))}
                </IonList> */}

        {authValues.user.guide && (
          <IonList id="inbox-list">
            <IonNote className="category">{lang.label100}</IonNote>
            {appPages2.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    routerLink={appPage.url}
                    routerDirection="forward"
                    lines="none"
                    detail={false}
                    onClick={
                      authValues.user.id === "guest"
                        ? (e) => {
                            e.preventDefault();
                            setShowGuestModal(true);
                          }
                        : () => console.log("")
                    }
                  >
                    <IonIcon
                      slot="start"
                      style={{ marginRight: "12px" }}
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>
        )}

        <IonList id="inbox-list">
          <IonNote className="category">{lang.label40}</IonNote>
          {appPages3.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  routerLink={appPage.url}
                  routerDirection="forward"
                  lines="none"
                  detail={false}
                  onClick={
                    authValues.user.id === "guest"
                      ? (e) => {
                          if (index !== 0) {
                            e.preventDefault();
                            setShowGuestModal(true);
                          }
                        }
                      : () => console.log("")
                  }
                >
                  <IonIcon
                    slot="start"
                    style={{ marginRight: "12px" }}
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="inbox-list">
          <IonNote className="category">{lang.label44}</IonNote>
          {appPages4.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  routerLink={appPage.url}
                  routerDirection="forward"
                  lines="none"
                  detail={false}
                  onClick={
                    authValues.user.id === "guest"
                      ? (e) => {
                          if (index === 0) {
                            e.preventDefault();
                            setShowGuestModal(true);
                          }
                        }
                      : () => console.log("")
                  }
                >
                  <IonIcon
                    slot="start"
                    style={{ marginRight: "12px" }}
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        {(isPlatform("capacitor") || authValues.user.id !== "guest") && (
          <IonList id="inbox-list">
            {appPages5.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    onClick={() => {
                      logout();
                      history.push("/login");
                    }}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon
                      slot="start"
                      style={{ marginRight: "12px" }}
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>
        )}
      </IonContent>
      <IonModal
        isOpen={showCropModal}
        cssClass="modal-cropper"
        backdropDismiss
        animated={false}
        onDidDismiss={() => {
          setShowCropModal(false);
          setShowSlow(false);
        }}
      >
        <IonContent>
          <div className="crop-container">
            {showCropModal && showSlow && (
              <Cropper
                image={cameraImage}
                crop={crop}
                zoom={zoom}
                cropShape="round"
                aspect={1}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </div>

          <div style={{ justifyContent: "center", display: "flex" }}>
            <IonButton
              onClick={() => {
                setShowCropModal(false);
              }}
              color="danger"
            >
              {lang.label166}
            </IonButton>
            <IonButton
              onClick={() => {
                showCroppedImage();
              }}
              color="primary"
            >
              {lang.label167}
            </IonButton>
          </div>
        </IonContent>
      </IonModal>
    </IonMenu>
  );
};

async function takePicture() {
  const image = await Camera.getPhoto({
    quality: 75,
    width: 2048,
    direction: CameraDirection.Front,
    resultType: CameraResultType.DataUrl,
  });
  return image.dataUrl;
}

export default Menu;
