export default function checkProperty(item: any, language: string) {
  let check = false;

  if (item === null) return "";

  item.hasOwnProperty(language.toUpperCase())
    ? (check = true)
    : (check = false);

  if (check) {
    if (
      item[language.toUpperCase()] === "" ||
      item[language.toUpperCase()] === null
    ) {
      let temp = Object.values(item);
      temp = temp.filter((t) => t);
      if (temp.length === 0) {
        return "";
      } else {
        return temp[0];
      }
    } else {
      return item[language.toUpperCase()];
    }
  } else {
    let temp = Object.values(item);
    temp = temp.filter((t) => t);
    if (temp.length === 0) {
      return "";
    } else {
      return temp[0];
    }
  }
}
