import React, { setGlobal } from "reactn";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

/* import { defineCustomElements } from "@ionic/pwa-elements/loader"; */
import { AuthProvider } from "./my-context";

/* // Call the element loader after the platform has been bootstrapped
defineCustomElements(window); */

setGlobal({
  language: window.navigator.language.substring(0, 2) === "cs" ? "cs" : "en",
  deviceInfo: { platform: "" },
  platformInfo: "",
  appWidth: 0,
  appUrl: "",
  AdventrureId: "",
  userId: "",
  myAdventureId: "",
  reservationId: "",
  dateId: "",
  showGuestModal: false,
  showLoginModal: false,
  showLoginPage: false,
  selected: "",
  companyName: "",
  companyId: "",
  country: "",
  street: "",
  city: "",
  postal: "",
  countrySearch: [""],
  shotModal1: false,
  advFilter: {
    destinations: [""],
    categories: [""],
    persons: 1,
    dateFrom: null,
    dateTo: null,
    priceFrom: 0,
    priceTo: 0,
    language: [""],
    transit: [""],
    accommodation: [""],
    meal: [""],
    difficulty: { lower: 0, upper: 0 },
    departure: [""],
  },
  showCreateAcc: false,
  newAdventureLang: [""],
  newAdventureLangCurrent: "",
  newAdventure: {
    image: "",
    images: [],
    difficulty: 0,
    meals: "",
    transit: "",
    accommodation: "",
    categories: [""],
    locations: [
      {
        type: "ADDRESS",
        latitude: null,
        longitude: null,
        airport: null,
        country: null,
        region: null,
        city: null,
        address: null,
        countryText: { CS: "", EN: "" },
      },
    ],
    departure: {
      type: "",
      latitude: null,
      longitude: null,
      airport: null,
      country: null,
      region: null,
      city: null,
      address: null,
      countryText: { CS: "", EN: "" },
    },
    arrival: {
      type: "",
      latitude: null,
      longitude: null,
      airport: null,
      country: null,
      region: null,
      city: null,
      address: null,
      countryText: { CS: "", EN: "" },
    },
    dates: [
      {
        begin: "",
        end: "",
        prices: {
          EUR: 0,
          CZK: 0,
        },
        capacity: 0,
      },
    ],
    itinerary: [
      {
        titleText: { CS: "", EN: "" },
        descriptionText: { CS: "", EN: "" },
        images: [],
      },
    ],
    descriptionText: { CS: "", EN: "" },
    includedText: { CS: null, EN: null },
    notIncludedText: { CS: null, EN: null },
    accommodationText: { CS: null, EN: null },
    mealsText: { CS: null, EN: null },
    transitText: { CS: null, EN: null },
    difficultyText: { CS: null, EN: null },
    moreInfoText: { CS: null, EN: null },
    nameText: {},
    documents: [],
    partner: null,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

let debug = require("debug");

debug.enable("axios");

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
