import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonItem,
  IonButtons,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonToast,
  IonText,
  IonModal,
  IonGrid,
  IonCol,
  IonRow,
} from "@ionic/react";
import "../Tab1.css";

import "../create.css";

import AuthContext from "../../my-context";
import React, { useState, useEffect, useGlobal } from "reactn";
import { useHistory } from "react-router";

import { ArrowLeft } from "phosphor-react";
import Header from "../header";

const TripSetup: React.FC = () => {
  const { lang, db, saveNewAdventure, clearNewAdventure } =
    React.useContext(AuthContext);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const history = useHistory();
  const [language] = useGlobal("language");
  const [newAdventure, setNewAdventure] = useGlobal("newAdventure");
  const [newAdventureLang, setNewAdventureLang] = useGlobal("newAdventureLang");
  const [, setNewAdventureLangCurrent] = useGlobal("newAdventureLangCurrent");
  const [appWidth] = useGlobal("appWidth");

  useEffect(() => {
    const timer = setInterval(() => {
      saveNewAdventure();
    }, 120000);
    // clearing interval
    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (newAdventureLang.length === 1 && newAdventureLang[0] === "") {
      setNewAdventureLang([language.toUpperCase()]);
      setNewAdventureLangCurrent(language.toUpperCase());
      let tempAdv = JSON.parse(JSON.stringify(newAdventure));
      tempAdv.nameText[language.toUpperCase()] = "";
      setNewAdventure(tempAdv);
    }
    /* setNewAdventureLangCurrent(language.toUpperCase()); */
  }, [newAdventureLang]);

  const handleInputChange = (event: any, i?: number) => {
    var helpx = [...newAdventureLang];

    if (i === undefined) {
      i = 0;
    }

    switch (event.target.name) {
      case "category":
        if (event.target.value.length === 0) {
          helpx[0] = "";
          break;
        }
        helpx[0] = event.target.value;
        if (event.target.value === "CS") {
          let tempAdv = JSON.parse(JSON.stringify(newAdventure));
          delete tempAdv.nameText["EN"];
          tempAdv.nameText.CS = "";
          setNewAdventure(tempAdv);
        } else {
          let tempAdv = JSON.parse(JSON.stringify(newAdventure));
          delete tempAdv.nameText["CS"];
          tempAdv.nameText.EN = "";
          setNewAdventure(tempAdv);
        }
        setNewAdventureLangCurrent(event.target.value);
        break;
      case "category" + i:
        if (event.target.value.length === 0) {
          helpx[i] = "";
          break;
        }
        helpx[i] = event.target.value;
        break;
      default:
        break;
    }
    setNewAdventureLang(helpx);
  };

  const remLang = (index: number) => {
    let arr = [...newAdventureLang];
    let tempAdv = JSON.parse(JSON.stringify(newAdventure));
    if (
      newAdventureLang.filter((la: string) => (la = newAdventureLang[index]))
        .length === 1
    ) {
      delete tempAdv.nameText[arr[index]];
      tempAdv.descriptionText[arr[index]] = "";
      tempAdv.includedText[arr[index]] = "";
      tempAdv.notIncludedText[arr[index]] = "";
      tempAdv.accommodationText[arr[index]] = "";
      tempAdv.mealsText[arr[index]] = "";
      tempAdv.transitText[arr[index]] = "";
      tempAdv.difficultyText[arr[index]] = "";
      tempAdv.moreInfoText[arr[index]] = "";
      tempAdv.itinerary.forEach((it: any) => {
        it.titleText[arr[index]] = "";
        it.descriptionText[arr[index]] = "";
      });
    }
    setNewAdventure(tempAdv);
    arr.splice(index, 1);
    setNewAdventureLang(arr);
  };

  return (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={async () => {
                await saveNewAdventure();
                history.goBack();
              }}
              className="modal-search-back"
            >
              <ArrowLeft size={24} color="black" />
              <IonLabel className="page-title">{lang.label379}</IonLabel>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="web-box">
          <IonText style={{ display: "block", margin: "20px 36px 10px 36px" }}>
            {lang.label380}
          </IonText>
          <IonLabel className="user-info-label">{lang.label381} *</IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonSelect
              interface="alert"
              multiple={false}
              name="category"
              placeholder={lang.label104}
              onIonChange={(event) => handleInputChange(event)}
              value={newAdventureLang[0]}
              className="user-info-input"
            >
              <IonSelectOption value="CS">{lang.label383}</IonSelectOption>
              <IonSelectOption value="EN">{lang.label384}</IonSelectOption>
            </IonSelect>
          </IonItem>
          {newAdventureLang.map((item, i) => {
            if (i === 0) return;
            return (
              <div key={i}>
                <IonLabel className="user-info-label">
                  {lang.label106} {i + 1} *
                </IonLabel>
                {i > 0 && (
                  <div
                    style={{
                      display: "inline-block",
                      float: "right",
                      position: "relative",
                      top: 0,
                      right: "36px",
                      zIndex: 1000,
                    }}
                  >
                    <IonText
                      style={{
                        fontSize: "12px",
                        textDecoration: "underline",
                      }}
                      onClick={() => remLang(i)}
                    >
                      {lang.label386}
                    </IonText>
                  </div>
                )}
                <IonItem className="user-info-item item-input-grey">
                  <IonSelect
                    interface="alert"
                    multiple={false}
                    name={"category" + i}
                    placeholder={lang.label104}
                    onIonChange={(event) => handleInputChange(event, i)}
                    value={newAdventureLang[i]}
                    className="user-info-input"
                  >
                    {newAdventureLang[0] !== "CS" && (
                      <IonSelectOption value="CS">
                        {lang.label383}
                      </IonSelectOption>
                    )}
                    {newAdventureLang[0] !== "EN" && (
                      <IonSelectOption value="EN">
                        {lang.label384}
                      </IonSelectOption>
                    )}
                  </IonSelect>
                </IonItem>
              </div>
            );
          })}
          {newAdventureLang.length < 2 && (
            <IonText
              style={{ fontSize: "12px", textDecoration: "underline" }}
              className="user-info-label"
              onClick={() => {
                let temp = [...newAdventureLang];
                let temp2 = "";
                temp[0] === "CS" ? (temp2 = "EN") : (temp2 = "CS");
                temp.push(temp2);
                setNewAdventureLang(temp);
                let tempAdv = JSON.parse(JSON.stringify(newAdventure));
                tempAdv.nameText[temp2] = "";
                setNewAdventure(tempAdv);
              }}
            >
              {lang.label385}
            </IonText>
          )}
          <IonText
            style={{
              paddingRight: "36px",
              fontSize: "12px",
              textDecoration: "underline",
              float: "right",
            }}
            className="user-info-label"
            onClick={() => {
              setShowConfirmModal(true);
            }}
          >
            {lang.label462}
          </IonText>
        </div>
      </IonContent>
      <IonToolbar slot="bottom" className="text-center safe-zone">
        <IonButton
          className="footer-button"
          onClick={async () => {
            if (
              newAdventureLang.length === 0 ||
              newAdventureLang[0] === "" ||
              newAdventureLang[1] === "" ||
              newAdventureLang[2] === "" ||
              newAdventureLang[3] === "" ||
              newAdventureLang[4] === "" ||
              newAdventureLang[5] === ""
            ) {
              setToastMessage(lang.label233);
              setShowToast(true);
            } else {
              setNewAdventureLangCurrent(newAdventureLang[0]);
              await saveNewAdventure();
              history.push("/create/trip/1");
            }
          }}
        >
          <IonLabel color="white" style={{ textTransform: "initial" }}>
            {lang.label382}
          </IonLabel>
        </IonButton>
      </IonToolbar>
      <IonModal
        isOpen={showConfirmModal}
        cssClass="small-modal"
        onDidDismiss={() => setShowConfirmModal(false)}
      >
        <IonContent fullscreen>
          <IonGrid
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
              marginTop: "8vh",
              maxWidth: "100vw",
            }}
          >
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonText style={{ width: "100%" }}>{lang.label463}</IonText>
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonButton
                  onClick={() => {
                    setShowConfirmModal(false);
                  }}
                  color="danger"
                  style={{ marginRight: "15px" }}
                >
                  {lang.label166}
                </IonButton>

                <IonButton
                  onClick={async () => {
                    await clearNewAdventure();
                    setShowConfirmModal(false);
                  }}
                  color="primary"
                  style={{ marginRight: "15px" }}
                >
                  {lang.label121}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={1200}
        color="danger"
        cssClass="toast-pass-change"
      />
    </IonPage>
  );
};

export default TripSetup;
