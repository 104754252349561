export default function setEnumText(name: string, lang: any) {
  switch (name) {
    case "Europe":
    case "europe":
      return lang.label13;
    case "Asia":
    case "asia":
      return lang.label15;
    case "Africa":
    case "africa":
      return lang.label10;
    case "Oceania":
    case "australia":
      return lang.label14;
    case "South America":
    case "southAmerica":
      return lang.label12;
    case "North America":
    case "northAmerica":
      return lang.label11;
    case "Polar":
    case "antarctica":
      return lang.label76;

    case "OWN":
      return lang.label199;
    case "VAN":
      return lang.label200;
    case "BUS":
      return lang.label201;
    case "CAR":
      return lang.label202;
    case "AIRPLANE":
      return lang.label203;
    case "TRAIN":
      return lang.label204;
    case "BOAT":
      return lang.label205;
    case "HOTEL":
      return lang.label214;
    case "HOUSE":
      return lang.label215;
    case "HOSTEL":
      return lang.label216;
    case "CAMPING":
      return lang.label217;
    case "BIVOUACKING":
      return lang.label218;
    case "OWNm":
      return lang.label199;
    case "BREAKFASTm":
      return lang.label219;
    case "BREAKFAST_AND_DINNERm":
      return lang.label220;
    case "ALL_INCLUSIVEm":
      return lang.label221;

    case "CROSS_COUNTRY_SKIING":
      return lang.label99;
    case "CANYONING":
      return lang.label93;
    case "BIKING":
      return lang.label97;
    case "EXPEDITION":
      return lang.label78;
    case "HELISKIING":
      return lang.label89;
    case "MOUNTAIN_ASCENTS":
      return lang.label82;
    case "BOATING":
      return lang.label92;
    case "ICEBERG":
      return lang.label86;
    case "CLIMBING":
      return lang.label90;
    case "SKIING":
      return lang.label85;
    case "OTHERS":
      return lang.label98;
    case "DIVING":
      return lang.label91;
    case "SIGHTSEEING":
      return lang.label84;
    case "MOUNTAIN_CROSSING":
      return lang.label83;
    case "RELAX_AND_CHILL":
      return lang.label95;
    case "ROADTRIP":
      return lang.label80;
    case "SKI_MOUNTAINEERING":
      return lang.label88;
    case "SNOWSHOES":
      return lang.label87;
    case "SNOWBOARDING":
      return lang.label96;
    case "HIKING":
      return lang.label94;
    case "VIA_FERRATA":
      return lang.label81;
    case "WEEKEND_TRIP":
      return lang.label79;

    case "UNCONFIRMED":
      return lang.label255;
    case "CONFIRMED":
      return lang.label256;
    case "PAID":
      return lang.label257;
    case "CONTRACT":
      return lang.label261;
    case "CANCELLED":
      return lang.label258;
    case "REFUNDED":
      return lang.label259;
    case "FINISHED":
      return lang.label260;

    default:
      return;
  }
}
