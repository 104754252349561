import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonItem,
  IonList,
  IonButtons,
  IonLabel,
  IonText,
  IonBackButton,
  IonInput,
  useIonLoading,
  IonToast,
} from "@ionic/react";

import "./Tab1.css";

import "./changepassword.css";

import axios from "axios";

import AuthContext from "../my-context";
import React, { useEffect, useState } from "react";
import { Eye, EyeSlash } from "phosphor-react";
import Header from "./header";
import { useGlobal } from "reactn";

const Changepassword: React.FC = () => {
  const { token, lang } = React.useContext(AuthContext);

  const [present] = useIonLoading();
  const [appWidth] = useGlobal("appWidth");

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  async function ulozUserData() {
    present("Ukládám", 300, "dots");
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .patch(
        "/user",
        {
          currentPassword: currentPassword,
          newPassword: newPassword,
        },
        config
      )
      .then((res) => {
        if (!res.data.success) {
          setToastMessage(lang.label177);
          setShowToast1(true);
        }
      })
      .catch((err) => {
        // what now?
        setToastMessage(lang.label178);
        setShowToast1(true);
        console.log(err.data);
      });
  }

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const [showToast1, setShowToast1] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleInputChange = (event: any) => {
    switch (event.target.name) {
      case "currentPassword":
        setCurrentPassword(event.target.value);
        break;
      case "newPassword":
        setNewPassword(event.target.value);
        break;
      case "newPassword2":
        setNewPassword2(event.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar /* className="back-butt" */>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tab1"
              text={lang.label43}
              className="back-button"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="web-box">
          <IonList>
            <IonLabel className="user-info-label">{lang.label179}</IonLabel>
            <IonItem className="user-info-item item-input-grey">
              <IonInput
                value={currentPassword}
                name="currentPassword"
                placeholder={lang.label105}
                type={showPassword ? "text" : "password"}
                inputmode="text"
                required={true}
                minlength={8}
                maxlength={25}
                onIonChange={(event) => handleInputChange(event)}
                onIonBlur={() => setShowPassword(false)}
                className="user-info-input"
              ></IonInput>
              {showPassword
                ? currentPassword.length > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        right: "15px",
                        bottom: "8px",
                        zIndex: 10,
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <Eye
                        size={20}
                        onClick={() => setShowPassword((state) => !state)}
                      />
                    </div>
                  )
                : currentPassword.length > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        right: "15px",
                        bottom: "8px",
                        zIndex: 10,
                        width: "24px",
                        height: "24px",
                        paddingTop: "2px",
                      }}
                    >
                      <EyeSlash
                        size={20}
                        onClick={() => setShowPassword((state) => !state)}
                      />
                    </div>
                  )}
            </IonItem>
            <IonLabel className="user-info-label">{lang.label180}</IonLabel>
            <IonItem className="user-info-item item-input-grey">
              <IonInput
                value={newPassword}
                name="newPassword"
                placeholder={lang.label105}
                type={showPassword2 ? "text" : "password"}
                inputmode="text"
                required={true}
                minlength={8}
                maxlength={25}
                onIonChange={(event) => handleInputChange(event)}
                onIonBlur={() => setShowPassword2(false)}
                className="user-info-input"
              ></IonInput>
              {showPassword2
                ? newPassword.length > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        right: "15px",
                        bottom: "8px",
                        zIndex: 10,
                        width: "24px",
                        height: "24px",
                        paddingTop: "2px",
                      }}
                    >
                      <Eye
                        size={20}
                        onClick={() => setShowPassword2((state) => !state)}
                      />
                    </div>
                  )
                : newPassword.length > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        right: "15px",
                        bottom: "8px",
                        zIndex: 10,
                        width: "24px",
                        height: "24px",
                        paddingTop: "2px",
                      }}
                    >
                      <EyeSlash
                        size={20}
                        onClick={() => setShowPassword2((state) => !state)}
                      />
                    </div>
                  )}
            </IonItem>
            <IonLabel className="user-info-label">{lang.label181}</IonLabel>
            <IonItem className="user-info-item item-input-grey">
              <IonInput
                value={newPassword2}
                name="newPassword2"
                placeholder={lang.label105}
                type={showPassword3 ? "text" : "password"}
                inputmode="text"
                required={true}
                minlength={8}
                maxlength={25}
                onIonChange={(event) => handleInputChange(event)}
                onIonBlur={() => setShowPassword3(false)}
                className="user-info-input"
                disabled={newPassword.length < 8}
              ></IonInput>
              {showPassword3
                ? newPassword2.length > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        right: "15px",
                        bottom: "8px",
                        zIndex: 10,
                        width: "24px",
                        height: "24px",
                        paddingTop: "2px",
                      }}
                    >
                      <Eye
                        size={20}
                        onClick={() => setShowPassword3((state) => !state)}
                      />
                    </div>
                  )
                : newPassword2.length > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        right: "15px",
                        bottom: "8px",
                        zIndex: 10,
                        width: "24px",
                        height: "24px",
                        paddingTop: "2px",
                      }}
                    >
                      <EyeSlash
                        size={20}
                        onClick={() => {
                          setShowPassword3((state) => !state);
                        }}
                      />
                    </div>
                  )}
            </IonItem>
            {newPassword !== newPassword2 && newPassword2.length !== 0 && (
              <IonItem className="user-info-item item-input-grey no-border">
                <IonText color="danger">{lang.label182}</IonText>
              </IonItem>
            )}
            {newPassword.length < 8 && newPassword.length > 0 && (
              <IonItem className="user-info-item item-input-grey no-border">
                <IonText color="danger">{lang.label183}</IonText>
              </IonItem>
            )}
          </IonList>
          <IonToast
            isOpen={showToast1}
            onDidDismiss={() => setShowToast1(false)}
            message={toastMessage}
            duration={1200}
            color="danger"
            cssClass="toast-pass-change"
          />
        </div>
      </IonContent>
      <IonToolbar slot="bottom" className="safe-zone web-box">
        <IonButton
          onClick={() => {
            if (currentPassword.length === 0) {
              setToastMessage(lang.label184);
              setShowToast1(true);
            } else if (newPassword.length < 8) {
              setToastMessage(lang.label185);
              setShowToast1(true);
            } else if (newPassword !== newPassword2) {
              setToastMessage(lang.label182);
              setShowToast1(true);
            } else ulozUserData();
          }}
          className="ud-save-butt"
        >
          {lang.label43}
        </IonButton>
      </IonToolbar>
    </IonPage>
  );
};

export default Changepassword;
