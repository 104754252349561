import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonItem,
  IonButtons,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonList,
  IonToast,
  IonImg,
  IonLoading,
  IonSpinner,
  IonText,
  IonRow,
  IonGrid,
  IonCol,
  IonModal,
} from "@ionic/react";
import "../Tab1.css";

import { Camera, CameraDirection, CameraResultType } from "@capacitor/camera";

import getCroppedImg from "../../components/cropImage";
import Cropper from "react-easy-crop";

import "../create.css";

import axios from "axios";

import AuthContext from "../../my-context";
import React, { useState, useEffect, useGlobal, useRef } from "reactn";
import { useHistory } from "react-router";

import { MinusCircle, Image, ArrowsClockwise, ArrowLeft } from "phosphor-react";
import { useCallback } from "react";
import Header from "../header";

const CreateTrip: React.FC = () => {
  const isFirstRender = useRef(true);
  const { token, lang, db, saveNewAdventure, checkImageCache } =
    React.useContext(AuthContext);
  const isThirdRender = useRef(false);

  const [newAdventureLang] = useGlobal("newAdventureLang");
  const [newAdventureLangCurrent, setNewAdventureLangCurrent] = useGlobal(
    "newAdventureLangCurrent"
  );

  const [uploading, setUploading] = useState(false);
  const [appWidth] = useGlobal("appWidth");

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast2, setShowToast2] = useState(false);
  const [toastMessage2, setToastMessage2] = useState("");
  const [newMainImage, setNewMainImage] = useState<string | undefined>("");

  const history = useHistory();

  const emptyCountry = [
    {
      id: 0,
      name: "",
      iso3: "",
      iso2: "",
      numeric_code: "",
      phone_code: "",
      capital: "",
      currency: "",
      currency_symbol: "",
      tld: "",
      native: "",
      region: "",
      subregion: "",
      timezones: [
        {
          zoneName: "",
          gmtOffset: 0,
          gmtOffsetName: "",
          abbreviation: "",
          tzName: "",
        },
      ],
      translations: {
        kr: "",
        br: "",
        pt: "",
        nl: "",
        hr: "",
        fa: "",
        de: "",
        es: "",
        fr: "",
        ja: "",
        it: "",
        cn: "",
        cs: "",
      },
      latitude: "",
      longitude: "",
      emoji: "",
      emojiU: "",
      states: [
        {
          id: 0,
          name: "",
          state_code: "",
          latitude: "",
          longitude: "",
          cities: [{}],
        },
      ],
    },
  ];

  useEffect(() => {
    if (isThirdRender.current === true) {
      return;
    }
    if (newAdventure.image === "") return;
    let helpX = JSON.parse(JSON.stringify(newAdventure));
    setIsLoading(true);
    const getImage = async () => {
      if (await checkImageCache(newAdventure.image)) {
        isThirdRender.current = true;
        let temp = await db.get(newAdventure.image);
        setNewMainImage(temp);
        setIsLoading(false);
      } else {
        const getImageData = async () => {
          const api = axios.create({
            baseURL: `https://app.kangelo.com/`,
          });
          var config = {
            headers: {
              "X-Auth-Token": token,
            },
          };

          api
            .get("/media/" + helpX.image, config)
            .then(async (res) => {
              isThirdRender.current = true;
              setNewMainImage(res.data.data);
              await db.set(helpX.image, res.data.data);
              setIsLoading(false);
            })
            .catch((err) => {
              // what now?
              console.log(err.data);
            });
        };
        getImageData();
      }
    };
    getImage();
  }, [newMainImage]);

  const [isLoading, setIsLoading] = useState(false);

  const [language] = useGlobal("language");
  const [newAdventure, setNewAdventure] = useGlobal("newAdventure");
  const [countrySearch, setCountrySearch] = useState([""]);
  const [countryFilter, setCountryFilter] = useState([[emptyCountry[0]]]);
  const [countrySearchShow, setCountrySearchShow] = useState([false]);
  const [locationSearch, setLocationSearch] = useState([""]);
  const [locationFilter, setLocationFilter] = useState([
    emptyCountry[0].states,
  ]);
  const [locationSearchShow, setLocationSearchShow] = useState([false]);

  useEffect(() => {
    let helpX = [""];
    let helpXX = [[emptyCountry[0]]];
    newAdventure.locations.forEach((location: any, index: number) => {
      helpX[index] =
        language === "en" ? location.countryText.EN : location.countryText.CS;

      if (index !== 0) helpXX[index] = [emptyCountry[0]];
    });
    setCountrySearch(helpX);
    setCountryFilter(helpXX);
    let helpY = [""];
    let helpYY = [emptyCountry[0].states];
    newAdventure.locations.forEach((location: any, index: number) => {
      helpY[index] = location.region;
      if (index !== 0) helpYY[index] = emptyCountry[0].states;
    });
    setLocationSearch(helpY);
    setLocationFilter(helpYY);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      saveNewAdventure();
    }, 120000);
    // clearing interval
    return () => clearInterval(timer);
  });

  const handleInputChange = (event: any) => {
    var helpx = { ...newAdventure };

    switch (event.target.name) {
      case "name":
        helpx.nameText[newAdventureLangCurrent] = event.target.value;
        break;
      case "category":
        if (event.target.value.length === 0) {
          helpx.categories = [""];
          break;
        }
        helpx.categories = event.target.value;
        break;
      default:
        break;
    }
    setNewAdventure(helpx);
  };

  function searchFunction(e: any, index: number) {
    if (!e) {
      return;
    }
    let searchTemp = [...countrySearch];
    searchTemp[index] = e;
    setCountrySearch(searchTemp);
    if (e.length > 2) {
      const countries =
        require("../../assets/countries_states_cities/countries+states+cities.json")?.filter(
          (item: any) =>
            item.name.toLowerCase().includes(e.toLowerCase()) ||
            item.translations.cs.toLowerCase().includes(e.toLowerCase()) ||
            item.native.toLowerCase().includes(e.toLowerCase())
        );
      let countriesSearch = emptyCountry;
      if (countries.length === 0) {
        countriesSearch = emptyCountry;
      } else {
        countriesSearch = countries;
      }
      let countriesFilterTemp = [...countryFilter];
      countriesFilterTemp[index] = countriesSearch;
      setCountryFilter(countriesFilterTemp);
    } else {
      let countriesFilterTemp = [...countryFilter];
      countriesFilterTemp[index] = emptyCountry;
      setCountryFilter(countriesFilterTemp);
    }
  }

  function searchFunction2(e: any, index: number) {
    if (!e) {
      return;
    }
    let searchTemp = [...locationSearch];
    searchTemp[index] = e;
    setLocationSearch(searchTemp);
    if (e.length > 2) {
      const locations = countryFilter[index][0].states.filter((item: any) =>
        item.name.toLowerCase().includes(e.toLowerCase())
      );
      let locationsSearch = emptyCountry[0].states;
      if (locations.length === 0) {
        locationsSearch = emptyCountry[0].states;
      } else {
        locationsSearch = locations;
      }
      let locationFilterTemp = [...locationFilter];
      locationFilterTemp[index] = locationsSearch;
      setLocationFilter(locationFilterTemp);
    } else {
      let locationFilterTemp = [...locationFilter];
      locationFilterTemp[index] = emptyCountry[0].states;
      setLocationFilter(locationFilterTemp);
    }
  }
  /*       const states = locationsJson.filter(
        (item) =>
          item.name.toLowerCase().includes(e.toLowerCase()) ||
          item.translations.cs.toLowerCase().includes(e.toLowerCase())
      );

      let allSearch = {};

      if (countries.length === 0) {
        allSearch.countries = [];
      } else {
        allSearch.countries = countries;
      } */

  /*       const helpDest = items.filter((item) =>
        item.location.toLowerCase().includes(e.toLowerCase())
      );
      if (helpDest.length === 0) {
        setSearchTextModalDest(empty);
      } else {
        setSearchTextModalDest(helpDest);
      }
    } else {
      setSearchTextModalAdv(empty);
      setSearchTextModalDest(empty);
    } */

  const handlePictureClick = async () => {
    const image = await takePicture();
    if (image === cameraImage) {
      setShowCropModal(true);
      setTimeout(() => setShowSlow(true), 500);
    } else setCameraImage(image);
  };

  async function uploadImage(img: string | undefined) {
    let data = {
      type: "IMAGE",
      data: img,
      name: "test",
    };
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .post("/media", data, config)
      .then(async (res) => {
        let newAdventureTemp = JSON.parse(JSON.stringify(newAdventure));
        newAdventureTemp.image = res.data.id;
        await db.set(res.data.id, img);
        setNewMainImage(img);
        setNewAdventure(newAdventureTemp);
        setUploading(false);
        setShowCropModal(false);
      })
      .catch((err) => {
        // what now?
        setUploading(false);
        setToastMessage(err.response.data);
        setShowToast(true);
        console.log(err.data);
      });
  }

  const addCountry = () => {
    let arrCountry = { ...newAdventure };
    arrCountry.locations.push({
      type: "ADDRESS",
      latitude: null,
      longitude: null,
      airport: null,
      country: "",
      region: "",
      city: "",
      address: "",
      countryText: {
        CS: "",
        EN: "",
      },
    });
    let countrySearchTemp = [...countrySearch];
    countrySearchTemp.push("");
    setCountrySearch(countrySearchTemp);
    let locationSearchTemp = [...locationSearch];
    locationSearchTemp.push("");
    setLocationSearch(locationSearchTemp);
    let countrySearchShowTemp = [...countrySearchShow];
    countrySearchShowTemp.push(false);
    setCountrySearchShow(countrySearchShowTemp);
    let locationSearchShowTemp = [...locationSearchShow];
    locationSearchShowTemp.push(false);
    setLocationSearchShow(locationSearchShowTemp);
    let countryFilterTemp = [...countryFilter];
    countryFilterTemp.push(emptyCountry);
    setCountryFilter(countryFilterTemp);
    let locationFilterTemp = [...locationFilter];
    locationFilterTemp.push(emptyCountry[0].states);
    setLocationFilter(locationFilterTemp);
    setNewAdventure(arrCountry);
  };

  const deleteCountry = () => {
    let arrCountry = { ...newAdventure };
    arrCountry.locations.splice(arrCountry.locations.length - 1, 1);
    let countrySearchTemp = [...countrySearch];
    countrySearchTemp.splice(countrySearchTemp.length - 1, 1);
    setCountrySearch(countrySearchTemp);
    let locationSearchTemp = [...locationSearch];
    locationSearchTemp.splice(locationSearchTemp.length - 1, 1);
    setLocationSearch(locationSearchTemp);
    let countrySearchShowTemp = [...countrySearchShow];
    countrySearchShowTemp.splice(countrySearchShowTemp.length - 1, 1);
    setCountrySearchShow(countrySearchShowTemp);
    let locationSearchShowTemp = [...locationSearchShow];
    locationSearchShowTemp.splice(locationSearchShowTemp.length - 1, 1);
    setLocationSearchShow(locationSearchShowTemp);
    let countryFilterTemp = [...countryFilter];
    countryFilterTemp.splice(countryFilterTemp.length - 1, 1);
    setCountryFilter(countryFilterTemp);
    let locationFilterTemp = [...locationFilter];
    locationFilterTemp.splice(locationFilterTemp.length - 1, 1);
    setLocationFilter(locationFilterTemp);
    setNewAdventure(arrCountry);
  };

  const [croppedImage, setCroppedImage] = useState<null | string>(null);
  const [cameraImage, setCameraImage] = useState<string>();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any | null>(null);

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const [showCropModal, setShowCropModal] = useState(false);
  const [showSlow, setShowSlow] = useState(false);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        cameraImage,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
    } catch (err) {
      console.error(err);
      setToastMessage(String(err));
      setShowToast(true);
      setUploading(false);
    }
  }, [croppedAreaPixels, rotation]);

  useEffect(() => {
    if (!isFirstRender.current) {
      setShowCropModal(true);
      setTimeout(() => setShowSlow(true), 500);
    }
    isFirstRender.current = false;
  }, [cameraImage]);

  useEffect(() => {
    if (croppedImage !== null) {
      uploadImage(croppedImage);
    }
  }, [croppedImage]);

  useEffect(() => {
    if (uploading) showCroppedImage();
  }, [uploading]);

  return (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={async () => {
                await saveNewAdventure();
                history.goBack();
              }}
              className="modal-search-back"
            >
              <ArrowLeft size={24} color="black" />
              <IonLabel className="page-title">{lang.label232}</IonLabel>
            </IonButton>
          </IonButtons>
          {newAdventureLang.length > 1 && (
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setNewAdventureLangCurrent(
                    newAdventureLangCurrent === "CS" ? "EN" : "CS"
                  );
                  setToastMessage2(
                    newAdventureLangCurrent === "CS"
                      ? lang.label426
                      : lang.label425
                  );
                  setShowToast2(true);
                }}
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                  width: "50px",
                  "--background": "transparent",
                  "--background-focused": "transparent",
                }}
              >
                {newAdventureLangCurrent === "CS" ? "CZ" : "ENG"}
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="web-box" style={{ position: "relative" }}>
          <IonLabel
            style={{ display: "block", marginTop: "5px" }}
            className="user-info-label"
          >
            {lang.label229} *
          </IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonInput
              value={newAdventure.nameText[newAdventureLangCurrent]}
              name="name"
              placeholder={lang.label373}
              inputmode="text"
              maxlength={50}
              onIonChange={(event) => handleInputChange(event)}
              className="user-info-input"
            ></IonInput>
          </IonItem>
          <IonLabel className="user-info-label">{lang.label228} *</IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonInput
              value={countrySearch[0]}
              name="location"
              placeholder={lang.label371}
              inputmode="text"
              maxlength={30}
              onIonChange={(e) => searchFunction(e.detail.value, 0)}
              onIonFocus={() => {
                let tempCountrySearchShow = [...countrySearchShow];
                tempCountrySearchShow[0] = true;
                setCountrySearchShow(tempCountrySearchShow);
              }}
              onIonBlur={() =>
                setTimeout(function () {
                  let tempCountrySearchShow = [...countrySearchShow];
                  tempCountrySearchShow[0] = false;
                  setCountrySearchShow(tempCountrySearchShow);
                }, 200)
              }
              className="user-info-input"
            ></IonInput>
          </IonItem>
          {
            <IonList className="over-search">
              {countryFilter[0][0].id !== 0 && countrySearchShow[0] && (
                <div
                  style={{
                    position: "absolute",
                    transform: "translate(-50 %, .75rem)",
                    width: "80%",
                    left: "36px",
                    border: "none",
                  }}
                  className="web-box"
                >
                  {countryFilter[0].map((item, index) => {
                    return (
                      <IonItem
                        key={index}
                        onClick={() => {
                          let tempSearch = [...countrySearch];
                          tempSearch[0] =
                            language === "en"
                              ? item.name
                              : item.translations.cs;
                          setCountrySearch(tempSearch);
                          let helpx = { ...newAdventure };
                          helpx.locations[0].country = item.iso2;
                          helpx.locations[0].countryText.CS =
                            item.translations.cs;
                          helpx.locations[0].countryText.EN = item.name;
                          setNewAdventure(helpx);
                          let tempCountrySearchShow = [...countrySearchShow];
                          tempCountrySearchShow[0] = false;
                          setCountrySearchShow(tempCountrySearchShow);
                        }}
                        style={{
                          border: "solid 1px black",
                          borderRadius: "10px",
                          zIndex: 10000,
                        }}
                      >
                        {language === "en" ? item.name : item.translations.cs}
                      </IonItem>
                    );
                  })}
                </div>
              )}
            </IonList>
          }
          <IonLabel className="user-info-label">{lang.label227}</IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonInput
              value={locationSearch[0]}
              name="location"
              placeholder={lang.label372}
              inputmode="text"
              maxlength={30}
              onIonChange={(e) => searchFunction2(e.detail.value, 0)}
              onIonFocus={() => {
                let tempLocationSearchShow = [...locationSearchShow];
                tempLocationSearchShow[0] = true;
                setLocationSearchShow(tempLocationSearchShow);
              }}
              onIonBlur={() =>
                setTimeout(function () {
                  let tempLocationSearchShow = [...locationSearchShow];
                  tempLocationSearchShow[0] = false;
                  setLocationSearchShow(tempLocationSearchShow);
                }, 200)
              }
              className="user-info-input"
            ></IonInput>
          </IonItem>
          {
            <IonList className="over-search web-box">
              {locationFilter[0][0].id !== 0 && locationSearchShow[0] && (
                <div
                  style={{
                    position: "absolute",
                    transform: "translate(-50 %, .75rem)",
                    width: "80%",
                    left: "36px",
                    zIndex: 1000,
                    border: "none",
                  }}
                  className="web-box"
                >
                  {locationFilter[0].map((item, index) => {
                    return (
                      <IonItem
                        key={index}
                        onClick={() => {
                          let tempSearch = [...locationSearch];
                          tempSearch[0] = item.name;
                          setLocationSearch(tempSearch);
                          let helpx = { ...newAdventure };
                          helpx.locations[0].region = item.name;
                          setNewAdventure(helpx);
                          let tempLocationSearchShow = [...locationSearchShow];
                          tempLocationSearchShow[0] = false;
                          setLocationSearchShow(tempLocationSearchShow);
                        }}
                        style={{
                          border: "solid 1px black",
                          borderRadius: "10px",
                          zIndex: 10000,
                        }}
                      >
                        {item.name}
                      </IonItem>
                    );
                  })}
                </div>
              )}
            </IonList>
          }
          {newAdventure.locations.map((location: any, index: number) => {
            return index !== 0 ? (
              <div key={index}>
                <IonLabel className="user-info-label">
                  {lang.label228 + " " + (index + 1) + " *"}
                </IonLabel>
                <IonItem className="user-info-item item-input-grey">
                  <IonInput
                    value={countrySearch[index]}
                    name="location"
                    placeholder={lang.label371}
                    inputmode="text"
                    maxlength={30}
                    onIonChange={(e) => searchFunction(e.detail.value, index)}
                    onIonFocus={() => {
                      let tempCountrySearchShow = [...countrySearchShow];
                      tempCountrySearchShow[index] = true;
                      setCountrySearchShow(tempCountrySearchShow);
                    }}
                    onIonBlur={() =>
                      setTimeout(function () {
                        let tempCountrySearchShow = [...countrySearchShow];
                        tempCountrySearchShow[index] = false;
                        setCountrySearchShow(tempCountrySearchShow);
                      }, 250)
                    }
                    className="user-info-input"
                  ></IonInput>
                </IonItem>
                {newAdventure.locations.length === countryFilter.length && (
                  <IonList className="over-search">
                    {countryFilter[index][0].id !== 0 &&
                      countrySearchShow[index] && (
                        <div
                          style={{
                            position: "absolute",
                            transform: "translate(-50 %, .75rem)",
                            width: "80%",
                            left: "36px",
                            border: "none",
                          }}
                        >
                          {countryFilter[index].map((item, indexos) => {
                            return (
                              <IonItem
                                key={indexos}
                                onClick={() => {
                                  let tempSearch = [...countrySearch];
                                  tempSearch[index] =
                                    language === "en"
                                      ? item.name
                                      : item.translations.cs;
                                  setCountrySearch(tempSearch);
                                  let helpx = { ...newAdventure };
                                  helpx.locations[index].country = item.iso2;
                                  helpx.locations[index].countryText.CS =
                                    item.translations.cs;
                                  helpx.locations[index].countryText.EN =
                                    item.name;
                                  setNewAdventure(helpx);
                                  let tempCountrySearchShow = [
                                    ...countrySearchShow,
                                  ];
                                  tempCountrySearchShow[index] = false;
                                  setCountrySearchShow(tempCountrySearchShow);
                                }}
                                style={{
                                  border: "solid 1px black",
                                  borderRadius: "10px",
                                  zIndex: 10000,
                                }}
                              >
                                {language === "en"
                                  ? item.name
                                  : item.translations.cs}
                              </IonItem>
                            );
                          })}
                        </div>
                      )}
                  </IonList>
                )}
                <IonLabel className="user-info-label">
                  {lang.label227 + " " + (index + 1)}
                </IonLabel>
                <IonItem className="user-info-item item-input-grey">
                  <IonInput
                    value={locationSearch[index]}
                    name="location"
                    placeholder={lang.label372}
                    inputmode="text"
                    maxlength={30}
                    onIonChange={(e) => searchFunction2(e.detail.value, index)}
                    onIonFocus={() => {
                      let tempLocationSearchShow = [...locationSearchShow];
                      tempLocationSearchShow[index] = true;
                      setLocationSearchShow(tempLocationSearchShow);
                    }}
                    onIonBlur={() =>
                      setTimeout(function () {
                        let tempLocationSearchShow = [...locationSearchShow];
                        tempLocationSearchShow[index] = false;
                        setLocationSearchShow(tempLocationSearchShow);
                      }, 250)
                    }
                    className="user-info-input"
                  ></IonInput>
                </IonItem>
                {newAdventure.locations.length === locationFilter.length && (
                  <IonList className="over-search">
                    {locationFilter[index][0].id !== 0 &&
                      locationSearchShow[index] && (
                        <div
                          style={{
                            position: "absolute",
                            transform: "translate(-50 %, .75rem)",
                            width: "80%",
                            left: "36px",
                            zIndex: 1000,
                            border: "none",
                          }}
                        >
                          {locationFilter[index].map((item, indexos) => {
                            return (
                              <IonItem
                                key={indexos}
                                onClick={() => {
                                  let tempSearch = [...locationSearch];
                                  tempSearch[index] = item.name;
                                  setLocationSearch(tempSearch);
                                  let helpx = { ...newAdventure };
                                  helpx.locations[index].region = item.name;
                                  setNewAdventure(helpx);
                                  let tempLocationSearchShow = [
                                    ...locationSearchShow,
                                  ];
                                  tempLocationSearchShow[index] = false;
                                  setLocationSearchShow(tempLocationSearchShow);
                                }}
                                style={{
                                  border: "solid 1px black",
                                  borderRadius: "10px",
                                  zIndex: 10000,
                                }}
                              >
                                {item.name}
                              </IonItem>
                            );
                          })}
                        </div>
                      )}
                  </IonList>
                )}
              </div>
            ) : null;
          })}
          <IonItem className="user-info-item item-input-grey add-country">
            <IonText
              style={{ fontSize: "12px", textDecoration: "underline" }}
              onClick={() => addCountry()}
            >
              {lang.label370}
            </IonText>
            {/* <PlusCircle
            size={34}
            className="add-country"
            onClick={() => addCountry()}
          /> */}
            {/* <IonLabel>{lang.label234}</IonLabel> */}
            {newAdventure.locations.length === 1 ? null : (
              <MinusCircle
                size={34}
                className="add-country"
                style={{
                  position: "absolute",
                  marginLeft: "10px",
                  right: "0px",
                }}
                onClick={() => deleteCountry()}
              />
            )}
          </IonItem>
          <IonLabel className="user-info-label">{lang.label8} *</IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonSelect
              interface="alert"
              multiple={true}
              name="category"
              placeholder={lang.label104}
              onIonChange={(event) => handleInputChange(event)}
              value={newAdventure.categories}
              className="user-info-input"
            >
              <IonSelectOption value="CROSS_COUNTRY_SKIING">
                {lang.label99}
              </IonSelectOption>
              <IonSelectOption value="CANYONING">
                {lang.label93}
              </IonSelectOption>
              <IonSelectOption value="BIKING">{lang.label97}</IonSelectOption>
              <IonSelectOption value="EXPEDITION">
                {lang.label78}
              </IonSelectOption>
              <IonSelectOption value="HELISKIING">
                {lang.label89}
              </IonSelectOption>
              <IonSelectOption value="MOUNTAIN_ASCENTS">
                {lang.label82}
              </IonSelectOption>
              <IonSelectOption value="BOATING">{lang.label92}</IonSelectOption>
              <IonSelectOption value="ICEBERG">{lang.label86}</IonSelectOption>
              <IonSelectOption value="CLIMBING">{lang.label90}</IonSelectOption>
              <IonSelectOption value="SKIING">{lang.label85}</IonSelectOption>
              <IonSelectOption value="OTHERS">{lang.label98}</IonSelectOption>
              <IonSelectOption value="DIVING">{lang.label91}</IonSelectOption>
              <IonSelectOption value="SIGHTSEEING">
                {lang.label84}
              </IonSelectOption>
              <IonSelectOption value="MOUNTAIN_CROSSING">
                {lang.label83}
              </IonSelectOption>
              <IonSelectOption value="RELAX_AND_CHILL">
                {lang.label95}
              </IonSelectOption>
              <IonSelectOption value="ROADTRIP">{lang.label80}</IonSelectOption>
              <IonSelectOption value="SKI_MOUNTAINEERING">
                {lang.label88}
              </IonSelectOption>
              <IonSelectOption value="SNOWSHOES">
                {lang.label87}
              </IonSelectOption>
              <IonSelectOption value="SNOWBOARDING">
                {lang.label96}
              </IonSelectOption>
              <IonSelectOption value="HIKING">{lang.label94}</IonSelectOption>
              <IonSelectOption value="VIA_FERRATA">
                {lang.label81}
              </IonSelectOption>
              <IonSelectOption value="WEEKEND_TRIP">
                {lang.label79}
              </IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonLabel className="user-info-label">{lang.label231} *</IonLabel>
          <IonItem
            className="user-info-item item-input-grey no-border"
            style={{ height: "auto" }}
          >
            {newMainImage === "" ? (
              !isLoading ? (
                <div style={{ marginTop: "0px", width: "100%" }}>
                  <IonButton
                    style={{
                      display: "block",
                      height: "10vh",
                      width: "100%",
                      /* position: "absolute",
                    right: "0",
                    left: 0, */
                      "--background": "#ebebeb",
                      "--border-radius": "10px",
                      "--box-shadow": "none",
                      color: "#3fd0d4",
                    }}
                    onClick={() => handlePictureClick()}
                  >
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <IonRow
                            style={{
                              justifyContent: "center",
                              marginBottom: "5px",
                            }}
                          >
                            <Image size={35} style={{ width: "80%" }} />
                          </IonRow>
                          <IonRow style={{ justifyContent: "center" }}>
                            <IonText style={{ textTransform: "initial" }}>
                              {lang.label333}
                            </IonText>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonButton>
                </div>
              ) : (
                <IonSpinner className="spinner-loading" color="primary" />
              )
            ) : (
              <div>
                <IonImg
                  onClick={() => handlePictureClick()}
                  style={{ paddingTop: "20px" }}
                  src={newMainImage}
                ></IonImg>

                <ArrowsClockwise
                  size={35}
                  color="#4ED4D7"
                  style={{
                    position: "absolute",
                    top: "22px",
                    right: "5px",
                  }}
                  onClick={() => handlePictureClick()}
                />
              </div>
            )}
          </IonItem>
        </div>
      </IonContent>
      <IonToolbar slot="bottom" className="text-center safe-zone">
        <IonButton
          className="footer-button"
          onClick={async () => {
            let check = false;
            if (newAdventureLang.length > 1) {
              newAdventure.locations.forEach((loc: any, i: number) => {
                if (loc.countryText.CS === "" || loc.countryText.EN === "") {
                  check = true;
                }
              });
              if (
                newAdventure.nameText.CS === "" ||
                newAdventure.nameText.EN === "" ||
                newAdventure.image === "" ||
                newAdventure.categories[0] === ""
              ) {
                check = true;
              }
              if (check) {
                setToastMessage(lang.label412);
                setShowToast(true);
              } else {
                await saveNewAdventure();
                history.push("/create/trip/2");
              }
            } else {
              newAdventure.locations.forEach((loc: any, i: number) => {
                if (loc.countryText[newAdventureLangCurrent] === "") {
                  check = true;
                }
              });
              if (
                newAdventure.nameText[newAdventureLangCurrent] === "" ||
                newAdventure.image === "" ||
                newAdventure.categories[0] === ""
              ) {
                check = true;
              }
              if (check) {
                setToastMessage(lang.label233);
                setShowToast(true);
              } else {
                await saveNewAdventure();
                history.push("/create/trip/2");
              }
            }
          }}
        >
          <IonLabel color="white" style={{ textTransform: "initial" }}>
            {lang.label387}
          </IonLabel>
        </IonButton>
      </IonToolbar>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={1200}
        color="danger"
        cssClass="toast-pass-change"
      />

      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message={toastMessage2}
        duration={2000}
        color="success"
        cssClass="toast-pass-change"
      />

      <IonModal
        isOpen={showCropModal}
        cssClass="modal-cropper"
        backdropDismiss
        onDidDismiss={() => {
          setShowCropModal(false);
          setShowSlow(false);
        }}
      >
        <IonContent>
          <div className="crop-container">
            {showCropModal && showSlow && (
              <Cropper
                image={cameraImage}
                crop={crop}
                zoom={zoom}
                cropShape="rect"
                aspect={16 / 10}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </div>

          <div style={{ justifyContent: "center", display: "flex" }}>
            <IonButton
              onClick={() => {
                setShowCropModal(false);
              }}
              color="danger"
            >
              {lang.label166}
            </IonButton>
            <IonButton
              onClick={() => {
                setUploading(true);
              }}
              color="primary"
            >
              {lang.label167}
            </IonButton>
          </div>
        </IonContent>
        <IonLoading
          cssClass="loading-spinner"
          isOpen={uploading}
          onDidDismiss={() => setUploading(false)}
          message={lang.label222}
        />
      </IonModal>
    </IonPage>
  );
};

async function takePicture() {
  const image = await Camera.getPhoto({
    quality: 75,
    width: 2048,
    direction: CameraDirection.Front,
    resultType: CameraResultType.DataUrl,
  });
  return image.dataUrl;
}

export default CreateTrip;
