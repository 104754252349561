import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonItem,
  IonButtons,
  IonLabel,
  IonBackButton,
  IonList,
  IonReorderGroup,
  IonReorder,
  IonImg,
  IonSpinner,
  IonModal,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonLoading,
  IonToast,
  IonThumbnail,
} from "@ionic/react";

import { Camera, CameraDirection, CameraResultType } from "@capacitor/camera";

import { ArrowLeft, Image } from "phosphor-react";

import { ItemReorderEventDetail } from "@ionic/core";

import "../Tab1.css";

import "../create.css";

import axios from "axios";

import AuthContext from "../../my-context";
import React, { useEffect, useGlobal } from "reactn";

import { CaretCircleRight } from "phosphor-react";
import { useRef, useState } from "react";
import { useHistory } from "react-router";
import Header from "../header";

const CreateTrip5: React.FC = () => {
  const {
    token,
    lang,
    saveNewAdventure,
    clearNewAdventure,
    checkImageCache,
    db,
  } = React.useContext(AuthContext);

  const history = useHistory();

  const [myAdventureId, setMyAdventureId] = useGlobal("myAdventureId");
  const [appWidth] = useGlobal("appWidth");

  const isFirstRender = useRef(true);
  const isThirdRender = useRef(false);

  const [responseData, setResponseData] = useState<any | null>(null);
  const [isCreated, setIsCreated] = useState(false);

  const [uploading, setUploading] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [newAdventure, setNewAdventure] = useGlobal("newAdventure");

  const [showEditModal, setShowEditModal] = useState(false);

  const [images, setImages] = useState([""]);

  const [reorder, setReorder] = useState(true);

  useEffect(() => {
    let helpX = JSON.parse(JSON.stringify(newAdventure.images));
    helpX.forEach((h: any, i: number) => {
      if (h === null) {
        helpX[i] = "";
      }
    });
    if (helpX.length > 0) {
      setImages(helpX);
    } else setImages([""]);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      saveNewAdventure();
    }, 120000);
    // clearing interval
    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (isFirstRender.current === true) {
      isFirstRender.current = false;
      return;
    }
    if (isThirdRender.current === true) {
      return;
    }
    let helpX = JSON.parse(JSON.stringify(images));
    Promise.all(
      helpX.map(async (image: string) => {
        if (image.substring(0, 4) !== "data") {
          if (image) {
            if (await checkImageCache(image)) {
              image = await db.get(image);
              return image;
            } else {
              const getImageData = async () => {
                const api = axios.create({
                  baseURL: `https://app.kangelo.com/`,
                });
                var config = {
                  headers: {
                    "X-Auth-Token": token,
                  },
                };

                return api
                  .get("/media/" + image, config)
                  .then(async (res) => {
                    await db.set(image, res.data.data);
                    image = await res.data.data;
                  })
                  .catch((err) => {
                    // what now?
                    console.log(err.data);
                  });
              };
              await getImageData();
              return image;
            }
          } else {
            return "";
          }
        }
      })
    ).then((images) => {
      helpX = images;
      isThirdRender.current = true;
      setImages(helpX);
    });
  }, [images]);

  const addImage = () => {
    let arrDate = JSON.parse(JSON.stringify(newAdventure));
    let arrIty = [...images];
    if (arrDate.images.length === 0) {
      handlePictureClick(0);
    } else {
      arrDate.images.push("");
      arrIty.push("");

      handlePictureClick(arrIty.length - 1);
    }
  };

  const remDate = (index: number) => {
    let arrDate = JSON.parse(JSON.stringify(newAdventure));
    arrDate.images.splice(index, 1);
    if (arrDate.images.length === 1 && arrDate.images[0] === ("" || null)) {
      arrDate.images = [];
    }
    let arrIty = [...images];
    arrIty.splice(index, 1);
    if (arrIty.length === 0) {
      arrIty.push("");
    }
    setNewAdventure(arrDate);
    setImages(arrIty);
  };

  const moved = (array: any, from: number, to: number, on = 1) => {
    return (
      (array = array.slice()),
      array.splice(to, 0, ...array.splice(from, on)),
      array
    );
  };

  function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
    event.detail.complete();
    let arrHelp = [...images];
    arrHelp = moved(arrHelp, event.detail.from, event.detail.to);

    let newAdhelp = JSON.parse(JSON.stringify(newAdventure));

    newAdhelp.images = moved(
      newAdhelp.images,
      event.detail.from,
      event.detail.to
    );
    setImages(arrHelp);
    setNewAdventure(newAdhelp);
  }

  async function postAdv() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .post("/adventures", newAdventure, config)
      .then((res) => {
        setResponseData(res.data);
        setIsCreated(true);
        clearNewAdventure();
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  const handlePictureClick = async (index: number) => {
    const image = await takePicture();
    if (image) {
      setUploading(true);
      uploadImage(image, index);
    }
  };

  async function uploadImage(img: string, index: number) {
    let data = {
      type: "IMAGE",
      data: img,
    };
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .post("/media", data, config)
      .then(async (res) => {
        await db.set(res.data.id, img);
        let mediaDataTemp = [...images];
        mediaDataTemp[index] = img;
        setImages(mediaDataTemp);

        let newAdventureTemp = JSON.parse(JSON.stringify(newAdventure));
        newAdventureTemp.images[index] = res.data.id;
        setNewAdventure(newAdventureTemp);
        setUploading(false);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  return (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar /* className="back-butt" */>
          <IonButtons
            slot="start"
            style={{
              width: "100%",
              justifyContent: "space-between",
              marginInlineEnd: "4px",
            }}
          >
            <IonButton
              onClick={async () => {
                await saveNewAdventure();
                history.goBack();
              }}
              className="modal-search-back"
            >
              <ArrowLeft size={24} color="black" />
              <IonLabel className="page-title">{lang.label273}</IonLabel>
            </IonButton>
            <IonButton
              className="reorder-butt"
              onClick={() => {
                setReorder((state) => !state);
              }}
            >
              {!reorder && lang.label167}
              {reorder && lang.label271}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="web-box">
          <IonList style={{ margin: "0 0" }}>
            <IonReorderGroup disabled={reorder} onIonItemReorder={doReorder}>
              {images.map((oneImage: any, index: number) => {
                return (
                  <IonReorder
                    key={
                      newAdventure.images[index] !== undefined
                        ? newAdventure.images[index] + index
                        : index
                    }
                  >
                    <IonItem className="user-info-item item-input-grey">
                      <IonLabel
                        position="stacked"
                        style={{ marginBottom: "15px" }}
                      >
                        {lang.label274} {index + 1}
                      </IonLabel>
                      {oneImage === "" ? (
                        <div style={{ marginTop: "0px", width: "100%" }}>
                          <IonButton
                            style={{
                              display: "inline-block",
                              height: "100px",
                              width: "100%",
                              margin: "0 0 20px 0",
                              "--background": "#ebebeb",
                              "--border-radius": "10px",
                              "--box-shadow": "none",
                              color: "#3fd0d4",
                            }}
                            onClick={() => handlePictureClick(index)}
                          >
                            <IonGrid>
                              <IonRow>
                                <IonCol>
                                  <IonRow
                                    style={{
                                      justifyContent: "center",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <Image size={35} style={{ width: "80%" }} />
                                  </IonRow>
                                  <IonRow style={{ justifyContent: "center" }}>
                                    <IonText
                                      style={{ textTransform: "initial" }}
                                    >
                                      {lang.label333}
                                    </IonText>
                                  </IonRow>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonButton>
                        </div>
                      ) : oneImage.substring(0, 4) === "data" ? (
                        <IonThumbnail
                          style={{
                            "--border-radius": "20px",
                            width: "98%",
                            height: "auto",
                            marginLeft: "1%",
                          }}
                        >
                          <IonImg
                            onClick={() => handlePictureClick(index)}
                            className="gal-img"
                            src={oneImage}
                          ></IonImg>
                        </IonThumbnail>
                      ) : (
                        <IonSpinner
                          className="spinner-loading"
                          color="primary"
                        />
                      )}
                      {(index > 0 ||
                        (newAdventure.images.length > 0 &&
                          images[index] !== "")) && (
                        <div
                          style={{
                            position: "absolute",
                            top: "8px",
                            right: "0px",
                            zIndex: 1000,
                          }}
                        >
                          <IonText
                            style={{
                              fontSize: "12px",
                              textDecoration: "underline",
                            }}
                            onClick={() => remDate(index)}
                          >
                            {lang.label378}
                          </IonText>
                          {/* <MinusCircle
                            size={34}
                            onClick={() => remDate(index)}
                          /> */}
                        </div>
                      )}
                    </IonItem>
                  </IonReorder>
                );
              })}
            </IonReorderGroup>
            <IonText
              style={{
                textTransform: "Initial",
                marginLeft: "36px",
                textDecoration: "underline",
                fontSize: "12px",
              }}
              onClick={() => addImage()}
            >
              {lang.label374}
            </IonText>
          </IonList>
        </div>
      </IonContent>
      <IonToolbar slot="bottom" className="text-center safe-zone web-box">
        <IonButton
          className="footer-button"
          onClick={async () => {
            if (newAdventure.images.includes("" || null)) {
              setToastMessage(lang.label377);
              setShowToast(true);
            } else {
              await saveNewAdventure();
              setShowEditModal(true);
            }
          }}
        >
          <IonLabel style={{ textTransform: "initial" }} color="white">
            {lang.label275}
          </IonLabel>
        </IonButton>
      </IonToolbar>

      <IonModal
        isOpen={showEditModal}
        cssClass="small-modal"
        /* onDidDismiss={async () => await clearNewAdventure()} */
      >
        <IonContent fullscreen>
          <IonGrid
            style={{
              /* width: "max-content", */
              marginLeft: "auto",
              marginRight: "auto",
              /* height: "max-content", */
              textAlign: "center",
              marginTop: "10vh",
              maxWidth: "100vw",
            }}
          >
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                {!isCreated ? (
                  <IonText style={{ width: "100%" }}>{lang.label266}</IonText>
                ) : (
                  <IonText style={{ width: "100%" }}>{lang.label283}</IonText>
                )}
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                {!isCreated ? (
                  <IonButton
                    onClick={() => {
                      setShowEditModal(false);
                    }}
                    color="danger"
                    style={{ marginRight: "15px" }}
                  >
                    {lang.label166}
                  </IonButton>
                ) : (
                  <IonButton
                    onClick={async () => {
                      await clearNewAdventure();
                      history.replace("/tab1");
                    }}
                    color="danger"
                    style={{ marginRight: "15px" }}
                  >
                    {lang.label226}
                  </IonButton>
                )}
                {!isCreated ? (
                  <IonButton
                    onClick={() => {
                      postAdv();
                    }}
                    color="primary"
                    style={{ marginLeft: "15px" }}
                  >
                    {lang.label121}
                  </IonButton>
                ) : (
                  <IonButton
                    onClick={async () => {
                      setMyAdventureId(responseData.id);
                      await clearNewAdventure();
                      history.replace("/myadventures/" + responseData.id);
                    }}
                    color="primary"
                    style={{ marginRight: "15px" }}
                  >
                    {lang.label284}
                  </IonButton>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={1200}
        color="danger"
        cssClass="toast-pass-change"
      />
      <IonLoading
        cssClass="loading-spinner"
        isOpen={uploading}
        onDidDismiss={() => setUploading(false)}
        message={lang.label222}
      />
    </IonPage>
  );
};

async function takePicture() {
  const image = await Camera.getPhoto({
    quality: 75,
    width: 2048,
    direction: CameraDirection.Front,
    resultType: CameraResultType.DataUrl,
  });
  return image.dataUrl;
}

export default CreateTrip5;
