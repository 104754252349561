import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonItem,
  IonButtons,
  IonLabel,
  IonBackButton,
  IonSelect,
  IonSelectOption,
  IonInput,
  useIonLoading,
  IonDatetime,
  IonToast,
  IonText,
  IonModal,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import "./Tab1.css";

import "./userdetails.css";

import axios from "axios";

import AuthContext from "../my-context";
import React, { useState } from "react";

import { useEffect, useGlobal } from "reactn";
import {
  ArrowLeft,
  MagnifyingGlass,
  MinusCircle,
  PlusCircle,
} from "phosphor-react";

import { useHistory } from "react-router";
import Header from "./header";

const Userdetails: React.FC = () => {
  const { token, setAuthValues, lang, authValues, db } =
    React.useContext(AuthContext);

  const [present] = useIonLoading();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [appWidth] = useGlobal("appWidth");

  const history = useHistory();

  async function ulozUserData() {
    present("Ukládám", 500, "dots");
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .patch(
        "/user",
        {
          phone: phone,
          email: email,
          firstName: firstName,
          lastName: lastName,
          dateOfBirth: dob,
          gender: gender,
          languages: languages,
        },
        config
      )
      .then(async (res) => {
        await setAuthValues((prevState: any) => ({
          ...prevState,
          user: {
            ...prevState.user,
            phone: phone,
            email: email,
            firstName: firstName,
            lastName: lastName,
            dateOfBirth: dob,
            gender: gender,
            languages: languages,
          },
        }));
      })
      .then(async () => {
        let authTemp = { ...authValues };
        authTemp.user.phone = phone;
        authTemp.user.email = email;
        authTemp.user.firstName = firstName;
        authTemp.user.lastName = lastName;
        authTemp.user.dateOfBirth = dob;
        authTemp.user.gender = gender;
        authTemp.user.languages = languages;
        await db.set("authValues", authTemp);
        /* history.goBack(); */
        setShowConfirmModal(false);
        setShowToast2(true);
      })
      .catch((err) => {
        // what now?
        setShowConfirmModal(false);
        setToastMessage(err?.data?.message);
        setShowToast(true);
        console.log(err.data);
      });
  }

  useEffect(() => {
    setFirstName(authValues.user.firstName);
    setLastName(authValues.user.lastName);
    setPhone(authValues.user.phone);
    setEmail(authValues.user.email);
    setDob(authValues.user.dateOfBirth);
    setGender(authValues.user.gender);
    setLanguages(authValues.user.languages);
    let langJSON = require("../assets/language/languages.json");
    setAllLanguagesKeys(Object.keys(langJSON[0]));
    setAllLanguages(langJSON[0]);
  }, []);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [languages, setLanguages] = useState<any>({});
  const [allLanguages, setAllLanguages] = useState<any>({});
  const [allLanguagesFiltered, setAllLanguagesFiltered] = useState<any>([]);
  const [allLanguagesKeys, setAllLanguagesKeys] = useState([""]);
  const [language] = useGlobal("language");

  const [showEditModal, setShowEditModal] = useState(false);

  const [searchTextModal, setSearchTextModal] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [showToast2, setShowToast2] = useState(false);

  const handleInputChange = (event: any, key?: string) => {
    switch (event.target.name) {
      case "firstName":
        setFirstName(event.target.value);
        break;
      case "lastName":
        setLastName(event.target.value);
        break;
      case "gender":
        setGender(event.target.value);
        break;
      case "dob":
        setDob(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleInputChange2 = (event: any, key: string) => {
    switch (event.target.name) {
      case "langskill":
        let langTemp = { ...languages };
        langTemp[key] = event.target.value;
        setLanguages(langTemp);
        break;
      default:
        break;
    }
  };

  function showAddLanguage() {
    setShowEditModal(true);
  }

  function addLanguage(key: string) {
    let languagesTemp = { ...languages };
    languagesTemp[key] = 0;
    setLanguages(languagesTemp);
    setShowEditModal(false);
  }

  function remLanguage(key: string) {
    let languageTemp = /* { ...languages } */ JSON.parse(
      JSON.stringify(languages)
    );
    delete languageTemp[key];
    let languageKeysTemp = [...allLanguagesKeys];
    languageKeysTemp = languageKeysTemp.filter((f) => !f.includes(key));
    setAllLanguagesKeys(languageKeysTemp);
    setLanguages(languageTemp);
  }

  function validateEmail(email: string) {
    const res =
      //eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  }

  function searchFunction(e: string) {
    setSearchTextModal(e);
    if (e.length > 2) {
      const asArray = Object.entries(allLanguages);
      const filtered = asArray.filter(
        (item: any) =>
          item[1].native.toLowerCase().includes(e.toLowerCase()) ||
          item[1].cs.toLowerCase().includes(e.toLowerCase()) ||
          item[1].en.toLowerCase().includes(e.toLowerCase())
      );
      if (filtered.length > 0) {
        setAllLanguagesFiltered(filtered);
      } else {
        setAllLanguagesFiltered([]);
      }
    } else {
      setAllLanguagesFiltered([]);
    }
  }

  return (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar /* className="back-butt" */>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tab1"
              text={lang.label35}
              className="back-button"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="web-box">
          <IonLabel
            className="user-info-label"
            style={{ display: "block", marginTop: "5px" }}
          >
            {lang.label49}
          </IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonInput
              value={firstName}
              name="firstName"
              placeholder={lang.label49}
              inputmode="text"
              maxlength={25}
              onIonChange={(event) => handleInputChange(event)}
              className="user-info-input"
            ></IonInput>
          </IonItem>
          <IonLabel className="user-info-label">{lang.label50}</IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonInput
              value={lastName}
              name="lastName"
              placeholder={lang.label50}
              inputmode="text"
              maxlength={25}
              onIonChange={(event) => handleInputChange(event)}
              className="user-info-input"
            ></IonInput>
          </IonItem>
          <IonLabel className="user-info-label">{lang.label51}</IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonSelect
              value={gender}
              okText="OK"
              cancelText="Dismiss"
              className="user-info-input"
              name="gender"
              onIonChange={(event) => handleInputChange(event)}
            >
              <IonSelectOption value="M">{lang.label52}</IonSelectOption>
              <IonSelectOption value="F">{lang.label53}</IonSelectOption>
              <IonSelectOption value="X">{lang.label54}</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonLabel className="user-info-label">{lang.label55}</IonLabel>
          <IonItem className="user-info-item item-input-grey">
            {/* <IonInput
            value={dob}
            name="dob"
            placeholder="Datum vašeho narození"
            type="date"
            inputmode="decimal"
            maxlength={25}
            onIonChange={(event) => handleInputChange(event)}
          ></IonInput> */}
            <IonDatetime
              displayFormat="DD.MM.YYYY"
              value={dob}
              name="dob"
              onIonChange={(event) => handleInputChange(event)}
              className="user-info-datetime"
            ></IonDatetime>
          </IonItem>
          <IonLabel className="user-info-label">E-mail</IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonInput
              value={email}
              name="email"
              placeholder="e-mail"
              inputmode="email"
              maxlength={30}
              onIonChange={(event) => handleInputChange(event)}
              className="user-info-input"
            ></IonInput>
          </IonItem>
          <IonLabel className="user-info-label">{lang.label57}</IonLabel>
          <IonItem className="user-info-item item-input-grey">
            <IonInput
              value={phone}
              name="phone"
              placeholder={lang.label57}
              inputmode="tel"
              pattern="tel"
              type="tel"
              maxlength={25}
              onIonChange={(event) => handleInputChange(event)}
              className="user-info-input"
            ></IonInput>
          </IonItem>
          <IonLabel className="user-info-label">{lang.label58}</IonLabel>
          {Object.keys(languages).length > 0 && (
            <IonList className="user-info-item item-input-grey">
              {allLanguagesKeys.length !== 0 &&
                Object.keys(languages).map((item: string, indexos: number) => {
                  if (item === null) return;
                  if (allLanguagesKeys.length === 0) return;

                  return (
                    <IonGrid key={indexos} style={{ padding: 0 }}>
                      <IonRow>
                        <IonText>{allLanguages[item][language]}</IonText>
                      </IonRow>
                      <IonRow>
                        <IonCol style={{ width: "auto", paddingLeft: 0 }}>
                          <IonSelect
                            value={languages[item]}
                            okText="OK"
                            cancelText="Dismiss"
                            className="user-info-lang-input"
                            name="langskill"
                            onIonChange={(event) =>
                              handleInputChange2(event, item)
                            }
                          >
                            <IonSelectOption value={1}>
                              {lang.label300}
                            </IonSelectOption>
                            <IonSelectOption value={2}>
                              {lang.label301}
                            </IonSelectOption>
                            <IonSelectOption value={3}>
                              {lang.label302}
                            </IonSelectOption>
                            <IonSelectOption value={4}>
                              {lang.label303}
                            </IonSelectOption>
                            <IonSelectOption value={5}>
                              {lang.label304}
                            </IonSelectOption>
                          </IonSelect>
                        </IonCol>
                        <IonCol
                          className="minus-col"
                          /* style={{ maxWidth: "55px !important" }} */
                        >
                          <MinusCircle
                            size={36}
                            style={{ verticalAlign: "middle" }}
                            onClick={() => remLanguage(item)}
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  );
                })}
            </IonList>
          )}
          <div
            className="text-center"
            style={{
              height: "inherit",
              lineHeight: "36px",
              margin: "0 auto 5px auto",
            }}
            onClick={() => showAddLanguage()}
          >
            <PlusCircle size={36} style={{ verticalAlign: "middle" }} />
            <IonText onClick={() => showAddLanguage()}>{lang.label305}</IonText>
          </div>
        </div>
      </IonContent>
      <IonToolbar slot="bottom" className="top-border safe-zone web-box">
        <IonButton
          onClick={() => {
            if (
              validateEmail(email) &&
              phone !== "" &&
              firstName !== "" &&
              lastName !== ""
            ) {
              setShowConfirmModal(true);
            } else if (!validateEmail(email) && email !== "") {
              setToastMessage(lang.label186);
              setShowToast(true);
            } else if (email === "") {
              setToastMessage(lang.label187);
              setShowToast(true);
            } /* else if (phone === "") {
              setToastMessage(lang.label188);
              setShowToast(true);
            } */ else if (firstName === "") {
              setToastMessage(lang.label189);
              setShowToast(true);
            } else if (lastName === "") {
              setToastMessage(lang.label190);
              setShowToast(true);
            } else setShowConfirmModal(true);
          }}
          className="ud-save-butt"
        >
          {lang.label167}
        </IonButton>
      </IonToolbar>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={1200}
        color="danger"
        cssClass="toast-pass-change"
      />
      <IonModal
        isOpen={showEditModal}
        backdropDismiss
        onDidDismiss={() => {
          setShowEditModal(false);
        }}
      >
        <IonHeader
          className="main-header-search safe-zone-header"
          style={{ borderBottom: "1px solid #DEDEDE" }}
        >
          <IonButton
            onClick={() => setShowEditModal(false)}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
          <IonItem
            className="search-item searchbar-input"
            style={{ marginTop: "5px" }}
          >
            <MagnifyingGlass
              size={24}
              style={{ paddingLeft: "5px", marginLeft: "5px" }}
            />
            <IonInput
              className="modal-search"
              value={searchTextModal}
              autofocus={true}
              placeholder={lang.label432}
              onIonChange={(e) => searchFunction(e.detail.value!)}
            ></IonInput>
          </IonItem>
        </IonHeader>
        <IonContent>
          <IonList>
            {allLanguagesKeys.length > 1 && allLanguagesFiltered.length > 0
              ? allLanguagesFiltered.map((languageItem: any, index: number) => {
                  return (
                    <IonItem
                      key={index}
                      className="user-info-item item-input-grey"
                      onClick={() => addLanguage(languageItem[0])}
                    >
                      <IonLabel>
                        {(language === "cs"
                          ? languageItem[1]?.cs
                          : languageItem[1]?.en) +
                          ", " +
                          languageItem[1]?.native}
                      </IonLabel>
                    </IonItem>
                  );
                })
              : allLanguagesKeys.map((key, index) => {
                  return (
                    <IonItem
                      key={index}
                      className="user-info-item item-input-grey"
                      onClick={() => addLanguage(key)}
                    >
                      <IonLabel>
                        {(language === "cs"
                          ? allLanguages[key]?.cs
                          : allLanguages[key]?.en) +
                          ", " +
                          allLanguages[key]?.native}
                      </IonLabel>
                    </IonItem>
                  );
                })}
          </IonList>
        </IonContent>
      </IonModal>

      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message={lang.label444}
        duration={1200}
        color="success"
        cssClass="toast-pass-change"
      />
      <IonModal
        isOpen={showConfirmModal}
        /* cssClass="modal-fullscreen" */
        backdropDismiss
        onDidDismiss={() => {
          setShowConfirmModal(false);
        }}
      >
        <IonContent fullscreen>
          <IonGrid
            style={{
              width: "max-content",
              marginLeft: "auto",
              marginRight: "auto",
              height: "max-content",
              textAlign: "center",
              marginTop: "45vh",
            }}
          >
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonText style={{ width: "100%" }}>{lang.label266}</IonText>
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonButton
                  onClick={() => {
                    setShowConfirmModal(false);
                  }}
                  color="danger"
                  style={{ marginRight: "15px" }}
                >
                  {lang.label166}
                </IonButton>
                <IonButton
                  onClick={() => {
                    ulozUserData();
                  }}
                  color="primary"
                  style={{ marginLeft: "15px" }}
                >
                  {lang.label121}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          {/* </div> */}
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default Userdetails;
