import {
  IonButton,
  IonTitle,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonList,
  IonTextarea,
} from "@ionic/react";
/* import './Tab1.css'; */

import React from "react";

import AuthContext from "../../my-context";

interface Props {
  myStoryText: any;
  isDisabled: boolean;
  handleInputChange: any;
  handleButton: any;
}

const MyStory: React.FC<Props> = ({
  myStoryText,
  isDisabled,
  handleInputChange,
  handleButton,
}: Props) => {
  const { lang } = React.useContext(AuthContext);

  /* function diffOpt() {
    switch (advData.difficulty) {
      case 1:
        return "easy";
      case 2:
        return "medium";
      case 3:
        return "hard";
      case 4:
        return "very hard";
      case 5:
        return "extreme";
      default:
        return "Neznámá";
    }
  } */

  return (
    <div>
      {/* {advData.descriptionText && ( */}
      {/* <IonItem className="no-border">
        <IonText className="desc-text">
          {userData.myStoryText?.CS ? userData.myStoryText.CS : "Tvůj příběh."}
        </IonText>
      </IonItem> */}
      <IonItem
        className="edit-my-story-area no-border"
        style={{ /*  margin: "0 25px", */ width: "100%" }}
      >
        {isDisabled ? (
          <IonTextarea
            value={
              myStoryText?.CS
                ? myStoryText.CS
                : !isDisabled
                ? "Tvůj příběh."
                : myStoryText?.CS
                ? myStoryText.CS
                : ""
            }
            autoGrow={true}
            style={
              !isDisabled
                ? {
                    fontSize: "12px",
                    color: "black !important",
                    opacity: "1 !important",
                    background: "transparent",
                  }
                : {
                    fontSize: "12px",
                    color: "black !important",
                    opacity: "1 !important",
                  }
            }
            name="myStoryText"
            className={
              !isDisabled ? "edit-adv-input2-area" : "edit-adv-input-area"
            }
            disabled={!isDisabled}
            onIonChange={(event) => handleInputChange(event)}
          ></IonTextarea>
        ) : (
          <IonText
            style={{
              fontSize: "12px",
              display: "block",
              width: "90vw",
              padding: "0 15px",
              whiteSpace: "pre-line",
            }}
          >
            {myStoryText?.CS
              ? myStoryText.CS
              : !isDisabled
              ? "Tvůj příběh."
              : myStoryText.CS}
          </IonText>
        )}
      </IonItem>
      {isDisabled && (
        <IonItem className="no-border">
          <IonGrid
            style={{
              width: "max-content",
              marginLeft: "auto",
              marginRight: "auto",
              height: "max-content",
              textAlign: "center",
            }}
          >
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonButton
                  onClick={() => {
                    handleButton(0);
                  }}
                  color="danger"
                  style={{ marginRight: "15px" }}
                >
                  {lang.label166}
                </IonButton>
                <IonButton
                  onClick={() => {
                    handleButton(1);
                  }}
                  color="primary"
                  style={{ marginLeft: "15px" }}
                >
                  {lang.label121}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      )}
      {/* )} */}
      {/*       <IonTitle style={{ lineHeight: "40px", height: "40px" }}>
        <Newspaper
          size={32}
          style={{ verticalAlign: "middle", marginRight: "10px" }}
        />
        Moje cestopisy
      </IonTitle>
      <IonList>
        <IonItem>Cestopis 1</IonItem>
      </IonList> */}
    </div>
  );
};

export default MyStory;
