import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonText,
  IonList,
  IonItem,
  IonImg,
  IonButton,
  IonThumbnail,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from "@ionic/react";
import "./Tab1.css";

import axios from "axios";

import "rc-rate/assets/index.css";

import AuthContext from "../my-context";
import React, { useEffect, useRef, useState } from "react";

import defaultAdvImage from "../assets/images/default_adventure_image.jpg";
import { useHistory } from "react-router";

import { useGlobal } from "reactn";
import { ArrowLeft } from "phosphor-react";

import showImage from "../functions/showImage";
import checkProperty from "../functions/checkProperty";
import Header from "./header";

const MyAdventures: React.FC = () => {
  const isFirstRender = useRef(true);

  const history = useHistory();

  const [, setAdventureId] = useGlobal("AdventrureId");
  const [myAdventureId, setMyAdventureId] = useGlobal("myAdventureId");
  const [language] = useGlobal("language");
  const [appWidth] = useGlobal("appWidth");

  const { token, lang, checkImageCache, db } = React.useContext(AuthContext);

  const empty = [
    {
      id: "",
      image: "",
      rating: null,
      ratingCount: null,
      difficulty: null,
      guide: {
        id: "",
        email: "",
        phone: "",
        firstName: "",
        lastName: "",
        image: "",
        guide: false,
        admin: false,
        languages: {
          CS: 0,
          DE: 0,
          EN: 0,
        },
      },
      meals: "",
      transit: "",
      accommodation: "",
      categories: [""],
      location: {
        type: "",
        latitude: null,
        longitude: null,
        airport: null,
        country: "",
        region: null,
        city: null,
        address: null,
        countryText: {
          EN: "",
        },
      },
      departure: null,
      arrival: null,
      dates: [
        {
          id: "",
          begin: "",
          end: "",
          prices: {
            EUR: 0.0,
            CZK: 0.0,
          },
          capacity: 0,
          free: 0,
        },
      ],
      itinerary: [
        {
          title: "",
          description: "",
          image: "",
        },
      ],
      mealsText: null,
      transitText: null,
      difficultyText: null,
      moreInfoText: null,
      nameText: {
        CS: "",
        EN: "",
      },
      descriptionText: null,
      includedText: null,
      notIncludedText: null,
      accommodationText: null,
    },
  ];

  const [adventures, setAdventures] = useState<any>(empty);

  useEffect(() => {
    if (isFirstRender.current) {
      fetchData();
      // eslint-disable-next-line
      isFirstRender.current = false;
    }
  }, [adventures]);

  useIonViewDidEnter(() => {
    if (!isFirstRender.current) {
      fetchData();
    }
  });

  async function fetchData() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .get("/adventures/my", config)
      .then(async (res) => {
        await Promise.all(
          res.data.data.map(async (item: any) => {
            if (await checkImageCache(item.image)) {
              item.imageData = await db.get(item.image);
            } else item.imageData = await showImage(item.image, token);
            if (await checkImageCache(item.guide.profileImage)) {
              item.guide.profileImageData = await db.get(
                item.guide.profileImage
              );
            } else
              item.guide.profileImageData = await showImage(
                item.guide.profileImage,
                token
              );
            return item;
          })
        ).then((resp) => {
          /* let respTemp: any = { data: [] };
          respTemp.data = resp;
          console.log(resp); */
          setAdventures(resp);
        });
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
        console.log(err.status);
        console.log(err.statusText);
        console.log(err.headers);
        console.log(err.config);
        console.log(err);
      });
  }

  return (
    <IonPage id="main">
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar /* className="back-butt" */>
          <IonButtons slot="start" onClick={() => history.push("/tab1")}>
            <IonButton className="modal-search-back">
              <ArrowLeft size={24} color="black" />
              <IonText style={{ textTransform: "initial" }}>
                {lang.label101}
              </IonText>
            </IonButton>
            {/* <IonBackButton defaultHref="tab1" text="Moje dobrodružství" /> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className="web-box">
          <IonList>
            {adventures.map((advent: any, i: number) => {
              if (advent.nameText === null) return;
              return (
                <IonItem
                  key={i}
                  className="user-info-item item-input-grey hover-anime"
                  onClick={() => {
                    setMyAdventureId(advent.id);
                    history.push("/myadventures/" + advent.id);
                  }}
                >
                  <IonThumbnail
                    style={{
                      width: "3em",
                      objectFit: "cover",
                      height: "3em",
                      marginLeft: "5px",
                      "--border-radius": "10px",
                    }}
                  >
                    <IonImg
                      style={{}}
                      src={
                        advent.image !== "" ? advent.imageData : defaultAdvImage
                      }
                      alt={
                        advent?.nameText.hasOwnProperty("CS")
                          ? advent.nameText.CS
                          : advent.nameText.EN
                      }
                    ></IonImg>
                  </IonThumbnail>
                  <IonText style={{ marginLeft: "10px", fontSize: "12px" }}>
                    {checkProperty(advent.nameText, language)}
                  </IonText>
                </IonItem>
              );
            })}
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyAdventures;
