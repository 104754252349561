import { IonGrid, IonRow, IonText, IonCol, IonAvatar } from "@ionic/react";

import React, { useEffect, useState } from "react";

import AuthContext from "../../my-context";
import { Translate, UserCircle } from "phosphor-react";
import axios from "axios";
import { useGlobal } from "reactn";
import { useHistory } from "react-router";

interface Props {
  advData: any;
}

const AdvGuide: React.FC<Props> = ({ advData }: Props) => {
  const { lang, authValues, token } = React.useContext(AuthContext);

  const [languages, setLanguages] = useState("");

  const history = useHistory();
  const [, setUserId] = useGlobal("userId");
  const [language] = useGlobal("language");
  const [, setShowGuestModal] = useGlobal("showGuestModal");

  const [guideAdventures, setGuideAdventures] = useState([]);
  const [countriesCount, setCountriesCount] = useState(0);

  useEffect(() => {
    if (!advData.guide.languages) return;
    let langJSON = require("../../assets/language/languages.json");
    let langKeys = Object.keys(advData.guide.languages);
    langKeys.forEach((key, index) => {
      langKeys[index] = langJSON[0][key][language];
    });

    let tempText = "";
    for (var i = 0; i < langKeys.length; i++) {
      if (i === 0) {
        tempText = langKeys[i];
      } else {
        tempText = tempText + ", " + langKeys[i];
      }
    }
    setLanguages(tempText);
  }, [advData]);

  useEffect(() => {
    fetchData();
  }, [advData]);

  async function fetchData() {
    let help = [];
    let help2 = [""];
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {};
    if (authValues.user.id !== "guest") {
      config = {
        headers: {
          "X-Auth-Token": token,
        },
      };
    }
    api
      .get("/adventures/current", config)
      .then(async (res) => {
        help = res.data.data.filter(
          (d: any) => d.guide.id === advData.guide.id
        );
        setGuideAdventures(help);

        help.forEach((adv: any, i: number) => {
          help2[i] = adv.locations[0].country;
        });
        let help2unique = [""];

        help2.forEach((c) => {
          if (!help2unique.includes(c)) {
            if (help2unique[0] === "") {
              help2unique[0] = c;
            } else {
              help2unique.push(c);
            }
          }
        });

        setCountriesCount(help2unique.length);
      })
      .catch((err) => {
        console.log(err.data);
        console.log(err.status);
        console.log(err.statusText);
        console.log(err.headers);
        console.log(err.config);
        console.log(err);
      });
  }

  return (
    <div
      style={{
        borderBottom: "1px solid #DEDEDE",
        margin: "5px 20px 0 20px",
      }}
    >
      <IonText className="text-bold">{lang.label197}</IonText>
      <IonText
        className="text-bold"
        color="primary"
        onClick={
          authValues.user.id === "guest"
            ? (e) => {
                e.preventDefault();
                setShowGuestModal(true);
              }
            : () => {
                setUserId(advData.guide.id);
                history.push("/userpage/" + advData.guide.id);
              }
        }
      >
        {" "}
        {advData.guide.firstName}
      </IonText>
      {/* <IonCard className="guide-card">
        <div className="card-cont">
          <img
            src={
              advData.guide.coverImageData
                ? advData.guide.coverImageData
                : coverPhoto
            }
            alt="guide"
            className="guider-cover"
          />
        </div> */}
      {/* <IonChip className="adv-chip">
          <IonLabel>{lang.label197}</IonLabel>
        </IonChip> */}
      {/*  <IonCardContent style={{ paddingTop: "5px" }}> */}
      {/* <IonFooter> */}

      <IonGrid>
        <IonRow style={{ height: "100px" }}>
          <IonCol /* size="3" */ style={{ maxWidth: "80px" }}>
            <IonAvatar
              className="guide-avatar-adventure"
              onClick={
                authValues.user.id === "guest"
                  ? (e) => {
                      e.preventDefault();
                      setShowGuestModal(true);
                    }
                  : () => {
                      setUserId(advData.guide.id);
                      history.push("/userpage/" + advData.guide.id);
                    }
              }
            >
              {advData.guide.profileImage === null ? (
                <UserCircle
                  size={78}
                  fill="black"
                  weight="fill"
                  style={{
                    marginLeft: "-7px",
                    marginTop: "-7px",
                  }}
                />
              ) : (
                <img
                  src={advData.guide.profileImageData}
                  alt="guide"
                  className="guide-avatar-img"
                />
              )}
            </IonAvatar>
          </IonCol>
          <IonCol>
            {" "}
            {lang.label320} <strong>{advData.guide.firstName}!</strong>{" "}
            {lang.label321} <strong>{guideAdventures.length}</strong>{" "}
            <IonText style={{ textTransform: "lowercase", fontWeight: "bold" }}>
              {lang.label21} {lang.label239} {countriesCount}{" "}
              {countriesCount > 1 ? lang.label322 : lang.label228}.
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow
          style={{ lineHeight: "25px", height: "25px", display: "block" }}
        >
          <Translate
            size={21}
            style={{
              verticalAlign: "middle",
              marginRight: "5px",
              marginTop: "-2px",
            }}
            color="#3FD0D4"
          />
          {languages !== "" ? languages : lang.label332}
        </IonRow>
      </IonGrid>
      {/* </IonFooter>
        </IonCardContent>
      </IonCard> */}
    </div>
  );
};

export default AdvGuide;
