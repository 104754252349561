import { IonTitle, IonCard, IonCardContent, IonFooter, IonChip, IonText, IonAvatar, IonSpinner } from "@ionic/react";
import axios from "axios";
/* import './Tab1.css'; */
import AuthContext from "../../my-context";

import React, { useEffect, useState } from "react";
import showImage from "../../functions/showImage";
import defaultAdvImage from "../../assets/images/default_adventure_image.jpg";
import { Heart, UserCircle } from "phosphor-react";
import setLike from "../../functions/setLike";
import { useGlobal } from "reactn";
import { useHistory } from "react-router";

interface Props {
  token: string;
}

const TravelGuide: React.FC<Props> = ({ token }: Props) => {
  const { lang, db, checkImageCache } = React.useContext(AuthContext);

  useEffect(() => {
    fetchData();
  }, []);
  
  const history = useHistory();
  const [userId, setUserId] = useGlobal("userId");
  const [guidesData, setGuidesData] = useState([{ profileImageData: "",isLiked: false,id :"" }]);
  const [loading,setLoading]=useState(true);

  async function fetchData() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .get("/likes/users", config)
      .then((res) => {
        /*         let change = [...res.data.data];
        let change = change.data.data.map((item: any) => {
          item.data = empty[0].data;
        });
        console.log(change); */
        if (res.data.data.length>0) {fetchAdv(res.data.data)} else {setLoading(false)};
      })
      .catch((err) => {
        // what now?
        setLoading(false);
        console.log(err.data);
        console.log(err.status);
        console.log(err.statusText);
        console.log(err.headers);
        console.log(err.config);
        console.log(err);
      });
  }

  async function fetchAdv(prop: any) {
    let help = [{}];
    Promise.all(
      prop.map(async (x: any, index: number) => {
        const api = axios.create({
          baseURL: `https://app.kangelo.com/`,
        });
        var config = {
          headers: {
            "X-Auth-Token": token,
          },
        };
        return api
          .get("/user/" + x, config)
          .then((res) => {
            help[index] = res.data;
          })
          .catch((err) => {
            // what now?
            console.log(err.data);
            console.log(err.status);
            console.log(err.statusText);
            console.log(err.headers);
            console.log(err.config);
            console.log(err);
          });
      })
    ).then(() => setImages(help));
  }

  async function setImages(help: any) {
    Promise.all(
      help.map(async (item: any) => {
        if (await checkImageCache(item.profileImage)) {
          item.profileImageData = await db.get(item.profileImage);
        } else {
          if (item.profileImage !== "" || item.profileImage !== null) {
            item.profileImageData = await showImage(item.profileImage, token);
            await db.set(item.profileImage, item.profileImageData);
          } else item.profileImageData = "";
        }
        if (await checkImageCache(item.coverImage)) {
          item.coverImageData = await db.get(item.coverImage);
        } else {
          if (item.coverImage !== "") {
            if (item.coverImage !== null) {
              item.coverImageData = await showImage(item.coverImage, token);
              await db.set(item.coverImage, item.coverImageData);
            }
          } else item.coverImageData = "";
        }
        return item;
      })
    ).then((resp) => {
      setGuidesData(resp);
      setLoading(false)
    });

    /* setAdvData(help); */
  }

  return (
    <div className="text-center">
      {loading ? 
      <IonSpinner
          className="spinner-loading"
          color="primary"
        /> : <div>
      {guidesData.map((guide: any, i: number) => {
        if (guide === undefined) return;
        if (guide?.id === "") return;
        return (
          <IonCard key={i} className="adventures-card" onClick={()=>{setUserId(guide.id);
            history.push("/userpage/" + guide.id);}}>
            <div className="card-cont">
              <img
                src={
                  guide?.coverImageData !== ""
                    ? guide.coverImageData
                    : defaultAdvImage
                }
                alt="guide"
                className="adventure-cover"
              />
              <IonChip
                className="adv-like"
                onClick={() => {
                   let likesTemp = [...guidesData];
                  
                   likesTemp[i].isLiked = !likesTemp[i].isLiked;
                  
                  setGuidesData(likesTemp);
                  setLike(guide.id, "users", likesTemp[i].isLiked, token); 
                }}
              >
                {guide.isLiked ? (
                  <Heart
                    size={24}
                    weight=/* {likes[i] ?  */"fill"/*  : "regular"} */
                    color="#3fd0d4"
                  />
                ) : (
                  <Heart
                    size={24}
                    weight=/* {likes[i] ? "fill" : */ "duotone"/* } */
                    color="#3fd0d4"
                  />
                )}
              </IonChip>
              <IonAvatar className="guide-avatar" >
                    {guide.profileImage === null ? (
                      <UserCircle
                        size={78}
                        fill="black"
                        weight="fill"
                        style={{ marginLeft: "-7px", marginTop: "-7px" }}
                      />
                    ) : (
                      <img src={guide.profileImageData} alt="guide" />
                    )}
              </IonAvatar>
            </div>
            
            <IonCardContent>
              <IonFooter>
                <IonTitle>
                  {guide.firstName} {guide.lastName}
                </IonTitle>
              </IonFooter>
            </IonCardContent>
          </IonCard>
        );
      })}
      {guidesData.length === 1 && guidesData[0].id === "" &&<IonText>{lang.label464}</IonText>}</div>}
    </div>
  );
};

export default TravelGuide;
