import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButton,
  IonLabel,
  IonInput,
  IonItem,
  IonToast,
  IonModal,
  IonImg,
  IonSlides,
  IonSlide,
  IonList,
  IonSelect,
  IonSelectOption,
  IonRow,
  IonGrid,
  IonText,
  IonThumbnail,
  IonIcon,
} from "@ionic/react";

import AuthContext from "../my-context";
import { useHistory } from "react-router";
import { CreateAccountPage } from "./CreateAccountPage";

import { useGlobal } from "reactn";

import { languageOutline, languageSharp } from "ionicons/icons";

import logo from "../assets/images/kangelo.png";

import "./modal.css";
import "./ionic.css";
import axios from "axios";
import { ArrowLeft, Eye, EyeSlash, Gear } from "phosphor-react";

import { Keyboard } from "@capacitor/keyboard";

export const LoginPage: React.FC = () => {
  const { db, authValues, setAuthValues, setToken, lang, userData } =
    React.useContext(AuthContext);

  const [deviceInfo] = useGlobal("deviceInfo");

  if (deviceInfo.platform !== "web" && deviceInfo.platform !== "") {
    Keyboard.addListener("keyboardWillShow", () => {
      setShowText(false);
    });

    Keyboard.addListener("keyboardWillHide", () => {
      setShowText(true);
    });
  }

  //input form
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState("");

  //error screen
  const [showErrors, setShowErrors] = useState(false);

  //login page modal
  const [showModal2, setShowModal2] = useGlobal("showLoginPage");

  //become a member modal
  const [showModal3, setShowModal3] = useGlobal("showCreateAcc");

  const [showLoginModal, setShowLoginModal] = useGlobal("showLoginModal");

  const [showText, setShowText] = useState(true);

  const [deleteModal, setDeleteModal] = useState(false);

  const [language, setLanguage] = useGlobal("language");

  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPassword2, setForgotPassword2] = useState(false);
  const [forgotPassword3, setForgotPassword3] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  /*   useEffect(() => {
    setLanguage(languageOption);
  }, [languageOption]); */

  useEffect(() => {
    if (!showModal3) {
      setTimeout(() => setSlow(false), 200);
    }
  }, [showModal3]);

  useEffect(() => {
    if (!showModal2) {
      setTimeout(() => setSlow(false), 200);
    }
  }, [showModal2]);

  useEffect(() => {
    if (showPassword) {
      setTimeout(() => setShowPassword(false), 3000);
    }
  }, [showPassword]);

  const [slow, setSlow] = useState(false);

  const history = useHistory();

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast2, setShowToast2] = useState(false);
  const [toastMessage2, setToastMessage2] = useState("");

  async function login({ user, password }: { user: string; password: string }) {
    const loginData = {
      email: user,
      password: password,
    };

    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    api
      .post("/login", loginData)
      .then(async (res) => {
        await db.set("token", res.data.token);
        setToken(res.data.token);
        userData(res.data.token);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setToastMessage("Zadané přihlašovací údaje jsou chybné!");
          setShowToast(true);
        }
      });
  }

  function validateEmail(email: string) {
    const res =
      //eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  }

  /* "test@example.com" "testik123" */
  async function doLogin(em: string, pas: string) {
    if (validateEmail(em) && pas.length > 0) {
      login({
        user: em,
        password: pas,
      });
    } else if (em.length === 0 || pas.length === 0) {
      setToastMessage(lang.label233);
      setShowToast(true);
    } else if (!validateEmail(em)) {
      setToastMessage(lang.label186);
      setShowToast(true);
    } else return;
  }

  async function doLoginAsGuest() {
    db.set("token", "");
    setToken("");
    db.set("authValues", {
      authenticated: true,
      user: {
        id: "guest",
        email: "",
        phone: "",
        firstName: "Guest",
        lastName: "Mode",
        guide: false,
        admin: false,
        languages: {
          CS: 5,
          EN: 5,
        },
        partner: "",
        appLanguage: language.toUpperCase(),
        profileImage: null,
        coverImage: null,
        address: {
          type: "ADDRESS",
          latitude: null,
          longitude: null,
          airport: null,
          country: "",
          region: "",
          city: "",
          address: "",
          countryText: {
            CS: "Česko",
            EN: "Czech Republic",
          },
        },
        gender: "M",
        dateOfBirth: "2000-01-01",
      },
    });
    setAuthValues(
      {
        authenticated: true,
        user: {
          id: "guest",
          email: "",
          phone: "",
          firstName: "Guest",
          lastName: "Mode",
          guide: false,
          admin: false,
          languages: {
            CS: 5,
            EN: 5,
          },
          partner: "",
          appLanguage: language.toUpperCase(),
          profileImage: null,
          coverImage: null,
          address: {
            type: "ADDRESS",
            latitude: null,
            longitude: null,
            airport: null,
            country: "",
            region: "",
            city: "",
            address: "",
            countryText: {
              CS: "Česko",
              EN: "Czech Republic",
            },
          },
          gender: "M",
          dateOfBirth: "2000-01-01",
        },
      },
      history.push("/tab1")
    );
  }

  async function doResetViaMail(em: string) {
    if (validateEmail(em)) {
      const api = axios.create({
        baseURL: `https://app.kangelo.com/`,
      });
      api
        .post("/forgotPassword/" + em)
        .then((res) => {
          setForgotPassword2(true);
        })
        .catch((error) => {
          setToastMessage(error.message);
          setShowToast(true);
        });
    } else {
      setToastMessage(
        language === "cs"
          ? "Zadejte platný e-mail!"
          : "Fill in valid e-mail address!"
      );
      setShowToast(true);
    }
  }

  async function doVerifyCode(code: string) {
    if (code.length > 0) {
      let data = { forgotPasswordCode: code };
      const api = axios.create({
        baseURL: `https://app.kangelo.com/`,
      });
      api
        .post("/forgotPassword/" + email, data)
        .then((res) => {
          if (res.data.message === "Invalid new password") {
            setForgotPassword3(true);
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status === 400) {
            if (
              error.response.data.message === "Too late, code no longer valid"
            ) {
              setToastMessage(
                language === "cs"
                  ? "Platnost zadaného kódu již vypršela!"
                  : "Too late, code no longer valid!"
              );
              setShowToast(true);
            } else if (error.response.data.message === "Invalid code") {
              setToastMessage(
                language === "cs" ? "Neplatný kód!" : "Invalid code!"
              );
              setShowToast(true);
            } else if (error.response.data.message === "Invalid new password") {
              setForgotPassword3(true);
            } else {
              setToastMessage(
                language === "cs"
                  ? "Příliš mnoho pokusů, zažádej si o nový kód!"
                  : "Too many attempts, request a new code!"
              );
              setShowToast(true);
              setForgotPassword2(false);
              setVerificationCode("");
            }
          }
        });
    } else {
      setToastMessage(
        language === "cs" ? "Zadejte platný kód!" : "Fill in valid code!"
      );
      setShowToast(true);
    }
  }

  async function doSetNewPassword() {
    let data = {
      forgotPasswordCode: verificationCode,
      newPassword: newPassword,
    };
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    api
      .post("/forgotPassword/" + email, data)
      .then(() => {
        setForgotPassword(false);
        setForgotPassword2(false);
        setForgotPassword3(false);
        setToastMessage2(
          language === "cs"
            ? "Heslo bylo úspěšně změněno"
            : "Password changed successfully"
        );
        setShowToast2(true);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.data.message === "Too late, code no longer valid") {
          setToastMessage(
            language === "cs"
              ? "Platnost zadaného kódu již vypršela!"
              : "Too late, code no longer valid!"
          );
          setShowToast(true);
          setForgotPassword2(false);
          setForgotPassword3(false);
        } else {
          setToastMessage(error.response.data.message);
          setShowToast(true);
        }
      });
  }

  const doReg = () => {
    setSlow(true);
    setShowModal3(true);
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    /* autoplay: true, */
  };

  return (
    <IonPage className="background-img">
      {!showModal3 && !showModal2 && !slow && (
        <IonContent
          className="ion-padding"
          fullscreen
          style={{ overflowY: "hidden !important" }}
        >
          <IonSlides
            style={{ width: "100%", overflowY: "hidden" }}
            pager={true}
            options={slideOpts}
          >
            <IonSlide className="slideFirstFirst">
              <IonRow
                style={{
                  flex: 1,
                  minHeight: "50px",
                  alignItems: "flex-end",
                  maxHeight: "210px",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <IonImg
                  className="logo-login"
                  src={logo}
                  alt="kangelo"
                  style={{
                    height: "100%",
                    marginBottom: "15px",
                  }}
                ></IonImg>
              </IonRow>
              <IonRow style={{ justifyContent: "center", marginTop: "15px" }}>
                Kangelo Travel Club
              </IonRow>
              <IonRow
                style={{
                  justifyContent: "center",
                  marginTop: "15px",
                  /* marginBottom: "25%", */
                  height: "54px",
                }}
              >
                {lang.label1}
              </IonRow>
            </IonSlide>
            <IonSlide className="slideFirstFirst">
              <IonRow
                style={{
                  flex: 1,
                  minHeight: "50px",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <IonImg
                  className="logo-login"
                  src={logo}
                  alt="kangelo"
                  style={{
                    height: "100%",
                    marginBottom: "15px",
                  }}
                ></IonImg>
              </IonRow>
              <IonRow style={{ justifyContent: "center", marginTop: "15px" }}>
                {lang.label21}
              </IonRow>
              <IonRow
                style={{
                  justifyContent: "center",
                  marginTop: "15px",
                  marginBottom: "25%",
                  height: "54px",
                }}
              >
                {lang.label416}!
              </IonRow>
            </IonSlide>
            <IonSlide className="slideFirstFirst">
              <IonRow
                style={{
                  flex: 1,
                  minHeight: "50px",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <IonImg
                  className="logo-login"
                  src={logo}
                  alt="kangelo"
                  style={{
                    height: "100%",
                    marginBottom: "15px",
                  }}
                ></IonImg>
              </IonRow>
              <IonRow style={{ justifyContent: "center", marginTop: "15px" }}>
                {lang.label22}
              </IonRow>
              <IonRow
                style={{
                  justifyContent: "center",
                  marginTop: "15px",
                  marginBottom: "25%",
                  height: "54px",
                }}
              >
                {lang.label417}.
              </IonRow>
            </IonSlide>
            <IonSlide className="slideFirstFirst">
              <IonRow
                style={{
                  flex: 1,
                  minHeight: "50px",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <IonImg
                  className="logo-login"
                  src={logo}
                  alt="kangelo"
                  style={{
                    height: "100%",
                    marginBottom: "15px",
                  }}
                ></IonImg>
              </IonRow>
              <IonRow style={{ justifyContent: "center", marginTop: "15px" }}>
                {lang.label23}
              </IonRow>
              <IonRow
                style={{
                  justifyContent: "center",
                  marginTop: "15px",
                  marginBottom: "25%",
                  height: "54px",
                }}
              >
                {lang.label418}.
              </IonRow>
            </IonSlide>
            <IonSlide className="slideFirstFirst">
              <IonRow
                style={{
                  flex: 1,
                  minHeight: "50px",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <IonImg
                  className="logo-login"
                  src={logo}
                  alt="kangelo"
                  style={{
                    height: "100%",
                    marginBottom: "15px",
                  }}
                ></IonImg>
              </IonRow>
              <IonRow style={{ justifyContent: "center", marginTop: "15px" }}>
                {lang.label24}
              </IonRow>
              <IonRow
                style={{
                  justifyContent: "center",
                  marginTop: "15px",
                  marginBottom: "25%",
                  height: "54px",
                }}
              >
                {lang.label419}!
              </IonRow>
            </IonSlide>
            <IonSlide className="slideFirstFirst">
              <IonRow
                style={{
                  flex: 1,
                  minHeight: "50px",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <IonImg
                  className="logo-login"
                  src={logo}
                  alt="kangelo"
                  style={{
                    height: "100%",
                    marginBottom: "15px",
                  }}
                ></IonImg>
              </IonRow>
              <IonRow style={{ justifyContent: "center", marginTop: "15px" }}>
                {lang.label420}
              </IonRow>
              <IonRow
                style={{
                  justifyContent: "center",
                  marginTop: "15px",
                  marginBottom: "25%",
                  height: "54px",
                }}
              >
                {lang.label421}!
              </IonRow>
            </IonSlide>
          </IonSlides>
        </IonContent>
      )}
      {!showModal3 && !showModal2 && !slow && (
        <IonToolbar slot="bottom" className="log-tool-bot">
          <IonItem className="item-lang no-border">
            <IonIcon ios={languageOutline} md={languageSharp}></IonIcon>
            <IonLabel
              /* position="fixed" */ style={{
                maxWidth: "fit-content",
                marginLeft: "5px",
              }}
            >
              {lang.label106}:
            </IonLabel>
            <IonSelect
              value={language}
              onIonChange={(e) => setLanguage(e.detail.value)}
              style={{
                background: "transparent",
                maxWidth: "fit-content",
              }}
            >
              <IonSelectOption value="cs">Čeština</IonSelectOption>
              <IonSelectOption value="en">English</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonButton onClick={doReg} className="LogButt">
            {lang.label2}
          </IonButton>
          <IonButton
            onClick={() => {
              setSlow(true);
              setShowModal2(true);
            }}
            className="LogButt2"
          >
            {lang.label4}
          </IonButton>
          {authValues.user === null && (
            <IonButton onClick={() => doLoginAsGuest()} className="LogButt3">
              {lang.label3}
            </IonButton>
          )}
        </IonToolbar>
      )}
      {/* <IonFooter>
                <IonToolbar>
                    <IonRow >
                        <IonCol text-center>
                            <IonCol>
                                {<IonButton onClick={() => doLogin()}>LOGIN</IonButton>}
                                <IonButton color="secondary" onClick={() => setShowModal2(true)}>Become a member</IonButton>
                            </IonCol>
                            <IonCol>
                                {<IonButton onClick={() => history.push("/create-account")}>CREATE ACCOUNT</IonButton>}
                                <IonButton color="secondary" onClick={() => setShowModal1(true)}>Already a member</IonButton>
                            </IonCol>
                        </IonCol>
                    </IonRow>
                </IonToolbar>
            </IonFooter> */}
      {/* <IonModal
        isOpen={showModal1}
        cssClass="enter-modal"
        backdropDismiss={false}
      >
        <IonButton color="secondary" onClick={() => setShowModal3(true)}>
          Become a member
        </IonButton>
        <IonButton color="secondary" onClick={() => guestLogin()}>
          Continue as a guest
        </IonButton>
        <p style={{ textAlign: "center" }}>
          Already a member? <a>Sign in</a>
        </p>
      </IonModal> */}

      <IonModal
        isOpen={showModal2}
        cssClass={showLoginModal ? "modal-pc-login" : "modal-fullscreen"}
        backdropDismiss
        onDidDismiss={() => {
          setForgotPassword(false);
          setForgotPassword2(false);
          setForgotPassword3(false);
          setShowModal2(false);
          if (showLoginModal) {
            setShowLoginModal(false);
          }
        }}
      >
        <IonPage /* style={{ background: "transparent" }} */>
          <IonHeader
            className="back-butt safe-zone-header"
            style={{ zIndex: 2 }}
          >
            <IonButton
              className="back-butt test"
              onClick={() => {
                setShowModal2(false);
                if (showLoginModal) {
                  setTimeout(() => setShowLoginModal(false), 178);
                }
              }}
            >
              <ArrowLeft size={28} />
            </IonButton>
            <IonButton
              className="del-butt test"
              style={{ float: "right" }}
              onClick={() => {
                setDeleteModal(true);
              }}
            >
              <Gear size={28} style={{ color: "transparent" }} />
            </IonButton>
          </IonHeader>
          <IonContent className="ion-padding" fullscreen style={{ zIndex: 0 }}>
            <div
              style={{
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                height: "100%",
              }}
            >
              <IonRow
                style={{
                  justifyContent: "center",
                  height: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <IonThumbnail
                  style={{
                    height: "auto",
                    width: "25vw",
                    maxWidth: "100px",
                    position: "relative",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <IonImg
                    src={logo}
                    style={{ height: "100%", maxHeight: "100px" }}
                  />
                </IonThumbnail>
              </IonRow>
              {showText && (
                <div>
                  <IonRow
                    style={{ justifyContent: "center", marginTop: "15px" }}
                  >
                    Kangelo Travel Club
                  </IonRow>
                  <IonRow
                    style={{
                      justifyContent: "center",
                      marginTop: "15px",
                      marginBottom: "5vh",
                    }}
                  >
                    {lang.label1}
                  </IonRow>
                </div>
              )}
            </div>
          </IonContent>
          <IonToolbar
            className="ion-padding"
            style={{
              background: "rgba(255, 255, 255, 0.63)",
              height: "auto",
              borderRadius: "25px 25px 0 0",
              display: "block",
              padding: 0,
              overflow: "auto",
              zIndex: 1,
            }}
            slot="bottom"
          >
            <IonList className="list-transparent">
              <IonItem className="item-lang no-border">
                <IonIcon ios={languageOutline} md={languageSharp}></IonIcon>
                <IonLabel
                  /* position="fixed" */ style={{
                    maxWidth: "fit-content",
                    marginLeft: "5px",
                  }}
                >
                  {lang.label106}:
                </IonLabel>
                <IonSelect
                  value={language}
                  onIonChange={(e) => setLanguage(e.detail.value)}
                  style={{
                    background: "transparent",
                    maxWidth: "fit-content",
                  }}
                >
                  <IonSelectOption value="cs">Čeština</IonSelectOption>
                  <IonSelectOption value="en">English</IonSelectOption>
                </IonSelect>
              </IonItem>
              {!forgotPassword && (
                <div>
                  <IonItem className="item-transparent no-border">
                    <IonLabel position="floating">E-mail *</IonLabel>
                    <IonInput
                      style={{ maxHeight: "25px", padding: 0 }}
                      value={email}
                      type="email"
                      name="email"
                      onIonChange={(e) => {
                        setEmail(e.detail.value as any);
                      }}
                    />
                  </IonItem>
                  <IonItem className="item-transparent no-border">
                    <IonLabel position="floating">{lang.label159} *</IonLabel>
                    <IonInput
                      style={{ maxHeight: "25px", padding: 0 }}
                      value={password}
                      type={showPassword ? "text" : "password"} /* "password" */
                      name="password"
                      clearOnEdit={false}
                      onIonChange={(e) => setPassword(e.detail.value as any)}
                    />
                    {showPassword
                      ? password.length > 0 && (
                          <div
                            style={{
                              position: "absolute",
                              right: "15px",
                              bottom: "8px",
                              zIndex: 10,
                              width: "24px",
                              height: "24px",
                              paddingTop: "2px",
                            }}
                          >
                            <Eye
                              size={20}
                              onClick={() => setShowPassword((state) => !state)}
                            />
                          </div>
                        )
                      : password.length > 0 && (
                          <div
                            style={{
                              position: "absolute",
                              right: "15px",
                              bottom: "8px",
                              zIndex: 10,
                              width: "24px",
                              height: "24px",
                              paddingTop: "2px",
                            }}
                          >
                            <EyeSlash
                              size={20}
                              onClick={() => setShowPassword((state) => !state)}
                            />
                          </div>
                        )}
                  </IonItem>
                </div>
              )}
              {forgotPassword && !forgotPassword2 && (
                <div style={{ textAlign: "center" }}>
                  <IonText>{lang.label334}</IonText>

                  <IonItem
                    className="item-transparent no-border"
                    style={{ marginTop: "10px" }}
                  >
                    <IonLabel position="floating">E-mail *</IonLabel>
                    <IonInput
                      value={email}
                      type="email"
                      name="email"
                      onIonChange={(e) => setEmail(e.detail.value as any)}
                    />
                  </IonItem>
                </div>
              )}
            </IonList>
            {!forgotPassword && (
              <div>
                <IonButton
                  className="forgot-pass-butt"
                  onClick={() => setForgotPassword(true)}
                  style={{ marginBottom: "10px", textTransform: "Initial" }}
                >
                  {lang.label5}
                </IonButton>
                <IonButton
                  className="sign-up-butt"
                  onClick={() => doLogin(email, password)}
                  style={{ marginBottom: "25px" }}
                >
                  {lang.label160}
                </IonButton>
              </div>
            )}
            {forgotPassword && !forgotPassword2 && (
              <IonGrid>
                <IonRow style={{ justifyContent: "center" }}>
                  <IonButton
                    className="sign-up-butt"
                    onClick={() => setForgotPassword2(true)}
                    style={{
                      marginLeft: 0,
                      marginRight: "8px",
                      marginBottom: "25px",
                    }}
                    disabled={!validateEmail(email) || email === ""}
                  >
                    {lang.label336}
                  </IonButton>
                  <IonButton
                    className="sign-up-butt"
                    onClick={() => doResetViaMail(email)}
                    style={{
                      marginLeft: "8px",
                      marginRight: 0,
                      marginBottom: "25px",
                    }}
                    disabled={!validateEmail(email) || email === ""}
                  >
                    {lang.label173}
                  </IonButton>
                </IonRow>
              </IonGrid>
            )}
            {forgotPassword2 && !forgotPassword3 && (
              <div className="text-center">
                <IonText>
                  {lang.label335} {" " + email}
                  {lang.label461}
                </IonText>
                <IonItem
                  className="item-transparent no-border"
                  style={{ marginTop: "10px" }}
                >
                  <IonLabel position="floating">{lang.label337}</IonLabel>
                  <IonInput
                    type="text"
                    name="verificationCode"
                    required={true}
                    onIonChange={(e) =>
                      setVerificationCode(e.detail.value as any)
                    }
                  />
                </IonItem>
                <IonButton
                  className="sign-up-butt"
                  onClick={() => doVerifyCode(verificationCode)}
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  {lang.label338}
                </IonButton>
              </div>
            )}

            {forgotPassword3 && (
              <div>
                <IonItem className="item-transparent no-border">
                  <IonLabel position="floating">{lang.label180}</IonLabel>
                  <IonInput
                    type="password"
                    name="newPassword"
                    required={true}
                    minlength={8}
                    maxlength={25}
                    onIonChange={(e) => setNewPassword(e.detail.value as any)}
                  />
                </IonItem>
                <IonItem className="item-transparent no-border">
                  <IonLabel position="floating">{lang.label181}</IonLabel>
                  <IonInput
                    type="password"
                    name="newPassword2"
                    required={true}
                    minlength={8}
                    maxlength={25}
                    disabled={newPassword.length === 0}
                    onIonChange={(e) => setNewPassword2(e.detail.value as any)}
                  />
                </IonItem>
                <IonButton
                  className="sign-up-butt"
                  onClick={() => doSetNewPassword()}
                  style={{
                    marginBottom: "10px",
                  }}
                  disabled={
                    newPassword !== newPassword2 || newPassword.length < 8
                  }
                >
                  {lang.label167}
                </IonButton>
                {/* {newPassword !== newPassword2 && newPassword2.length !== 0 && ( */}
                <IonText
                  style={{
                    display: "block",
                    height: "40px",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                  color="danger"
                >
                  {newPassword !== newPassword2 &&
                    newPassword2.length !== 0 &&
                    lang.label182}
                  {newPassword !== newPassword2 &&
                    newPassword2.length !== 0 &&
                    newPassword.length < 8 &&
                    newPassword.length > 0 && <br />}
                  {newPassword.length < 8 &&
                    newPassword.length > 0 &&
                    lang.label183}
                </IonText>
              </div>
            )}
            {/*<p style={{ textAlign: "center" }}>OR</p>
                             <IonButton
                className="login-buttons"
                color="secondary"
                onClick={() => setShowModal2(false)}
              >
                Login with Facebook
              </IonButton>
              <IonButton
                className="login-buttons"
                color="secondary"
                onClick={() => setShowModal2(false)}
              >
                Login with Google
              </IonButton> */}
          </IonToolbar>
        </IonPage>
      </IonModal>

      <IonModal
        isOpen={showModal3}
        cssClass={showLoginModal ? "modal-pc-login" : "modal-fullscreen"}
        onDidDismiss={() => {
          setShowModal3(false);
          if (showLoginModal) {
            setShowLoginModal(false);
          }
        }}
      >
        <CreateAccountPage login={login} />
      </IonModal>

      <IonModal
        isOpen={deleteModal}
        /* cssClass="modal-fullscreen" */
        backdropDismiss
        onDidDismiss={() => {
          setDeleteModal(false);
        }}
      >
        <IonHeader className="main-header-search safe-zone-header">
          <IonButton
            onClick={() => setDeleteModal(false)}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
        </IonHeader>
        <IonContent fullscreen>
          <IonGrid
            style={{
              width: "max-content",
              marginLeft: "auto",
              marginRight: "auto",
              height: "max-content",
              textAlign: "center",
              marginTop: "60%",
            }}
          >
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonButton
                onClick={async () => {
                  await db.clear();
                  setDeleteModal(false);
                }}
                color="primary"
                style={{ marginRight: "15px" }}
              >
                Smazat uložená data
              </IonButton>
            </IonRow>
          </IonGrid>
          {/* </div> */}
        </IonContent>
      </IonModal>

      <IonToast
        isOpen={showErrors}
        message={authValues?.errors?.message}
        onDidDismiss={() => setShowErrors(false)}
        duration={3000}
        color="danger"
      ></IonToast>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={3000}
        color="danger"
        cssClass="toast-pass-change"
      />
      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage2}
        duration={3000}
        color="success"
        cssClass="toast-pass-change"
      />
    </IonPage>
  );
};
