import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonFooter,
  IonAvatar,
  IonLabel,
  IonModal,
  IonText,
  IonBackButton,
  IonList,
  IonItem,
  IonImg,
  IonSegment,
  IonSegmentButton,
  IonCol,
  IonRow,
  IonButton,
  IonGrid,
} from "@ionic/react";
import "./Tab1.css";

import axios from "axios";

import AuthContext from "../my-context";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { useGlobal } from "reactn";
import {
  Handshake,
  CheckCircle,
  WarningCircle,
  XCircle,
  Checks,
  ArrowCounterClockwise,
  CurrencyCircleDollar,
  ArrowLeft,
  UserCircle,
} from "phosphor-react";
import showImage from "../functions/showImage";
import Header from "./header";

interface Props {
  status: string;
}

const DateReservations: React.FC = () => {
  const isFirstRender = useRef(true);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const history = useHistory();

  const [confirmModal, setConfirmModal] = useState(false);
  const [action, setAction] = useState("");

  const [language] = useGlobal("language");
  const [userId, setUserId] = useGlobal("userId");
  const [dateId] = useGlobal("dateId");

  const [segment, setSegment] = useState<string | undefined>("default");

  const { token, lang, checkImageCache, db } = React.useContext(AuthContext);

  const [appWidth] = useGlobal("appWidth");

  const [userData, setUserData] = useState([
    {
      id: "",
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      guide: false,
      admin: false,
      languages: {},
      partner: null,
      appLanguage: null,
      profileImage: null,
      profileImageData: "",
      coverImage: null,
      isLiked: false,
      success: false,
    },
  ]);

  const [dateData, setDateData] = useState({
    begin: "",
    end: "",
    prices: {
      EUR: 0,
      CZK: 0,
    },
    capacity: 0,
    documents: [],
    reservations: [{ id: "", status: "" }],
  });

  const [emptyReservationsData] = useState([
    {
      id: "",
      status: "",
      people: [
        {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
      ],
      adventureDatesId: "",
      adventureId: "",
      price: { CZK: 0, EN: 0 },
      success: false,
    },
  ]);
  const [reservationsData, setReservationsData] = useState(
    emptyReservationsData
  );

  useEffect(() => {
    getReservations(dateId);
  }, [dateId]);

  async function getImageData(id: string) {
    if (await checkImageCache(id)) {
      return await db.get(id);
    } else {
      if (id !== "" || id !== null) {
        let tempData = await showImage(id, token);
        await db.set(id, tempData);
        return;
      } else return "";
    }
  }

  async function parseReservations() {
    let reservationsDataTemp = emptyReservationsData;
    let dateDataTempos = dateData;
    Promise.all(
      dateDataTempos.reservations.map(
        async (reservation: any, index: number) => {
          const api = axios.create({
            baseURL: `https://app.kangelo.com/`,
          });
          var config = {
            headers: {
              "X-Auth-Token": token,
            },
          };
          return api
            .get("/reservations/" + reservation.id, config)
            .then(async (response) => {
              reservationsDataTemp[index] = await response.data;
            })
            .catch((err) => {
              // what now?
              console.log(err.data);
            });
        }
      )
    ).then(() => {
      let userDataTemp = [
        {
          id: "",
          email: "",
          phone: "",
          firstName: "",
          lastName: "",
          guide: false,
          admin: false,
          languages: {},
          partner: null,
          appLanguage: null,
          profileImage: null,
          profileImageData: "",
          coverImage: null,
          isLiked: false,
          success: false,
        },
      ];

      Promise.all(
        reservationsDataTemp.map(async (reservation: any, indexik: number) => {
          const api = axios.create({
            baseURL: `https://app.kangelo.com/`,
          });
          var config = {
            headers: {
              "X-Auth-Token": token,
            },
          };
          return api
            .get("/user/" + reservation.userId, config)
            .then(async (response) => {
              userDataTemp[indexik] = await response.data;
              userDataTemp[indexik].profileImageData = await getImageData(
                response.data.profileImage
              );
            })
            .catch((err) => {
              // what now?
              console.log(err.data);
            });
        })
      ).then(() => {
        setUserData([...userDataTemp]);
        setReservationsData([...reservationsDataTemp]);
      });
    });
  }

  async function getReservations(dateIdLocal: string) {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    let dateTemp = {
      begin: "",
      end: "",
      prices: {
        EUR: 0,
        CZK: 0,
      },
      capacity: 0,
      documents: [],
      reservations: [{ id: "", status: "" }],
    };
    api
      .get("/adventures/my", config)
      .then((responsed) => {
        responsed.data.data.forEach((adventure: any) => {
          adventure.dates.forEach((date: any) => {
            if (date.id === dateIdLocal) {
              dateTemp = date;
              setDateData(dateTemp);
            }
          });
        });
      })
      .catch((err) => {
        // what now?
        console.log(err);
      });
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    parseReservations();
  }, [dateData]);

  const Paid = () => {
    return <Common status="PAID" />;
  };

  const Reserved = () => {
    return (
      <div>
        <Common status="UNCONFIRMED" />
        <Common status="CONFIRMED" />
        <Common status="CONTRACT" />
      </div>
    );
  };

  const AllReservations = () => {
    return <Common status="ALL" />;
  };

  const Common: React.FC<Props> = ({ status }: Props) => {
    return (
      <IonList className="reserv-list">
        {reservationsData.map((reservation, index) => {
          if (reservation.status === status || status === "ALL")
            return reservation.status !== "" ? (
              <IonItem
                key={index}
                onClick={() => {
                  setModalIndex(index);
                  setIsModalVisible(true);
                }}
              >
                <IonCol size="1">
                  {/* <IonThumbnail slot="start"> */}
                  <IonAvatar style={{ width: "36px", height: "36px" }}>
                    {userData[index].profileImage === null ? (
                      <UserCircle
                        size={36}
                        fill="black"
                        weight="fill"
                        /* style={{ marginLeft: "-7px", marginTop: "-7px" }} */
                      />
                    ) : (
                      <IonImg src={userData[index].profileImageData}></IonImg>
                    )}
                  </IonAvatar>
                  {/* </IonThumbnail> */}
                </IonCol>
                <IonCol>
                  <IonRow className="text-bold">
                    {userData[index].firstName}
                  </IonRow>
                  {/* <IonRow style={{ fontSize: "12px" }}>Česká Republika</IonRow> */}
                </IonCol>
                <IonCol style={{ maxWidth: "35px" }}>
                  {setStatusIcon(reservation.status)}
                </IonCol>
                <IonCol style={{ maxWidth: "35px" }}>
                  {reservation.people.length}
                </IonCol>
              </IonItem>
            ) : (
              <IonText
                key={index}
                style={{
                  display: "block",
                  paddingInline: "20px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {lang.label298}
              </IonText>
            );
        })}
      </IonList>
    );
  };

  function setStatusIcon(status: string) {
    switch (status) {
      case "CONFIRMED":
        return (
          <CheckCircle size={24} color="#05f034" style={{ marginTop: "5px" }} />
        );
      case "PAID":
        return (
          <CurrencyCircleDollar
            size={24}
            color="#05f034"
            style={{ marginTop: "5px" }}
          />
        );
      case "CONTRACT":
        return (
          <Handshake size={24} color="#05f034" style={{ marginTop: "5px" }} />
        );
      case "CANCELLED":
        return (
          <XCircle size={24} color="#fe0606" style={{ marginTop: "5px" }} />
        );
      case "REFUNDED":
        return (
          <ArrowCounterClockwise
            size={24}
            color="#fe0606"
            style={{ marginTop: "5px" }}
          />
        );
      case "FINISHED":
        return (
          <Checks size={24} color="#05f034" style={{ marginTop: "5px" }} />
        );
      default:
        return (
          <WarningCircle
            size={24}
            color="#fe0606"
            style={{ marginTop: "5px" }}
          />
        );
    }
  }

  function renderSwitch(segment: any) {
    switch (segment) {
      case "paid":
        return <Paid />;
      case "reserved":
        return <Reserved />;
      /* case "itinerary":
        return <AdvItinerary advData={advData} />;
      case "gallery":
        return <AdvGallery advData={advData} />;
      case "ratings":
        return <AdvRatings advData={advData} />; */
      default:
        return <AllReservations />;
    }
  }

  function setReservationStateText(status: string) {
    switch (status) {
      case "CONFIRMED":
        return lang.label256;
      case "PAID":
        return lang.label257;
      case "CONTRACT":
        return lang.label261;
      case "CANCELLED":
        return lang.label258;
      case "REFUNDED":
        return lang.label259;
      case "FINISHED":
        return lang.label260;
      default:
        return lang.label255;
    }
  }

  function setReservationStateColor(status: string) {
    switch (status) {
      case "CONFIRMED":
        return "action-not-required";
      case "PAID":
        return "action-required";
      case "CONTRACT":
        return "action-not-required";
      case "CANCELLED":
        return "canceled-reservation";
      case "REFUNDED":
        return "canceled-reservation";
      case "FINISHED":
        return "action-not-required";
      default:
        return "action-required";
    }
  }

  async function setReservationStatus(status: string) {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    return api
      .patch(
        "/reservations/" + reservationsData[modalIndex].id,
        { status: status },
        config
      )
      .then((response) => {
        let reservationsDataTemp = [...reservationsData];
        reservationsDataTemp[modalIndex] = response.data;
        setReservationsData(reservationsDataTemp);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  return (
    <IonPage id="main">
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader
        className="ud-header web-box"
        style={{ borderBottom: "1px solid #DEDEDE" }}
      >
        <IonToolbar /* className="back-butt" */>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tab1"
              text={lang.label37}
              className="back-button"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className="web-box">
          <IonSegment
            scrollable
            onIonChange={(e) => setSegment(e.detail.value)}
            value={segment}
            style={{ justifyContent: "center" }}
          >
            <IonSegmentButton value="default" className="segment-picker">
              <IonLabel>{lang.label152}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="reserved" className="segment-picker">
              <IonLabel>{lang.label153}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="paid" className="segment-picker">
              <IonLabel>{lang.label155}</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {renderSwitch(segment)}
        </div>
      </IonContent>

      <IonModal
        isOpen={isModalVisible}
        /* cssClass="modal-fullscreen" */
        backdropDismiss
        onDidDismiss={() => {
          setIsModalVisible(false);
        }}
      >
        {appWidth > 920 && <Header styles="shadow-box" />}
        <IonHeader
          className="back-header web-box"
          style={{
            justifyContent: "flex-start !important",
            borderBottom: "1px solid #DEDEDE",
          }}
        >
          <IonButton
            onClick={() => setIsModalVisible(false)}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
          <IonAvatar
            style={{ width: "36px", height: "36px", marginBlockStart: "10px" }}
            onClick={() => {
              setUserId(userData[modalIndex].id);
              history.push("/userpage/" + userData[modalIndex].id);
            }}
          >
            {userData[modalIndex].profileImage === null ? (
              <UserCircle
                size={36}
                fill="black"
                weight="fill"
                style={{ marginLeft: "-7px", marginTop: "0px" }}
              />
            ) : (
              <IonImg src={userData[modalIndex].profileImageData}></IonImg>
            )}
          </IonAvatar>
          <IonText
            onClick={() => {
              setUserId(userData[modalIndex].id);
              history.push("/userpage/" + userData[modalIndex].id);
            }}
            style={{ marginLeft: "10px", paddingBlockStart: "18px" }}
          >
            {userData[modalIndex].firstName}
          </IonText>
        </IonHeader>
        <IonContent fullscreen>
          <div className="web-box">
            <IonText
              className="text-bold display-block text-center"
              style={{ fontSize: "20px", marginBlockEnd: "15px" }}
            >
              {lang.label262}
            </IonText>
            <IonText
              className={
                setReservationStateColor(reservationsData[modalIndex].status) +
                " " +
                "text-bold display-block"
              }
              style={{
                fontSize: "16px",
                padding: "4px 25px",
                borderRadius: "15px",
                width: "max-content",
                marginLeft: "auto",
                marginRight: "auto",
                marginBlockEnd: "15px",
                color: "white",
              }}
            >
              {setReservationStateText(reservationsData[modalIndex].status)}
            </IonText>
            <IonList style={{ borderTop: "1px solid #DEDEDE" }}>
              {/* <IonItem className="user-info-item item-input-grey">
              <IonCol
                size="2"
                className="text-center"
                style={{ marginTop: "5px" }}
              >
                <PersonSimpleWalk size={20} color="#3fd0d4" />
              </IonCol>
              <IonCol>{lang.label263}</IonCol>
            </IonItem> */}
              {/* <IonItem className="user-info-item item-input-grey">
              <IonCol
                size="2"
                className="text-center"
                style={{ marginTop: "5px" }}
              >
                <Files size={20} color="#3fd0d4" />
              </IonCol>
              <IonCol>{lang.label116}</IonCol>
            </IonItem> */}
              <IonItem className="user-info-item item-input-grey">
                <IonCol>
                  <IonRow
                    className="text-bold display-block"
                    style={{ fontSize: "12px" }}
                  >
                    {lang.label132}
                  </IonRow>
                  <IonRow
                    className="display-block"
                    style={{ fontSize: "12px" }}
                  >
                    {reservationsData[modalIndex].people.length}
                  </IonRow>
                </IonCol>
              </IonItem>
              <IonItem className="user-info-item item-input-grey">
                <IonCol>
                  <IonRow
                    className="text-bold display-block"
                    style={{ fontSize: "12px" }}
                  >
                    {lang.label133}
                  </IonRow>
                  <IonRow
                    className="display-block"
                    style={{ fontSize: "12px" }}
                  >
                    {reservationsData[modalIndex].price.CZK.toLocaleString(
                      language === "cs" ? "cs-CZ" : "en-GB"
                    )}{" "}
                    Kč
                  </IonRow>
                </IonCol>
              </IonItem>
            </IonList>
            <IonText
              className="text-bold display-block text-center"
              style={{
                fontSize: "18px",
                marginBlockStart: "15px",
                borderBottom: "1px solid #DEDEDE",
              }}
            >
              {lang.label151}
            </IonText>
            <IonList>
              {reservationsData[modalIndex].people.map((person, index) => {
                return (
                  <IonItem
                    key={index}
                    className="user-info-item item-input-grey"
                  >
                    <IonGrid>
                      <IonRow
                        className="text-bold display-block"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {lang.label264} {index + 1}
                      </IonRow>
                      <IonCol className="display-block">
                        <IonRow
                          className="text-bold display-block"
                          style={{ fontSize: "12px" }}
                        >
                          {lang.label49}
                        </IonRow>
                        <IonRow
                          className="display-block"
                          style={{ fontSize: "12px" }}
                        >
                          {reservationsData[modalIndex].people[index].firstName}
                        </IonRow>
                        <IonRow
                          className="text-bold display-block"
                          style={{ fontSize: "12px" }}
                        >
                          {lang.label50}
                        </IonRow>
                        <IonRow
                          className="display-block"
                          style={{ fontSize: "12px" }}
                        >
                          {reservationsData[modalIndex].people[index].lastName}
                        </IonRow>
                        <IonRow
                          className="text-bold display-block"
                          style={{ fontSize: "12px" }}
                        >
                          {lang.label57}
                        </IonRow>
                        <IonRow
                          className="display-block"
                          style={{ fontSize: "12px" }}
                        >
                          {reservationsData[modalIndex].people[index].phone}
                        </IonRow>
                        <IonRow
                          className="text-bold display-block"
                          style={{ fontSize: "12px" }}
                        >
                          {lang.label56}
                        </IonRow>
                        <IonRow
                          className="display-block"
                          style={{ fontSize: "12px" }}
                        >
                          {reservationsData[modalIndex].people[index].email}
                        </IonRow>
                      </IonCol>
                    </IonGrid>
                  </IonItem>
                );
              })}
            </IonList>
          </div>
        </IonContent>
        <IonFooter
          className="reservation-footer web-box"
          style={{
            justifyContent: "flex-start !important",
            borderTop: "1px solid #DEDEDE",
          }}
        >
          {reservationsData[modalIndex].status === "UNCONFIRMED" && (
            <IonRow className="text-center">
              <IonCol>
                <IonButton
                  onClick={() => {
                    setAction("CANCELLED");
                    setConfirmModal(true);
                  }}
                  className="confirm-reservation"
                  color="danger"
                >
                  {lang.label140}
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  onClick={() => {
                    setAction("CONFIRMED");
                    setConfirmModal(true);
                  }}
                  className="confirm-reservation"
                >
                  {lang.label265}
                </IonButton>
              </IonCol>
            </IonRow>
          )}
          {reservationsData[modalIndex].status === "CONFIRMED" && (
            <IonRow className="text-center" style={{ textAlign: "center" }}>
              <IonCol>
                <IonButton
                  onClick={() => {
                    setAction("CANCELLED");
                    setConfirmModal(true);
                  }}
                  className="confirm-reservation"
                  color="danger"
                >
                  {lang.label140}
                </IonButton>
              </IonCol>
            </IonRow>
          )}
          {reservationsData[modalIndex].status === "PAID" && (
            <IonRow className="text-center">
              <IonCol>
                <IonButton
                  onClick={() => {
                    setAction("CANCELLED");
                    setConfirmModal(true);
                  }}
                  className="confirm-reservation"
                  color="danger"
                >
                  {lang.label140}
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  onClick={() => {
                    setAction("CONTRACT");
                    setConfirmModal(true);
                  }}
                  className="confirm-reservation"
                >
                  {lang.label267}
                </IonButton>
              </IonCol>
            </IonRow>
          )}
          {reservationsData[modalIndex].status === "CONTRACT" && (
            <IonRow className="text-center">
              <IonCol>
                <IonButton
                  onClick={() => {
                    setAction("CANCELLED");
                    setConfirmModal(true);
                  }}
                  className="confirm-reservation"
                  color="danger"
                >
                  {lang.label140}
                </IonButton>
              </IonCol>
            </IonRow>
          )}
        </IonFooter>
      </IonModal>
      <IonModal
        isOpen={confirmModal}
        /* cssClass="modal-fullscreen" */
        backdropDismiss
        onDidDismiss={() => {
          setConfirmModal(false);
        }}
      >
        <IonContent fullscreen>
          <IonGrid
            style={{
              /* width: "max-content", */
              marginLeft: "auto",
              marginRight: "auto",
              /* height: "max-content", */
              textAlign: "center",
              marginTop: "40vh",
            }}
          >
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonText style={{ width: "100%" }}>{lang.label266}</IonText>
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonButton
                  onClick={() => {
                    setConfirmModal(false);
                  }}
                  color="danger"
                  style={{ marginRight: "15px" }}
                >
                  {lang.label166}
                </IonButton>
                <IonButton
                  onClick={() => {
                    setReservationStatus(action);
                    setConfirmModal(false);
                  }}
                  color="primary"
                  style={{ marginLeft: "15px" }}
                >
                  {lang.label121}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          {/* </div> */}
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default DateReservations;
