import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonItem,
  IonButtons,
  IonLabel,
  IonDatetime,
  IonGrid,
  IonRow,
  IonInput,
  IonToast,
  IonText,
} from "@ionic/react";
import "../Tab1.css";

import "../create.css";

import AuthContext from "../../my-context";
import React, { useState, useEffect, useGlobal } from "reactn";

import { ArrowLeft } from "phosphor-react";
import { useHistory } from "react-router";
import moment from "moment";
import Header from "../header";

const CreateTrip2: React.FC = () => {
  const { lang, saveNewAdventure } = React.useContext(AuthContext);

  const history = useHistory();

  const [newAdventure, setNewAdventure] = useGlobal("newAdventure");
  const [appWidth] = useGlobal("appWidth");

  // @ts-ignore
  /* const [freeCapacity] = useState( */ /* [...Array(50).keys()].splice(1) */ /* 0); */

  useEffect(() => {
    const timer = setInterval(() => {
      saveNewAdventure();
    }, 120000);
    // clearing interval
    return () => clearInterval(timer);
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleDateChange = (index: number, event: any) => {
    let arrDate = { ...newAdventure };
    switch (event.target.name) {
      case "dob":
        arrDate.dates[index].begin = moment(event.target.value).format(
          "YYYY-MM-DD"
        );
        if (arrDate.dates[index].end < arrDate.dates[index].begin) {
          arrDate.dates[index].end = "";
          setToastMessage(lang.label459);
          setShowToast(true);
        }
        break;
      case "dob2":
        arrDate.dates[index].end = moment(event.target.value).format(
          "YYYY-MM-DD"
        );
        break;
      case "capacity":
        if (Number(event.target.value) < 1001) {
          arrDate.dates[index].capacity = Number(event.target.value);
        } else {
          arrDate.dates[index].capacity = 1000;
        }
        break;
      case "price":
        if (Number(event.target.value) < 1000000) {
          arrDate.dates[index].prices.CZK = Number(event.target.value);
        } else {
          arrDate.dates[index].prices.CZK = 999999;
        }
        break;
      default:
        break;
    }
    setNewAdventure(arrDate);
  };

  const addDate = () => {
    let arrDate = { ...newAdventure };
    arrDate.dates.push({
      begin: "",
      end: "",
      prices: {
        EUR: 0,
        CZK: 0,
      },
      capacity: 0,
    });
    setNewAdventure(arrDate);
  };

  const remDate = (index: number) => {
    let arrDate = { ...newAdventure };
    arrDate.dates.splice(index, 1);
    setNewAdventure(arrDate);
  };

  const customPopoverOptions = {
    header: lang.label392,
  };

  return (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={async () => {
                await saveNewAdventure();
                history.goBack();
              }}
              className="modal-search-back"
            >
              <ArrowLeft size={24} color="black" />
              <IonLabel className="page-title">{lang.label388}</IonLabel>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="web-box">
          <IonLabel
            className="user-info-label"
            style={{ marginBottom: "0px", marginTop: "20px", display: "block" }}
          >
            {lang.label127 + " " + lang.label389 + " 1 *"}
          </IonLabel>
          <IonItem className="no-border">
            <IonItem style={{ width: "100%" }}>
              <IonGrid style={{ width: "100% !important" }}>
                {newAdventure.dates.map((date: any, index: number) => {
                  return (
                    <IonRow
                      key={index + date.begin + date.end}
                      className="date-row"
                      style={{ borderBlockEnd: "solid 1px black" }}
                    >
                      {index > 0 && (
                        <IonRow
                          style={{
                            marginTop: "15px",
                            marginBottom: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <IonLabel
                            className="user-info-label"
                            style={{ marginLeft: 0 }}
                          >
                            {lang.label127 + " " + lang.label389 + " "}
                            {index + 1}
                            {" *"}
                          </IonLabel>

                          <div
                            style={{
                              display: "inline-block",
                              float: "right",
                              position: "relative",
                              top: 0,
                              zIndex: 1000,
                            }}
                          >
                            <IonText
                              style={{
                                fontSize: "12px",
                                textDecoration: "underline",
                              }}
                              onClick={() => remDate(index)}
                            >
                              {lang.label386}
                            </IonText>
                          </div>
                        </IonRow>
                      )}
                      <IonRow style={{ display: "block", width: "100%" }}>
                        <IonItem
                          className="user-info-item item-input-grey"
                          style={{
                            marginLeft: "0px",
                            marginRight: "0px",
                            width: "100%",
                          }}
                        >
                          <IonDatetime
                            min={moment(new Date()).format("YYYY-MM-DD")}
                            max={moment(new Date())
                              .add(2, "year")
                              .format("YYYY-MM")}
                            displayFormat="DD.MM.YYYY"
                            value={date.begin}
                            name="dob"
                            placeholder={lang.label390}
                            onIonChange={(event) =>
                              handleDateChange(index, event)
                            }
                            style={{
                              paddingLeft: "0",
                              paddingTop: "7px",
                              height: "40px",
                              width: "100%",
                              textAlign: "center",
                            }}
                            className="contact-input"
                          ></IonDatetime>
                        </IonItem>
                      </IonRow>
                      <IonRow style={{ display: "block", width: "100%" }}>
                        <IonItem
                          className="user-info-item item-input-grey"
                          style={{
                            marginLeft: "0px",
                            marginRight: "0px",
                            width: "100%",
                          }}
                        >
                          <IonDatetime
                            min={
                              date.begin === ""
                                ? moment(new Date()).format("YYYY-MM-DD")
                                : moment(date.begin, "YYYY-MM-DD").format(
                                    "YYYY-MM-DD"
                                  )
                            }
                            max={moment(new Date())
                              .add(2, "year")
                              .format("YYYY-MM")}
                            displayFormat="DD.MM.YYYY"
                            value={date.end}
                            name="dob2"
                            placeholder={lang.label391}
                            onIonChange={(event) =>
                              handleDateChange(index, event)
                            }
                            style={{
                              paddingLeft: "0",
                              paddingTop: "7px",
                              height: "40px",
                              width: "100%",
                              textAlign: "center",
                            }}
                            className="contact-input"
                          ></IonDatetime>
                        </IonItem>
                      </IonRow>
                      <IonRow style={{ display: "block", width: "100%" }}>
                        <IonItem
                          className="user-info-item item-input-grey"
                          style={{
                            marginLeft: "0px",
                            marginRight: "0px",
                            width: "100%",
                          }}
                        >
                          {/* <IonInput
                          value={date.capacity}
                          name="capacity"
                          onIonChange={(event) =>
                            handleDateChange(index, event)
                          }
                          placeholder={lang.label392}
                          inputmode="numeric"
                          type="number"
                          style={{
                            paddingLeft: "0",
                            paddingTop: "7px",
                            height: "40px",
                            width: "100%",
                            textAlign: "center",
                          }}
                          className="contact-input"
                        ></IonInput> */}
                          {/* <IonSelect
                          interfaceOptions={customPopoverOptions}
                          className="cap-input test"
                          value={String(date.capacity)}
                          name="capacity"
                          onIonChange={(event) =>
                            handleDateChange(index, event)
                          }
                          placeholder={lang.label392}
                          style={{
                            width: "100%",
                            textAlign: "center",
                            "--padding-start": "19px !important",
                          }}
                        >
                          {freeCapacity.map((item, index) => {
                            return (
                              <IonSelectOption key={index} value={String(item)}>
                                {String(item)}
                              </IonSelectOption>
                            );
                          })}
                        </IonSelect> */}
                          <IonInput
                            value={date.capacity === 0 ? "" : date.capacity}
                            name="capacity"
                            placeholder={lang.label392}
                            onIonChange={(event) =>
                              handleDateChange(index, event)
                            }
                            inputmode="numeric"
                            type="number"
                            className="contact-input"
                            style={{
                              paddingLeft: "0",
                              height: "40px",
                              width: "100%",
                              textAlign: "center",
                            }}
                          ></IonInput>
                        </IonItem>
                      </IonRow>

                      <IonRow style={{ display: "block", width: "100%" }}>
                        <IonItem
                          className="user-info-item item-input-grey"
                          style={{
                            marginLeft: "0px",
                            marginRight: "0px",
                            width: "100%",
                          }}
                        >
                          <IonInput
                            value={date.prices.CZK}
                            name="price"
                            onIonChange={(event) =>
                              handleDateChange(index, event)
                            }
                            inputmode="numeric"
                            type="number"
                            className="price-input kc-input"
                            style={{
                              height: "40px",
                              width: "100%",
                              textAlign: "center",
                            }}
                          ></IonInput>
                        </IonItem>
                      </IonRow>
                    </IonRow>
                  );
                })}
              </IonGrid>
            </IonItem>
          </IonItem>

          <IonItem className="no-border">
            <IonText
              style={{
                display: "block",
                marginLeft: "20px",
                fontSize: "12px",
                textDecoration: "underline",
              }}
              onClick={() => addDate()}
            >
              {lang.label393}
            </IonText>
          </IonItem>
        </div>
      </IonContent>
      <IonToolbar slot="bottom" className="text-center safe-zone web-box">
        <IonButton
          className="footer-button"
          onClick={async () => {
            let check = false;
            newAdventure.dates.forEach((d: any, i: number) => {
              if (
                newAdventure.dates[i].begin === "" ||
                newAdventure.dates[i].end === "" ||
                newAdventure.dates[i].prices.CZK === 0 ||
                newAdventure.dates[i].capacity === 0
              ) {
                check = true;
              }
            });
            if (check) {
              setToastMessage(lang.label233);
              setShowToast(true);
            } else {
              await saveNewAdventure();
              history.push("/create/trip/3");
            }
          }}
        >
          <IonLabel color="white" style={{ textTransform: "initial" }}>
            {lang.label394}
          </IonLabel>
        </IonButton>
      </IonToolbar>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        color="danger"
        cssClass="toast-pass-change"
      />
    </IonPage>
  );
};

export default CreateTrip2;
