import React from "react";
import Promo1080 from "../assets/video/Kangelo_app_promo3_1080.webm";
import Promo720 from "../assets/video/Kangelo_app_promo3_720.webm";
import Promo360 from "../assets/video/Kangelo_app_promo3_360.webm";
import PromoSnap from "../assets/video/Kangelo_app_promo3_1080_snap.png";

class PromoVideo extends React.Component<
  { appWidth: number },
  { appWidth: number }
> {
  constructor(props: { appWidth: number }) {
    super(props);
    // Don't call this.setState() here!
    this.state = { appWidth: 0 };
  }

  componentDidMount() {
    this.setState({ appWidth: this.props.appWidth });
  }

  componentDidUpdate(prevProps: { appWidth: number }) {
    // Typical usage (don't forget to compare props):
    if (this.props.appWidth !== prevProps.appWidth) {
      this.setState({ appWidth: this.props.appWidth });
    }
  }

  render() {
    var appWidth = this.state.appWidth;
    return (
      <video
        width="100%"
        height="100%"
        preload="auto"
        autoPlay
        loop
        muted
        style={{ objectFit: "cover" }}
        id="main-video"
        poster={PromoSnap}
      >
        <source
          src={
            appWidth <= 800
              ? appWidth <= 620
                ? Promo360
                : Promo720
              : Promo1080
          }
          type="video/webm"
        />
        Your browser does not support HTML5 video.
      </video>
    );
  }
}

export default PromoVideo;
