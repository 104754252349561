import {
  IonContent,
  IonHeader,
  IonPage,
  IonCard,
  IonCardContent,
  IonFooter,
  IonAvatar,
  IonChip,
  IonLabel,
  IonModal,
  IonText,
  IonList,
  IonItem,
  IonCol,
  IonRow,
  IonButton,
  IonGrid,
  IonToast,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonToolbar,
  IonButtons,
  isPlatform,
} from "@ionic/react";
import "./Tab1.css";

import axios from "axios";

import moment from "moment";
import "moment/locale/cs";

import { FileOpener } from "@whiteguru/capacitor-plugin-file-opener";

import defaultAdvImage from "../assets/images/default_adventure_image.jpg";

import Rate from "rc-rate";
import "rc-rate/assets/index.css";

import AuthContext from "../my-context";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { useGlobal } from "reactn";
import {
  Heart,
  Handshake,
  ArrowLeft,
  Files,
  UserCircle,
  CaretDown,
  CaretUp,
} from "phosphor-react";
import showImage from "../functions/showImage";
import setLike from "../functions/setLike";
import setEnumText from "../functions/setEnumText";
import setCountryName from "../functions/setCountryName";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import checkProperty from "../functions/checkProperty";
import Header from "./header";

const Reservation: React.FC = () => {
  const isFirstRender = useRef(true);

  const isFirstRender3 = useRef(true);
  const isThirdRender3 = useRef(false);

  const history = useHistory();

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showCancelFeesModal, setShowCancelFeesModal] = useState(false);

  const [isDocumentsShow, setIsDocumentsShow] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);

  const [mark, setMark] = useState<number>(0);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [confirmModal, setConfirmModal] = useState(false);
  const [action, setAction] = useState("");

  const [, setAdventureId] = useGlobal("AdventrureId");
  const [reservationId] = useGlobal("reservationId");
  const [appWidth] = useGlobal("appWidth");

  const [language] = useGlobal("language");
  const [userId, setUserId] = useGlobal("userId");
  const [showModalPart, setShowModalPart] = useState(false); //partner modal
  const [partnerName, setPartnerName] = useState("");

  const [freeCapacity, setFreeCapacity] = useState([0]);
  const [persons, setPersons] = useState<number>(1);

  const [showModalDates, setShowModalDates] = useState(false);
  const [showModalReserv, setShowModalReserv] = useState(false);

  const [inputFields, setInputFields] = useState([
    { firstName: "", lastName: "", email: "", phone: "" },
  ]);

  const { token, lang, checkImageCache, db } = React.useContext(AuthContext);

  const [documents, setDocuments] = useState([
    {
      id: "",
      type: "",
      data: "",
      name: "",
    },
  ]);

  const [dateData, setDateData] = useState({
    id: "",
    status: "",
    people: [
      {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
    ],
    adventureDatesId: "",
    adventureId: "",
    price: { CZK: 0 },
    success: false,
    documents: [],
    payments: [
      {
        id: "",
        status: "UNPAID",
        currency: "CZK",
        amount: 0,
        due: "",
        localBankAccount: "",
        reservationId: "",
        paymentId: "",
        iban: "",
        bic: "",
        localBankCode: "",
      },
    ],
  });

  const [adventureData, setAdventureData] = useState({
    id: "",
    image: "",
    imageData: "",
    rating: 0,
    ratingCount: 0,
    difficulty: 0,
    guide: {
      id: "",
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      guide: false,
      languages: {},
      partner: null,
      profileImage: null,
      profileImageData: "",
      coverImage: null,
      coverImageData: "",
    },
    meals: "",
    transit: "",
    accommodation: "",
    categories: [""],
    departure: {
      type: "AIRPORT",
      latitude: null,
      longitude: null,
      airport: "",
      country: null,
      region: null,
      city: null,
      address: null,
      countryText: null,
    },
    arrival: {
      type: "AIRPORT",
      latitude: null,
      longitude: null,
      airport: "",
      country: null,
      region: null,
      city: null,
      address: null,
      countryText: null,
    },
    dates: [
      {
        id: "",
        begin: "",
        end: "",
        prices: {
          EUR: 0,
          CZK: 0,
        },
        capacity: 0,
        free: 0,
        documents: [],
      },
    ],
    itinerary: [
      {
        title: "",
        description: "",
        images: [""],
      },
    ],
    partner: null,
    nameText: {
      CS: "",
      EN: "",
    },
    descriptionText: null,
    includedText: null,
    notIncludedText: null,
    accommodationText: null,
    mealsText: null,
    transitText: null,
    difficultyText: null,
    moreInfoText: null,
    locations: [
      {
        type: "ADDRESS",
        latitude: null,
        longitude: null,
        airport: null,
        country: "",
        region: "",
        city: "",
        address: "",
        countryText: {
          CS: "",
          EN: "",
        },
      },
    ],
    documents: [],
    isLiked: false,
  });

  useEffect(() => {
    getReservation(reservationId);
  }, [reservationId]);

  useEffect(() => {
    fetchPrivacy();
  }, []);

  async function fetchPrivacy() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: { "Accept-Language": language },
    };
    api
      .get("/legal/terms", config)
      .then((resp) => {
        setTermsData(resp.data.data);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  const [termsData, setTermsData] = useState("");
  const [termsModal, setTermsModal] = useState(false);

  async function parseReservation() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    return api
      .get("/adventures/" + dateData.adventureId, config)
      .then(async (response) => {
        let temp = response.data;
        temp.dates = temp.dates.filter(
          (date: any) => date.id === dateData.adventureDatesId
        );

        if (await checkImageCache(temp.image)) {
          temp.imageData = await db.get(temp.image);
        } else {
          if (temp.image !== "" || temp.image !== null) {
            temp.imageData = await showImage(temp.image, token);
            await db.set(temp.image, temp.imageData);
          } else temp.imageData = "";
        }
        if (await checkImageCache(temp.guide.profileImage)) {
          temp.guide.profileImageData = await db.get(temp.guide.profileImage);
        } else {
          if (temp.guide.profileImage !== "") {
            if (temp.guide.profileImage !== null) {
              temp.guide.profileImageData = await showImage(
                temp.guide.profileImage,
                token
              );
              await db.set(
                temp.guide.profileImage,
                temp.guide.profileImageData
              );
            }
          } else temp.guide.profileImageData = "";
        }
        let tempFreeCapacity: [number] = [0];
        for (var i = 0; i < temp.dates[0].free; i++) {
          tempFreeCapacity[i] = i + 1;
        }
        setFreeCapacity(tempFreeCapacity);
        setAdventureData(temp);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  async function getReservation(reservId: string) {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .get("/reservations/" + reservId, config)
      .then((responsed) => {
        setDateData(responsed.data);
        setPersons(responsed.data.people.length);
        let temp = [...responsed.data.people];
        temp.splice(0, 1);
        setInputFields(temp);
      })
      .catch((err) => {
        // what now?
        console.log(err);
      });
  }

  const getTripLength = (item: any) => {
    var start = moment(item.dates[0].begin, "YYYY-MM-DD");
    var end = moment(item.dates[0].end, "YYYY-MM-DD");
    var length = end.diff(start, "days") + 1;
    if (length === 1) {
      return "1 " + lang.label191;
    } else if (length < 5) {
      let message = length.toString() + " " + lang.label192;
      return message;
    } else {
      let message = length.toString() + " " + lang.label193;
      return message;
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    parseReservation();
  }, [dateData]);

  function setReservationStateText(status: string) {
    switch (status) {
      case "CONFIRMED":
        return lang.label256;
      case "PAID":
        return lang.label257;
      case "CONTRACT":
        return lang.label261;
      case "CANCELLED":
        return lang.label258;
      case "REFUNDED":
        return lang.label259;
      case "FINISHED":
        return lang.label260;
      default:
        return lang.label255;
    }
  }

  const priceFormat = (item: any) => {
    let config = language;
    language === "cs" ? (config = config + "-CZ") : (config = config + "-GB");
    if (item) {
      return (
        <div style={{ display: "inline-block" }}>
          {item.toLocaleString(config) + " Kč"}
        </div>
      );
    } else return "";
  };

  function setReservationStateColor(status: string) {
    switch (status) {
      case "CONFIRMED":
        return "action-not-required";
      case "PAID":
        return "action-required";
      case "CONTRACT":
        return "action-not-required";
      case "CANCELLED":
        return "canceled-reservation";
      case "REFUNDED":
        return "canceled-reservation";
      case "FINISHED":
        return "action-not-required";
      default:
        return "action-required";
    }
  }

  async function setReservationStatus() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    return api
      .patch("/reservations/" + dateData.id, { status: action }, config)
      .then(() => {
        getReservation(reservationId);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  const getLowestPrice = (item: any) => {
    let min = 100000000;
    for (var i = 0; i < item.dates.length; i++) {
      if (item.dates[i].prices.CZK < min) {
        min = item.dates[i].prices.CZK;
      }
    }
    let config = language;
    language === "cs" ? (config = config + "-CZ") : (config = config + "-GB");
    return (
      <div style={{ display: "inline-block" }}>
        {" "}
        • {min.toLocaleString(config) + " Kč"}
      </div>
    );
  };

  useEffect(() => {
    if (isFirstRender3.current === true) {
      isFirstRender3.current = false;
      return;
    }
    /* if (isSecondRender.current === true) {
      isSecondRender.current = false;
      return;
    } */
    if (isThirdRender3.current === true) {
      return;
    }
    let helpX = JSON.parse(JSON.stringify(adventureData.documents));
    let helpY = JSON.parse(JSON.stringify(adventureData.dates[0].documents));
    let helpZ = JSON.parse(JSON.stringify(dateData.documents));
    helpX = helpX.concat(helpY);
    helpX = helpX.concat(helpZ);
    Promise.all(
      helpX.map(async (doc: any, index: number) => {
        if (doc) {
          const getDocData = async () => {
            const api = axios.create({
              baseURL: `https://app.kangelo.com/`,
            });
            var config = {
              headers: {
                "X-Auth-Token": token,
              },
            };
            if (doc) {
              return api
                .get("/media/" + doc, config)
                .then(async (res) => {
                  return (doc = await res.data);
                })
                .catch((err) => {
                  // what now?
                  console.log(err.data);
                });
            }
          };
          await getDocData();
        }
        return doc;
      })
    ).then((resData) => {
      isThirdRender3.current = true;
      setDocuments(resData);
    });

    /* setItinerary(helpX); */
  }, [adventureData]);

  const writeFile = async (name: string, data: string) => {
    await Filesystem.writeFile({
      path: name,
      data: data,
      /*       directory: Directory.Documents, */
      /* encoding: Encoding.UTF8, */
    });
  };

  const openFile = async (doc: any) => {
    /* setReservationStateColor("CONFIRMED"); */
    Filesystem.requestPermissions();
    const foo = await Filesystem.writeFile({
      path: doc.name,
      data: doc.data,
      directory: Directory.External,
    });
    let mimeType = doc.data.split(":").pop().split(";")[0];
    FileOpener.open({ path: foo.uri, mime: mimeType })
      .then()
      .catch((err) => {
        console.log(err.message);
        setToastMessage(err.message);
        setShowToast(true);
      });
  };

  const handleFields = (value: number) => {
    let values = [...inputFields];
    if (value > persons - 1) {
      for (var i = 0; i < value - persons; i++) {
        values.push({ firstName: "", lastName: "", email: "", phone: "" });
      }
      setInputFields(values);
    } else {
      for (i = 0; i < persons - value; i++) {
        values.splice(values.length - 1, 1);
      }
      setInputFields(values);
    }
  };

  const handleInputChange = (index: number, event: any) => {
    const values = [...inputFields];

    switch (event.target.name) {
      case "firstName":
        values[index].firstName = event.target.value;
        break;
      case "lastName":
        values[index].lastName = event.target.value;
        break;
      case "email":
        values[index].email = event.target.value;
        break;
      case "phone":
        values[index].phone = event.target.value;
        break;
      default:
        break;
    }

    setInputFields(values);
  };

  async function apiReservCall(apiData: any) {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .patch("/reservations/" + reservationId, apiData, config)
      .then((res) => {
        /* setInputFields([{ firstName: "", lastName: "", email: "", phone: "" }]); */
        /* let dateDataTemp = res */
        setDateData(res.data);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  async function makeReserv() {
    const personsDetails = [...inputFields];
    personsDetails.splice(inputFields.length - 1, 1);
    var apiData = { people: inputFields };
    if (personsDetails.length === 0) {
      apiReservCall(apiData);
      setShowModalReserv(false);
      setConfirmModal(false);
    } else if (
      personsDetails.filter(
        (item) =>
          item.firstName === "" ||
          item.lastName === "" ||
          item.email === "" ||
          item.phone === ""
      ).length === 0
    ) {
      apiReservCall(apiData);
      setShowModalReserv(false);
      setConfirmModal(false);
    } else {
      setToastMessage(lang.label291);
      setShowToast(true);
    }
  }

  return (
    <IonPage id="main">
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="bottom-border web-box">
        <IonToolbar>
          <IonButtons>
            <IonButton
              onClick={() => history.goBack()}
              /*  className="modal-search-back" */
            >
              <ArrowLeft size={32} />
              <IonLabel className="back-button">{lang.label306}</IonLabel>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {/* <IonText style={{ paddingBlockStart: "18px" }}>{lang.label306}</IonText> */}
      </IonHeader>
      <IonContent>
        <div className="web-box">
          <IonText
            className="text-bold display-block text-center"
            style={{ fontSize: "20px", marginBlockEnd: "15px" }}
          >
            {lang.label262}
          </IonText>
          <IonText
            className={
              setReservationStateColor(dateData.status) +
              " " +
              "text-bold display-block"
            }
            style={{
              fontSize: "16px",
              padding: "4px 25px",
              borderRadius: "15px",
              width: "max-content",
              marginLeft: "auto",
              marginRight: "auto",
              marginBlockEnd: "15px",
              color: "white",
            }}
          >
            {setReservationStateText(dateData.status)}
          </IonText>
          <IonText
            className="display-block text-center"
            style={{ fontSize: "16px", marginBlockEnd: "15px" }}
          >
            {lang.label307}: {dateData.id.substring(0, 12)}
          </IonText>
          <IonList style={{ borderTop: "1px solid #DEDEDE" }}>
            <IonItem
              onClick={() => setIsDocumentsShow((state) => !state)}
              className="user-info-item item-input-grey"
              style={{ textAlign: "left", marginBottom: "0" }}
            >
              <IonCol
                size="1"
                className="text-center"
                style={{ marginTop: "5px" }}
              >
                <Files size={20} color="#3fd0d4" />
              </IonCol>
              <IonCol>{lang.label313}</IonCol>
              {isDocumentsShow ? (
                <CaretDown size={20} color="#3fd0d4" />
              ) : (
                <CaretUp size={20} color="#3fd0d4" />
              )}
            </IonItem>
            {isDocumentsShow && (
              <IonList style={{ paddingTop: "0" }}>
                {documents.map((document: any, indexon: number) => {
                  if (document.name === "") return null;
                  return (
                    <IonItem
                      key={indexon}
                      style={{
                        marginLeft: "36px",
                        marginRight: "36px",
                        "--padding-start": "0",
                        "--inner-padding-end": "0",
                      }}
                      /* onClick={() => writeFile(document.name, document.data)} */
                    >
                      {isPlatform("capacitor") ? (
                        <IonButton
                          /* download={document.name}
                          href={document.data} */
                          style={{ width: "100%", height: "30px" }}
                          onClick={() => {
                            openFile(document);
                          }}
                        >
                          {document.name}
                        </IonButton>
                      ) : (
                        <IonButton
                          download={document.name}
                          href={document.data}
                          style={{ width: "100%", height: "30px" }}
                          /* onClick={() => {
                            openFile(document);
                          }} */
                        >
                          {document.name}
                        </IonButton>
                      )}
                      {/* <IonButton
                        download={document.name}
                        href={document.data}
                        style={{ width: "100%", height: "30px" }}
                        onClick={() => {
                          openFile(document);
                        }}
                      >
                        {document.name}
                      </IonButton> */}
                    </IonItem>
                  );
                })}
              </IonList>
            )}
            <IonItem className="user-info-item item-input-grey">
              <IonCol>
                <IonRow
                  className="text-bold display-block"
                  style={{ fontSize: "12px" }}
                >
                  {lang.label132}
                </IonRow>
                <IonRow className="display-block" style={{ fontSize: "12px" }}>
                  {dateData.people.length}
                </IonRow>
              </IonCol>
            </IonItem>
            <IonItem className="user-info-item item-input-grey">
              <IonCol>
                <IonRow
                  className="text-bold display-block"
                  style={{ fontSize: "12px" }}
                >
                  {lang.label133}
                </IonRow>
                <IonRow className="display-block" style={{ fontSize: "12px" }}>
                  {dateData.price.CZK.toLocaleString(
                    language === "cs" ? "cs-CZ" : "en-GB"
                  )}{" "}
                  Kč
                </IonRow>
              </IonCol>
            </IonItem>
            {dateData.status === "CONFIRMED" && (
              <IonItem className="user-info-item item-input-grey no-border">
                <IonGrid style={{ padding: 0 }}>
                  <IonRow>
                    <IonButton
                      style={{
                        width: "100%",
                        height: "3em",
                        textTransform: "none",
                        display: "block",
                      }}
                      onClick={() => {
                        setShowPaymentModal(true);
                      }}
                    >
                      {lang.label120}
                    </IonButton>
                  </IonRow>
                  <IonRow
                    style={{ justifyContent: "center", fontSize: "12px" }}
                  >
                    <IonText className="text-center">{lang.label123}</IonText>
                  </IonRow>
                </IonGrid>
              </IonItem>
            )}
          </IonList>
          <IonText
            className="text-bold display-block text-center"
            style={{
              fontSize: "18px",
              marginBlockStart: "15px",
              borderBottom: "1px solid #DEDEDE",
            }}
          >
            {lang.label124}
          </IonText>
          <div style={{ width: "100%", textAlign: "center" }}>
            <IonCard className="adventures-card">
              <div className="card-cont">
                <img
                  src={
                    adventureData.imageData !== ""
                      ? adventureData.imageData
                      : defaultAdvImage
                  }
                  className="adventure-cover"
                  onClick={() => {
                    return (
                      setAdventureId(adventureData.id),
                      history.push("/adventures/" + adventureData.id)
                    );
                  }}
                  alt="adventure background"
                />
                <IonChip className="adv-chip">
                  <IonLabel>
                    {setCountryName(
                      adventureData.locations[0].country,
                      language
                    )}
                  </IonLabel>
                </IonChip>
                {adventureData.categories.map((category: any, i: number) => {
                  return (
                    <IonChip className={"adv-chip" + i} key={`${i}`}>
                      <IonLabel>{setEnumText(category, lang)}</IonLabel>
                    </IonChip>
                  );
                })}
                <IonChip
                  className="adv-like"
                  onClick={() => {
                    let reservationsTemp = { ...adventureData };
                    reservationsTemp.isLiked = !adventureData.isLiked;
                    setAdventureData(reservationsTemp);
                    setLike(
                      adventureData.id,
                      "adventures",
                      reservationsTemp.isLiked,
                      token
                    );
                  }}
                >
                  <Heart
                    size={24}
                    weight={adventureData.isLiked ? "fill" : "duotone"}
                    color="#3fd0d4"
                  />
                </IonChip>
                <IonChip
                  className="adv-partner"
                  onClick={() => {
                    setPartnerName(
                      adventureData.partner !== null
                        ? adventureData.partner
                        : ""
                    );
                    setShowModalPart(true);
                  }}
                >
                  <Handshake size={24} color="#3fd0d4" />
                </IonChip>
                <IonChip
                  className="adv-guide-chip"
                  onClick={() => {
                    setUserId(adventureData.guide.id);
                    history.push("/userpage/" + adventureData.guide.id);
                  }}
                >
                  <IonLabel>{adventureData.guide.firstName}</IonLabel>
                </IonChip>
                <IonAvatar
                  className="guide-avatar"
                  onClick={() => {
                    setUserId(adventureData.guide.id);
                    history.push("/userpage/" + adventureData.guide.id);
                  }}
                >
                  {adventureData.guide.profileImage === null ? (
                    <UserCircle
                      size={78}
                      fill="black"
                      weight="fill"
                      style={{ marginLeft: "-7px", marginTop: "-7px" }}
                    />
                  ) : (
                    <img
                      src={adventureData.guide.profileImageData}
                      alt="guide"
                    />
                  )}
                </IonAvatar>
              </div>
              <IonCardContent style={{ paddingTop: "8px" }}>
                <IonFooter>
                  {adventureData.rating !== null && (
                    <div
                      style={{
                        display: "inline",
                        marginRight: "5px",
                      }}
                    >
                      <Rate
                        count={5}
                        value={adventureData.rating}
                        allowHalf={true}
                        disabled={true}
                        className="rating-stars"
                      />{" "}
                      {adventureData.rating} ({adventureData.ratingCount})
                    </div>
                  )}
                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "30px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="20"
                      fill="rgb(63, 208, 212)"
                      viewBox="0 0 400 256"
                    >
                      <rect width="52" height="256" fill="none"></rect>
                      <line
                        x1="340"
                        y1="208"
                        x2="28"
                        y2="208"
                        fill="none"
                        stroke="rgb(63, 208, 212)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="16"
                      ></line>
                      <rect
                        x="42"
                        y="160"
                        width="56"
                        height="48"
                        strokeWidth="16"
                        stroke="rgb(63, 208, 212)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill={adventureData.difficulty >= 1 ? "true" : "none"}
                      ></rect>
                      <rect
                        x="100"
                        y="140"
                        width="56"
                        height="68"
                        strokeWidth="16"
                        stroke="rgb(63, 208, 212)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill={adventureData.difficulty >= 2 ? "true" : "none"}
                      ></rect>
                      <rect
                        x="158"
                        y="120"
                        width="56"
                        height="88"
                        strokeWidth="16"
                        stroke="rgb(63, 208, 212)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill={adventureData.difficulty >= 3 ? "true" : "none"}
                      ></rect>
                      <rect
                        x="216"
                        y="100"
                        width="56"
                        height="108"
                        strokeWidth="16"
                        stroke="rgb(63, 208, 212)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill={adventureData.difficulty >= 4 ? "true" : "none"}
                      ></rect>
                      <rect
                        x="274"
                        y="80"
                        width="56"
                        height="128"
                        strokeWidth="16"
                        stroke="rgb(63, 208, 212)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill={adventureData.difficulty >= 5 ? "true" : "none"}
                      ></rect>
                    </svg>
                  </div>
                  <IonCol>
                    {adventureData.difficulty}
                    /5
                  </IonCol>
                </IonFooter>
                <IonFooter style={{ fontWeight: "bold", color: "black" }}>
                  {checkProperty(adventureData.nameText, language)}
                </IonFooter>
                <IonFooter>
                  {moment(adventureData.dates[0].begin, "YYYY-MM-DD").format(
                    "DD.MM."
                  )}{" "}
                  -{" "}
                  {moment(adventureData.dates[0].end, "YYYY-MM-DD").format(
                    "DD.MM.YYYY"
                  )}{" "}
                  • {getTripLength(adventureData)}{" "}
                  {getLowestPrice(adventureData)}
                </IonFooter>
              </IonCardContent>
            </IonCard>
          </div>
          <IonText
            className="text-bold display-block text-center"
            style={{
              fontSize: "18px",
              marginBlockStart: "15px",
              borderBottom: "1px solid #DEDEDE",
            }}
          >
            {lang.label151}
          </IonText>
          <IonList className="safe-zone">
            {dateData.people.map((person, index) => {
              return (
                <IonItem key={index} className="item-persons no-border">
                  <IonGrid>
                    <IonRow
                      className="text-bold display-block"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {lang.label264} {index + 1}
                    </IonRow>
                    <IonCol className="display-block">
                      <IonRow
                        className="text-bold display-block"
                        style={{ fontSize: "12px" }}
                      >
                        {lang.label49}
                      </IonRow>
                      <IonRow
                        className="display-block"
                        style={{ fontSize: "12px" }}
                      >
                        {dateData.people[index].firstName}
                      </IonRow>
                      <IonRow
                        className="text-bold display-block"
                        style={{ fontSize: "12px" }}
                      >
                        {lang.label50}
                      </IonRow>
                      <IonRow
                        className="display-block"
                        style={{ fontSize: "12px" }}
                      >
                        {dateData.people[index].lastName}
                      </IonRow>
                      <IonRow
                        className="text-bold display-block"
                        style={{ fontSize: "12px" }}
                      >
                        {lang.label57}
                      </IonRow>
                      <IonRow
                        className="display-block"
                        style={{ fontSize: "12px" }}
                      >
                        {dateData.people[index].phone}
                      </IonRow>
                      <IonRow
                        className="text-bold display-block"
                        style={{ fontSize: "12px" }}
                      >
                        {lang.label56}
                      </IonRow>
                      <IonRow
                        className="display-block"
                        style={{ fontSize: "12px" }}
                      >
                        {dateData.people[index].email}
                      </IonRow>
                    </IonCol>
                  </IonGrid>
                </IonItem>
              );
            })}
            {dateData.status === "UNCONFIRMED" && (
              <IonItem
                className="item-buttons2"
                style={{ borderTop: "1px solid #DEDEDE" }}
              >
                <IonRow className="text-center" style={{ width: "100%" }}>
                  <IonButton
                    onClick={() => {
                      /* setAction("CANCELLED");
                    setConfirmModal(true); */
                      setShowModalDates(true);
                    }}
                    className="confirm-reservation"
                    color="primary"
                    style={{
                      width: "80vw",
                      height: "35px",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {lang.label134}
                  </IonButton>
                </IonRow>
              </IonItem>
            )}
            {dateData.status !== "CANCELLED" &&
              dateData.status !== "REFUNDED" &&
              dateData.status !== "FINISHED" && (
                <IonItem className="item-buttons no-border">
                  <IonRow className="text-center" style={{ width: "100%" }}>
                    <IonButton
                      onClick={() => {
                        setAction("CANCELLED");
                        setMark(1);
                        setConfirmModal(true);
                      }}
                      className="confirm-reservation"
                      color="danger"
                      style={{ width: "80vw", height: "35px" }}
                    >
                      {lang.label140}
                    </IonButton>
                  </IonRow>
                </IonItem>
              )}
          </IonList>
        </div>
      </IonContent>

      <IonModal
        isOpen={showModalDates}
        cssClass="dates-modal2"
        backdropDismiss={true}
        onDidDismiss={() => setShowModalDates(false)}
      >
        <IonGrid className="persons-grid">
          <IonRow>
            <IonCol className="persons-col" style={{ fontWeight: "bold" }}>
              {lang.label287}
            </IonCol>
            <IonCol className="persons-col" style={{ fontWeight: "bold" }}>
              {lang.label133}
            </IonCol>
          </IonRow>
          <IonRow style={{ height: "25px" }}>
            <IonCol
              className="persons-col"
              style={{ height: "25px", padding: 0 }}
            >
              <IonSelect
                value={String(persons)}
                onIonChange={async (e) => {
                  await handleFields(e.detail.value);
                  setPersons(Number(e.detail.value));
                }}
                placeholder="1"
              >
                {freeCapacity.map((item, index) => {
                  return (
                    <IonSelectOption key={index} value={String(item)}>
                      {String(item)}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </IonCol>
            <IonCol
              className="persons-col"
              style={{
                fontWeight: "bold",
                color: "#3fd0d4",
                height: "25px",
                padding: 0,
              }}
            >
              {(persons * adventureData.dates[0].prices.CZK).toLocaleString(
                language === "cs" ? "cs-CZ" : "en-GB"
              )}{" "}
              Kč
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonButton
          className="reserv-button footer-button"
          onClick={() => {
            if (persons !== dateData.people.length) {
              setShowModalDates(false);
              setShowModalReserv(true);
            }

            /*             if (selectedDate.length === 0) {
              setShowModalNeniDatum(true);
            } else {
              setShowModalDates(false);
              console.log(persons);
              setShowModalReserv(true);
            } */
          }}
        >
          {lang.label288}
        </IonButton>
        <IonText className="reserv-text">{lang.label289}</IonText>
      </IonModal>

      <IonModal
        isOpen={showModalReserv}
        cssClass={persons === 1 ? "reserv-modal-small" : "reserv-modal"}
        backdropDismiss={true}
        onDidDismiss={() => setShowModalReserv(false)}
      >
        <IonButton
          fill="clear"
          className="back-reserv-butt"
          onClick={() => {
            setShowModalDates(true);
            setShowModalReserv(false);
          }}
        >
          {" "}
          <ArrowLeft size={24} />
        </IonButton>
        <IonGrid
          style={{
            /* maxHeight: "70%", */
            overflow: "auto",
            padding: "0 25px 0 25px",
          }}
        >
          <IonRow>
            <IonLabel style={{ fontWeight: "bold" }}>
              {lang.label264} 1 - {lang.label297}
            </IonLabel>
          </IonRow>
          {inputFields.map((inputField: any, index: number) => (
            <div style={{ display: "block", marginTop: "5px" }} key={index}>
              <IonRow key={`${inputField}~${index}`}>
                <IonLabel style={{ fontWeight: "bold" }}>
                  {lang.label264} {index + 2}
                </IonLabel>
              </IonRow>
              <IonRow>
                <IonCol style={{ padding: "5px 2.5px 0 0" }}>
                  <IonInput
                    value={inputField.firstName}
                    name="firstName"
                    placeholder={lang.label49}
                    inputmode="text"
                    maxlength={40}
                    onIonChange={(event) => handleInputChange(index, event)}
                    className="reserv-input"
                  ></IonInput>
                </IonCol>
                <IonCol style={{ padding: "5px 0 0 2.5px" }}>
                  <IonInput
                    value={inputField.lastName}
                    name="lastName"
                    placeholder={lang.label50}
                    inputmode="text"
                    maxlength={40}
                    onIonChange={(event) => handleInputChange(index, event)}
                    className="reserv-input"
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol style={{ padding: "5px 0 0 0" }}>
                  <IonInput
                    value={inputField.email}
                    name="email"
                    placeholder="email"
                    inputmode="email"
                    maxlength={30}
                    onIonChange={(event) => handleInputChange(index, event)}
                    className="reserv-input"
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol style={{ padding: "5px 0 0 0" }}>
                  <IonInput
                    value={inputField.phone}
                    name="phone"
                    placeholder={lang.label57}
                    inputmode="tel"
                    maxlength={16}
                    onIonChange={(event) => handleInputChange(index, event)}
                    className="reserv-input"
                  ></IonInput>
                </IonCol>
              </IonRow>
            </div>
          ))}
        </IonGrid>
        {/* <IonToolbar> */}
        <IonFooter style={{ display: "flex" }}>
          <IonGrid>
            <IonRow>
              <IonButton
                className="reserv-button footer-button"
                style={{ width: "100%", padding: "0 20px 0 20px" }}
                /* style={{ marginLeft: "auto", marginRight: "auto", width: "50%" }} */
                onClick={() => {
                  setMark(2);
                  setConfirmModal(true);
                }}
              >
                {lang.label292}
              </IonButton>
            </IonRow>
            <IonRow>
              <IonText className="reserv-text">{lang.label289}</IonText>
            </IonRow>
          </IonGrid>
        </IonFooter>
        {/* </IonToolbar> */}
      </IonModal>

      <IonModal
        isOpen={confirmModal}
        cssClass="bec-guide-modal"
        backdropDismiss
        onDidDismiss={() => {
          setConfirmModal(false);
        }}
      >
        <IonContent fullscreen>
          <IonGrid
            style={{
              width: "max-content",
              marginLeft: "auto",
              marginRight: "auto",
              height: "max-content",
              textAlign: "center",
              marginTop: "80vh",
            }}
          >
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonText style={{ width: "100%" }}>{lang.label266}</IonText>
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonButton
                  onClick={() => {
                    setConfirmModal(false);
                  }}
                  color="danger"
                  style={{ marginRight: "15px" }}
                >
                  {lang.label166}
                </IonButton>
                <IonButton
                  onClick={() => {
                    if (mark === 1) {
                      setReservationStatus();
                      setConfirmModal(false);
                    } else if (mark === 2) {
                      makeReserv();
                    }
                  }}
                  color="primary"
                  style={{ marginLeft: "15px" }}
                >
                  {lang.label121}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          {/* </div> */}
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showPaymentModal}
        /* cssClass="modal-fullscreen" */
        backdropDismiss
        onDidDismiss={() => {
          setShowPaymentModal(false);
        }}
      >
        <IonHeader
          className="ud-header safe-zone-header"
          style={{
            backgroundColor: "white !important",
            borderBottom: "1px solid #DEDEDE",
          }}
        >
          <IonButton
            onClick={() => {
              setShowPaymentModal(false);
            }}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
          <IonText
            style={{
              display: "inline-block",
              marginTop: "15px",
              verticalAlign: "middle",
              fontWeight: "bold",
            }}
          >
            {lang.label325}
          </IonText>
        </IonHeader>
        <IonContent>
          <IonGrid
            className="payment-info"
            style={{ margin: "15px 20px 0 20px" }}
          >
            <IonRow className="payment-info text-bold">
              <IonText>{lang.label133}</IonText>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonText
                style={{
                  display: "inline-block",
                  marginBottom: "5px",
                  userSelect: "auto",
                }}
              >
                {priceFormat(dateData.payments[0]?.amount)}
              </IonText>
            </IonRow>

            <IonRow className="payment-info text-bold">
              <IonText>{lang.label326}</IonText>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonText
                style={{
                  display: "inline-block",
                  marginBottom: "5px",
                  userSelect: "auto",
                }}
              >
                {dateData.payments[0]?.localBankAccount}
              </IonText>
            </IonRow>

            <IonRow className="payment-info text-bold">
              <IonText>{lang.label327}</IonText>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonText
                style={{
                  display: "inline-block",
                  marginBottom: "5px",
                  userSelect: "auto",
                }}
              >
                {dateData.payments[0]?.localBankCode}
              </IonText>
            </IonRow>

            <IonRow className="payment-info text-bold">
              <IonText>{lang.label328}</IonText>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonText
                style={{
                  display: "inline-block",
                  marginBottom: "5px",
                  userSelect: "auto",
                }}
              >
                {dateData.payments[0]?.paymentId}
              </IonText>
            </IonRow>

            <IonRow className="payment-info text-bold">
              <IonText>IBAN</IonText>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonText
                style={{
                  display: "inline-block",
                  marginBottom: "5px",
                  userSelect: "auto",
                }}
              >
                {dateData.payments[0]?.iban}
              </IonText>
            </IonRow>

            <IonRow className="payment-info text-bold">
              <IonText>BIC</IonText>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonText
                style={{
                  display: "inline-block",
                  marginBottom: "5px",
                  userSelect: "auto",
                }}
              >
                {dateData.payments[0]?.bic}
              </IonText>
            </IonRow>

            <IonRow className="payment-info text-bold">
              <IonText>{lang.label329}</IonText>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonText
                style={{
                  display: "inline-block",
                  marginBottom: "5px",
                  userSelect: "auto",
                }}
              >
                {moment(dateData.payments[0]?.due, "YYYY-MM-DD")
                  .locale("cs")
                  .format("D. M. YYYY")}
              </IonText>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonText
                className="text-center"
                style={{ display: "inline-block", marginBottom: "5px" }}
              >
                {lang.label123}
              </IonText>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonText
                className="text-center"
                style={{
                  display: "inline-block",
                  marginBottom: "5px",
                  textDecoration: "underline",
                }}
                onClick={() => setTermsModal(true)}
              >
                {lang.label458}
              </IonText>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonText
                className="text-center"
                style={{ display: "inline-block", marginBottom: "5px" }}
              >
                {lang.label330}
              </IonText>
            </IonRow>
            <IonRow
              className="payment-info payment-border"
              onClick={() => setShowCancelFeesModal(true)}
            >
              <IonText
                className="text-center"
                style={{
                  display: "inline-block",
                  marginBottom: "5px",
                  textDecoration: "underline",
                }}
              >
                {lang.label447}
              </IonText>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showCancelFeesModal}
        /* cssClass="modal-fullscreen" */
        backdropDismiss
        onDidDismiss={() => {
          setShowCancelFeesModal(false);
        }}
      >
        <IonHeader
          className="ud-header safe-zone-header"
          style={{
            backgroundColor: "white !important",
            borderBottom: "1px solid #DEDEDE",
          }}
        >
          <IonButton
            onClick={() => {
              setShowCancelFeesModal(false);
            }}
            className="modal-search-back"
          >
            <ArrowLeft size={24} color="black" />
          </IonButton>
          <IonText
            style={{
              display: "inline-block",
              marginTop: "15px",
              verticalAlign: "middle",
              fontWeight: "bold",
            }}
          >
            {lang.label447}
          </IonText>
        </IonHeader>
        <IonContent>
          <IonGrid
            className="payment-info text-center"
            style={{ margin: "15px 20px 0 20px" }}
          >
            <IonRow className="payment-info text-bold payment-border">
              <IonCol>
                <IonText>{lang.label448}</IonText>
              </IonCol>
              <IonCol>
                <IonText>{lang.label449}*</IonText>
              </IonCol>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonCol>
                <IonText
                  style={{
                    display: "inline-block",
                    marginBottom: "5px",
                    userSelect: "auto",
                  }}
                >
                  {lang.label450}
                </IonText>
              </IonCol>
              <IonCol>
                <IonText
                  style={{
                    display: "inline-block",
                    marginBottom: "5px",
                    userSelect: "auto",
                  }}
                >
                  20 %
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonCol>
                <IonText
                  style={{
                    display: "inline-block",
                    marginBottom: "5px",
                    userSelect: "auto",
                  }}
                >
                  {lang.label451}
                </IonText>
              </IonCol>
              <IonCol>
                <IonText
                  style={{
                    display: "inline-block",
                    marginBottom: "5px",
                    userSelect: "auto",
                  }}
                >
                  50 %
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonCol>
                <IonText
                  style={{
                    display: "inline-block",
                    marginBottom: "5px",
                    userSelect: "auto",
                  }}
                >
                  {lang.label452}
                </IonText>
              </IonCol>
              <IonCol>
                <IonText
                  style={{
                    display: "inline-block",
                    marginBottom: "5px",
                    userSelect: "auto",
                  }}
                >
                  100 %
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonText
                className="text-center"
                style={{
                  display: "inline-block",
                  marginBottom: "5px",
                }}
              >
                {lang.label453}
              </IonText>
            </IonRow>
            <IonRow className="payment-info payment-border">
              <IonText
                className="text-center"
                style={{
                  display: "inline-block",
                  marginBottom: "5px",
                }}
              >
                * {lang.label454}
              </IonText>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={termsModal}
        /* cssClass="modal-fullscreen" */
        backdropDismiss
        onDidDismiss={() => {
          setTermsModal(false);
        }}
      >
        <IonHeader
          /* className="main-header-search safe-zone-header" */ style={{
            borderBottom: "1px solid #DEDEDE",
          }}
        >
          <IonToolbar>
            <IonButtons>
              <IonButton
                onClick={() => setTermsModal(false)}
                className="modal-search-back"
              >
                <ArrowLeft size={24} color="black" />
              </IonButton>
              <IonLabel>{lang.label455}</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem className="legal-item">
            <div
              style={{ width: "100%" }}
              dangerouslySetInnerHTML={{
                __html: termsData,
              }}
            />
          </IonItem>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showModalPart}
        cssClass="part-modal"
        onDidDismiss={() => setShowModalPart(false)}
      >
        <IonText style={{ display: "block", marginTop: "10px" }}>
          {lang.label196}
          <br /> {partnerName}.
        </IonText>
      </IonModal>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={1200}
        cssClass="toast-pass-change"
        color="danger"
      />
    </IonPage>
  );
};

export default Reservation;
