import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonMenuButton,
  IonButton,
} from "@ionic/react";
import "./Tab1.css";
import { HeartStraight, Calendar, PlusCircle } from "phosphor-react";

import AuthContext from "../my-context";

import React from "react";
import { useHistory } from "react-router-dom";

import logo from "../assets/images/kangelo_logo_web.png";
import { useGlobal } from "reactn";

interface Props {
  styles?: string;
  styles2?: string;
}

const Header: React.FC<Props> = (props: Props) => {
  const history = useHistory();

  const { authValues, lang, logout } = React.useContext(AuthContext);

  const [showGuestModal, setShowGuestModal] = useGlobal("showGuestModal");
  const [, setShowLoginModal] = useGlobal("showLoginModal");
  const [, setShowLoginPage] = useGlobal("showLoginPage");
  const [, setShowCreateAcc] = useGlobal("showCreateAcc");
  const [appWidth] = useGlobal("appWidth");

  return (
    <IonHeader
      className={"main-header" + (props.styles ? " " + props.styles : "")}
      no-border
    >
      <IonToolbar
        className={
          "main-header-tool" + (props.styles2 ? " " + props.styles2 : "")
        }
      >
        <IonTitle className="main-header-tool-title">
          <img
            className="hover-anime"
            style={{
              height: "36px",
              verticalAlign: "middle",
              marginLeft: "-0px",
              marginTop: "4px",
            }}
            src={logo}
            alt="kangelo logo"
            onClick={() => history.push("/tab1")}
          ></img>
          <div className="title-icons">
            {authValues.user.id !== "guest" && (
              <div className="title-icons" style={{ alignItems: "center" }}>
                <PlusCircle
                  size={25}
                  className="menu-icon hover-anime"
                  onClick={() => {
                    return history.push("/create/");
                  }}
                />
                <HeartStraight
                  size={25}
                  className="menu-icon hover-anime"
                  onClick={
                    authValues.user.id === "guest"
                      ? (e) => {
                          e.preventDefault();
                          setShowGuestModal(true);
                        }
                      : () => {
                          return history.push("/travellist");
                        }
                  }
                />
                <Calendar
                  size={25}
                  className="menu-icon hover-anime"
                  onClick={
                    authValues.user.id === "guest"
                      ? (e) => {
                          e.preventDefault();
                          setShowGuestModal(true);
                        }
                      : () => {
                          return history.push("/reservations/");
                        }
                  }
                />
              </div>
            )}
            {/* <ChatCircle size={25} className="menu-icon" /> */}
            {/* <IonAvatar className="header-avatar">
            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
          </IonAvatar> */}
            {authValues.user.id === "guest" && (
              <div style={{ height: "100%" }}>
                <IonButton
                  className="login-button"
                  onClick={() => {
                    if (appWidth > 920) {
                      setShowLoginPage(false);
                      setShowCreateAcc(false);
                      setShowLoginModal(true);
                    } else {
                      setShowGuestModal(false);
                      logout();
                    }
                  }}
                >
                  {lang.label160}
                </IonButton>
              </div>
            )}
            <IonMenuButton className="hover-anime" />
          </div>
        </IonTitle>
        {/* <IonButtons slot="end"><IonMenuButton className="mainMenu" /></IonButtons> */}
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
