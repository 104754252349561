import axios from "axios";

export default async function showImage(id: string, token: string) {
  if (id === null) return;
  if (id.substr(0, 4) === "http") {
    return id;
  }
  const api = axios.create({
    baseURL: `https://app.kangelo.com/`,
  });
  /* var config = {
    headers: {
      "X-Auth-Token": token,
    },
  }; */
  return api
    .get("/media/" + id)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      // what now?
      console.log(err.data);
    });
}
