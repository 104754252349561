import {
  IonText,
  IonAvatar,
  IonCard,
  IonCardContent,
  IonChip,
  IonFooter,
  IonLabel,
  IonCol,
} from "@ionic/react";
/* import './Tab1.css'; */

import React, { useEffect,  useState } from "react";

import { Handshake, Heart, UserCircle } from "phosphor-react";

import moment from "moment";
import Rate from "rc-rate";

import defaultAdvImage from "../../assets/images/default_adventure_image.jpg";

import AuthContext from "../../my-context";
import { useHistory } from "react-router";
import { useGlobal } from "reactn";
import setLike from "../../functions/setLike";
import setEnumText from "../../functions/setEnumText";
import setCountryName from "../../functions/setCountryName";
import checkProperty from "../../functions/checkProperty";

interface Props {
  advData: any;
}

const TravelAdventures: React.FC<Props> = ({ advData }: Props) => {
  const { lang, token } = React.useContext(AuthContext);
  const [likes, setLikes] = useState([false]);
  const [userId, setUserId] = useGlobal("userId");

  useEffect(() => {
    let likesTemp = [false];
    advData.forEach((data: any, index: number) => {
      likesTemp[index] = data===undefined ? false : JSON.parse(JSON.stringify(data?.isLiked));
    });
    setLikes(likesTemp);
  }, [advData]);

  const history = useHistory();

  const [, setAdventureId] = useGlobal("AdventrureId");
  const [language]=useGlobal("language")
/*   const [, setPartnerName] = useState("");
  const [, setShowModalPart] = useState(false); //partner modal */

  const getTripLength = (item: any) => {
    var start = moment(item.dates[0].begin, "YYYY-MM-DD");
    var end = moment(item.dates[0].end, "YYYY-MM-DD");
    var length = end.diff(start, "days") + 1;
    if (length === 1) {
      return "1 " + lang.label191;
    } else if (length < 5) {
      let message = length.toString() + " " + lang.label192;
      return message;
    } else {
      let message = length.toString() + " " + lang.label193;
      return message;
    }
  };

  return (
    <div className="text-center">
      {advData.map((adventure: any, i: number) => {
        if (adventure === undefined) return;
        if (adventure.id !== "") {
        return (
          <IonCard key={i} className="adventures-card">
            <div className="card-cont">
              <img
                className="adventure-cover"
                src={adventure.image !== ""?adventure.imageData:defaultAdvImage}
                onClick={() => {
                  return (
                    setAdventureId(adventure.id),
                    history.push("adventures/" + adventure.id)
                  );
                }}
                alt="adventure background"
              />
              <IonChip className="adv-chip">
                <IonLabel>{setCountryName(
                                adventure.locations[0].country,
                                language
                              )}</IonLabel>
              </IonChip>
              {adventure.categories.map((category: any, indexos: number) => {
                return (
                  <IonChip key={indexos} className={"adv-chip" + indexos} >
                    <IonLabel>{setEnumText(category, lang)}</IonLabel>
                  </IonChip>
                );
              })}
              <IonChip
                className="adv-like"
                onClick={() => {
                  let likesTemp: boolean[] = [...likes];
                  if (typeof adventure.isLiked === "boolean") {
                    likesTemp[i] = !likesTemp[i];
                  }
                  setLikes(likesTemp);
                  setLike(adventure.id, "adventures", likesTemp[i], token);
                }}
              >
                {likes[i] ? (
                  <Heart
                    size={24}
                    weight=/* {likes[i] ?  */"fill"/*  : "regular"} */
                    color="#3fd0d4"
                  />
                ) : (
                  <Heart
                    size={24}
                    weight=/* {likes[i] ? "fill" : */ "duotone"/* } */
                    color="#3fd0d4"
                  />
                )}
              </IonChip>
              {/* <IonChip
                className="adv-partner"
                onClick={() => {
                  setPartnerName(adventure.partner !== null ? adventure.partner:"");
                  setShowModalPart(true);
                }}
              >
                <Handshake size={24} color="#3fd0d4"/>
              </IonChip> */}
              <IonChip className="adv-guide-chip" onClick={() => {
                  setUserId(adventure.guide.id);
                  history.push("/userpage/" + adventure.guide.id);
              }}>
                <IonLabel>{adventure.guide.firstName}</IonLabel>
              </IonChip>
              <IonAvatar className="guide-avatar" onClick={() => {
                  setUserId(adventure.guide.id);
                  history.push("/userpage/" + adventure.guide.id);
              }}>
                    {adventure.guide.profileImage === null ? (
                      <UserCircle
                        size={78}
                        fill="black"
                        weight="fill"
                        style={{ marginLeft: "-7px", marginTop: "-7px" }}
                      />
                    ) : (
                      <img src={adventure.guide.profileImageData} alt="guide" />
                    )}
              </IonAvatar>
            </div>
            <IonCardContent style={{ paddingTop: "8px" }}>
              <IonFooter>
                {adventure.rating !== null && (
                <div
                  style={{
                   display: "inline",
                   marginRight: "5px",
                 }}
                >
                  <Rate
                    count={5}
                    value={adventure.rating}
                    allowHalf={true}
                    disabled={true}
                    className="rating-stars"
                  />{" "}
                {adventure.rating} ({adventure.ratingCount})
                </div>
                )}
                <div
                  style={{ display: "inline-block", verticalAlign: "top",width: "30px", }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="20"
                    fill="rgb(63, 208, 212)"
                    viewBox="0 0 400 256"
                  >
                    <rect width="52" height="256" fill="none"></rect>
                    <line
                      x1="340"
                      y1="208"
                      x2="28"
                      y2="208"
                      fill="none"
                      stroke="rgb(63, 208, 212)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="16"
                    ></line>
                    <rect
                      x="42"
                      y="160"
                      width="56"
                      height="48"
                      strokeWidth="16"
                      stroke="rgb(63, 208, 212)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill={adventure.difficulty >= 1 ? "true" : "none"}
                    ></rect>
                    <rect
                      x="100"
                      y="140"
                      width="56"
                      height="68"
                      strokeWidth="16"
                      stroke="rgb(63, 208, 212)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill={adventure.difficulty >= 2 ? "true" : "none"}
                    ></rect>
                    <rect
                      x="158"
                      y="120"
                      width="56"
                      height="88"
                      strokeWidth="16"
                      stroke="rgb(63, 208, 212)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill={adventure.difficulty >= 3 ? "true" : "none"}
                    ></rect>
                    <rect
                      x="216"
                      y="100"
                      width="56"
                      height="108"
                      strokeWidth="16"
                      stroke="rgb(63, 208, 212)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill={adventure.difficulty >= 4 ? "true" : "none"}
                    ></rect>
                    <rect
                      x="274"
                      y="80"
                      width="56"
                      height="128"
                      strokeWidth="16"
                      stroke="rgb(63, 208, 212)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill={adventure.difficulty >= 5 ? "true" : "none"}
                    ></rect>
                  </svg>
                </div>
                <IonCol>
                {adventure.difficulty}
                /5
                </IonCol>
              </IonFooter>
              <IonFooter style={{ fontWeight: "bold", color: "black" }}>
                {checkProperty(adventure.nameText,language)}
              </IonFooter>
              <IonFooter>
                {adventure.dates.length>0 && <div>{moment(adventure.dates[0]?.begin, "YYYY-MM-DD").format(
                  "DD.MM."
                ) }
                {" "}-{" "}
                {moment(adventure.dates[0].end, "YYYY-MM-DD").format(
                  "DD.MM.YYYY"
                )}{" "}
                • {adventure.dates.length}{" "}
                {adventure.dates.length > 1 ? lang.label194 : lang.label195} •{" "}
                {getTripLength(adventure)}</div>}
              </IonFooter>
            </IonCardContent>
          </IonCard>
        ) }  
      })}
      {advData.length === 1 && advData[0].id === "" && <div className="text-center" style={{width:"100%", marginTop:"15px"}}><IonText className="text-center" >{lang.label281}</IonText></div>}
    </div>
  );
};

export default TravelAdventures;
