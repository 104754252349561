import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonItem,
  IonButtons,
  IonLabel,
  IonBackButton,
  IonList,
  IonFooter,
  IonModal,
  IonTextarea,
  IonText,
  IonRow,
  IonCol,
  IonToast,
  IonGrid,
  IonRadioGroup,
  IonRadio,
  IonListHeader,
  IonInput,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
  IonLoading,
} from "@ionic/react";
import "../Tab1.css";

import "../create.css";

import "../contact.css";

import axios from "axios";

import AuthContext from "../../my-context";
import React, { useEffect, useGlobal, useState } from "reactn";
import { useHistory } from "react-router";
import {
  ArrowLeft,
  CaretCircleRight,
  MinusCircle,
  PlusCircle,
} from "phosphor-react";
import Header from "../header";
import { useRef } from "react";

const BecomeAGuide: React.FC = () => {
  const { token, lang, authValues } = React.useContext(AuthContext);

  useEffect(() => {
    fetchPrivacy();
    // eslint-disable-next-line
    fetchTerms();
    // eslint-disable-next-line
  }, []);

  const history = useHistory();

  const mark = useRef(false);

  const [language] = useGlobal("language");
  const [appWidth] = useGlobal("appWidth");

  const [shotModal1, setShowModal1] = useGlobal("shotModal1");
  const [shotModal2, setShowModal2] = useState(false);
  const [shotModal3, setShowModal3] = useState(false);
  const [shotModal5, setShowModal5] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [uploading, setUploading] = useState(false);

  async function fetchPrivacy() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    api.defaults.headers.common = {};
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .get("/legal/privacy", config)
      .then((resp) => {
        setPolicyData(resp.data.data);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  async function fetchTerms() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    api.defaults.headers.common = {};
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .get("/legal/terms", config)
      .then((resp) => {
        setTermsData(resp.data.data);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  async function sendSurvey() {
    let requestData = {
      professional: selected,
      companyName: companyName,
      companyId: companyId,
      companyAddress: {
        type: "ADDRESS",
        country: country,
        region: "",
        city: city + " " + postal,
        address: street,
      },
      adventureTypes: categoriesString,
      documents: documentsEntireIds,
      message: message,
    };
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .post("/become/guide", requestData, config)
      .then((res) => {
        setUploading(false);
        setShowModal5(true);
      })
      .catch((err) => {
        // what now?
        setUploading(false);
        setToastMessage(err?.response?.data?.message);
        setShowToast(true);
        console.log(err.data);
        console.log(err.status);
        console.log(err.statusText);
        console.log(err.headers);
        console.log(err.config);
        console.log(err);
      });
  }

  const [selected, setSelected] = useGlobal("selected");

  const [companyName, setCompanyName] = useGlobal("companyName");
  const [companyId, setCompanyId] = useGlobal("companyId");
  const [country, setCountry] = useGlobal("country");
  const [street, setStreet] = useGlobal("street");
  const [city, setCity] = useGlobal("city");
  const [postal, setPostal] = useGlobal("postal");

  const [categories, setCategories] = useState([""]);
  const [categoriesString, setCategoriesString] = useState("");

  useEffect(() => {
    let tempString = "";
    categories.forEach((c) => {
      tempString = tempString + ", " + c;
    });
    if (tempString.slice(0, 2) === ", ") {
      setCategoriesString(tempString.slice(2));
    }
  }, [categories]);

  const handleInputChange = (event: any, key?: string) => {
    switch (event.target.name) {
      case "companyName":
        setCompanyName(event.target.value);
        break;
      case "companyId":
        setCompanyId(event.target.value);
        break;
      case "country":
        setCountry(event.target.value);
        break;
      case "street":
        setStreet(event.target.value);
        break;
      case "city":
        setCity(event.target.value);
        break;
      case "postal":
        setPostal(event.target.value);
        break;
      case "category":
        if (event.target.value.length === 0) {
          setCategories([""]);
          break;
        }
        setCategories(event.target.value);
        break;
      case "message":
        setMessage(event.target.value);
        break;

      default:
        break;
    }
  };

  const emptyCountry = [
    {
      id: 0,
      name: "",
      iso3: "",
      iso2: "",
      numeric_code: "",
      phone_code: "",
      capital: "",
      currency: "",
      currency_symbol: "",
      tld: "",
      native: "",
      region: "",
      subregion: "",
      timezones: [
        {
          zoneName: "",
          gmtOffset: 0,
          gmtOffsetName: "",
          abbreviation: "",
          tzName: "",
        },
      ],
      translations: {
        kr: "",
        br: "",
        pt: "",
        nl: "",
        hr: "",
        fa: "",
        de: "",
        es: "",
        fr: "",
        ja: "",
        it: "",
        cn: "",
        cs: "",
      },
      latitude: "",
      longitude: "",
      emoji: "",
      emojiU: "",
      states: [
        {
          id: 0,
          name: "",
          state_code: "",
          latitude: "",
          longitude: "",
          cities: [{}],
        },
      ],
    },
  ];

  const [countrySearchShow, setCountrySearchShow] = useState([false]);
  const [countrySearch, setCountrySearch] = useGlobal("countrySearch");
  const [countryFilter, setCountryFilter] = useState([[emptyCountry[0]]]);

  function searchFunction(e: any, index: number) {
    if (!e) {
      return;
    }

    let searchTemp = [...countrySearch];
    searchTemp[index] = e;
    setCountrySearch(searchTemp);
    if (e.length > 2) {
      const countries =
        require("../../assets/countries_states_cities/countries+states+cities.json")?.filter(
          (item: any) =>
            item.name.toLowerCase().includes(e.toLowerCase()) ||
            item.translations.cs.toLowerCase().includes(e.toLowerCase()) ||
            item.native.toLowerCase().includes(e.toLowerCase())
        );
      let countriesSearch = emptyCountry;
      if (countries.length === 0) {
        countriesSearch = emptyCountry;
      } else {
        countriesSearch = countries;
      }
      let countriesFilterTemp = [...countryFilter];
      countriesFilterTemp[index] = countriesSearch;
      setCountryFilter(countriesFilterTemp);
    } else {
      let countriesFilterTemp = [...countryFilter];
      countriesFilterTemp[index] = emptyCountry;
      setCountryFilter(countriesFilterTemp);
    }
  }

  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);

  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const [termsData, setTermsData] = useState("");
  const [policyData, setPolicyData] = useState("");

  const [documentsEntire, setDocumentsEntire] = useState<any>([]);

  const [entireDocumentsDeletedId, setEntireDocumentsDeletedId] = useState([
    "",
  ]);

  const [newEntireDocuments, setNewEntireDocuments] = useState([
    {
      type: "",
      data: "",
      name: "",
    },
  ]);
  const [documentsEntireIds, setDocumentsEntireIds] = useState([""]);
  function remDocument(index: number) {
    let temp = [];

    temp = [...documentsEntire];
    if (temp[index].id) {
      let tempDeletedId = [...entireDocumentsDeletedId];
      tempDeletedId = tempDeletedId.concat(temp[index].id);
      setEntireDocumentsDeletedId(tempDeletedId);
    }
    temp.splice(index, 1);
    setDocumentsEntire(temp);
  }

  useEffect(() => {
    let temp = [...documentsEntire];
    for (var i = 0; i < newEntireDocuments.length; i++) {
      temp.push(newEntireDocuments[i]);
    }
    setDocumentsEntire([...temp]);
  }, [newEntireDocuments]);

  const noop = () => {};

  const FileInput = ({
    index,
    value,
    onChange = noop,
    ...rest
  }: {
    index?: number;
    value: Array<{ type: string; data: string; name: string }>;
    onChange: any;
  }) => (
    <div style={{ height: "36px", width: "100%" }} className="text-center">
      <label>
        <PlusCircle size={36} /* className="add-date" */ style={{}} />
        <IonText
          style={{
            display: "table-caption",
            width: "max-content",
            marginBottom: "6px",
          }}
        >
          {lang.label316}
        </IonText>
        <input
          {...rest}
          style={{ display: "none" }}
          type="file"
          multiple
          onChange={(e: any) => {
            onChange([...e.target.files], index);
          }}
        />
      </label>
    </div>
  );

  function parse(file: File) {
    // Always return a Promise
    return new Promise((resolve, reject) => {
      let content = "";
      const reader = new FileReader();
      // Wait till complete
      reader.onloadend = function (e: any) {
        content = e.target.result;
        /* const result = content.split(/\r\n|\n/); */
        resolve(content);
      };
      // Make sure to handle error states
      reader.onerror = function (e: any) {
        reject(e);
      };
      reader.readAsDataURL(file);
    });
  }

  const readFile = async (files: any) => {
    let temp = [...files];
    let check = false;
    temp = temp.filter((file) => {
      if (file.size > 5242880) {
        check = true;
      }
      return file.size < 5242881;
    });
    if (check) {
      setToastMessage(lang.label431);
      setShowToast(true);
    }
    for (var i = 0; i < temp.length; i++) {
      temp[i] = {
        type: "DOCUMENT",
        name: temp[i].name,
        data: await parse(temp[i]),
      };
    }

    setNewEntireDocuments(temp);
  };

  async function uploadFiles() {
    let tempDocs = [...documentsEntire];
    if (tempDocs.length === 1 && tempDocs[0].id === "") {
      sendSurvey();
      return;
    } else if (tempDocs.length === 0) {
      sendSurvey();
      return;
    }
    Promise.all(
      documentsEntire.map(async (doc: any, index: number) => {
        if (!doc.id && doc.data !== "") {
          const api = axios.create({
            baseURL: `https://app.kangelo.com/`,
          });
          var config = {
            headers: {
              "X-Auth-Token": token,
            },
          };
          return api
            .post("/media", doc, config)
            .then((res) => {
              tempDocs[index] = res.data;
              return res.data.id;
            })
            .catch((err) => {
              // what now?
              console.log(err.data);
            });
        }
      })
    ).then((res) => {
      let tempIds: Array<string> = [];
      let tempRes = res.filter((d) => d !== undefined);
      tempIds = tempIds.concat(tempRes);
      if (tempIds.length > 0) {
        setDocumentsEntireIds(tempIds);
      } else {
        sendSurvey();
      }
    });
  }

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (documentsEntireIds[0] !== "") sendSurvey();
  }, [documentsEntireIds]);

  return (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar /* className="back-butt" */>
          <IonButtons>
            <IonBackButton
              defaultHref="/tab1"
              text={lang.label226}
              className="back-button"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="web-box">
          {/* <IonText
          style={{ marginTop: "5px", padding: "0 25px", display: "block" }}
        >
          Ahoj Kangeláku! Jsme rádi, že tě zajímá možnost se stát průvodcem. Ať
          už jsi zkušeným nebo se chystáš začít, tak neváhej nám vyplnit pár
          otázek. Brzy se ti ozveme zpátky.
        </IonText> */}
          <IonList style={{ padding: "10px 35px 0 35px" }}>
            <IonRadioGroup
              value={selected}
              onIonChange={(e) => setSelected(e.detail.value)}
            >
              <IonListHeader
                className="text-center text-bold"
                style={{ fontSize: "16px" }}
              >
                <IonLabel>{lang.label340}</IonLabel>
              </IonListHeader>

              <IonItem className="become-partner-radio-item">
                <IonLabel>{lang.label342}</IonLabel>
                <IonRadio slot="end" value="INDIVIDUAL" />
              </IonItem>

              <IonItem className="become-partner-radio-item">
                <IonLabel>{lang.label343}</IonLabel>
                <IonRadio slot="end" value="TRAVEL_AGENCY" />
              </IonItem>

              <IonItem className="become-partner-radio-item">
                <IonLabel>{lang.label344}</IonLabel>
                <IonRadio slot="end" value="TOUR_OPERATOR" />
              </IonItem>
              <IonItem className="become-partner-radio-item">
                <IonLabel>{lang.label345}</IonLabel>
                <IonRadio slot="end" value="CLUB" />
              </IonItem>
              <IonItem className="become-partner-radio-item">
                <IonLabel>{lang.label346}</IonLabel>
                <IonRadio slot="end" value="OTHER_ORGANIZATION" />
              </IonItem>
              <IonListHeader
                className="text-center text-bold"
                style={{ fontSize: "16px" }}
              >
                <IonLabel>{lang.label341}</IonLabel>
              </IonListHeader>
              <IonItem className="become-partner-radio-item">
                <IonLabel>{lang.label347}</IonLabel>
                <IonRadio slot="end" value="FALSE" />
              </IonItem>
            </IonRadioGroup>
          </IonList>
        </div>
      </IonContent>

      <IonToolbar slot="bottom" className="text-center safe-zone">
        <IonButton
          className="footer-button"
          onClick={
            selected !== ""
              ? () => {
                  setShowModal1(true);
                }
              : () => {
                  setToastMessage(lang.label348 + "!");
                  setShowToast(true);
                }
          }
        >
          <IonLabel color="white" style={{ textTransform: "initial" }}>
            {lang.label382}
          </IonLabel>
        </IonButton>
      </IonToolbar>

      <IonModal
        isOpen={shotModal1}
        cssClass="bec-guide-modal"
        /* onDidDismiss={() => setShowModal1(false)} */
      >
        {appWidth > 920 && <Header styles="shadow-box" />}
        <IonHeader className="bottom-border web-box">
          <IonToolbar>
            <IonButtons>
              <IonButton onClick={() => setShowModal1(false)}>
                <ArrowLeft size={32} />
                <IonLabel className="back-button">{lang.label226}</IonLabel>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div
            className="web-box"
            style={{ width: "100vw", position: "relative" }}
          >
            <IonLabel
              className="user-info-label"
              style={{ display: "block", marginTop: "5px", height: "24px" }}
            >
              {lang.label49 + " *"}
            </IonLabel>
            <IonItem
              className="user-info-item item-input-grey"
              style={{ height: "40px" }}
            >
              <IonInput
                value={authValues.user.firstName}
                name="firstName"
                disabled={true}
                className="user-info-input"
              ></IonInput>
            </IonItem>
            <IonButton
              style={{
                position: "absolute",
                top: "30px",
                zIndex: "10",
                right: "40px",
                height: "30px",
                fontSize: "12px",
                textTransform: "Initial",
              }}
              onClick={() => {
                history.push("/userdetails/");
              }}
            >
              {lang.label354}
            </IonButton>
            <IonLabel
              className="user-info-label"
              style={{ display: "block", height: "24px" }}
            >
              {lang.label50 + " *"}
            </IonLabel>
            <IonItem
              className="user-info-item item-input-grey"
              style={{ height: "40px" }}
            >
              <IonInput
                value={authValues.user.lastName}
                name="lastName"
                disabled={true}
                className="user-info-input"
              ></IonInput>
            </IonItem>
            <IonButton
              style={{
                position: "absolute",
                top: "108px",
                zIndex: "10",
                right: "40px",
                height: "30px",
                fontSize: "12px",
                textTransform: "Initial",
              }}
              onClick={() => {
                history.push("/userdetails/");
              }}
            >
              {lang.label354}
            </IonButton>
            <IonLabel
              className="user-info-label"
              style={{ display: "block", height: "24px" }}
            >
              {lang.label57 + " *"}
            </IonLabel>
            <IonItem
              className="user-info-item item-input-grey"
              style={{ height: "40px" }}
            >
              <IonInput
                value={authValues.user.phone}
                name="phone"
                disabled={true}
                className="user-info-input"
              ></IonInput>
            </IonItem>
            <IonButton
              style={{
                position: "absolute",
                top: "188px",
                zIndex: "10",
                right: "40px",
                height: "30px",
                fontSize: "12px",
                textTransform: "Initial",
              }}
              onClick={() => {
                history.push("/userdetails/");
              }}
            >
              {lang.label354}
            </IonButton>
            {selected !== "INDIVIDUAL" && selected !== "FALSE" && (
              <div>
                <IonLabel className="user-info-label">
                  {lang.label349 + " *"}
                </IonLabel>
                <IonItem className="user-info-item item-input-grey">
                  <IonInput
                    value={companyName}
                    name="companyName"
                    placeholder={lang.label349}
                    inputmode="text"
                    maxlength={50}
                    onIonChange={(event) => handleInputChange(event)}
                    className="user-info-input"
                  ></IonInput>
                </IonItem>
              </div>
            )}
            {selected !== "FALSE" && (
              <div>
                <IonLabel className="user-info-label">
                  {lang.label350 + " *"}
                </IonLabel>
                <IonItem className="user-info-item item-input-grey">
                  <IonInput
                    value={companyId}
                    name="companyId"
                    placeholder={lang.label350}
                    inputmode="text"
                    maxlength={50}
                    onIonChange={(event) => handleInputChange(event)}
                    className="user-info-input"
                  ></IonInput>
                </IonItem>
              </div>
            )}

            <IonLabel className="user-info-label">
              {lang.label228 + " *"}
            </IonLabel>
            <IonItem className="user-info-item item-input-grey">
              <IonInput
                value={countrySearch[0]}
                name="location"
                placeholder={lang.label228}
                inputmode="text"
                maxlength={30}
                onIonChange={(e) => searchFunction(e.detail.value, 0)}
                onIonFocus={() => {
                  mark.current = false;
                  setCountry("");
                  let tempCountrySearchShow = [...countrySearchShow];
                  tempCountrySearchShow[0] = true;
                  setCountrySearchShow(tempCountrySearchShow);
                }}
                onIonBlur={(e) => {
                  setTimeout(function () {
                    if (!mark.current) {
                      setCountrySearch([""]);
                    }
                    let tempCountrySearchShow = [...countrySearchShow];
                    tempCountrySearchShow[0] = false;
                    setCountrySearchShow(tempCountrySearchShow);
                  }, 200);
                }}
                className="user-info-input"
              ></IonInput>
            </IonItem>
            {
              <IonList className="over-search">
                {countryFilter[0][0].id !== 0 && countrySearchShow[0] && (
                  <div
                    style={{
                      position: "absolute",
                      transform: "translate(-50 %, .75rem)",
                      width: "91.5%",
                      left: "16px",
                      border: "none",
                    }}
                  >
                    {countryFilter[0].map((item, index) => {
                      return (
                        <IonItem
                          key={index}
                          onClick={() => {
                            mark.current = true;
                            let tempSearch = [...countrySearch];
                            tempSearch[0] =
                              language === "en"
                                ? item.name
                                : item.translations.cs;
                            setCountrySearch(tempSearch);
                            setCountry(item.iso2);
                            let tempCountrySearchShow = [...countrySearchShow];
                            tempCountrySearchShow[0] = false;
                            setCountrySearchShow(tempCountrySearchShow);
                          }}
                          style={{
                            border: "solid 1px black",
                            borderRadius: "10px",
                            zIndex: 10000,
                          }}
                        >
                          {language === "en" ? item.name : item.translations.cs}
                        </IonItem>
                      );
                    })}
                  </div>
                )}
              </IonList>
            }
            {selected !== "FALSE" && (
              <div>
                <IonLabel className="user-info-label">
                  {lang.label351 + " *"}
                </IonLabel>
                <IonItem className="user-info-item item-input-grey">
                  <IonInput
                    value={street}
                    name="street"
                    placeholder={lang.label351}
                    inputmode="text"
                    maxlength={50}
                    onIonChange={(event) => handleInputChange(event)}
                    className="user-info-input"
                  ></IonInput>
                </IonItem>
              </div>
            )}
            <IonLabel className="user-info-label">
              {lang.label352 + " *"}
            </IonLabel>
            <IonItem className="user-info-item item-input-grey">
              <IonInput
                value={city}
                name="city"
                placeholder={lang.label352}
                inputmode="text"
                maxlength={50}
                onIonChange={(event) => handleInputChange(event)}
                className="user-info-input"
              ></IonInput>
            </IonItem>
            {selected !== "FALSE" && (
              <div>
                <IonLabel className="user-info-label">
                  {lang.label353 + " *"}
                </IonLabel>
                <IonItem className="user-info-item item-input-grey">
                  <IonInput
                    value={postal}
                    name="postal"
                    placeholder={lang.label353}
                    inputmode="text"
                    maxlength={50}
                    onIonChange={(event) => handleInputChange(event)}
                    className="user-info-input"
                  ></IonInput>
                </IonItem>
              </div>
            )}
          </div>
        </IonContent>
        <IonToolbar slot="bottom" className="text-center safe-zone">
          <IonButton
            className="footer-button"
            onClick={() => {
              {
                if (selected === "INDIVIDUAL") {
                  if (
                    authValues.user.firstName === "" ||
                    authValues.user.lastName === "" ||
                    authValues.user.phone === "" ||
                    companyId === "" ||
                    country === "" ||
                    street === "" ||
                    city === "" ||
                    postal === ""
                  ) {
                    setToastMessage(lang.label233);
                    setShowToast(true);
                  } else setShowModal2(true);
                } else if (selected === "FALSE") {
                  if (
                    authValues.user.firstName === "" ||
                    authValues.user.lastName === "" ||
                    authValues.user.phone === "" ||
                    country === "" ||
                    city === ""
                  ) {
                    setToastMessage(lang.label233);
                    setShowToast(true);
                  } else setShowModal2(true);
                } else {
                  if (
                    authValues.user.firstName === "" ||
                    authValues.user.lastName === "" ||
                    authValues.user.phone === "" ||
                    companyName === "" ||
                    companyId === "" ||
                    country === "" ||
                    street === "" ||
                    city === "" ||
                    postal === ""
                  ) {
                    setToastMessage(lang.label233);
                    setShowToast(true);
                  } else setShowModal2(true);
                }
              }
            }}
          >
            <IonLabel color="white" style={{ textTransform: "initial" }}>
              {lang.label382}
            </IonLabel>
          </IonButton>
        </IonToolbar>
      </IonModal>

      <IonModal
        isOpen={shotModal2}
        cssClass="bec-guide-modal"
        onDidDismiss={() => setShowModal2(false)}
      >
        {appWidth > 920 && <Header styles="shadow-box" />}
        <IonHeader className="bottom-border web-box">
          <IonToolbar>
            <IonButtons>
              <IonButton onClick={() => setShowModal2(false)}>
                <ArrowLeft size={32} />
                <IonLabel className="back-button">{lang.label226}</IonLabel>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="web-box">
            <IonList style={{ padding: "15px 30px 0 30px" }}>
              <IonListHeader
                className="text-bold"
                style={{
                  fontSize: "16px",
                  textDecoration: "underline",
                  paddingInlineStart: "0",
                }}
                onClick={() => setShowTerms(true)}
              >
                <IonLabel>{lang.label355}*</IonLabel>
              </IonListHeader>

              <IonItem style={{ "--padding-start": "0" }}>
                <IonLabel
                  style={{
                    fontSize: "12px",
                    whiteSpace: "normal",
                    marginRight: "10px",
                  }}
                >
                  {lang.label357}
                </IonLabel>
                <IonCheckbox
                  checked={check1}
                  onIonChange={(e) => setCheck1(e.detail.checked)}
                />
              </IonItem>

              <IonListHeader
                className="text-bold"
                style={{
                  fontSize: "16px",
                  textDecoration: "underline",
                  paddingInlineStart: "0",
                }}
                onClick={() => setShowPrivacy(true)}
              >
                <IonLabel>{lang.label356}*</IonLabel>
              </IonListHeader>

              <IonItem style={{ "--padding-start": "0" }}>
                <IonLabel
                  style={{
                    fontSize: "12px",
                    whiteSpace: "normal",
                    marginRight: "10px",
                  }}
                >
                  {lang.label358}
                </IonLabel>
                <IonCheckbox
                  slot="end"
                  checked={check2}
                  onIonChange={(e) => setCheck2(e.detail.checked)}
                />
              </IonItem>
            </IonList>
          </div>
        </IonContent>
        <IonToolbar slot="bottom" className="text-center safe-zone">
          <IonButton
            className="footer-button"
            onClick={() => {
              if (check1 && check2) {
                setShowModal3(true);
              } else {
                setToastMessage(lang.label359);
                setShowToast(true);
              }
            }}
          >
            <IonLabel color="white" style={{ textTransform: "initial" }}>
              {lang.label382}
            </IonLabel>
          </IonButton>
        </IonToolbar>
      </IonModal>

      <IonModal
        isOpen={showTerms}
        cssClass="bec-guide-modal"
        onDidDismiss={() => setShowTerms(false)}
      >
        {appWidth > 920 && <Header styles="shadow-box" />}
        <IonHeader className="bottom-border web-box">
          <IonToolbar>
            <IonButtons>
              <IonButton onClick={() => setShowTerms(false)}>
                <ArrowLeft size={32} />
                <IonLabel className="back-button">{lang.label226}</IonLabel>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="web-box">
            <IonItem className="legal-item">
              <IonText className="legal-text">
                <div
                  style={{
                    width: "77vw",
                    overflow: "visible",
                    marginBlockStart: "0 important",
                    marginBlockEnd: "0 important",
                  }}
                  className="legal-div"
                  dangerouslySetInnerHTML={{ __html: termsData }}
                />
              </IonText>
            </IonItem>
          </div>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showPrivacy}
        cssClass="bec-guide-modal"
        onDidDismiss={() => setShowPrivacy(false)}
      >
        {appWidth > 920 && <Header styles="shadow-box" />}
        <IonHeader className="bottom-border web-box">
          <IonToolbar>
            <IonButtons>
              <IonButton onClick={() => setShowPrivacy(false)}>
                <ArrowLeft size={32} />
                <IonLabel className="back-button">{lang.label226}</IonLabel>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="web-box">
            <IonItem className="legal-item">
              <IonText className="legal-text">
                <div
                  style={{
                    width: "77vw",
                    overflow: "visible",
                    marginBlockStart: "0 important",
                    marginBlockEnd: "0 important",
                  }}
                  className="legal-div"
                  dangerouslySetInnerHTML={{ __html: policyData }}
                />
              </IonText>
            </IonItem>
          </div>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={shotModal3}
        cssClass="bec-guide-modal"
        onDidDismiss={() => setShowModal3(false)}
      >
        {appWidth > 920 && <Header styles="shadow-box" />}
        <IonHeader className="bottom-border web-box">
          <IonToolbar>
            <IonButtons>
              <IonButton onClick={() => setShowModal3(false)}>
                <ArrowLeft size={32} />
                <IonLabel className="back-button">{lang.label226}</IonLabel>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="web-box">
            <IonLabel
              className="user-info-label"
              style={{
                marginTop: "10px",
                marginRight: "30px",
                display: "block",
                whiteSpace: "normal",
              }}
            >
              {lang.label360} *
            </IonLabel>
            <IonItem className="user-info-item item-input-grey">
              <IonSelect
                interface="alert"
                multiple={true}
                name="category"
                placeholder={lang.label104}
                onIonChange={(event) => handleInputChange(event)}
                value={categories}
                className="user-info-input"
              >
                <IonSelectOption value="CROSS_COUNTRY_SKIING">
                  {lang.label99}
                </IonSelectOption>
                <IonSelectOption value="CANYONING">
                  {lang.label93}
                </IonSelectOption>
                <IonSelectOption value="BIKING">{lang.label97}</IonSelectOption>
                <IonSelectOption value="EXPEDITION">
                  {lang.label78}
                </IonSelectOption>
                <IonSelectOption value="HELISKIING">
                  {lang.label89}
                </IonSelectOption>
                <IonSelectOption value="MOUNTAIN_ASCENTS">
                  {lang.label82}
                </IonSelectOption>
                <IonSelectOption value="BOATING">
                  {lang.label92}
                </IonSelectOption>
                <IonSelectOption value="ICEBERG">
                  {lang.label86}
                </IonSelectOption>
                <IonSelectOption value="CLIMBING">
                  {lang.label90}
                </IonSelectOption>
                <IonSelectOption value="SKIING">{lang.label85}</IonSelectOption>
                <IonSelectOption value="OTHERS">{lang.label98}</IonSelectOption>
                <IonSelectOption value="DIVING">{lang.label91}</IonSelectOption>
                <IonSelectOption value="SIGHTSEEING">
                  {lang.label84}
                </IonSelectOption>
                <IonSelectOption value="MOUNTAIN_CROSSING">
                  {lang.label83}
                </IonSelectOption>
                <IonSelectOption value="RELAX_AND_CHILL">
                  {lang.label95}
                </IonSelectOption>
                <IonSelectOption value="ROADTRIP">
                  {lang.label80}
                </IonSelectOption>
                <IonSelectOption value="SKI_MOUNTAINEERING">
                  {lang.label88}
                </IonSelectOption>
                <IonSelectOption value="SNOWSHOES">
                  {lang.label87}
                </IonSelectOption>
                <IonSelectOption value="SNOWBOARDING">
                  {lang.label96}
                </IonSelectOption>
                <IonSelectOption value="HIKING">{lang.label94}</IonSelectOption>
                <IonSelectOption value="VIA_FERRATA">
                  {lang.label81}
                </IonSelectOption>
                <IonSelectOption value="WEEKEND_TRIP">
                  {lang.label79}
                </IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonLabel
              className="user-info-label"
              style={{
                marginTop: "15px",
                marginRight: "30px",
                display: "block",
                whiteSpace: "normal",
              }}
            >
              {lang.label361}
            </IonLabel>
            <IonText
              style={{
                marginTop: "5px",
                marginLeft: "36px",
                marginRight: "36px",
                display: "block",
                fontSize: "12px",
              }}
              className="certi-input"
            >
              {lang.label362}
            </IonText>

            <IonList>
              {documentsEntire.map((document: any, indexon: number) => {
                if (document.name === "") return null;
                return (
                  <IonItem
                    key={indexon}
                    style={{
                      marginLeft: "36px",
                      marginRight: "36px",
                      "--padding-start": "0",
                      "--inner-padding-end": "0",
                    }}
                  >
                    <IonGrid
                      style={{
                        height: "40px",
                        paddingInline: 0,
                        width: "100%",
                      }}
                    >
                      <IonRow style={{ height: "40px" }}>
                        <IonCol
                          size="10"
                          style={{
                            height: "40px",
                            paddingLeft: 0,
                            paddingTop: 0,
                          }}
                        >
                          <IonButton
                            download={document.name}
                            href={document.data}
                            style={{
                              width: "100%",
                              height: "30px",
                              marginTop: 2,
                              marginLeft: 0,
                            }}
                          >
                            <IonText
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {document.name}
                            </IonText>
                          </IonButton>
                        </IonCol>
                        <IonCol
                          style={{
                            height: "40px",
                            paddingTop: "0",
                          }}
                        >
                          <MinusCircle
                            size={36}
                            /* className="add-date" */
                            onClick={() => remDocument(indexon)}
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                );
              })}
              <IonItem className="no-border">
                <FileInput value={newEntireDocuments} onChange={readFile} />
              </IonItem>
            </IonList>
            <IonLabel className="user-info-label">{lang.label363}</IonLabel>
            <IonItem
              className="contact-item item-input-grey"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <IonTextarea
                value={message}
                name="message"
                autoGrow={true}
                placeholder={lang.label105}
                inputmode="text"
                maxlength={1000}
                onIonChange={(event) => handleInputChange(event)}
                className="contact-input"
                style={{ height: "auto", fontSize: "12px", minHeight: "66px" }}
              ></IonTextarea>
            </IonItem>
          </div>
        </IonContent>
        <IonToolbar slot="bottom" className="text-center safe-zone">
          <IonButton
            color="primary"
            className="footer-button"
            style={{ /* width: "80vw", */ textTransform: "Initial" }}
            onClick={() => {
              if (categoriesString === "") {
                setToastMessage(lang.label233);
                setShowToast(true);
              } else {
                setShowConfirmModal(true);
              }
            }}
          >
            {lang.label364}
          </IonButton>
        </IonToolbar>
      </IonModal>

      <IonModal
        isOpen={showConfirmModal}
        cssClass="bec-guide-modal"
        backdropDismiss
        onDidDismiss={() => setShowConfirmModal(false)}
      >
        <IonContent>
          <IonGrid
            style={{
              /* width: "100vw", */
              marginLeft: "auto",
              marginRight: "auto",
              /* height: "100vh", */
              textAlign: "center",
              marginTop: "40vh",
            }}
          >
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonText style={{ width: "100%" }}>{lang.label266}</IonText>
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <IonCol size="12">
                <IonButton
                  onClick={() => {
                    setShowConfirmModal(false);
                  }}
                  color="danger"
                  style={{ marginRight: "15px" }}
                >
                  {lang.label166}
                </IonButton>
                <IonButton
                  onClick={() => {
                    setUploading(true);
                    uploadFiles();
                  }}
                  color="primary"
                  style={{ marginLeft: "15px" }}
                >
                  {lang.label121}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonLoading
          cssClass="loading-spinner"
          isOpen={uploading}
          onDidDismiss={() => setUploading(false)}
          message={lang.label222}
        />
      </IonModal>

      <IonModal
        isOpen={shotModal5}
        cssClass="bec-guide-modal"
        onDidDismiss={() => {
          setSelected("");
          setCompanyName("");
          setCompanyId("");
          setCountry("");
          setStreet("");
          setCity("");
          setPostal("");
          setCountrySearch([""]);
          setShowModal1(false);
          setShowModal5(false);
          history.replace("/tab1/");
        }}
      >
        <IonContent>
          <div className="web-box">
            <IonText
              style={{
                padding: "0 25px",
                display: "block",
                marginTop: "30vh",
                textAlign: "center",
              }}
            >
              {lang.label365}
            </IonText>
            <IonText
              style={{
                padding: "0 25px",
                display: "block",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              {lang.label366}
            </IonText>
            <IonText
              style={{
                padding: "0 25px",
                display: "block",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              {lang.label367}
            </IonText>
            <IonToolbar style={{ marginTop: "5vh" }}>
              <IonButton
                className="filter-search-butt"
                onClick={() => {
                  setSelected("");
                  setCompanyName("");
                  setCompanyId("");
                  setCountry("");
                  setStreet("");
                  setCity("");
                  setPostal("");
                  setCountrySearch([""]);
                  setShowModal1(false);
                  setShowModal5(false);
                  history.replace("/tab1/");
                }}
                style={{ textTransform: "Initial" }}
              >
                <IonLabel>{lang.label368}</IonLabel>
              </IonButton>
            </IonToolbar>
          </div>
        </IonContent>
        {/* <IonFooter></IonFooter> */}
      </IonModal>

      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={1200}
        color="danger"
        cssClass="toast-pass-change"
      />
    </IonPage>
  );
};

export default BecomeAGuide;
