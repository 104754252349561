import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonFooter,
  IonAvatar,
  IonChip,
  IonLabel,
  IonText,
  IonBackButton,
  IonSpinner,
  IonCol,
} from "@ionic/react";
import "./Tab1.css";

import axios from "axios";

import moment from "moment";

import Rate from "rc-rate";
import "rc-rate/assets/index.css";

import AuthContext from "../my-context";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import defaultAdvImage from "../assets/images/default_adventure_image.jpg";

import { useGlobal } from "reactn";
import { Heart, Handshake, UserCircle } from "phosphor-react";
import { nanoid } from "nanoid";
import showImage from "../functions/showImage";
import setCountryName from "../functions/setCountryName";
import setEnumText from "../functions/setEnumText";
import setLike from "../functions/setLike";
import checkProperty from "../functions/checkProperty";
import Header from "./header";

const Reservations: React.FC = () => {
  const isFirstRender = useRef(true);
  const [showLoading, setShowLoading] = useState(true);
  const history = useHistory();

  const [, setReservationId] = useGlobal("reservationId");
  const [language] = useGlobal("language");
  const [, setUserId] = useGlobal("userId");
  const [appWidth] = useGlobal("appWidth");

  const { token, lang, checkImageCache, db } = React.useContext(AuthContext);

  /*   const [showModalPart, setShowModalPart] = useState(false); //partner modal
  const [partnerName, setPartnerName] = useState(""); */

  const empty = {
    data: {
      id: "",
      image: "",
      imageData: "",
      rating: 0,
      ratingCount: 0,
      difficulty: 0,
      isLiked: false,
      guide: {
        id: "",
        firstName: "",
        lastName: "",
        profileImage: "",
        profileImageData: "",
        guide: false,
        admin: false,
      },
      meals: "",
      transit: "",
      accommodation: "",
      categories: [""],
      country: "",
      locations: [
        {
          type: "",
          latitude: "",
          longitude: "",
          airport: "",
          country: "",
          city: "",
          address: "",
          countryText: {
            CS: "",
            EN: "",
          },
        },
      ],
      departure: {
        type: "",
        latitude: "",
        longitude: "",
        airport: "",
        country: "",
        city: "",
        address: "",
        countryText: "",
      },
      arrival: {
        type: "",
        latitude: "",
        longitude: "",
        airport: "",
        country: "",
        city: "",
        address: "",
        countryText: "",
      },
      dates: [
        {
          id: "",
          begin: "",
          end: "",
          prices: {
            EUR: 0,
            CZK: 0,
          },
          capacity: 0,
          free: 0,
        },
        {
          id: "",
          begin: "",
          end: "",
          prices: {
            EUR: 0,
            CZK: 0,
          },
          capacity: 0,
          free: 0,
        },
      ],
      itinerary: [
        {
          title: "",
          description: "",
          image: "",
        },
      ],
      nameText: {
        CS: "",
        EN: "",
      },
      descriptionText: null,
      includedText: null,
      notIncludedText: null,
      accommodationText: null,
      mealsText: null,
      transitText: null,
      difficultyText: null,
      moreInfoText: null,
    },
  };

  const [reservations, setReservations] = useState<any>([empty]);
  const [reservationsData, setReservationsData] = useState<any>([
    {
      id: "",
      status: "",
      people: [
        {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
        {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
      ],
      adventureDatesId: "",
      adventureId: "",
      userId: "",
      price: {
        CZK: 0,
      },
    },
  ]);

  const getTripLength = (item: any) => {
    var start = moment(item.dates[0].begin, "YYYY-MM-DD");
    var end = moment(item.dates[0].end, "YYYY-MM-DD");
    var length = end.diff(start, "days") + 1;
    if (length === 1) {
      return "1 " + lang.label191;
    } else if (length < 5) {
      let message = length.toString() + " " + lang.label192;
      return message;
    } else {
      let message = length.toString() + " " + lang.label193;
      return message;
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      fetchData();
      // eslint-disable-next-line
      isFirstRender.current = false;
    }
  }, [reservations]);

  async function fetchData() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .get("/reservations", config)
      .then((res) => {
        /*         let change: any = JSON.parse(JSON.stringify(res));
        change.data.data.map((item: any) => {
          if (typeof empty !== "undefined") {
            item.data = empty[0]?.data;
          }
        }); */

        if (res.data.data.length === 0) {
          setShowLoading(false);
          return;
        }
        setReservationsData(res.data.data);
        fetchAdv(res.data.data);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
        console.log(err.status);
        console.log(err.statusText);
        console.log(err.headers);
        console.log(err.config);
        console.log(err);
      });
  }

  async function fetchAdv(prop: any) {
    let help = JSON.parse(JSON.stringify(reservations));
    Promise.all(
      prop.map(async (x: any, index: number) => {
        const api = axios.create({
          baseURL: `https://app.kangelo.com/`,
        });
        var config = {
          headers: {
            "X-Auth-Token": token,
          },
        };
        return api
          .get("/adventures/" + x.adventureId, config)
          .then(async (res) => {
            help[index] = await res.data;
            /* if (index + 1 === help.data.length) {
              let help2 = JSON.parse(JSON.stringify(help));
              setImages(help2.data);
            } */
          })
          .catch((err) => {
            // what now?
            console.log(err.data);
            console.log(err.status);
            console.log(err.statusText);
            console.log(err.headers);
            console.log(err.config);
            console.log(err);
          });
      })
    ).then(() => {
      setImages(help);
    });
  }

  async function setImages(items: any) {
    if (items) {
      Promise.all(
        items.map(async (itemX: any) => {
          if (await checkImageCache(itemX.image)) {
            itemX.imageData = await db.get(itemX.image);
          } else {
            if (itemX.image !== "" || itemX.image !== null) {
              itemX.imageData = await showImage(itemX.image, token);
              await db.set(itemX.image, itemX.imageData);
            } else itemX.imageData = "";
          }
          if (await checkImageCache(itemX.guide.profileImage)) {
            itemX.guide.profileImageData = await db.get(
              itemX.guide.profileImage
            );
          } else {
            if (itemX.guide.profileImage !== "") {
              if (itemX.guide.profileImage !== null) {
                itemX.guide.profileImageData = await showImage(
                  itemX.guide.profileImage,
                  token
                );
                await db.set(
                  itemX.guide.profileImage,
                  itemX.guide.profileImageData
                );
              }
            } else itemX.guide.profileImageData = "";
          }
          return itemX;
        })
      ).then((resp) => {
        setReservations(resp);
        setShowLoading(false);
      });
    } else return null;
  }

  return (
    <IonPage id="main">
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar /* className="back-butt" */>
          <IonButtons slot="start">
            <IonBackButton
              className="back-button"
              defaultHref="/tab1"
              text={lang.label37}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen style={{ paddingBottom: "15px" }}>
        <div className="web-box text-center">
          {showLoading ? (
            <IonSpinner className="spinner-loading" color="primary" />
          ) : (
            reservations.map((reserve: any, i: number) => {
              if (reserve.id !== "" && reservationsData[0].id !== "") {
                return (
                  <IonCard
                    key={nanoid()}
                    className="adventures-card safe-zone-last"
                  >
                    <div className="card-cont">
                      <img
                        src={
                          reserve.imageData !== ""
                            ? reserve.imageData
                            : defaultAdvImage
                        }
                        className="adventure-cover"
                        onClick={() => {
                          return (
                            setReservationId(reservationsData[i].id),
                            history.push(
                              "/reservations/" + reservationsData[i].id
                            )
                          );
                        }}
                        alt="adventure background"
                      />
                      <IonChip className="adv-chip">
                        <IonLabel>
                          {setCountryName(
                            reserve.locations[0].country,
                            language
                          )}
                        </IonLabel>
                      </IonChip>
                      {reserve.categories.map((category: any, i: number) => {
                        return (
                          <IonChip className={"adv-chip" + i} key={`${i}`}>
                            <IonLabel>{setEnumText(category, lang)}</IonLabel>
                          </IonChip>
                        );
                      })}
                      <IonChip
                        className="adv-like"
                        onClick={() => {
                          let reservationsTemp = [...reservations];
                          reservationsTemp[i].isLiked = !reserve.isLiked;
                          setReservations(reservationsTemp);
                          setLike(
                            reserve.id,
                            "adventures",
                            reserve.isLiked,
                            token
                          );
                        }}
                      >
                        <Heart
                          size={24}
                          weight={reserve.isLiked ? "fill" : "duotone"}
                          color="#3fd0d4"
                        />
                      </IonChip>
                      {/* <IonChip
                      className="adv-partner"
                      onClick={() => {
                        setPartnerName(
                          reserve.partner !== null ? reserve.partner : ""
                        );
                        setShowModalPart(true);
                      }}
                    >
                      <Handshake size={24} color="#3fd0d4" />
                    </IonChip> */}
                      <IonChip
                        className="adv-guide-chip"
                        onClick={() => {
                          setUserId(reserve.guide.id);
                          history.push("/userpage/" + reserve.guide.id);
                        }}
                      >
                        <IonLabel>{reserve.guide.firstName}</IonLabel>
                      </IonChip>
                      <IonAvatar
                        className="guide-avatar"
                        onClick={() => {
                          setUserId(reserve.guide.id);
                          history.push("/userpage/" + reserve.guide.id);
                        }}
                      >
                        {reserve.guide.profileImage === null ? (
                          <UserCircle
                            size={78}
                            fill="black"
                            weight="fill"
                            style={{ marginLeft: "-7px", marginTop: "-7px" }}
                          />
                        ) : (
                          <img
                            src={reserve.guide.profileImageData}
                            alt="guide"
                          />
                        )}
                      </IonAvatar>
                    </div>
                    <IonCardContent style={{ paddingTop: "8px" }}>
                      <IonFooter>
                        {reserve.rating !== null && (
                          <div
                            style={{
                              display: "inline",
                              marginRight: "5px",
                            }}
                          >
                            <Rate
                              count={5}
                              value={reserve.rating}
                              allowHalf={true}
                              disabled={true}
                              className="rating-stars"
                            />{" "}
                            {reserve.rating} ({reserve.ratingCount})
                          </div>
                        )}
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "top",
                            width: "30px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="20"
                            fill="rgb(63, 208, 212)"
                            viewBox="0 0 400 256"
                          >
                            <rect width="52" height="256" fill="none"></rect>
                            <line
                              x1="340"
                              y1="208"
                              x2="28"
                              y2="208"
                              fill="none"
                              stroke="rgb(63, 208, 212)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="16"
                            ></line>
                            <rect
                              x="42"
                              y="160"
                              width="56"
                              height="48"
                              strokeWidth="16"
                              stroke="rgb(63, 208, 212)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill={reserve.difficulty >= 1 ? "true" : "none"}
                            ></rect>
                            <rect
                              x="100"
                              y="140"
                              width="56"
                              height="68"
                              strokeWidth="16"
                              stroke="rgb(63, 208, 212)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill={reserve.difficulty >= 2 ? "true" : "none"}
                            ></rect>
                            <rect
                              x="158"
                              y="120"
                              width="56"
                              height="88"
                              strokeWidth="16"
                              stroke="rgb(63, 208, 212)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill={reserve.difficulty >= 3 ? "true" : "none"}
                            ></rect>
                            <rect
                              x="216"
                              y="100"
                              width="56"
                              height="108"
                              strokeWidth="16"
                              stroke="rgb(63, 208, 212)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill={reserve.difficulty >= 4 ? "true" : "none"}
                            ></rect>
                            <rect
                              x="274"
                              y="80"
                              width="56"
                              height="128"
                              strokeWidth="16"
                              stroke="rgb(63, 208, 212)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill={reserve.difficulty >= 5 ? "true" : "none"}
                            ></rect>
                          </svg>
                        </div>
                        <IonCol>
                          {reserve.difficulty}
                          /5
                        </IonCol>
                      </IonFooter>
                      <IonFooter style={{ fontWeight: "bold", color: "black" }}>
                        {checkProperty(reserve.nameText, language)}
                      </IonFooter>
                      <IonFooter>
                        {moment(
                          reserve.dates.filter((d: any) =>
                            d.id.includes(reservationsData[i].adventureDatesId)
                          )[0].begin,
                          "YYYY-MM-DD"
                        ).format("DD.MM.")}{" "}
                        -{" "}
                        {moment(
                          reserve.dates.filter((d: any) =>
                            d.id.includes(reservationsData[i].adventureDatesId)
                          )[0].end,
                          "YYYY-MM-DD"
                        ).format("DD.MM.YYYY")}{" "}
                        • {getTripLength(reserve)} •{" "}
                        {setEnumText(reservationsData[i].status, lang)}
                      </IonFooter>
                    </IonCardContent>
                  </IonCard>
                );
              } else
                return (
                  <div
                    key={i}
                    className="text-center"
                    style={{ width: "100%", marginTop: "15px" }}
                  >
                    <IonText className="text-center">{lang.label282}.</IonText>
                  </div>
                );
            })
          )}
        </div>
      </IonContent>

      {/* <IonModal
        isOpen={showModalPart}
        cssClass="part-modal"
        onDidDismiss={() => setShowModalPart(false)}
      >
        <IonText>
          {lang.label196} {partnerName}.
        </IonText>
      </IonModal> */}
    </IonPage>
  );
};

export default Reservations;
