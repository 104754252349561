import {
  IonPage,
  IonHeader,
  IonBackButton,
  IonButtons,
  IonToolbar,
  IonContent,
  IonText,
  IonItem,
  IonImg,
  IonTitle,
  IonButton,
} from "@ionic/react";

import "./Tab1.css";

import React from "react";
import AuthContext from "../my-context";
import Header from "./header";
import becomeatrav from "../assets/images/becomeatrav.jpg";
import { useGlobal } from "reactn";
import FooterPageMap from "./FooterPageMap";

const Becomeatraveler: React.FC = () => {
  const { lang, logout } = React.useContext(AuthContext);

  const [, setShowCreateAcc] = useGlobal("showCreateAcc");
  const [, setShowGuestModal] = useGlobal("showGuestModal");
  const [appWidth] = useGlobal("appWidth");
  const [showLoginModal, setShowLoginModal] = useGlobal("showLoginModal");
  const [, setShowLoginPage] = useGlobal("showLoginPage");

  return (
    <IonPage id="main">
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar /* className="back-butt" */>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tab1"
              text={lang.label465}
              className="back-button"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="web-box">
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <IonItem className="text-item no-border">
              <IonText className="legal-text">{lang.label466}</IonText>
            </IonItem>
            <IonImg src={becomeatrav}></IonImg>
            <IonTitle className="text-item" style={{ padding: 0 }}>
              {lang.label467}
            </IonTitle>
            <IonText style={{ display: "block" }}>{lang.label468}</IonText>
            <IonText style={{ display: "block" }}>{lang.label469}</IonText>
            <IonText style={{ display: "block" }}>{lang.label470}</IonText>
            <div
              style={{ width: "100%", marginTop: "10px" }}
              className="text-center"
            >
              <IonButton
                onClick={() => {
                  if (appWidth > 920) {
                    setShowLoginPage(false);
                    setShowCreateAcc(false);
                    setShowLoginModal(true);
                  } else {
                    setShowGuestModal(false);
                    logout();
                  }
                  /* setShowCreateAcc(true);
                  setShowGuestModal(false);
                  logout(); */
                }}
                className="footer-button"
              >
                {lang.label471}
              </IonButton>
            </div>
          </div>
        </div>
        <FooterPageMap />
      </IonContent>
    </IonPage>
  );
};

export default Becomeatraveler;
