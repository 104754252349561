import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonItem,
  IonButtons,
  IonLabel,
  IonTextarea,
  IonInput,
  IonList,
  IonReorderGroup,
  IonReorder,
  IonToast,
  IonImg,
  IonRow,
  IonCol,
  IonGrid,
  IonSpinner,
  IonLoading,
  IonText,
  IonThumbnail,
} from "@ionic/react";

import { nanoid } from "nanoid";

import { ItemReorderEventDetail } from "@ionic/core";

import { Camera, CameraDirection, CameraResultType } from "@capacitor/camera";

import { ArrowLeft, Image, XCircle } from "phosphor-react";

import AuthContext from "../../my-context";

import "../Tab1.css";

import "../create.css";

import React, { useEffect, useGlobal } from "reactn";

import { CaretCircleRight, MinusCircle, PlusCircle } from "phosphor-react";
import { useRef, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import Header from "../header";

const CreateTrip4: React.FC = () => {
  const { token, lang, saveNewAdventure, checkImageCache, db } =
    React.useContext(AuthContext);
  const isFirstRender = useRef(true);
  const isThirdRender = useRef(false);

  const history = useHistory();

  const [newAdventure, setNewAdventure] = useGlobal("newAdventure");

  const [newAdventureLang] = useGlobal("newAdventureLang");
  const [newAdventureLangCurrent, setNewAdventureLangCurrent] = useGlobal(
    "newAdventureLangCurrent"
  );
  const [appWidth] = useGlobal("appWidth");

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [showToast2, setShowToast2] = useState(false);
  const [toastMessage2, setToastMessage2] = useState("");

  const [uploading, setUploading] = useState(false);

  const [itinerary, setItinerary] = useState<any>([
    {
      titleText: { CS: "", EN: "" },
      descriptionText: { CS: "", EN: "" },
      images: [],
      id: "",
    },
  ]);

  const [reorder, setReorder] = useState(true);

  useEffect(() => {
    let helpX = JSON.parse(JSON.stringify(newAdventure));
    helpX.itinerary.map((obj: any) => (obj.id = nanoid()));
    setItinerary(helpX.itinerary);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      saveNewAdventure();
    }, 120000);
    // clearing interval
    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (isFirstRender.current === true) {
      isFirstRender.current = false;
      return;
    }
    /* if (isSecondRender.current === true) {
      isSecondRender.current = false;
      return;
    } */
    if (isThirdRender.current === true) {
      return;
    }
    let helpX = JSON.parse(JSON.stringify(itinerary));
    Promise.all(
      helpX.map(async (itiner: any, index: number) => {
        if (itiner.images.length === 0) return;
        await Promise.all(
          itiner.images.map(async (image: string) => {
            if (image.substring(0, 4) !== "data") {
              if (image) {
                if (await checkImageCache(image)) {
                  image = await db.get(image);
                  return image;
                } else {
                  const getImageData = async () => {
                    const api = axios.create({
                      baseURL: `https://app.kangelo.com/`,
                    });
                    var config = {
                      headers: {
                        "X-Auth-Token": token,
                      },
                    };

                    return api
                      .get("/media/" + image, config)
                      .then(async (res) => {
                        await db.set(image, res.data.data);
                        image = await res.data.data;
                      })
                      .catch((err) => {
                        // what now?
                        console.log(err.data);
                      });
                  };
                  await getImageData();
                  return image;
                }
              } else {
                return "";
              }
            }
          })
        ).then((images) => {
          itiner.images = images;
        });
      })
    ).then(() => {
      isThirdRender.current = true;
      setItinerary(helpX);
    });

    /* setItinerary(helpX); */
  }, [itinerary]);

  /* helpX.map((obj: any) => (obj.id = nanoid())); */

  const handleItiChange = (index: number, event: any) => {
    let arrIti = [...itinerary];
    let arrNewAd = { ...newAdventure };
    switch (event.target.name) {
      case "title":
        arrIti[index].titleText[newAdventureLangCurrent] = event.target.value;
        arrNewAd.itinerary[index].titleText[newAdventureLangCurrent] =
          event.target.value;
        break;
      case "desc":
        arrIti[index].descriptionText[newAdventureLangCurrent] =
          event.target.value;
        arrNewAd.itinerary[index].descriptionText[newAdventureLangCurrent] =
          event.target.value;
        break;
      default:
        break;
    }

    /* arrNewAd.itinerary.forEach(function (v: any) {
      delete v.id;
    }); */
    setNewAdventure(arrNewAd);
    setItinerary(arrIti);
  };

  const addDate = () => {
    let arrDate = JSON.parse(JSON.stringify(newAdventure));
    arrDate.itinerary.push({
      titleText: { CS: "", EN: "" },
      descriptionText: { CS: "", EN: "" },
      images: [],
    });
    let arrIty = [...itinerary];
    arrIty.push({
      titleText: { CS: "", EN: "" },
      descriptionText: { CS: "", EN: "" },
      images: [],
      id: nanoid(),
    });
    setNewAdventure(arrDate);
    setItinerary(arrIty);
  };

  const remDate = (index: number) => {
    let arrDate = { ...newAdventure };
    arrDate.itinerary.splice(index, 1);
    let arrIty = [...itinerary];
    arrIty.splice(index, 1);
    setNewAdventure(arrDate);
    setItinerary(arrIty);
  };

  const remImage = (index: number, indexis: number) => {
    let adventureTemp = JSON.parse(JSON.stringify(newAdventure));
    let itineraryTemp = [...itinerary];
    adventureTemp.itinerary[index].images.splice(indexis, 1);
    /* if (adventureTemp.itinerary[index].images.length === 0) {
      adventureTemp.itinerary[index].images.push("");
    } */
    itineraryTemp[index].images.splice(indexis, 1);
    /* if (itineraryTemp[index].images.length === 0) {
      itineraryTemp[index].images.push("");
    } */
    setNewAdventure(adventureTemp);
    setItinerary(itineraryTemp);
  };

  const moved = (array: any, from: number, to: number, on = 1) => {
    return (
      (array = array.slice()),
      array.splice(to, 0, ...array.splice(from, on)),
      array
    );
  };

  function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
    event.detail.complete();
    let arrHelp = [...itinerary];
    arrHelp = moved(arrHelp, event.detail.from, event.detail.to);
    arrHelp.forEach((element) => (element.id = nanoid()));

    let newAdhelp = JSON.parse(JSON.stringify(newAdventure));

    newAdhelp.itinerary = JSON.parse(JSON.stringify(arrHelp));
    newAdhelp.itinerary.forEach(function (v: any) {
      delete v.id;
    });
    setItinerary(arrHelp);
    setNewAdventure(newAdhelp);
  }

  const handlePictureClick = async (index: number) => {
    const image = await takePicture();
    if (image) {
      setUploading(true);
      uploadImage(image, index);
    }
  };

  async function uploadImage(img: string, index: number) {
    let data = {
      type: "IMAGE",
      data: img,
    };
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = {
      headers: {
        "X-Auth-Token": token,
      },
    };
    api
      .post("/media", data, config)
      .then(async (res) => {
        await db.set(res.data.id, img);

        let mediaDataTemp = [...itinerary];

        mediaDataTemp[index].images.push(img);

        setItinerary(mediaDataTemp);

        let newAdventureTemp = { ...newAdventure };
        if (newAdventureTemp.itinerary[index].images[0] === "") {
          newAdventureTemp.itinerary[index].images[0] = res.data.id;
        } else {
          newAdventureTemp.itinerary[index].images.push(res.data.id);
        }

        setNewAdventure(newAdventureTemp);
        setUploading(false);
      })
      .catch((err) => {
        // what now?
        console.log(err.data);
      });
  }

  return (
    <IonPage>
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar /* className="back-butt" */>
          <IonButtons
            slot="start"
            style={{
              width: "100%",
              justifyContent: "space-between",
              marginInlineEnd: "4px",
            }}
          >
            {/* <IonBackButton
              defaultHref="tab1"
              text={lang.label409}
              className="back-button"
            /> */}
            <IonButton
              onClick={async () => {
                await saveNewAdventure();
                history.goBack();
              }}
              className="modal-search-back"
            >
              <ArrowLeft size={24} color="black" />
              <IonLabel className="page-title">{lang.label409}</IonLabel>
            </IonButton>
            <IonButton
              className="reorder-butt"
              onClick={() => {
                setReorder((state) => !state);
              }}
            >
              {!reorder && lang.label167}
              {reorder && lang.label271}
            </IonButton>
          </IonButtons>
          {newAdventureLang.length > 1 && (
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setNewAdventureLangCurrent(
                    newAdventureLangCurrent === "CS" ? "EN" : "CS"
                  );
                  setToastMessage2(
                    newAdventureLangCurrent === "CS"
                      ? lang.label426
                      : lang.label425
                  );
                  setShowToast2(true);
                }}
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                  width: "45px",
                }}
              >
                {newAdventureLangCurrent === "CS" ? "CZ" : "ENG"}
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="web-box">
          <IonList>
            <IonReorderGroup disabled={reorder} onIonItemReorder={doReorder}>
              {itinerary &&
                itinerary.map((oneItinerary: any, index: number) => {
                  return (
                    <IonReorder key={oneItinerary?.id}>
                      <div style={{ position: "relative" }}>
                        {index > 0 && (
                          <div
                            style={{
                              position: "absolute",
                              right: "36px",
                              zIndex: 1000,
                            }}
                          >
                            <IonText
                              style={{
                                fontSize: "12px",
                                textDecoration: "underline",
                              }}
                              onClick={() => remDate(index)}
                            >
                              {lang.label376}
                            </IonText>
                            {/* <MinusCircle
                            size={34}
                            onClick={() => remDate(index)}
                          /> */}
                          </div>
                        )}
                        <IonLabel
                          className="user-info-label"
                          style={{ marginTop: "10px !important" }}
                        >
                          {lang.label250} {lang.label389} {index + 1} {" *"}
                        </IonLabel>
                        <IonItem className="user-info-item item-input-grey">
                          <IonInput
                            value={
                              oneItinerary.titleText[newAdventureLangCurrent]
                            }
                            name="title"
                            placeholder={lang.label410}
                            inputmode="text"
                            maxlength={100}
                            onIonChange={(event) =>
                              handleItiChange(index, event)
                            }
                            className="user-info-input"
                            style={{ fontSize: "14px" }}
                          ></IonInput>
                        </IonItem>
                        {/* {index > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        right: "10%",
                        top: "0%",
                        zIndex: 1000,
                      }}
                    >
                      <MinusCircle size={34} onClick={() => remDate(index)} />
                    </div>
                  )} */}
                        <IonItem className="user-info-item item-input-grey">
                          <IonTextarea
                            value={
                              oneItinerary.descriptionText[
                                newAdventureLangCurrent
                              ]
                            }
                            disabled={
                              oneItinerary.titleText[
                                newAdventureLangCurrent
                              ] === ""
                            }
                            name="desc"
                            autoGrow={true}
                            placeholder={lang.label411}
                            inputmode="text"
                            maxlength={1200}
                            onIonChange={(event) =>
                              handleItiChange(index, event)
                            }
                            className="create-info-input"
                            style={{ fontSize: "14px" }}
                          ></IonTextarea>
                        </IonItem>
                        <IonItem
                          className="user-info-item item-input-grey last-itinerary"
                          style={{ marginBottom: "10px" }}
                        >
                          {oneItinerary.images.length === 0 ? (
                            <div style={{ marginTop: "0px", width: "100%" }}>
                              <IonButton
                                style={{
                                  display: "inline-block",
                                  height: "100px",
                                  width: "100%",
                                  margin: "0 0 20px 0",
                                  "--background": "#ebebeb",
                                  "--border-radius": "10px",
                                  "--box-shadow": "none",
                                  color: "#3fd0d4",
                                }}
                                onClick={() => handlePictureClick(index)}
                              >
                                <IonGrid>
                                  <IonRow>
                                    <IonCol>
                                      <IonRow
                                        style={{
                                          justifyContent: "center",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <Image
                                          size={35}
                                          style={{ width: "80%" }}
                                        />
                                      </IonRow>
                                      <IonRow
                                        style={{ justifyContent: "center" }}
                                      >
                                        <IonText
                                          style={{ textTransform: "initial" }}
                                        >
                                          {lang.label333}
                                        </IonText>
                                      </IonRow>
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              </IonButton>
                            </div>
                          ) : (
                            <IonGrid
                              style={{
                                padding: 0,
                                width: "100%",
                                marginTop: "10px",
                              }}
                            >
                              {oneItinerary.images.map(
                                (img: any, indexis: number) => {
                                  return (
                                    <IonRow key={indexis}>
                                      <IonCol
                                        style={{ padding: 0, width: "100%" }}
                                      >
                                        {img.substring(0, 4) === "data" ? (
                                          <div>
                                            <IonThumbnail
                                              style={{
                                                "--border-radius": "20px",
                                                height: "auto",
                                                width: "100%",
                                              }}
                                            >
                                              <IonImg
                                                src={img}
                                                alt={
                                                  oneItinerary.titleText[
                                                    newAdventureLangCurrent
                                                  ]
                                                }
                                              ></IonImg>
                                            </IonThumbnail>
                                            <XCircle
                                              size={35}
                                              color="#d21e1e"
                                              style={{
                                                position: "absolute",
                                                top: "5px",
                                                right: "5px",
                                              }}
                                              onClick={() =>
                                                remImage(index, indexis)
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <IonSpinner
                                            className="spinner-loading"
                                            color="primary"
                                          />
                                        )}
                                      </IonCol>
                                    </IonRow>
                                  );
                                }
                              )}
                              <IonRow
                                style={{ height: "35px", paddingTop: "8px" }}
                              >
                                <IonText
                                  onClick={() => handlePictureClick(index)}
                                  style={{
                                    fontSize: "12px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {lang.label374}
                                </IonText>
                              </IonRow>
                            </IonGrid>
                          )}
                        </IonItem>
                      </div>
                    </IonReorder>
                  );
                })}
            </IonReorderGroup>
            <IonButton
              style={{ marginLeft: "36px", width: "14em" }}
              onClick={() => addDate()}
            >
              <PlusCircle
                size={30}
                className="add-date"
                style={{ paddingTop: "7px" }}
              />
              <IonLabel style={{ textTransform: "Initial" }}>
                {lang.label375}
              </IonLabel>
            </IonButton>
          </IonList>
        </div>
      </IonContent>
      <IonToolbar slot="bottom" className="text-center safe-zone web-box">
        <IonButton
          className="footer-button"
          onClick={async () => {
            let check = false;
            if (newAdventureLang.length > 1) {
              newAdventure.itinerary.forEach((it: any, i: number) => {
                if (it.titleText.CS === "" || it.titleText.EN === "") {
                  check = true;
                }
              });
              if (
                newAdventure.itinerary[0].titleText.CS === "" ||
                newAdventure.itinerary[0].titleText.EN === ""
              ) {
                setToastMessage(lang.label272);
                setShowToast(true);
              } else if (check) {
                setToastMessage(lang.label412);
                setShowToast(true);
              } else {
                await saveNewAdventure();
                history.push("/create/trip/5");
              }
            } else {
              newAdventure.itinerary.forEach((it: any, i: number) => {
                if (it.titleText[newAdventureLangCurrent] === "") {
                  check = true;
                }
              });
              if (
                newAdventure.itinerary[0].titleText[newAdventureLangCurrent] ===
                ""
              ) {
                setToastMessage(lang.label272);
                setShowToast(true);
              } else if (check) {
                setToastMessage(lang.label233);
                setShowToast(true);
              } else {
                await saveNewAdventure();
                history.push("/create/trip/5");
              }
            }
          }}
        >
          <IonLabel style={{ textTransform: "initial" }} color="white">
            {lang.label408}
          </IonLabel>
        </IonButton>
      </IonToolbar>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={1200}
        color="danger"
        cssClass="toast-pass-change"
      />
      <IonToast
        isOpen={showToast2}
        onDidDismiss={() => setShowToast2(false)}
        message={toastMessage2}
        duration={2000}
        color="success"
        cssClass="toast-pass-change"
      />
      <IonLoading
        cssClass="loading-spinner"
        isOpen={uploading}
        onDidDismiss={() => setUploading(false)}
        message={lang.label222}
      />
    </IonPage>
  );
};

async function takePicture() {
  const image = await Camera.getPhoto({
    quality: 75,
    width: 2048,
    direction: CameraDirection.Front,
    resultType: CameraResultType.DataUrl,
  });
  return image.dataUrl;
}

export default CreateTrip4;
