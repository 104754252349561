import {
  IonPage,
  IonHeader,
  IonBackButton,
  IonButtons,
  IonToolbar,
  IonContent,
  IonText,
  IonItem,
} from "@ionic/react";

import "./Tab1.css";

import React, { useEffect, useState } from "react";
import AuthContext from "../my-context";
import axios from "axios";
import Header from "./header";
import { useGlobal } from "reactn";
import FooterPageMap from "./FooterPageMap";

const Termsandconditions: React.FC = () => {
  const { lang, token } = React.useContext(AuthContext);

  const [termsData, setTermsData] = useState("");
  const [appWidth] = useGlobal("appWidth");
  const [language] = useGlobal("language");

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  async function fetchData() {
    const api = axios.create({
      baseURL: `https://app.kangelo.com/`,
    });
    var config = token
      ? {
          headers: {
            "Accept-Language": language,
            "X-Auth-Token": token,
          },
        }
      : { headers: { "Accept-Language": language } };
    api
      .get("/legal/terms", config)
      .then((resp) => {
        setTermsData(resp.data.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }

  return (
    <IonPage id="main">
      {appWidth > 920 && <Header styles="shadow-box" />}
      <IonHeader className="ud-header bottom-border web-box">
        <IonToolbar /* className="back-butt" */>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/tab1"
              text={lang.label457}
              className="back-button"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="web-box">
          <IonItem className="legal-item">
            <IonText className="legal-text">
              <div
                style={{
                  width: "100%",
                  overflow: "visible",
                  marginBlockStart: "0 important",
                  marginBlockEnd: "0 important",
                }}
                className="legal-div"
                dangerouslySetInnerHTML={{ __html: termsData }}
              />
            </IonText>
          </IonItem>
        </div>
        <FooterPageMap />
      </IonContent>
    </IonPage>
  );
};

export default Termsandconditions;
