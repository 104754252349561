import "./footerpagemap.css";

import AuthContext from "../my-context";

import React from "react";
import { useHistory } from "react-router";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import "swiper/swiper.scss"; // core Swiper
/* import "swiper/scss/navigation"; */ // Navigation module
import "swiper//modules/pagination/pagination.scss"; // Pagination module
import {
  IonCol,
  IonGrid,
  IonImg,
  IonRow,
  IonText,
  IonTitle,
} from "@ionic/react";
import { useGlobal } from "reactn";
import {
  FacebookLogo,
  InstagramLogo,
  SpotifyLogo,
  YoutubeLogo,
} from "phosphor-react";
import logo from "../assets/images/kangelo_logo_web.png";

const FooterPageMap: React.FC = () => {
  const history = useHistory();
  const { lang, authValues } = React.useContext(AuthContext);
  const [showGuestModal, setShowGuestModal] = useGlobal("showGuestModal");

  return (
    <div className="footer-swiper" style={{ marginTop: "15px" }}>
      <div className="web-box" style={{ width: "100vw" }}>
        <Swiper slidesPerView={2} spaceBetween={30} className="footer-swiper">
          <SwiperSlide style={{ height: "auto" }}>
            <IonGrid style={{ padding: "15px 5px 15px 10vw", height: "100%" }}>
              <IonRow>
                <IonCol style={{ textAlign: "left" }}>
                  <IonTitle
                    style={{
                      padding: 0,
                      fontSize: "14px",
                      marginBottom: "1em",
                    }}
                  >
                    {lang.label435}
                  </IonTitle>
                  {/* <IonText className="footer-links">{lang.label2}</IonText> */}
                  {!authValues.user.guide && (
                    <IonText
                      className="footer-links"
                      style={{ marginBottom: "1em" }}
                      onClick={() => {
                        authValues.user.id === "guest"
                          ? setShowGuestModal(true)
                          : history.push("/becomeaguide/");
                      }}
                    >
                      {lang.label438}
                    </IonText>
                  )}
                  {authValues.user.guide && (
                    <IonText
                      className="footer-links"
                      style={{ marginBottom: "1em" }}
                      onClick={() => {
                        return history.push("/create/trip/0");
                      }}
                    >
                      {lang.label235}
                    </IonText>
                  )}
                  <IonText
                    className="footer-links"
                    onClick={() => {
                      authValues.user.id === "guest"
                        ? setShowGuestModal(true)
                        : history.push("/travellist");
                    }}
                  >
                    {lang.label443}
                  </IonText>
                  <IonText
                    className="footer-links"
                    onClick={() => {
                      authValues.user.id === "guest"
                        ? setShowGuestModal(true)
                        : history.push("/reservations");
                    }}
                  >
                    {lang.label37}
                  </IonText>
                  <IonText
                    className="footer-links"
                    onClick={() => {
                      history.push("/personaldataprotection");
                    }}
                  >
                    {lang.label441}
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          </SwiperSlide>
          {/* <SwiperSlide style={{ height: "auto" }}>
        <IonGrid style={{ padding: "15px 5px 15px 10vw", height: "100%" }}>
          <IonRow>
            <IonCol style={{ textAlign: "left" }}>
              <IonTitle
                style={{ padding: 0, fontSize: "14px", marginBottom: "1em" }}
              >
                {lang.label436}
              </IonTitle>
              <IonText className="footer-links">{lang.label21}</IonText>
              <IonText className="footer-links" style={{ marginBottom: "1em" }}>
                {lang.label22}
              </IonText>
              <IonText className="footer-links">{lang.label439}</IonText>
              <IonText className="footer-links">{lang.label440}</IonText>
              <IonText className="footer-links">{lang.label441}</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </SwiperSlide> */}
          <SwiperSlide style={{ height: "auto" }}>
            <IonGrid style={{ padding: "15px 5px 15px 10vw", height: "100%" }}>
              <IonRow>
                <IonCol style={{ textAlign: "left" }}>
                  <IonTitle
                    style={{
                      padding: 0,
                      fontSize: "14px",
                      marginBottom: "1em",
                    }}
                  >
                    {lang.label437}
                  </IonTitle>
                  <IonText
                    className="footer-links"
                    onClick={() => {
                      authValues.user.id === "guest"
                        ? setShowGuestModal(true)
                        : history.push("/contact/");
                    }}
                  >
                    {lang.label45}
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          </SwiperSlide>
        </Swiper>
        <IonGrid
          style={{
            marginLeft: "15px",
            marginRight: "15px",
            borderTop: "1px solid white",
          }}
        >
          <IonRow>
            <IonCol style={{ maxWidth: "122px" }}>
              <IonImg
                className="hover-anime"
                onClick={() => history.push("/tab1")}
                style={{ height: "36px" }}
                src={logo}
              ></IonImg>
            </IonCol>
            <IonCol style={{ alignSelf: "center", textAlign: "center" }}>
              © 2022 Kangelo Media s.r.o.
            </IonCol>
            <IonCol style={{ maxWidth: "175px" }}>
              <FacebookLogo
                className="hover-anime"
                size={32}
                onClick={() =>
                  window.open("https://www.facebook.com/KangeloClub", "_blank")
                }
              />
              <InstagramLogo
                className="hover-anime"
                size={32}
                style={{ marginLeft: "10px" }}
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/kangeloclub/",
                    "_blank"
                  )
                }
              />
              <YoutubeLogo
                className="hover-anime"
                size={32}
                style={{ marginLeft: "10px" }}
                onClick={() =>
                  window.open("https://www.youtube.com/kangelo", "_blank")
                }
              />
              <SpotifyLogo
                className="hover-anime"
                size={32}
                style={{ marginLeft: "10px" }}
                onClick={() =>
                  window.open(
                    "https://open.spotify.com/show/1N45pv8kGyB8pR3grq8lqk?si=118b359d59904c00",
                    "_blank"
                  )
                }
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default FooterPageMap;
