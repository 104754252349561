import {
  IonButton,
  IonTitle,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
} from "@ionic/react";
/* import './Tab1.css'; */

import React from "react";

import AuthContext from "../../my-context";

import {
  ForkKnife,
  House,
  Airplane,
  AirplaneTakeoff,
  AirplaneLanding,
  Car,
  CarSimple,
  ChartBar,
} from "phosphor-react";

import moment from "moment";
import setEnumText from "../../functions/setEnumText";
import { useGlobal } from "reactn";
import checkProperty from "../../functions/checkProperty";

interface Props {
  advData: any;
  pickDate(item: any): any;
}

const AdvDefault: React.FC<Props> = ({ advData, pickDate }: Props) => {
  const { lang } = React.useContext(AuthContext);

  const [language] = useGlobal("language");

  function diffOpt() {
    switch (advData.difficulty) {
      case 1:
        return lang.label242;
      case 2:
        return lang.label243;
      case 3:
        return lang.label244;
      case 4:
        return lang.label245;
      case 5:
        return lang.label246;
      default:
        return "Unknown";
    }
  }

  return (
    <div style={{ paddingBottom: "15px" }}>
      <IonTitle
        className="text-bold"
        style={{ fontSize: "16px", paddingTop: "10px" }}
      >
        {lang.label232}
      </IonTitle>
      {/*       <IonGrid
        style={{ paddingLeft: "20px", paddingRight: "20px", fontSize: "15px" }}
      >
        {advData.dates.map((date: any, i: number) => {
          return (
            <IonRow key={`${i}`}>
              <IonCol
                size="6"
                style={{
                  lineHeight: "42px",
                  display: "grid",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 0,
                }}
              >
                {date.begin.substring(0, 3) === date.end.substring(0, 3)
                  ? moment(date.begin, "YYYY-MM-DD").format("D.M.")
                  : moment(date.begin, "YYYY-MM-DD").format("D.M.YYYY")}{" "}
                - {moment(date.end, "YYYY-MM-DD").format("D.M.YYYY")}
              </IonCol>
              <IonCol
                style={{
                  lineHeight: "42px",
                  display: "grid",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 0,
                }}
              >
                {date.prices.CZK.toLocaleString(
                  language === "cs" ? "cs-CZ" : "en-GB"
                )}{" "}
                Kč
              </IonCol>
              <IonCol
                style={{
                  lineHeight: "42px",
                  display: "grid",
                  alignItems: "center",
                  padding: 0,
                }}
              >
                <IonButton
                  onClick={() => {
                    pickDate(date);
                  }}
                  className="dates-button"
                >
                  {lang.label276}
                </IonButton>
              </IonCol>
            </IonRow>
          );
        })}
      </IonGrid> */}
      <IonGrid style={{ paddingLeft: "20px" }}>
        <IonRow>
          <IonCol size="1.5">
            <ForkKnife size={24} color="#3FD0D4" />
          </IonCol>
          <IonCol>{lang.label210}</IonCol>
          <IonCol>{setEnumText(advData.meals + "m", lang)}</IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="1.5">
            <House size={24} color="#3FD0D4" />
          </IonCol>
          <IonCol>{lang.label209}</IonCol>
          <IonCol>{setEnumText(advData.accommodation, lang)}</IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="1.5">
            <Airplane size={24} color="#3FD0D4" />
          </IonCol>
          <IonCol>{lang.label206}</IonCol>
          <IonCol>{setEnumText(advData.transit, lang)}</IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="1.5">
            {advData.transit === "AIRPLANE" ? (
              <AirplaneTakeoff size={24} color="#3FD0D4" />
            ) : (
              <Car size={24} color="#3FD0D4" />
            )}
          </IonCol>
          <IonCol>
            {advData.transit === "AIRPLANE" ? lang.label129 : lang.label128}
          </IonCol>
          <IonCol>
            {advData.transit === "AIRPLANE"
              ? advData.departure.airport
              : advData.departure.city}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="1.5">
            {advData.transit === "AIRPLANE" ? (
              <AirplaneLanding size={24} color="#3FD0D4" />
            ) : (
              <CarSimple size={24} color="#3FD0D4" />
            )}
          </IonCol>
          <IonCol>
            {advData.transit === "AIRPLANE" ? lang.label131 : lang.label130}
          </IonCol>
          <IonCol>
            {advData.transit === "AIRPLANE"
              ? advData.arrival.airport
              : advData.arrival.city}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="1.5">
            <ChartBar size={24} color="#3FD0D4" />
          </IonCol>
          <IonCol>{lang.label211}</IonCol>
          <IonCol>
            {advData.difficulty}/5 - {diffOpt()}
          </IonCol>
        </IonRow>
      </IonGrid>
      {checkProperty(advData.includedText, language) !== "" && (
        <div>
          <IonTitle
            className="text-bold"
            style={{ fontSize: "16px", paddingTop: "10px" }}
          >
            {lang.label277}
          </IonTitle>
          <IonText
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "16px",
              display: "block",
              whiteSpace: "pre-line",
            }}
          >
            {checkProperty(advData.includedText, language)}
          </IonText>
        </div>
      )}
      {checkProperty(advData.notIncludedText, language) !== "" && (
        <div>
          <IonTitle
            className="text-bold"
            style={{ fontSize: "16px", paddingTop: "10px" }}
          >
            {lang.label278}
          </IonTitle>
          <IonText
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "16px",
              display: "block",
              whiteSpace: "pre-line",
            }}
          >
            {checkProperty(advData.notIncludedText, language)}
          </IonText>
        </div>
      )}
      {checkProperty(advData.accommodationText, language) !== "" && (
        <div>
          <IonTitle
            className="text-bold"
            style={{ fontSize: "16px", paddingTop: "10px" }}
          >
            {lang.label146}
          </IonTitle>
          <IonText
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "16px",
              display: "block",
              whiteSpace: "pre-line",
            }}
          >
            {checkProperty(advData.accommodationText, language)}
          </IonText>
        </div>
      )}
      {checkProperty(advData.mealsText, language) !== "" && (
        <div>
          <IonTitle
            className="text-bold"
            style={{ fontSize: "16px", paddingTop: "10px" }}
          >
            {lang.label210}
          </IonTitle>
          <IonText
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "16px",
              display: "block",
              whiteSpace: "pre-line",
            }}
          >
            {checkProperty(advData.mealsText, language)}
          </IonText>
        </div>
      )}
      {checkProperty(advData.transitText, language) !== "" && (
        <div>
          <IonTitle
            className="text-bold"
            style={{ fontSize: "16px", paddingTop: "10px" }}
          >
            {lang.label206}
          </IonTitle>
          <IonText
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "16px",
              display: "block",
              whiteSpace: "pre-line",
            }}
          >
            {checkProperty(advData.transitText, language)}
          </IonText>
        </div>
      )}
      {checkProperty(advData.difficultyText, language) !== "" && (
        <div>
          <IonTitle
            className="text-bold"
            style={{ fontSize: "16px", paddingTop: "10px" }}
          >
            {lang.label211}
          </IonTitle>
          <IonText
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "16px",
              display: "block",
              whiteSpace: "pre-line",
            }}
          >
            {checkProperty(advData.difficultyText, language)}
          </IonText>
        </div>
      )}
      {checkProperty(advData.moreInfoText, language) !== "" && (
        <div>
          <IonTitle
            className="text-bold"
            style={{ fontSize: "16px", paddingTop: "10px" }}
          >
            {lang.label249}
          </IonTitle>
          <IonText
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              fontSize: "16px",
              display: "block",
              whiteSpace: "pre-line",
            }}
          >
            {checkProperty(advData.moreInfoText, language)}
          </IonText>
        </div>
      )}
    </div>
  );
};

export default AdvDefault;
